import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Nav, NavItem, NavBrand, Navbar } from 'quantux-ui';
import qtxLogoSmall from '../../imgs/qtx_logo_small.svg';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingRight: '24px'
  },
  navbarBrandImage: {
    minHeight: '24px'
  }
});

const NavbarDemo = () => {
  return (
    <div className={css(styles.container)}>
      <Navbar className='qtxui-navbar fixed-top'>
        <NavBrand>
          <div className="fx">
            <img
              alt="Quantux Logo Small"
              src={qtxLogoSmall}
              className={css(styles.navbarBrandImage)}
            />
          </div>
        </NavBrand>
        <Nav>
          <NavItem>
            <i className={`fa fa-bars ${css(styles.navIcons)}`} />
          </NavItem>
          <NavItem>
            <i className={`fa fa-search ${css(styles.navIcons)}`} />
          </NavItem>
          <NavItem>
            <i className={`fa fa-user-circle ${css(styles.navIcons)}`} />
          </NavItem>
        </Nav>
      </Navbar>
    </div>
  );
};

export default NavbarDemo;
