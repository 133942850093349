import React, { Component } from 'react';
import { Link, Switch, Route, withRouter } from 'react-router-dom';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { css, StyleSheet } from 'aphrodite/no-important';
import debounce from 'lodash.debounce';
import { clone } from 'ramda';
import { connect } from 'react-redux';
import {
  DialogBox,
  Button,
  Loader,
  Drawer,
  PopupMenu,
  FileUploader,
  TextInputWithLabel,
  TabsContainer,
  TabItem,
  ThemeContext
} from 'quantux-ui';
import { updateBanner, updateAvatar, updateProfile, addProvider } from '../../actions';
import About from '../about';
import Contact from '../contact';
import { ROOT_URL, validateUserName } from '../../utils';

const styles = StyleSheet.create({
  detailIconContainer: {
    width: '40px'
  },
  detailIcon: {
    fontSize: '24px'
  },
  popupMenuContainer: {
    background: '#083050',
    width: '150px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'
  },
  menuItem: {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#5080B0'
  },
  menuItemIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    width: '40px'
  },
  menuItemIcon: {
    fontSize: '20px'
  },
  profileTextArea: {
    background: '#081018',
    color: '#60A0E0',
    width: '360px',
    height: '72px',
    borderColor: '#103A60',
    '::placeholder': {
      color: '#183C5A'
    }
  },
  profileBanner: {
    borderBottom: '1px solid transparent'
  },
  bigUserCardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid transparent'
  },
  tabs: {
    // borderBottom: '1px solid red'
  },
  tabText: {
    display: 'flex',
    minHeight: '40px',
    minWidth: '90px',
    alignItems: 'center',
    marginLeft: '-34px',
    paddingLeft: '34px'
  }
});

class AboutProfile extends Component {
  state = {
    isBannerDialogOpen: false,
    base64Img: '',
    bannerUrl: 'https://s3.amazonaws.com/quantumtrader/DG0J3YZQSkvi1nFiYd9oe.png',
    avatarUrl: '',
    showCropper: false,
    isUploadingFile: false,
    showAvatarCropper: false,
    isUploadingAvatarFile: false,
    isEditProfileDialogOpen: false,
    isPopupMenuOpen: false,
    showBannerCropperDrawer: false,
    showAvatarCropperDrawer: false,
    showAddProviderDrawer: false,
    _avatarImageUrl: '',
    _bannerImageUrl: '',
    _displayName: '',
    _isDisplayNameValid: false,
    _isDisplayNamePristine: true,
    _email: '',
    _mailingAddress: {},
    _mobile: '',
    _organization: '',
    _phone: '',
    _userName: '',
    _isUserNameValid: false,
    _isUserNamePristine: true,
    _isUserNameDuplicate: false,
    _fax: '',
    _profileDescription: '',
    activeTab: 'about',
    providerNpi: '',
    providerFirstName: '',
    providerMiddleName: '',
    providerLastName: '',
    providerMailingAddressOrg: '',
    providerMailingAddressStreet: '',
    providerMailingAddressSuite: '',
    providerMailingAddressCity: '',
    providerMailingAddressState: '',
    providerMailingAddressZip: '',
    providerEmail: '',
    providerMobile: '',
    providerPhone: '',
    providerFax: ''
  };

  // Delete 0 refs
  handleOnCrop = () => {
    const imageElement = this.cropperRef.current;
    const { cropper } = imageElement;
    console.log(cropper.getCroppedCanvas().toDataURL());

    cropper.getCroppedCanvas().toBlob((blob) => {
      debugger; // eslint-disable-line
      const url = URL.createObjectURL(blob);
      this.bannerRef.current.src = url;
    });

    this.setState({
      base64Img: cropper.getCroppedCanvas().toDataURL()
    });
  }

  uploadBanner = () => {
    const formData = new FormData();
    const img = this.bannerFileInputRef.current.files[0];
    formData.append('file', img);

    const requestOptions = {
      method: 'POST',
      body: formData,
      redirect: 'follow'
    };

    fetch(`${ROOT_URL}/api/v1/users/uploadImg`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        console.log(res);
        if (res.success) {
          this.setState({
            bannerUrl: res.fileUrl,
            isUploadingFile: false,
            showCropper: true
          });
        }
      })
      .catch(error => console.log('error', error));
  };

  uploadAvatar = () => {
    const formData = new FormData();
    const img = this.avatarFileInputRef.current.files[0];
    formData.append('file', img);

    const requestOptions = {
      method: 'POST',
      body: formData,
      redirect: 'follow'
    };

    fetch(`${ROOT_URL}/api/v1/users/uploadImg`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        console.log(res);
        if (res.success) {
          this.setState({
            avatarUrl: res.fileUrl,
            isUploadingAvatarFile: false,
            showAvatarCropper: true
          });
        }
      })
      .catch(error => console.log('error', error));
  };

  handleOnFileSelected = () => {
    if (this.bannerFileInputRef.current.files.length > 0) {
      console.log('Selected file = ', this.bannerFileInputRef.current.files[0]);
      console.log('typeof img', typeof this.bannerFileInputRef.current.files[0]);
      // this.setState({
      //   isInFileSelectMode: false,
      //   isInCropMode: false,
      //   isFileUploading: true,
      // });
      this.setState({
        isUploadingFile: true
      });
      this.uploadBanner();
    } else {
      console.log('No file is selected.');
    }
  };

  handleOnAvatarSelected = () => {
    if (this.avatarFileInputRef.current.files.length > 0) {
      console.log('Selected file = ', this.avatarFileInputRef.current.files[0]);
      console.log('typeof img', typeof this.avatarFileInputRef.current.files[0]);
      // this.setState({
      //   isInFileSelectMode: false,
      //   isInCropMode: false,
      //   isFileUploading: true,
      // });
      this.setState({
        isUploadingAvatarFile: true
      });
      this.uploadAvatar();
    } else {
      console.log('No file is selected.');
    }
  };

  handleOnClickCrop = () => {
    const imageElement = this.cropperRef.current;
    const { cropper } = imageElement;

    cropper.getCroppedCanvas().toBlob((blob) => {
      const url = URL.createObjectURL(blob);
      // console.log('url', url);
      // this.bannerRef.current.src = url;
      this.drawerBannerRef.current.src = url;

      this.props.updateBanner(blob);

      // const formData = new FormData();
      // formData.append('file', blob, 'example.png');

      // const requestOptions = {
      //   method: 'POST',
      //   body: formData,
      //   redirect: 'follow'
      // };

      // fetch(`${ROOT_URL}/users/uploadImg`, requestOptions)
      // .then(response => response.text())
      // .then(result => {
      //   const res = JSON.parse(result);
      //   console.log(res);
      //   if (res.success) {
      //     this.setState({
      //       bannerUrl: res.fileUrl
      //     });
      //   }
      // })
      // .catch(error => console.log('error', error));

      this.setState({
        // base64Img: cropper.getCroppedCanvas().toDataURL(),
        isBannerDialogOpen: false
      });
    });
  };

  handleOnClickAvatarCrop = () => {
    const imageElement = this.avatarCropperRef.current;
    const { cropper } = imageElement;
    cropper.getCroppedCanvas().toBlob((blob) => {
      const url = URL.createObjectURL(blob);
      this.drawerAvatarRef.current.src = url;
      this.props.updateAvatar(blob);
      this.setState({
        isBannerDialogOpen: false
      });
    });
  };

  handleOnFileDrop = (img) => {
    const formData = new FormData();
    formData.append('file', img);

    const requestOptions = {
      method: 'POST',
      body: formData,
      redirect: 'follow'
    };

    this.setState({ isUploadingFile: true });

    fetch(`${ROOT_URL}/api/v1/users/uploadImg`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        console.log(res);
        if (res.success) {
          this.setState({
            bannerUrl: res.fileUrl,
            isUploadingFile: false,
            showCropper: true
          });
        }
      })
      .catch(error => console.log('error', error));
  };

  handleOnAvatarFileDrop = (img) => {
    const formData = new FormData();
    formData.append('file', img);

    const requestOptions = {
      method: 'POST',
      body: formData,
      redirect: 'follow'
    };

    this.setState({ isUploadingAvatarFile: true });

    fetch(`${ROOT_URL}/api/v1/users/uploadImg`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        console.log(res);
        if (res.success) {
          this.setState({
            avatarUrl: res.fileUrl,
            isUploadingAvatarFile: false,
            showAvatarCropper: true
          });
        }
      })
      .catch(error => console.log('error', error));
  };

  toggleEditProfileDialog = (value) => {
    if (value) {
      this.setState({
        _avatarImageUrl: this.props.usr.avatarImageUrl,
        _bannerImageUrl: this.props.usr.bannerImageUrl,
        _displayName: this.props.usr.displayName,
        _email: this.props.usr.email,
        _mailingAddress: clone(this.props.usr.mailingAddress),
        _mobile: this.props.usr.mobile,
        _organization: this.props.usr.organization,
        _phone: this.props.usr.phone,
        _userName: this.props.usr.userName,
        _fax: this.props.usr.fax,
        _profileDescription: this.props.usr.profileDescription,
        isEditProfileDialogOpen: value
      });
    } else {
      this.setState({
        showCropper: false,
        isUploadingFile: false,
        showAvatarCropper: false,
        isUploadingAvatarFile: false,
        isEditProfileDialogOpen: false,
        isPopupMenuOpen: false,
        showBannerCropperDrawer: false,
        showAvatarCropperDrawer: false,
        _avatarImageUrl: '',
        _bannerImageUrl: '',
        _displayName: '',
        _isDisplayNameValid: false,
        _isDisplayNamePristine: true,
        _email: '',
        _mailingAddress: {},
        _mobile: '',
        _organization: '',
        _phone: '',
        _userName: '',
        _isUserNameValid: false,
        _isUserNamePristine: true,
        _isUserNameDuplicate: false,
        _fax: ''
      });
    }
  };

  toggleAddProviderDrawer = (value) => {
    this.setState({
      showAddProviderDrawer: false
    });
  };

  handleOnChangeOrganizationName = (e) => {
    const { value } = e.target;
    this.setState({
      _organization: value
    });
  };

  validateDisplayName = () => {
    this.setState({
      _isDisplayNameValid: this.state._displayName.length > 2
    });
  };

  handleOnChangeDisplayName = (e) => {
    const { value } = e.target;
    this.setState(() => ({
      _displayName: value,
      _isDisplayNamePristine: false
    }), this.validateDisplayName);
  }

  isDuplicateUserName = () => {
    const requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`${ROOT_URL}/api/v1/users/exists/username/${this.state._userName}`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const data = JSON.parse(result);
        console.log(this.state._userName, data.isDuplicate);
        this.setState({
          _isUserNameDuplicate: data.isDuplicate && this.state._userName !== this.props.usr.userName
        });
      })
      .catch(error => console.log('error', error));
  }

  isDuplicateUserNameDebounced = debounce(this.isDuplicateUserName, 300);

  validateUsername = () => {
    this.setState(() => {
      return { _isUserNameValid: validateUserName(this.state._userName) };
    }, this.isDuplicateUserNameDebounced);
  };

  handleOnChangeUsername = (e) => {
    const { value } = e.target;
    this.setState(() => ({
      _userName: value,
      _isUserNamePristine: false
    }), this.validateUsername);
  };

  handleOnChangeStreetAddress = (e) => {
    const { value } = e.target;
    const _mailingAddress = clone(this.state._mailingAddress);
    _mailingAddress.street = value;
    this.setState({ _mailingAddress });
  };

  handleOnChangeSuite = (e) => {
    const { value } = e.target;
    const _mailingAddress = clone(this.state._mailingAddress);
    _mailingAddress.suite = value;
    this.setState({ _mailingAddress });
  };

  handleOnChangeCity = (e) => {
    const { value } = e.target;
    const _mailingAddress = clone(this.state._mailingAddress);
    _mailingAddress.city = value;
    this.setState({ _mailingAddress });
  };

  handleOnChangeState = (e) => {
    const { value } = e.target;
    const _mailingAddress = clone(this.state._mailingAddress);
    _mailingAddress.state = value;
    this.setState({ _mailingAddress });
  };

  handleOnChangeZip = (e) => {
    const { value } = e.target;
    const _mailingAddress = clone(this.state._mailingAddress);
    _mailingAddress.zip = value;
    this.setState({ _mailingAddress });
  };

  handleOnChangePhone = (e) => {
    const { value } = e.target;
    this.setState({
      _phone: value
    });
  };

  handleOnChangeFax = (e) => {
    const { value } = e.target;
    this.setState({
      _fax: value
    });
  };

  handleOnChangeProfileDescription = (e) => {
    const { value } = e.target;
    this.setState({
      _profileDescription: value
    });
  };

  togglePopupMenu = (value) => {
    this.setState({
      isPopupMenuOpen: value
    });
  };

  toggleBannerCropperDrawer = (value) => {
    this.setState({
      showBannerCropperDrawer: value
    });
  };

  toggleAvatarCropperDrawer = (value) => {
    this.setState({
      showAvatarCropperDrawer: value
    });
  };

  handleOnClickSaveChanges = () => {
    this.props.updateProfile({
      displayName: this.state._displayName,
      userName: this.state._userName,
      organization: this.state._organization,
      street: this.state._mailingAddress.street,
      suite: this.state._mailingAddress.suite,
      city: this.state._mailingAddress.city,
      state: this.state._mailingAddress.state,
      zip: this.state._mailingAddress.zip,
      phone: this.state._phone,
      fax: this.state._fax,
      profileDescription: this.state._profileDescription
    });
    this.setState({
      showCropper: false,
      isUploadingFile: false,
      showAvatarCropper: false,
      isUploadingAvatarFile: false,
      isEditProfileDialogOpen: false,
      isPopupMenuOpen: false,
      showBannerCropperDrawer: false,
      showAvatarCropperDrawer: false,
      _avatarImageUrl: '',
      _bannerImageUrl: '',
      _displayName: '',
      _isDisplayNameValid: false,
      _isDisplayNamePristine: true,
      _email: '',
      _mailingAddress: {},
      _mobile: '',
      _organization: '',
      _phone: '',
      _userName: '',
      _isUserNameValid: false,
      _isUserNamePristine: true,
      _fax: '',
      _profileDescription: ''
    });
  };

  handleOnClickToggleProviderDrawer = () => {
    this.setState({ showAddProviderDrawer: !this.state.showAddProviderDrawer });
  };

  // #region Provider Data Handlers

  handleOnChangeProviderNpi = (e) => {
    const { value } = e.target;
    this.setState({ providerNpi: value });
  };

  handleOnChangeProviderFirstName = (e) => {
    const { value } = e.target;
    this.setState({ providerFirstName: value });
  };

  handleOnChangeProviderMiddleName = (e) => {
    const { value } = e.target;
    this.setState({ providerMiddleName: value });
  };

  handleOnChangeProviderLastName = (e) => {
    const { value } = e.target;
    this.setState({ providerLastName: value });
  };

  handleOnChangeProviderMailingAddressOrg = (e) => {
    const { value } = e.target;
    this.setState({ providerMailingAddressOrg: value });
  };

  handleOnChangeProviderMailingAddressStreet = (e) => {
    const { value } = e.target;
    this.setState({ providerMailingAddressStreet: value });
  };

  handleOnChangeProviderMailingAddressSuite = (e) => {
    const { value } = e.target;
    this.setState({ providerMailingAddressSuite: value });
  };

  handleOnChangeProviderMailingAddressCity = (e) => {
    const { value } = e.target;
    this.setState({ providerMailingAddressCity: value });
  };

  handleOnChangeProviderMailingAddressState = (e) => {
    const { value } = e.target;
    this.setState({ providerMailingAddressState: value });
  };

  handleOnChangeProviderMailingAddressZip = (e) => {
    const { value } = e.target;
    this.setState({ providerMailingAddressZip: value });
  };

  handleOnChangeProviderEmail = (e) => {
    const { value } = e.target;
    this.setState({ providerEmail: value });
  };

  handleOnChangeProviderMobile = (e) => {
    const { value } = e.target;
    this.setState({ providerMobile: value });
  };

  handleOnChangeProviderPhone = (e) => {
    const { value } = e.target;
    this.setState({ providerPhone: value });
  };

  handleOnChangeProviderFax = (e) => {
    const { value } = e.target;
    this.setState({ providerFax: value });
  };

  handleOnClickAddProvider = () => {
    this.props.addProvider({
      npi: this.state.providerNpi,
      firstName: this.state.providerFirstName,
      middleName: this.state.providerMiddleName,
      lastName: this.state.providerLastName,
      mailingAddressOrgName: this.state.providerMailingAddressOrg,
      mailingAddressStreet: this.state.providerMailingAddressStreet,
      mailingAddressSuite: this.state.providerMailingAddressSuite,
      mailingAddressCity: this.state.providerMailingAddressCity,
      mailingAddressState: this.state.providerMailingAddressState,
      mailingAddressZip: this.state.providerMailingAddressZip,
      email: this.state.providerEmail,
      mobile: this.state.providerMobile,
      phone: this.state.providerPhone,
      fax: this.state.providerFax
    });
    location.reload();
  };

  // #endregion

  // Context and refs
  static contextType = ThemeContext;
  cropperRef = React.createRef();
  avatarCropperRef = React.createRef();
  fileInput = React.createRef();
  bannerFileInputRef = React.createRef();
  avatarFileInputRef = React.createRef();
  bannerRef = React.createRef();
  drawerBannerRef = React.createRef();
  drawerAvatarRef = React.createRef();
  popupRef = React.createRef();

  render () {
    const { url, path } = this.props.match;
    const { gray, blue } = this.context.colors;
    const lStyles = StyleSheet.create({
      linkStyles: {
        color: gray[300],
        ':visited': {
          color: gray[300]
        }
      },
      selectedLink: {
        color: blue[500]
      }
    });
    const {
      showCropper,
      isUploadingFile,
      isEditProfileDialogOpen,
      isPopupMenuOpen,
      showBannerCropperDrawer,
      showAvatarCropperDrawer,
      showAddProviderDrawer,
      _isDisplayNameValid,
      _isDisplayNamePristine,
      _isUserNameValid,
      _isUserNamePristine,
      _isUserNameDuplicate,
      showAvatarCropper,
      isUploadingAvatarFile,
      isBannerDialogOpen
    } = this.state;
    const {
      // avatarImageUrl,
      // bannerImageUrl,
      displayName,
      email,
      mailingAddress = {},
      mobile,
      organization,
      phone,
      userName,
      fax,
      profileDescription
    } = this.props.usr;

    return (
      <div>
        {/* Banner upload dialog */}
        {isBannerDialogOpen
          ? <DialogBox
              initWidth={window.innerWidth <= 576 ? (window.innerWidth - 16) : window.innerWidth}
              top={window.innerWidth <= 576 ? 48 : null}
              left={window.innerWidth <= 576 ? 8 : null}
              initHeight={window.innerHeight}
              isOpen={this.state.isBannerDialogOpen}
              onClose={() => { this.setState({ isBannerDialogOpen: false }); }}
              maskBackground="#004080"
              maskOpacity={0.5}
              closeOnEscKey
              header={{
                text: 'Upload Banner Image',
                backColor: '#082840',
                foreColor: '#5080B0',
                closeColor: '#FF6060',
                fontStyle: 'bold',
                fontSize: '20px',
                icon: ''
              }}
            >
              <div className="p2" style={{ position: 'relative', maxHeight: '454px' }}>
              {showCropper
                ? <div>
                    <Cropper
                      src={this.state.bannerUrl}
                      style={{
                        height: 400,
                        width: '100%'
                      }}
                      checkCrossOrigin
                      checkOrientation={false}
                      // initialAspectRatio={1.77}
                      aspectRatio={6}
                      background={false}
                      viewMode={1}
                      autoCropArea={1}
                      guides={false}
                      ref={this.cropperRef}
                    />
                    {/* Get crop button */}
                    <div
                      className="mt-2 mb-2 pl-2"
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center'
                      }}
                    >
                      <Button btnType="primary" onClick={this.handleOnClickCrop}>
                        Crop Image
                      </Button>
                    </div>
                  </div>
                : null
              }

              {!showCropper
                ? <div>
                    <FileUploader onDrop={this.handleOnFileDrop} />
                  </div>
                : null
              }

              {isUploadingFile
                ? <div style={{ position: 'relative', height: '454px', top: '-400px', background: 'black' }}>
                    <Loader iconType="cog" iconSize="5x" text="Uploading Image" />
                  </div>
                : null
              }
            </div>
            </DialogBox>
          : null
        }

        {/* Profile Banner */}
        <div className={css(styles.profileBanner)}>
          <img
            ref={this.bannerRef}
            src={this.props.usr.bannerImageUrl}
            alt="cropped-banner"
            style={{
              width: '100%',
              height: 'auto',
              maxHeight: '220px'
            }}
          />
        </div>

        {/* Edit profile drawer */}
        {isEditProfileDialogOpen
          ? <Drawer
              placement="right"
              visible={isEditProfileDialogOpen}
              toggleDrawer={() => this.toggleEditProfileDialog(false)}
              zIndex="2000"
              width={window.innerWidth <= 700 ? window.innerWidth : 500}
            >
              {/* Drawer Container */}
              <div
                style={{
                  color: '#60A0E0',
                  minHeight: '700px',
                  position: 'relative'
                }}
              >
                {/* Header */}
                <div
                  style={{
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '8px',
                    fontWeight: 'bold'
                  }}
                >
                  Edit Profile
                </div>
                {/* Banner */}
                <div
                  style={{
                    position: 'relative'
                  }}
                >
                  <img
                    ref={this.drawerBannerRef}
                    src={this.state._bannerImageUrl}
                    alt="cropped-banner"
                    style={{
                      width: '100%',
                      height: 'auto',
                      maxHeight: '220px'
                    }}
                  />
                  {/* Camera icon */}
                  <div
                    onClick={() => { this.toggleBannerCropperDrawer(true); }}
                    className="fx fx__hv-center"
                    style={{
                      background: 'black',
                      color: 'white',
                      position: 'absolute',
                      right: '3px',
                      top: '58px',
                      fontSize: '11px',
                      width: '24px',
                      height: '24px',
                      cursor: 'pointer',
                      borderRadius: '50%'
                    }}
                  >
                    <i className="fa fa-camera" />
                  </div>
                </div>
                {/* Middle area */}
                <div
                  style={{
                    height: 'calc(100vh - 170px)',
                    overflowY: 'auto'
                  }}
                >
                  {/* User card */}
                  <div className="fx mt-2 ml-2">
                    {/* Avatar */}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '80px',
                        height: '80px',
                        background: '#0080FF',
                        color: 'white',
                        borderRadius: '4px',
                        position: 'relative'
                      }}
                    >
                      <img
                        src={this.state._avatarImageUrl}
                        ref={this.drawerAvatarRef}
                        alt="avatar"
                        style={{
                          width: 'auto',
                          height: '80px',
                          maxHeight: '80px'
                        }}
                      />
                      {/* <i
                        className="fa fa-building-o"
                        style={{ fontSize: '70px' }}
                      /> */}
                      <div
                        onClick={() => { this.toggleAvatarCropperDrawer(true); }}
                        className="fx fx__hv-center"
                        style={{
                          background: 'black',
                          color: 'white',
                          position: 'absolute',
                          left: '-3px',
                          top: '58px',
                          fontSize: '11px',
                          width: '24px',
                          height: '24px',
                          cursor: 'pointer',
                          borderRadius: '50%'
                        }}
                      >
                        <i className="fa fa-camera" />
                      </div>
                    </div>
                    <div
                      className="ml-2"
                      style={{ marginTop: '-8px' }}
                    >
                      {/* Display Name */}
                      <div>
                        <TextInputWithLabel
                          value={this.state._displayName}
                          titleText="Display Name (minimum 2 characters)"
                          isCompactLayout={true}
                          titleColor="#60A0E0"
                          textColor="#0080FF"
                          selectionColor="white"
                          selectionBackground="#0080FF"
                          spellcheck={false}
                          width={268}
                          onChange={this.handleOnChangeDisplayName}
                        />
                        {/* Error message */}
                        {!_isDisplayNameValid && !_isDisplayNamePristine
                          ? <div
                              style={{
                                color: '#FF6060',
                                fontSize: '12px'
                              }}
                            >
                              Invalid Display Name
                            </div>
                          : null
                        }
                      </div>
                      {/* UserName */}
                      <div
                        style={{
                          marginTop: (!_isDisplayNameValid && !_isDisplayNamePristine) ? '8px' : '4px'
                        }}
                      >
                        <TextInputWithLabel
                          value={this.state._userName}
                          titleText="Username (2 to 20 characters, no spaces)"
                          isCompactLayout={true}
                          titleColor="#60A0E0"
                          textColor="#0080FF"
                          selectionColor="white"
                          selectionBackground="#0080FF"
                          spellcheck={false}
                          width={268}
                          onChange={this.handleOnChangeUsername}
                        />
                        {/* Error message */}
                        {!_isUserNameValid && !_isUserNamePristine
                          ? <div
                              style={{
                                color: '#FF6060',
                                fontSize: '12px'
                              }}
                            >
                              Invalid User Name
                            </div>
                          : null
                        }
                        {!_isUserNamePristine && _isUserNameDuplicate
                          ? <div
                              style={{
                                color: '#FF6060',
                                fontSize: '12px'
                              }}
                            >
                              User Name Taken
                            </div>
                          : null
                        }
                      </div>
                    </div>
                  </div>
                  {/* Mailing Address */}
                  <div className="mt-4 ml-2">
                    {/* Heading */}
                    <div className="fx fx__v-center">
                      <i className="fa fa-map-marker" style={{ fontSize: '20px' }} />
                      <div className="ml-2">
                        Mailing Address
                      </div>
                    </div>
                    {/* Form */}
                    <div className="mt-2">
                      {/* Organization */}
                      <div>
                        <TextInputWithLabel
                          value={this.state._organization}
                          titleText="Organization"
                          isCompactLayout={true}
                          titleColor="#60A0E0"
                          textColor="white"
                          selectionColor="white"
                          selectionBackground="#0080FF"
                          spellcheck={false}
                          width={337}
                          onChange={this.handleOnChangeOrganizationName}
                        />
                      </div>
                      {/* Address */}
                      <div className="fx mt-4">
                        <div>
                          <TextInputWithLabel
                            value={this.state._mailingAddress.streetAddress1}
                            titleText="Street"
                            isCompactLayout={true}
                            titleColor="#60A0E0"
                            textColor="white"
                            selectionColor="white"
                            selectionBackground="#0080FF"
                            spellcheck={false}
                            width={284}
                            onChange={this.handleOnChangeStreetAddress}
                          />
                        </div>
                        <div className="ml-2">
                          <TextInputWithLabel
                            value={this.state._mailingAddress.suite}
                            titleText="Suite"
                            isCompactLayout={true}
                            titleColor="#60A0E0"
                            textColor="white"
                            selectionColor="white"
                            selectionBackground="#0080FF"
                            spellcheck={false}
                            width={45}
                            onChange={this.handleOnChangeSuite}
                          />
                        </div>
                      </div>
                      {/* City, State and Zip */}
                      <div className="fx mt-4">
                        <div>
                          <TextInputWithLabel
                            value={this.state._mailingAddress.city}
                            titleText="City"
                            isCompactLayout={true}
                            titleColor="#60A0E0"
                            textColor="white"
                            selectionColor="white"
                            selectionBackground="#0080FF"
                            spellcheck={false}
                            width={230}
                            onChange={this.handleOnChangeCity}
                          />
                        </div>
                        <div className="ml-2">
                          <TextInputWithLabel
                            value={this.state._mailingAddress.state}
                            titleText="State"
                            isCompactLayout={true}
                            titleColor="#60A0E0"
                            textColor="white"
                            selectionColor="white"
                            selectionBackground="#0080FF"
                            spellcheck={false}
                            width={45}
                            onChange={this.handleOnChangeState}
                          />
                        </div>
                        <div className="ml-2">
                          <TextInputWithLabel
                            value={this.state._mailingAddress.zip}
                            titleText="Zip"
                            isCompactLayout={true}
                            titleColor="#60A0E0"
                            textColor="white"
                            selectionColor="white"
                            selectionBackground="#0080FF"
                            spellcheck={false}
                            width={45}
                            onChange={this.handleOnChangeZip}
                          />
                        </div>
                      </div>
                      {/* Phone */}
                      <div className="mt-4">
                        <TextInputWithLabel
                          value={this.state._phone}
                          titleText="Phone"
                          isCompactLayout={true}
                          titleColor="#60A0E0"
                          textColor="white"
                          selectionColor="white"
                          selectionBackground="#0080FF"
                          spellcheck={false}
                          width={337}
                          onChange={this.handleOnChangePhone}
                        />
                      </div>
                      {/* Fax */}
                      <div className="mt-4">
                        <TextInputWithLabel
                          value={this.state._fax}
                          titleText="Fax"
                          isCompactLayout={true}
                          titleColor="#60A0E0"
                          textColor="white"
                          selectionColor="white"
                          selectionBackground="#0080FF"
                          spellcheck={false}
                          width={337}
                          onChange={this.handleOnChangeFax}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Message */}
                  <div className="mt-4 ml-2">
                    Mobile # and Email are found in the “Log In Credentials”. To edit them, go to “Settings” and click
                    on “Log In Credentials” tab.
                  </div>
                  {/* Profile descriptiom */}
                  <div className="mt-4 ml-2">
                    <div>Profile Description</div>
                    <div>
                      <textarea
                        className={css(styles.profileTextArea)}
                        placeholder="Write a description"
                        value={this.state._profileDescription}
                        onChange={this.handleOnChangeProfileDescription}
                      />
                    </div>
                  </div>
                </div>
                {/* Footer */}
                <div
                  className="fx pr-2"
                  style={{
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    height: '40px',
                    width: '100%',
                    borderTop: '1px solid #103A60',
                    position: 'absolute',
                    top: 'calc(100vh - 40px)'
                  }}
                >
                  <Button
                    btnType="primary"
                    isOutlineBtn
                    className="fx fx__hv-center mr-2"
                    style={{
                      height: '32px',
                      width: '89px',
                      padding: '3px 0'
                    }}
                    onClick={() => { this.toggleEditProfileDialog(false); }}
                  >
                    Cancel
                  </Button>

                  <Button
                    btnType="primary"
                    className="fx fx__hv-center"
                    onClick={this.handleOnClickSaveChanges}
                    style={{
                      height: '32px',
                      width: '140px',
                      padding: '3px 0'
                    }}
                  >
                    Save Changes
                  </Button>
                </div>
              </div>
            </Drawer>
          : null
        }

        {/* Profile Banner Image cropper Drawer */}
        {showBannerCropperDrawer
          ? <Drawer
              placement="right"
              visible={showBannerCropperDrawer}
              toggleDrawer={() => this.toggleBannerCropperDrawer(false)}
              zIndex="2000"
              width={window.innerWidth <= 700 ? window.innerWidth : 500}
            >
              {/* Drawer Container */}
              <div
                style={{
                  color: '#60A0E0',
                  minHeight: '700px',
                  position: 'relative'
                }}
              >
                {/* Header */}
                <div
                  className="pl-2 pr-2"
                  style={{
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '8px',
                    fontWeight: 'bold',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid #103A60'
                  }}
                >
                  <div>Upload Profile Banner</div>
                  <i
                    className="fa fa-times-circle"
                    onClick={() => {
                      this.toggleBannerCropperDrawer(false);
                      this.setState({
                        showCropper: false,
                        isUploadingFile: false
                      });
                    }}
                  />
                </div>
                {/* Cropper component with file upload */}
                <div className="p2" style={{ position: 'relative', maxHeight: '454px' }}>
                  {showCropper
                    ? <div>
                        <Cropper
                          src={this.state.bannerUrl}
                          style={{
                            height: 400,
                            width: '100%'
                          }}
                          checkCrossOrigin
                          checkOrientation={false}
                          // initialAspectRatio={1.77}
                          aspectRatio={6}
                          background={false}
                          viewMode={1}
                          autoCropArea={1}
                          guides={false}
                          ref={this.cropperRef}
                        />
                      </div>
                    : null
                  }

                  {!showCropper
                    ? <div>
                        <FileUploader
                          parentRef={this.bannerFileInputRef}
                          onDrop={this.handleOnFileDrop}
                          onChange={this.handleOnFileSelected}
                        />
                      </div>
                    : null
                  }

                  {isUploadingFile
                    ? <div style={{ position: 'relative', height: '454px', top: '-400px', background: 'black' }}>
                        <Loader iconType="cog" iconSize="5x" text="Uploading Image" />
                      </div>
                    : null
                  }
                </div>
                {/* Footer */}
                <div
                  className="fx pr-2"
                  style={{
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    height: '40px',
                    width: '100%',
                    borderTop: '1px solid #103A60',
                    position: 'absolute',
                    top: 'calc(100vh - 40px)'
                  }}
                >
                  <Button
                    btnType="primary"
                    isOutlineBtn
                    className="fx fx__hv-center mr-2"
                    style={{
                      height: '32px',
                      width: '89px',
                      padding: '2px 0'
                    }}
                    onClick={() => {
                      this.toggleBannerCropperDrawer(false);
                      this.setState({
                        showCropper: false,
                        isUploadingFile: false
                      });
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    btnType="primary"
                    className="fx fx__hv-center"
                    onClick={ () => {
                      this.handleOnClickCrop();
                      this.toggleBannerCropperDrawer(false);
                    }}
                    style={{
                      height: '32px',
                      width: '140px',
                      padding: '2px 0'
                    }}
                  >
                    Crop Image
                  </Button>
                </div>
              </div>
            </Drawer>
          : null
        }

        {/* Avatar Image cropper Drawer */}
        {showAvatarCropperDrawer
          ? <Drawer
              placement="right"
              visible={showAvatarCropperDrawer}
              toggleDrawer={() => this.toggleAvatarCropperDrawer(false)}
              zIndex="2000"
              width={window.innerWidth <= 700 ? window.innerWidth : 500}
            >
              {/* Drawer Container */}
              <div
                style={{
                  color: '#60A0E0',
                  minHeight: '700px',
                  position: 'relative'
                }}
              >
                {/* Header */}
                <div
                  className="pl-2 pr-2"
                  style={{
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: '8px',
                    fontWeight: 'bold',
                    justifyContent: 'space-between',
                    borderBottom: '1px solid #103A60'
                  }}
                >
                  <div>Upload Avatar</div>
                  <i
                    className="fa fa-times-circle"
                    onClick={() => {
                      this.toggleAvatarCropperDrawer(false);
                      this.setState({
                        showAvatarCropper: false,
                        isUploadingAvatarFile: false
                      });
                    }}
                  />
                </div>
                {/* Cropper component with file upload */}
                <div className="p2" style={{ position: 'relative', maxHeight: '454px' }}>
                  {showAvatarCropper
                    ? <div>
                        <Cropper
                          src={this.state.avatarUrl}
                          style={{
                            height: 400,
                            width: '100%'
                          }}
                          checkCrossOrigin
                          checkOrientation={false}
                          // initialAspectRatio={1.77}
                          aspectRatio={1}
                          background={false}
                          viewMode={1}
                          autoCropArea={1}
                          guides={false}
                          ref={this.avatarCropperRef}
                        />
                      </div>
                    : null
                  }

                  {!showAvatarCropper
                    ? <div>
                        <FileUploader
                          parentRef={this.avatarFileInputRef}
                          onDrop={this.handleOnAvatarFileDrop}
                          onChange={this.handleOnAvatarSelected}
                        />
                      </div>
                    : null
                  }

                  {isUploadingAvatarFile
                    ? <div style={{ position: 'relative', height: '454px', top: '-400px', background: 'black' }}>
                        <Loader iconType="cog" iconSize="5x" text="Uploading Image" />
                      </div>
                    : null
                  }
                </div>
                {/* Footer */}
                <div
                  className="fx pr-2"
                  style={{
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    height: '40px',
                    width: '100%',
                    borderTop: '1px solid #103A60',
                    position: 'absolute',
                    top: 'calc(100vh - 40px)'
                  }}
                >
                  <Button
                    btnType="primary"
                    isOutlineBtn
                    className="fx fx__hv-center mr-2"
                    style={{
                      height: '32px',
                      width: '89px',
                      padding: '2px 0'
                    }}
                    onClick={() => {
                      this.toggleAvatarCropperDrawer(false);
                      this.setState({
                        showAvatarCropper: false,
                        isUploadingAvatarFile: false
                      });
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    btnType="primary"
                    className="fx fx__hv-center"
                    onClick={ () => {
                      this.handleOnClickAvatarCrop();
                      this.toggleAvatarCropperDrawer(false);
                    }}
                    style={{
                      height: '32px',
                      width: '140px',
                      padding: '2px 0'
                    }}
                  >
                    Crop Image
                  </Button>
                </div>
              </div>
            </Drawer>
          : null
        }

        {/* Add provider drawer */}
        {showAddProviderDrawer
          ? <Drawer
              placement="right"
              visible={showAddProviderDrawer}
              toggleDrawer={() => this.toggleAddProviderDrawer(false)}
              zIndex="2000"
              width={window.innerWidth <= 700 ? window.innerWidth : 500}
            >
              {/* Drawer Container */}
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'column',
                  color: '#60A0E0',
                  height: '100%',
                  position: 'relative'
                }}
              >
                {/* Header */}
                <div
                  style={{
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0 8px',
                    fontWeight: 'bold',
                    borderBottom: '1px solid #0a3250',
                    flexShrink: '0'
                  }}
                >
                  <div>
                    Create New Provider Profile
                  </div>
                  <div>
                    <i
                      className='fa fa-times-circle' style={{ fontSize: '20px' }}
                    />
                  </div>
                </div>
                {/* Middle Section */}
                <div
                  className="pl-2 pr-2"
                  style={{
                    flexGrow: '1',
                    // minHeight: '700px',
                    overflowX: 'hidden',
                    overflowY: 'auto'
                  }}
                >
                  {/* NPI */}
                  <div>
                    <TextInputWithLabel
                      value={this.state.providerNpi}
                      titleText="NPI #"
                      isCompactLayout={true}
                      titleColor="#60A0E0"
                      textColor="#0080FF"
                      selectionColor="white"
                      selectionBackground="#0080FF"
                      spellcheck={false}
                      width={480}
                      onChange={this.handleOnChangeProviderNpi}
                    />
                  </div>
                  {/* Firstname, middlename and lastname */}
                  <div
                    className='fx mt-4'
                    style={{ justifyContent: 'space-between' }}
                  >
                    {/* First name */}
                    <div>
                      <TextInputWithLabel
                        value={this.state.providerFirstName}
                        titleText="First Name"
                        isCompactLayout={true}
                        titleColor="#60A0E0"
                        textColor="#0080FF"
                        selectionColor="white"
                        selectionBackground="#0080FF"
                        spellcheck={false}
                        width={145}
                        onChange={this.handleOnChangeProviderFirstName}
                    />
                    </div>
                    {/* Middle name */}
                    <div>
                      <TextInputWithLabel
                        value={this.state.providerMiddleName}
                        titleText="Middle Name"
                        isCompactLayout={true}
                        titleColor="#60A0E0"
                        textColor="#0080FF"
                        selectionColor="white"
                        selectionBackground="#0080FF"
                        spellcheck={false}
                        width={145}
                        onChange={this.handleOnChangeProviderMiddleName}
                      />
                    </div>
                    {/* Last name */}
                    <div>
                        <TextInputWithLabel
                          value={this.state.providerLastName}
                          titleText="Last Name"
                          isCompactLayout={true}
                          titleColor="#60A0E0"
                          textColor="#0080FF"
                          selectionColor="white"
                          selectionBackground="#0080FF"
                          spellcheck={false}
                          width={145}
                          onChange={this.handleOnChangeProviderLastName}
                      />
                      </div>
                  </div>
                  {/* Mailing Address */}
                  <div className='mt-4'>
                    {/* Heading */}
                    <div className='fx'>
                      <i className='mr-2 fa fa-map-marker' style={{ fontSize: '24px' }} />
                      <div>Mailing Address</div>
                    </div>
                    {/* Organization */}
                    <div className='pl-2'>
                      <TextInputWithLabel
                        value={this.state.providerMailingAddressOrg}
                        titleText="Organization (If Applicable)"
                        isCompactLayout={true}
                        titleColor="#60A0E0"
                        textColor="#0080FF"
                        selectionColor="white"
                        selectionBackground="#0080FF"
                        spellcheck={false}
                        width={472}
                        onChange={this.handleOnChangeProviderMailingAddressOrg}
                      />
                    </div>
                    {/* Street & Suite */}
                    <div className='fx pl-2 mt-2' style={{ justifyContent: 'space-between' }}>
                      <div>
                        <TextInputWithLabel
                          value={this.state.providerMailingAddressStreet}
                          titleText="Street"
                          isCompactLayout={true}
                          titleColor="#60A0E0"
                          textColor="#0080FF"
                          selectionColor="white"
                          selectionBackground="#0080FF"
                          spellcheck={false}
                          width={400}
                          onChange={this.handleOnChangeProviderMailingAddressStreet}
                        />
                      </div>
                      <div>
                        <TextInputWithLabel
                          value={this.state.providerMailingAddressSuite}
                          titleText="Suite"
                          isCompactLayout={true}
                          titleColor="#60A0E0"
                          textColor="#0080FF"
                          selectionColor="white"
                          selectionBackground="#0080FF"
                          spellcheck={false}
                          width={55}
                          onChange={this.handleOnChangeProviderMailingAddressSuite}
                        />
                      </div>
                    </div>
                    {/* City, State and Zip */}
                    <div className='fx pl-2 mt-2' style={{ justifyContent: 'space-between' }}>
                      <div>
                        <TextInputWithLabel
                          value={this.state.providerMailingAddressCity}
                          titleText="City"
                          isCompactLayout={true}
                          titleColor="#60A0E0"
                          textColor="#0080FF"
                          selectionColor="white"
                          selectionBackground="#0080FF"
                          spellcheck={false}
                          width={322}
                          onChange={this.handleOnChangeProviderMailingAddressCity}
                        />
                      </div>
                      <div>
                        <TextInputWithLabel
                          value={this.state.providerMailingAddressState}
                          titleText="State"
                          isCompactLayout={true}
                          titleColor="#60A0E0"
                          textColor="#0080FF"
                          selectionColor="white"
                          selectionBackground="#0080FF"
                          spellcheck={false}
                          width={55}
                          onChange={this.handleOnChangeProviderMailingAddressState}
                        />
                      </div>
                      <div>
                        <TextInputWithLabel
                          value={this.state.providerMailingAddressZip}
                          titleText="Zip"
                          isCompactLayout={true}
                          titleColor="#60A0E0"
                          textColor="#0080FF"
                          selectionColor="white"
                          selectionBackground="#0080FF"
                          spellcheck={false}
                          width={55}
                          onChange={this.handleOnChangeProviderMailingAddressZip}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Email */}
                  <div className='mt-4'>
                    <TextInputWithLabel
                      value={this.state.providerEmail}
                      titleText="Email"
                      isCompactLayout={false}
                      showPreIcon
                      preIcon='fa fa-envelope'
                      titleColor="#60A0E0"
                      textColor="#0080FF"
                      selectionColor="white"
                      selectionBackground="#0080FF"
                      spellcheck={false}
                      width={482}
                      onChange={this.handleOnChangeProviderEmail}
                    />
                  </div>
                  {/* Mobile # */}
                  <div className='mt-4'>
                    <TextInputWithLabel
                      value={this.state.providerMobile}
                      titleText="Mobile #"
                      isCompactLayout={false}
                      showPreIcon
                      preIcon='fa fa-mobile'
                      titleColor="#60A0E0"
                      textColor="#0080FF"
                      selectionColor="white"
                      selectionBackground="#0080FF"
                      spellcheck={false}
                      width={482}
                      onChange={this.handleOnChangeProviderMobile}
                    />
                  </div>
                  {/* Phone # */}
                  <div className='mt-4'>
                    <TextInputWithLabel
                      value={this.state.providerPhone}
                      titleText="Phone #"
                      isCompactLayout={false}
                      showPreIcon
                      preIcon='fa fa-phone'
                      titleColor="#60A0E0"
                      textColor="#0080FF"
                      selectionColor="white"
                      selectionBackground="#0080FF"
                      spellcheck={false}
                      width={482}
                      onChange={this.handleOnChangeProviderPhone}
                    />
                  </div>
                  {/* Fax # */}
                  <div className='mt-4'>
                    <TextInputWithLabel
                      value={this.state.providerFax}
                      titleText="Fax #"
                      isCompactLayout={false}
                      showPreIcon
                      preIcon='fa fa-fax'
                      titleColor="#60A0E0"
                      textColor="#0080FF"
                      selectionColor="white"
                      selectionBackground="#0080FF"
                      spellcheck={false}
                      width={482}
                      onChange={this.handleOnChangeProviderFax}
                    />
                  </div>
                </div>
                {/* Footer */}
                <div className="p-2">
                  <Button
                    btnType='primary'
                    style={{ width: '100%' }}
                    onClick={this.handleOnClickAddProvider}
                  >
                    Add Provider
                  </Button>
                </div>
              </div>
            </Drawer>
          : null
        }

        {/* User data */}
        <div className="m-2">
          {/* User card Contianer */}
          <div className={`pb-2 ${css(styles.bigUserCardContainer)}`}>
            {/* User card */}
            <div className="fx">
              <div
                className="fx fx__hv-center"
                style={{
                  display: 'flex',
                  width: '80px',
                  height: '80px',
                  background: '#0080FF',
                  borderRadius: '4px'
                }}
              >
                {this.props.usr && this.props.usr.avatarImageUrl && this.props.usr.avatarImageUrl.length > 0
                  ? <img
                      src={this.props.usr.avatarImageUrl}
                      alt="avatar"
                      style={{
                        width: 'auto',
                        height: '80px',
                        maxHeight: '80px',
                        borderRadius: '4px'
                      }}
                    />
                  : <i className="fa fa-building-o" style={{ color: 'white', fontSize: '70px' }} />
                }
              </div>
              <div className="ml-2">
                <div>{displayName}</div>
                <div style={{ color: '#0080FF' }}>
                  {`@${userName}`}
                </div>
              </div>
            </div>
            {/* Ellipsis menu */}
            <div
              ref={this.popupRef}
              className="fx fx__hv-center"
              onClick={() => this.togglePopupMenu(true)}
              style={{
                background: '#60A0E0',
                height: '24px',
                width: '24px',
                borderRadius: '50%',
                color: '#001824',
                position: 'relative'
              }}
            >
              <i className="fa fa-ellipsis-v" />
              <PopupMenu
                top={28}
                right={0}
                visible={isPopupMenuOpen}
                parent={this.popupRef}
                position="absolute"
                onOutsideClick={() => { this.togglePopupMenu(false); }}
              >
                <div className={css(styles.popupMenuContainer)}>
                  <div
                    className={`m-item ${css(styles.menuItem)}`}
                    onClick={() => this.toggleEditProfileDialog(true)}
                  >
                    <div className={css(styles.menuItemIconContainer)}>
                      <i className={`fa fa-pencil ${css(styles.menuItemIcon)}`} />
                    </div>
                    <div>Edit Profile</div>
                  </div>
                </div>
              </PopupMenu>
            </div>
          </div>

          {/* Tabs */}
          <div className={css(styles.tabs)}>
            <TabsContainer
              dropDownId="d1"
              showPlusIcon={false}
              containerWidth={800}
              otherElementsWidth={320}
              minWidth={810}
              dropDownTop={39}
              dropDownLeft={44}
              dropDownHeaderStyles={{
                minWidth: '170px',
                maxWidth: '370px',
                width: '100%'
              }}
              containerStyles={{
                width: 'calc(100% - 320px)'
              }}
            >
              {/* About Tab */}
              <TabItem
                tabKey="1"
                key='9898'
                parentKey="0111"
                text="About"
                isActive={this.props.location.pathname === '/admin/profile/about'}
                iconClass='fa fa-info-circle'
                style={{
                  borderRight: '1px solid #082840',
                  borderLeft: '1px solid #082840',
                  paddingRight: '0',
                  width: '90px',
                  minWidth: '90px',
                  maxWidth: '80px'
                }}
                textStyles={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
                onActivate={() => {
                  this.setState({
                    activeTab1: '1'
                  });
                }}
              >
                <Link
                  to={`${url}/about`}
                  className={css(styles.tabText, lStyles.linkStyles, this.props.location.pathname === '/admin/profile/about' && lStyles.selectedLink)}
                >
                  About
                </Link>
              </TabItem>
              {/* Contact */}
              <TabItem
                tabKey="1"
                parentKey="0111"
                key='9899'
                text="Contact"
                isActive={this.props.location.pathname === '/admin/profile/contact'}
                iconClass='fa fa-address-card-o'
                style={{
                  borderRight: '1px solid #082840',
                  borderLeft: '1px solid #082840',
                  paddingRight: '0',
                  width: '105px',
                  minWidth: '105px',
                  maxWidth: '105px'
                }}
                textStyles={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
                onActivate={() => {
                  this.setState({
                    activeTab1: '1'
                  });
                }}
              >
                <Link
                  to={`${url}/contact`}
                  className={css(styles.tabText, lStyles.linkStyles, this.props.location.pathname === '/admin/profile/contact' && lStyles.selectedLink)}
                >
                  Contact
                </Link>
              </TabItem>
            </TabsContainer>
          </div>
        </div>

        <Switch>
          <Route path={`${path}/about`}>
            <About description={profileDescription}/>
          </Route>
          <Route path={`${path}/contact`}>
            <Contact
              organization={`${organization}`}
              mailingAddress={mailingAddress}
              email={`${email}`}
              mobile={`${mobile}`}
              phone={`${phone}`}
              fax={`${fax}`}
              profileDescription={`${profileDescription}`}
            />
          </Route>
        </Switch>
      </div>
    );
  }
}

function mapStateToProps ({ user }) {
  return { ...user };
}

export default connect(
  mapStateToProps,
  {
    updateBanner,
    updateAvatar,
    updateProfile,
    addProvider
  })(withRouter(AboutProfile));
