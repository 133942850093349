import React from 'react';
import { Button } from 'quantux-ui';
import { css, StyleSheet } from 'aphrodite/no-important';
import { connect } from 'react-redux';
import { toggleUserSigninDialog } from '../../actions';

const styles = StyleSheet.create({
  signInMenu: {
    background: '#083050',
    display: 'flex',
    flexFlow: 'column nowrap'
  }
});

const SignInMenu = (props) => (
  <div className={`p-2 ${css(styles.signInMenu)}`}>
    <Button
        btnType="primary"
        className="mb-2 fx fx__hv-center"
        onClick={() => {
          props.toggleUserSigninDialog(true);
          props.onCloseMenu(false);
        }}
        style={{
          width: '140px',
          fontWeight: 'bold',
          color: 'white',
          borderRadius: '4'
        }}
      >
        Log In
      </Button>
    <Button
        className="fx fx__hv-center"
        btnType="primary"
        onClick={() => {
          console.log('Signup button click!');
          props.onCloseMenu(false);
        }}
        style={{
          width: '140px',
          fontWeight: 'bold',
          color: 'white',
          borderRadius: '4'
        }}
      >
        Sign Up
      </Button>
  </div>
);

function mapStateToProps ({ user }) {
  return { ...user };
}

export default connect(
  mapStateToProps, {
    toggleUserSigninDialog
  })(SignInMenu);
