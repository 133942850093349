import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { css, StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';
import { history } from '../../utils';

const propTypes = {
  userHandle: PropTypes.string,
  displayName: PropTypes.string,
  showBottomRow: PropTypes.bool
};

const defaultProps = {
  userHandle: '@johndoe',
  displayName: 'John Doe',
  showBottomRow: true
};

const styles = StyleSheet.create({
  userCard: {
    height: '80px',
    borderBottom: '1px solid #001824',
    background: '#103A60'
  },
  topSection: {
    display: 'flex',
    height: '41px'
  },
  avatarIcon: {
    fontSize: '50px'
  },
  userHandle: {
    color: '#0080FF'
  },
  bottomSection: {
    color: '#0080FF',
    padding: '12px 8px 8px 8px',
    fontSize: '14px',
    fontWeight: 'bold',
    cursor: 'pointer'
  }
});

const UserCard = (props) => {
  const handleOnClickSwitchToAdmin = () => {
    history.push('/admin/profile/about');
  };

  const handleOnClickSwitchToProvider = () => {
    history.push('/provider');
  };

  let profileSwitcher = <div></div>;

  if (props.usr.loggedInAs === 'admin' && props.usr.isProvider) {
    if (props.location.pathname.startsWith('/provider')) {
      profileSwitcher = (
        <div
          onClick={handleOnClickSwitchToAdmin}
        >
          Change to Admin Profile
        </div>
      );
    } else if (props.location.pathname.startsWith('/admin')) {
      profileSwitcher = (
        <div
          onClick={handleOnClickSwitchToProvider}
        >
          Change to Provider Profile
        </div>
      );
    }
  }

  if (props.usr.loggedInAs === 'provider' && props.usr.isAdmin) {
    if (props.location.pathname.startsWith('/provider')) {
      profileSwitcher = (
        <div
          onClick={handleOnClickSwitchToAdmin}
        >
          Change to Admin Profile
        </div>
      );
    } else if (props.location.pathname.startsWith('/admin')) {
      profileSwitcher = (
        <div
          onClick={handleOnClickSwitchToProvider}
        >
          Change to Provider Profile
        </div>
      );
    }
  }

  return (
    <div className={css(styles.userCard)}>
      {/* Top section */}
      <div className={css(styles.topSection)}>
        {/* Avatar */}
        <div className="pl-2 pt-1 mr-2">
          {/* <i className={`fa fa-user-md ${css(styles.avatarIcon)}`} /> */}
          <img
            src={props.usr.avatarImageUrl}
            alt="avatar"
            style={{
              width: 'auto',
              height: '50px',
              maxHeight: '50px',
              borderRadius: '4px'
            }}
          />
        </div>
        {/* User details */}
        <div className="pt-1">
          <div>
            {props.displayName}
          </div>
          <div className={css(styles.userHandle)}>
            {props.usr.isMasterAdmin
              ? <Link to='/admin/profile/about'>{props.userHandle}</Link>
              : <Link to='/user'>{props.userHandle}</Link>
            }
          </div>
        </div>
      </div>
      {/* Card bottom section */}
      {props.showBottomRow
        ? <div className={`noselect ${css(styles.bottomSection)}`}>
          {profileSwitcher}
        </div>
        : null
      }
    </div>
  );
};

UserCard.propTypes = propTypes;
UserCard.defaultProps = defaultProps;

function mapStateToProps({ user }) {
  return { ...user };
}

export default connect(mapStateToProps, {})(withRouter(UserCard));
