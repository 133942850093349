import { ROOT_URL } from './app-constants';

const _padZero = (val) => {
  if (val < 10) {
    return `0${val}`;
  }

  return val;
};

export const getAuthHeaders = () => {
  const token = localStorage.getItem('token');
  if (token) {
    return { Authorization: `Bearer ${token}` };
  }
  return {};
};

export const validateUserName = (userName) => {
  return userName.length >= 2 &&
    userName.length <= 20 &&
    userName.indexOf(' ') === -1;
};

export const addBaseURL = (options) => {
  if (process.env.NODE_ENV === 'development') {
    options.baseURL = ROOT_URL;
  }
};

export const titleCase = (str) => {
  return str
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const _toTitleCase = (str) => {
  return `${str.charAt(0)}${str.substring(1).toLowerCase()}`;
};

export const generateDisplayName = (org) => {
  // Entities abbreviation to omit from name
  const entities = [
    'LP',
    'LLP',
    'LLLP',
    'LLC',
    'PC',
    'PLLC',
    'PLC',
    'CORP',
    'PBC',
    'INC',
    'LTD'
  ];
  // Omit comma and periods
  let displayName = org.replace('.', '').replace(',', '').toUpperCase();
  // Omit entities as described above and trim
  for (let i = 0; i < entities.length; i++) {
    if (displayName.indexOf(entities[i]) > 0) {
      displayName = displayName.replace(entities[i], '');
    }
  }
  displayName = displayName.trim();

  let result = '';
  const tmp = displayName.split(' ');

  if (tmp.length <= 1) {
    return `${org.charAt(0)}${org.substring(1).toLowerCase()}`;
  }

  for (let i = 0; i < tmp.length; i++) {
    result = result + _toTitleCase(tmp[i]) + ' ';
  }

  return result.trim();
};

export const getDateString = (val) => {
  let currentDate = new Date();
  if (val) {
    currentDate = new Date(val);
  }

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  const month = months[currentDate.getMonth()];
  const date = currentDate.getDate();
  const year = currentDate.getFullYear();
  const hours = _padZero(currentDate.getHours());
  const minutes = _padZero(currentDate.getMinutes());
  // const seconds = _padZero(currentDate.getSeconds());

  const tzOffset = currentDate.getTimezoneOffset() > 0
    ? `UTC-${currentDate.getTimezoneOffset() / 60}`
    : `UTC+${currentDate.getTimezoneOffset() / 60}`;

  // May25, 2020 23:23:34 (UTC+13)
  // return `${month}${date}, ${year} ${hours}:${minutes}:${seconds} (${tzOffset})`;

  // May25, 2020 @ 23:23 UTC+13
  return `${month}${date}, ${year} @ ${hours}:${minutes} ${tzOffset}`;
};

export const getTimeString = (val) => {
  let currentDate = new Date();
  if (val) {
    currentDate = new Date(val);
  }
  const hours = _padZero(currentDate.getHours());
  const minutes = _padZero(currentDate.getMinutes());
  // const seconds = _padZero(currentDate.getSeconds());

  const tzOffset = currentDate.getTimezoneOffset() > 0
    ? `UTC-${currentDate.getTimezoneOffset() / 60}`
    : `UTC+${currentDate.getTimezoneOffset() / 60}`;

  // May25, 2020 23:23:34 (UTC+13)
  // return `${month}${date}, ${year} ${hours}:${minutes}:${seconds} (${tzOffset})`;

  // May25, 2020 @ 23:23 UTC+13
  return `${hours}:${minutes} ${tzOffset}`;
};

export const debugLogToConsole = (msg) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(msg);
  }
};

export const getWeekDay = (num) => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return days[num];
};

export const getMonthName = (num) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  return months[num];
};
