import React, { useState } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { PopupMenu, gray, salmon, blue } from 'quantux-ui';

const PracticeUserCard = (props) => {
  const [isAccessTypeMenuOpen, toggleAccessTypeMenu] = useState(false);

  const styles = StyleSheet.create({
    practiceUserMenuContainer: {
      minWidth: '140px'
    },
    practiceUserMenuItem: {
      display: 'flex',
      alignItems: 'center',
      height: '32px',
      width: '138px',
      paddingLeft: '8px',
      background: '#103A60',
      cursor: 'pointer',
      ':hover': {
        background: gray[800]
      }
    },
    practiceUserMenuItemActive: {
      display: 'flex',
      alignItems: 'center',
      height: '32px',
      width: '138px',
      paddingLeft: '8px',
      background: gray[800],
      color: 'white',
      ':hover': {
        background: gray[800],
        color: 'white'
      }
    },
    practiceOwnerRemoveBtn: {
      fontSize: '20px',
      ':hover': {
        color: salmon[500]
      }
    },
    practiceOwnerCard: {
      marginBottom: '8px',
      background: '#0A3250',
      borderRadius: '5px',
      padding: '8px'
    },
    practiceOwnerCardTopSection: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  });

  const accessTypeMenu = React.createRef();

  const {
    el,
    removePracticeOwner,
    updateAccessType
  } = props;

  const idt = el.id ? el.id : el.user;

  return (
    <div className={css(styles.practiceOwnerCard)}>
      <div className={css(styles.practiceOwnerCardTopSection)}>
        <div className='fx'>
          <img
            src={el.avatarImageUrl}
            alt='practice owner avatar image url'
            style={{
              maxHeight: '32px'
            }}
          />
          <div style={{ marginTop: '-2px', marginLeft: '8px' }}>
            <div style={{ fontSize: '12px' }}>{el.fullName}</div>
            <div style={{ fontSize: '12px' }}>{`NPI: ${el.npi}`}</div>
          </div>
        </div>
        {/* Close button */}
        <i
          className={`fa fa-times-circle ${css(styles.practiceOwnerRemoveBtn)}`}
          onClick={() => { removePracticeOwner(idt); }}
        />
      </div>
      <div
        style={{ position: 'relative' }}
        ref={accessTypeMenu}
      >
        <div
          className='fx'
          onClick={() => toggleAccessTypeMenu(!isAccessTypeMenuOpen)}
        >
          <div style={{ cursor: 'pointer' }}>
            Access Type: <span style={{ color: blue[500] }}>{el.accessType}</span>
          </div>
          <div
            className='ml-1'
            style={{ color: blue[500] }}
          >
            <i className={`fa fa-lg ${isAccessTypeMenuOpen ? 'fa-caret-up' : 'fa-caret-down'}`} />
          </div>
        </div>
        {/* User Access Type PopupMenu */}
        <PopupMenu
          top={34}
          left={-5}
          zIndex='1550'
          position="absolute"
          visible={isAccessTypeMenuOpen}
          parent={accessTypeMenu}
          onOutsideClick={() => { toggleAccessTypeMenu(false); }}
        >
          <div className={css(styles.practiceUserMenuContainer)}>
            <div
              className={css(styles.practiceUserMenuItem, el.accessType === 'Provider' && styles.practiceUserMenuItemActive)}
              onClick={() => {
                toggleAccessTypeMenu(false);
                updateAccessType('Provider', idt);
              }}
            >
              Provider
            </div>
            <div
              className={css(styles.practiceUserMenuItem, el.accessType === 'Practice Owner' && styles.practiceUserMenuItemActive)}
              onClick={() => {
                toggleAccessTypeMenu(false);
                updateAccessType('Practice Owner', idt);
              }}
            >
              Practice Owner
            </div>
            <div
              className={css(styles.practiceUserMenuItem, el.accessType === 'Practice Manager' && styles.practiceUserMenuItemActive)}
              onClick={() => {
                toggleAccessTypeMenu(false);
                updateAccessType('Practice Manager', idt);
              }}
            >
              Practice Manager
            </div>
            <div
              className={css(styles.practiceUserMenuItem, el.accessType === 'Credentialer' && styles.practiceUserMenuItemActive)}
              onClick={() => {
                toggleAccessTypeMenu(false);
                updateAccessType('Credentialer', idt);
              }}
            >
              Credentialer
            </div>
            <div
              className={css(styles.practiceUserMenuItem, el.accessType === 'Member' && styles.practiceUserMenuItemActive)}
              onClick={() => {
                toggleAccessTypeMenu(false);
                updateAccessType('Member', idt);
              }}
            >
              Member
            </div>
            <div
              className={css(styles.practiceUserMenuItem, el.accessType === 'Consultant' && styles.practiceUserMenuItemActive)}
              onClick={() => {
                toggleAccessTypeMenu(false);
                updateAccessType('Consultant', idt);
              }}
            >
              Consultant
            </div>
          </div>
        </PopupMenu>
      </div>
    </div>
  );
};

PracticeUserCard.displayName = 'PracticeUserCard';
export default PracticeUserCard;
