import React, { useState, useEffect } from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { css, StyleSheet } from 'aphrodite/no-important';
import { gray } from 'quantux-ui';
// Nested Routes
import TypographyDemo from '../components/typography';
import IconsDemo from '../components/icons';
import ButtonsDemo from '../components/buttons';
import NavbarDemo from '../components/navbar';

const KitchenSink = () => {
  const [isDrawerOpen, toggleDrawer] = useState(true);
  const [activeItem, setActiveItem] = useState('icons');
  const [isMobileScreen, setMobileScreen] = useState(false);
  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  // Heading text
  const headings = {
    typography: 'Typography',
    icons: 'Icons',
    buttons: 'Buttons',
    navbar: 'Navbar'
  };

  // Window resize eventhandlder
  const handleWindowResize = () => {
    setMobileScreen(window.innerWidth <= 700);
  };

  // Handle on drawer menu item click
  const handleMenuItemClick = (currentMenuItem) => {
    setActiveItem(currentMenuItem);
    toggleDrawer(window.innerWidth <= 700 ? false : isDrawerOpen);
  };

  // Styles
  const styles = StyleSheet.create({
    menuItem: {
      color: gray[300]
    },
    detailsPanel: {
      height: 'calc(100% - 80px)',
      display: 'flex',
      flexFlow: '1',
      overflow: 'auto'
    }
  });

  return (
    <div className="split-panel">
      {/* Panel drawer */}
      <div
        className={
          `${isMobileScreen ? 'drawer-popover' : 'drawer'}
          ${isDrawerOpen ? '' : 'hide-drawer'}`
        }
      >
        {/* Header */}
        <div className="header">
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <i
              style={{
                background: '#001824',
                height: '32px',
                width: '32px',
                borderRadius: '2px',
                fontSize: '24px',
                paddingLeft: '5px',
                paddingTop: '2px'
              }}
              className="fa fa-puzzle-piece"
            />
            <div className="pl-2 text">
              Front-End Components
            </div>
          </div>
        </div>
        {/* Drawer options */}
        <div
          className="custom-scroll"
          style={{
            height: 'calc(100% - 64px)',
            overflowY: 'auto'
          }}
        >
          <div className="drawer-accordian">
            {/* Typography */}
            <Link
              className={
                `mt-1 ml-0 drawer-menu-item noselect
                ${activeItem === 'typography' ? 'active' : ''}
                ${css(styles.menuItem)}`
              }
              to='/typography'
              onClick={() => { handleMenuItemClick('typography'); }}
            >
              <div className='icon d-none' />
              <div className='text'>
                Typography
              </div>
              <div className='action-icon'>
                <i className='fa fa-chevron-right' />
              </div>
            </Link>

            {/* Icons */}
            <Link
              className={
                `mt-1 ml-0 drawer-menu-item noselect
                ${activeItem === 'icons' ? 'active' : ''}
                ${css(styles.menuItem)}`
              }
              to='/icons'
              onClick={() => { handleMenuItemClick('icons'); }}
            >
              <div className='icon d-none' />
              <div className='text'>
                Icons
              </div>
              <div className='action-icon'>
                <i className='fa fa-chevron-right' />
              </div>
            </Link>

            {/* Buttons */}
            <Link
              className={
                `mt-1 ml-0 drawer-menu-item noselect
                ${activeItem === 'buttons' ? 'active' : ''}
                ${css(styles.menuItem)}`
              }
              to="/buttons"
              onClick={() => { handleMenuItemClick('buttons'); }}
            >
              <div className='icon d-none' />
              <div className='text'>
                Buttons
              </div>
              <div className='action-icon'>
                <i className='fa fa-chevron-right' />
              </div>
            </Link>
            {/* Navbar */}
            <Link
              className={`
                mt-1 ml-0 drawer-menu-item noselect
                ${activeItem === 'navbar' ? 'active' : ''}
                ${css(styles.menuItem)}`
              }
              to="/navbar"
              onClick={() => { handleMenuItemClick('navbar'); }}
            >
              <div className='icon d-none' />
              <div className='text'>
                Navbar
              </div>
              <div className='action-icon'>
                <i className='fa fa-chevron-right' />
              </div>
            </Link>
          </div>
        </div>
      </div>
      {/* Panel Detail */}
      <div className='detail expand'>
        {/* TAB HEADER */}
        <div
          className="header"
          style={{
            height: '40px',
            background: '#001824',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: '0.5px solid #082840'
          }}
        >
          {/* TABS */}
          <div
            style={{
              display: 'flex',
              flexFlow: 'row nowrap',
              alignItems: 'center',
              justifyContent: 'left'
            }}
          >
            <div>
              <i
                className="fa fa-square"
                style={{
                  fontSize: '20px',
                  marginLeft: '4px',
                  marginRight: '8px'
                }}
              />
            </div>
            <div
              style={{
                fontFamily: 'Lato',
                fontSize: '16px',
                fontWeight: 'bold'
              }}
            >
              {headings[activeItem]}
            </div>
          </div>
        </div>
        {/* Nested Route */}
        <div className={`pl-4 pt-4 ${css(styles.detailsPanel)}`}>
          <Switch>
            <Route path='/typography'>
              <TypographyDemo />
            </Route>
            <Route path='/icons'>
              <IconsDemo />
            </Route>
            <Route path='/buttons'>
              <ButtonsDemo />
            </Route>
            <Route path='/navbar'>
              <NavbarDemo />
            </Route>
            <Route path='/'>
              <IconsDemo />
              {/* <TypographyDemo /> */}
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default KitchenSink;
