import React from 'react';

const ColorTheme = (props) => {
  return (
    <div>
      <h1>Color Theme Placeholder</h1>
    </div>
  );
}

export default ColorTheme;
