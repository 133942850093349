import axios from 'axios';
import { actionTypes } from './action-types';
import { ROOT_URL, history, getAuthHeaders, addBaseURL, BASE_URL } from '../utils';

export function toggleUserSigninDialog (_value) {
  return {
    type: actionTypes.toggleUserSigninDialog,
    payload: _value
  };
}

export function toggle2FaVerificationDialog (_value) {
  return {
    type: actionTypes.toggle2FaVerificationDialog,
    payload: _value
  };
}

// Step 1 - user credentials verification
export function initiateSignIn (formData) {
  return async (dispatch) => {
    const options = {
      method: 'POST',
      url: '/api/v1/users/signIn',
      data: formData
    };
    addBaseURL(options);

    const res = await axios(options).catch((err) => {
      console.log(`Error: ${err.messagg}`);
    });

    if (res && res.status === 200 && res.data.success) {
      const {
        twoFactorAuthToken,
        usernameOrEmail,
        success,
        phone
      } = res.data;

      localStorage.setItem('twoFactorAuthToken', twoFactorAuthToken);
      localStorage.setItem('usernameOrEmail', usernameOrEmail);
      dispatch({
        type: actionTypes.initiateSignin,
        payload: {
          success,
          phone
        }
      });
    } else {
      dispatch({
        type: actionTypes.signinFailed
      });
    }
  };
}

// Step 2 - user signin - 2FA verification
export function finalizeSignIn (_2faCode) {
  return async (dispatch) => {
    const options = {
      url: '/api/v1/users/verify2FaForLogin',
      method: 'POST',
      data: {
        email: localStorage.getItem('usernameOrEmail'),
        _2faCode,
        _2faToken: localStorage.getItem('twoFactorAuthToken')
      }
    };
    addBaseURL(options);

    const res = await axios(options).catch((err) => {
      console.log(`Error: ${err.message}`);
    });

    if (res && res.status === 200 && res.data.success) {
      const {
        isSignedIn,
        token,
        usr
      } = res.data;

      localStorage.setItem('isSignedIn', isSignedIn);

      if (isSignedIn) {
        localStorage.setItem('token', token);
        localStorage.setItem('usr', usr);
        dispatch({
          type: actionTypes.finalizeSignIn,
          payload: usr
        });
        const tmpUsr = JSON.parse(usr);
        if (tmpUsr.isMasterAdmin) {
          history.push('/admin/profile/about');
        } else {
          history.push('/user');
        }
      } else {
        dispatch({
          type: actionTypes._2faVerificationFailed
        });
      }
    }
  };
}

export function resend2Fa () {
  return async (dispatch) => {
    const options = {
      url: '/api/v1/users/resend2FaCode',
      method: 'POST',
      data: {
        usernameOrEmail: localStorage.getItem('usernameOrEmail'),
        _2FaToken: localStorage.getItem('twoFactorAuthToken')
      }
    };
    addBaseURL(options);

    const res = await axios(options).catch((err) => {
      console.log(`Error: ${err.message}`);
    });

    if (res && res.status === 200 && res.data.success) {
      dispatch({
        type: actionTypes.resend2Fa
      });
    }
  };
}

export function isUserSignedIn () {
  return async (dispatch) => {
    const options = {
      method: 'POST',
      url: '/api/v1/users/isSignedIn',
      headers: getAuthHeaders()
    };

    addBaseURL(options);

    const res = await axios(options).catch((err) => {
      console.log(`Error: ${err.message}`);
    });

    if (res && res.status === 200 && res.data.success) {
      getNotifications(localStorage.getItem('usernameOrEmail'));
      dispatch({
        type: actionTypes.isSignedIn,
        payload: res.data
      });
    }
  };
}

export function logout (alert) {
  return {
    type: actionTypes.logout,
    payload: alert
  };
}

export function updateBanner (blob) {
  return async (dispatch) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const usr = JSON.parse(localStorage.getItem('usr'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('usernameOrEmail', usr.email);
    formdata.append('file', blob, 'testImage.png');

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${ROOT_URL}/api/v1/users/updateBanner`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        if (res.success) {
          localStorage.setItem('usr', res.usr);
          dispatch({
            type: actionTypes.updateBanner,
            payload: res
          });
        }
      })
      .catch(error => console.log('error', error));
  };
}

export function updatePracticeBanner (blob, _id) {
  return async (dispatch) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('practiceId', _id);
    formdata.append('file', blob, 'practiceBannerImage.png');

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${ROOT_URL}/api/v1/users/updatePracticeBanner`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        if (res.success) {
          dispatch({
            type: actionTypes.updatePracticeBanner,
            payload: res
          });
        }
      })
      .catch(error => console.log('error', error));
  };
}

export function updatePracticeAvatar (blob, _id) {
  return async (dispatch) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('practiceId', _id);
    formdata.append('file', blob, 'practiceBannerImage.png');

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${ROOT_URL}/api/v1/users/updatePracticeAvatar`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        if (res.success) {
          dispatch({
            type: actionTypes.updatePracticeAvatar,
            payload: res
          });
        }
      })
      .catch(error => console.log('error', error));
  };
}

export function updatePracticeProfile (data) {
  return async (dispatch) => {
    const options = {
      method: 'POST',
      url: '/api/v1/users/updatePracticeProfile',
      headers: getAuthHeaders(),
      data: { ...data }
    };
    addBaseURL(options);

    const res = await axios(options).catch((err) => {
      console.log(`Error: ${err.messagg}`);
    });

    if (res && res.status === 200 && res.data.success) {
      const { success } = res.data;
      if (success) {
        dispatch({
          type: actionTypes.updatePracticeProfile,
          payload: res.data
        });
      }
    }
  };
}

export function togglePracticeDataReloadFlag (value) {
  return {
    type: actionTypes.togglePracticeDataReloadFlag,
    payload: value
  };
}

export function updateUserBanner () {}

export function updateUserAvatar () {}

export function updateUserProfile () {}

export function updateAvatar (blob) {
  return async (dispatch) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const usr = JSON.parse(localStorage.getItem('usr'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('usernameOrEmail', usr.email);
    formdata.append('file', blob, 'testImage.png');

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${ROOT_URL}/api/v1/users/updateAvatar`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        if (res.success) {
          localStorage.setItem('usr', res.usr);
          dispatch({
            type: actionTypes.updateAvatar,
            payload: res
          });
        }
      })
      .catch(error => console.log('error', error));
  };
}

export function updateProfile (data) {
  return async (dispatch) => {
    const usr = JSON.parse(localStorage.getItem('usr'));
    const options = {
      method: 'POST',
      url: '/api/v1/users/updateProfile',
      headers: getAuthHeaders(),
      data: {
        ...data,
        usernameOrEmail: usr.email
      }
    };
    addBaseURL(options);

    const res = await axios(options).catch((err) => {
      console.log(`Error: ${err.messagg}`);
    });

    if (res && res.status === 200 && res.data.success) {
      const { success, usr } = res.data;

      localStorage.setItem('usr', usr);

      dispatch({
        type: actionTypes.updateProfile,
        payload: {
          success,
          usr
        }
      });
    }
  };
}

export function reloadData (usr) {
  return {
    type: actionTypes.reloadData,
    payload: usr
  };
}

export function addProvider (data) {
  return async (dispatch) => {
    const options = {
      url: '/api/v1/users/addUser',
      method: 'POST',
      headers: getAuthHeaders(),
      data: { ...data }
    };
    addBaseURL(options);

    const res = await axios(options).catch((err) => {
      console.log(`Error: ${err.message}`);
    });

    if (res && res.status === 200 && res.data.success) {
      const { user } = res.data;
      dispatch({
        type: actionTypes.addProvider,
        payload: user
      });
    }
  };
}

export function addFacility (data) {
  return async (dispatch) => {
    const options = {
      url: '/api/v1/users/addFacility',
      method: 'PUT',
      data: { ...data }
    };
    addBaseURL(options);

    const res = await axios(options).catch((err) => {
      console.log(`Error: ${err.message}`);
    });

    if (res && res.status === 200 && res.data.success) {
      dispatch({ type: actionTypes.addFacility });
    }
  };
}

export function addPractice (data) {
  return async (dispatch) => {
    const options = {
      url: '/api/v1/users/addPractice',
      method: 'PUT',
      data: { ...data }
    };
    addBaseURL(options);

    const res = await axios(options).catch((err) => {
      console.log(`Error: ${err.message}`);
    });

    if (res && res.status === 200 && res.data.success) {
      dispatch({ type: actionTypes.addPractice });
    }
  };
}

export function getProviders () {
  return async (dispatch) => {
    const options = {
      url: '/api/v1/users/verify2FaForLogin',
      method: 'GET'
    };
    addBaseURL(options);

    const res = await axios(options).catch((err) => {
      console.log(`Error: ${err.message}`);
    });

    if (res && res.status === 200 && res.data.success) {
      const { providers } = res.data;
      dispatch({
        type: actionTypes.getProviders,
        payload: providers
      });
    }
  };
}

export function toggleResetPasswordDialog () {
  return {
    type: actionTypes.toggleResetPasswordDialog
  };
}

export function sendTempPassword (email) {
  return async (dispatch) => {
    const options = {
      url: '/api/v1/users/sendTempPassword',
      method: 'POST',
      data: { email }
    };
    addBaseURL(options);

    const res = await axios(options).catch((err) => {
      console.log(`Error: ${err.message}`);
    });

    if (res && res.status === 200) {
      const { success, isEmailInvalid } = res.data;
      dispatch({
        type: actionTypes.sendTempPassword,
        payload: {
          success,
          isEmailInvalid
        }
      });
    }
  };
}

export function resetPassword (formData) {
  return async (dispatch) => {
    const options = {
      url: '/api/v1/users/resetPassword',
      method: 'POST',
      data: formData
    };
    addBaseURL(options);

    const res = await axios(options).catch((err) => {
      console.log(`Error: ${err.message}`);
    });

    if (res && res.status === 200) {
      const { success } = res.data;
      dispatch({
        type: actionTypes.resetPassword,
        payload: { success }
      });
    }
  };
}

// TODO: Delete initiateProviderSignIn action
// Step 1 - user credentials verification
export function initiateProviderSignIn (formData) {
  return async (dispatch) => {
    const options = {
      method: 'POST',
      url: '/api/v1/providers/signIn',
      data: formData
    };
    addBaseURL(options);

    const res = await axios(options).catch((err) => {
      console.log(`Error: ${err.messagg}`);
    });

    if (res && res.status === 200 && res.data.success) {
      const {
        twoFactorAuthToken,
        usernameOrEmail,
        success,
        phone
      } = res.data;

      localStorage.setItem('twoFactorAuthToken', twoFactorAuthToken);
      localStorage.setItem('usernameOrEmail', usernameOrEmail);
      dispatch({
        type: actionTypes.initiateProviderSignIn,
        payload: {
          success,
          phone
        }
      });
    } else {
      dispatch({
        type: actionTypes.providerSigninFailed
      });
    }
  };
}

// Step 2 - user signin - 2FA verification
export function finalizeProviderSignIn (_2faCode) {
  return async (dispatch) => {
    const options = {
      url: '/api/v1/providers/verify2FaForLogin',
      method: 'GET',
      params: {
        email: localStorage.getItem('usernameOrEmail'),
        _2faCode,
        _2faToken: localStorage.getItem('twoFactorAuthToken')
      }
    };
    addBaseURL(options);

    const res = await axios(options).catch((err) => {
      console.log(`Error: ${err.message}`);
    });

    if (res && res.status === 200 && res.data.success) {
      const {
        isSignedIn,
        token,
        usr
      } = res.data;

      localStorage.setItem('isSignedIn', isSignedIn);

      if (isSignedIn) {
        localStorage.setItem('token', token);
        localStorage.setItem('usr', usr);
        dispatch({
          type: actionTypes.finalizeProviderSignIn,
          payload: usr
        });
        history.push('/provider');
      } else {
        dispatch({
          type: actionTypes._2faVerificationFailed
        });
      }
    }
  };
}

export function updateProviderProfile (data) {
  return async (dispatch) => {
    const usr = JSON.parse(localStorage.getItem('usr'));
    const options = {
      method: 'POST',
      url: '/api/v1/providers/updateProfile',
      headers: getAuthHeaders(),
      data: {
        ...data,
        usernameOrEmail: usr.email
      }
    };
    addBaseURL(options);

    const res = await axios(options).catch((err) => {
      console.log(`Error: ${err.messagg}`);
    });

    if (res && res.status === 200 && res.data.success) {
      const { success, usr } = res.data;

      localStorage.setItem('usr', usr);

      dispatch({
        type: actionTypes.updateProfile,
        payload: {
          success,
          usr
        }
      });
    }
  };
}

export function updateProviderBanner (blob) {
  return async (dispatch) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const usr = JSON.parse(localStorage.getItem('usr'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('usernameOrEmail', usr.email);
    formdata.append('file', blob, 'testImage.png');

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${ROOT_URL}/api/v1/providers/updateBanner`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        if (res.success) {
          localStorage.setItem('usr', res.usr);
          dispatch({
            type: actionTypes.updateProviderBanner,
            payload: res
          });
        }
      })
      .catch(error => console.log('error', error));
  };
}

export function updateProviderAvatar (blob) {
  return async (dispatch) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    const usr = JSON.parse(localStorage.getItem('usr'));
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('usernameOrEmail', usr.email);
    formdata.append('file', blob, 'testImage.png');

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${ROOT_URL}/api/v1/providers/updateAvatar`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        if (res.success) {
          localStorage.setItem('usr', res.usr);
          dispatch({
            type: actionTypes.updateProviderAvatar,
            payload: res
          });
        }
      })
      .catch(error => console.log('error', error));
  };
}

export function setActiveRecord (data) {
  return {
    type: actionTypes.setActiveRecord,
    payload: data
  }
}

export function addAppAlert (alert) {
  return {
    type: actionTypes.addAppAlert,
    payload: alert
  };
}

export function deleteAppAlert (alertId) {
  return {
    type: actionTypes.deleteAppAlert,
    payload: alertId
  };
}

export function hideAlert (alertId) {
  return {
    type: actionTypes.hideAppAlert,
    payload: alertId
  };
}

export function getNotifications (email) {
  return async (dispatch) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('email', email);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${BASE_URL}/users/getNotifications`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        console.log('Checking ... notifications');
        console.log('res', res);
        if (res.success) {
          console.log(res.notifications);
          dispatch({
            type: actionTypes.getNotifications,
            payload: res.notifications
          });
        }
      })
      .catch(error => console.log('error', error));
  };
}

export function setNotificationsCount (count) {
  return {
    type: actionTypes.setNotificationsCount,
    payload: count
  }
}

export function clearChatBadge () {
  return { type: actionTypes.clearChatBadge };
}
