import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite/no-important';
import { blue, salmon, gray, Drawer } from 'quantux-ui';
import { BASE_URL, debugLogToConsole } from '../../utils';

const propTypes = {
  title: PropTypes.string,
  showNewMessageDrawer: PropTypes.bool,
  toggleNewMessageDrawer: PropTypes.func
};

const defaultProps = {
  title: 'Chat Title'
};

const ChatEditor = (props) => {
  const [chatMessage, setChatMessage] = useState('');
  // Refs
  const fileInputRef = React.createRef();

  const styles = StyleSheet.create({
    drawerContainer: {
      background: gray[800],
      color: gray[300],
      height: '100%',
      position: 'relative',
      display: 'flex',
      flexFlow: 'column',
      overflowY: 'hidden'
    },
    drawerHeader: {
      height: '45px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 8px',
      fontWeight: 'bold',
      borderBottom: `1px solid ${gray[500]}`,
      flexShrink: '0'
    },
    drawerHeaderCloseIcon: {
      color: gray[300],
      ':hover': {
        color: salmon[500]
      }
    },
    drawerContentArea: {
      overflowY: 'auto',
      color: gray[300],
      flexGrow: '1',
      cursor: 'pointer'
      // display: 'flex'
    },
    chatTextArea: {
      fontFamily: 'Lato',
      fontSize: '16px',
      height: window.innerWidth <= 700 ? '-webkit-fill-available' : '100%',
      // height: '100%',
      // flexGrow: '1',
      width: 'calc(100vh - 40px)',
      ':focus': {
        height: window.innerWidth <= 700 ? `${window.innerHeight / 2 - 45}px` : '100%'
      }
    },
    chatToolbarIcon: {
      background: blue[500],
      padding: '4px',
      color: 'white',
      borderRadius: '5px',
      minWidth: '32px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ':hover': {
        background: blue[700]
      }
    },
    toolbar: {
      display: 'flex',
      height: '40px'
    },
    toolbarIconContainer: {
      width: '40px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRight: `1px solid ${gray[500]}`
    },
    toolbarIcon: {
      fontSize: '32px'
    }
  });

  const handleOnChangeChatMessage = (e) => {
    const { value } = e.target;
    setChatMessage(value);
  };
  const handleOnFileSelected = () => {
    if (fileInputRef.current.files.length > 0) {
      console.log('Selected file = ', fileInputRef.current.files[0]);
      console.log('typeof img', typeof fileInputRef.current.files[0]);

      const formData = new FormData();
      const fileToUpload = fileInputRef.current.files[0];
      formData.append('file', fileToUpload);
      const options = { method: 'POST', body: formData, redirect: 'follow' };
      fetch(`${BASE_URL}/users/uploadImg`, options)
        .then(response => response.text())
        .then(result => {
          const res = JSON.parse(result);
          console.log(res);
          if (res.success) {
            debugLogToConsole(`File uploaded successfully ${res.fileUrl}`);
            props.onFileUpload(res.fileUrl, res.originalFileName);
            props.toggleNewMessageDrawer(false);
          }
        })
        .catch(error => console.log('error', error));
    } else {
      console.log('No file is selected.');
    }
  };

  return (
    <Drawer
      placement='right'
      visible={props.showNewMessageDrawer}
      toggleDrawer={() => props.toggleNewMessageDrawer(false)}
      zIndex="2000"
      width={window.innerWidth <= 700 ? window.innerWidth : 358}
    >
      <div className={css(styles.drawerContainer)}>
        {/* Drawer header */}
        <div className={css(styles.drawerHeader)}>
          <div>
            {`To: ${props.title}`}
          </div>
          {/* Icons */}
          <div className='fx fx__v-center'>
            {/* Send message icon */}
            <div
              className={`pl-2 pr-2 mr-2 ${css(styles.chatToolbarIcon)}`}
              onClick={() => {
                // Cannot send empty chat messages
                if (chatMessage && chatMessage !== '') {
                  props.onSendMessage(chatMessage);
                  setChatMessage('');
                  props.toggleNewMessageDrawer(false);
                }
              }}
            >
              <i className='fa fa-paper-plane' />
              <span
                className='pl-2'
                style={{ color: 'white' }}
              >
                Send
              </span>
            </div>
            {/* Close button */}
            <div
              onClick={() => props.toggleNewMessageDrawer(false)}
            >
              <i style={{ fontSize: '24px' }} className='fa fa-times-circle' />
            </div>
          </div>
        </div>
        {/* Toolbar */}
        <div className={css(styles.toolbar)}>
          <div className={css(styles.toolbarIconContainer)} />
          <div className={css(styles.toolbarIconContainer)} />
          <div className={css(styles.toolbarIconContainer)} />
          <div className={css(styles.toolbarIconContainer)} />
          <div className={css(styles.toolbarIconContainer)} />
          <div className={css(styles.toolbarIconContainer)}>
            {/* File upload icon */}
            <div className={`mr-0 ${css(styles.toolbarIconContainer)}`}>
              <div
                className={css(styles.toolbarIcon)}
                style={{ position: 'relative' }}
              >
                <i style={{ cursor: 'pointer' }} className='fa fa-paperclip' />
                <input
                  type='file'
                  accept='.pdf,.doc,.png, .jpg, .jpeg, .xlsx, .xls, .docx, .ppt, .pptx'
                  ref={fileInputRef}
                  onChange={handleOnFileSelected}
                  style={{
                    position: 'absolute',
                    right: '0px',
                    top: '0px',
                    fontSize: '6px',
                    width: '20px',
                    margin: '0px',
                    padding: '0px',
                    cursor: 'pointer',
                    opacity: '0',
                    height: '100%'
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Drawer content area */}
        <div className={css(styles.drawerContentArea)}>
          <textarea
            className={css(styles.chatTextArea)}
            placeholder='Type message'
            value={chatMessage}
            onChange={handleOnChangeChatMessage}
          />
        </div>
      </div>
    </Drawer>
  );
};

ChatEditor.propTypes = propTypes;
ChatEditor.defaultProps = defaultProps;
export default ChatEditor;
