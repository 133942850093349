import React, { Fragment, useState, useEffect } from 'react';
import Pusher from 'pusher-js';
import { Router, Switch, Link, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Nav, NavItem, NavBrand, Navbar, PopupMenu, Toast, blue, salmon } from 'quantux-ui';
// Pages
import KitchenSink from './pages/KitchenSink';
import Home from './pages/Home';
import AdminProfile from './pages/AdminProfile';
import UserProfile from './pages/UserProfile';
import Search from './pages/ Search';
import Legal from './pages/Legal';
import Settings from './pages/Settings';
import Chat from './pages/Chat';
import MobileChat from './pages/MobileChat';
// Local components
import SignInMenu from './components/menus/signin-menu';
import UserMenu from './components/menus/user-menu';
import SignInDialog from './components/dialogs/signin-dialog';
import ResetPasswordDialog from './components/dialogs/reset-password-dialog';
import TwoFactorAuthDialog from './components/dialogs/two-factor-auth-dialog';
import PracticeDashboard from './components/practice-dashboard/practice-dashboard';
import ProviderDashboard from './components/provider-dashboard/provider-dashboard';
import SiteMap from './components/site-map';
import NotificationsDrawer from './components/notifications-drawer';

// Utils
import {
  BASE_URL,
  debugLogToConsole,
  getAuthHeaders,
  history,
  PUSHER_KEY
} from './utils';
// Actions
import {
  toggleUserSigninDialog,
  isUserSignedIn,
  toggleResetPasswordDialog,
  hideAlert,
  getNotifications,
  setNotificationsCount
} from './actions';
// Images
import medprosLogoSmall from './imgs/medProsLogoSmall.svg';
import medprosLogoLarge from './imgs/medProsLogoLarge.svg';

const App = props => {
  let pusher;
  let channel;
  // State
  const [isMobileScreen, setMobileScreen] = useState(false);
  const [isUserMenuVisible, setUserMenuVisibility] = useState(false);
  const [showSiteMap, toggleSiteMap] = useState(false);
  const [showNotificationsDrawer, toggleNotificationsDrawer] = useState(false);
  const [storedAppAlert, setStoredAppAlert] = useState(JSON.parse(localStorage.getItem('alerts')))
  const [events, setEvents] = useState([]);
  // Refs
  const userIcon = React.createRef();

  const handleUserAlertEvent = (data) => {
    debugLogToConsole(data);
    const newArr = events.slice(0);
    newArr.unshift(data);
    setEvents(newArr);
  };
  const handleUserNotificationEvent = (data) => {
    debugLogToConsole(data);
    const newArr = events.slice(0);
    newArr.unshift(data);
    setEvents(newArr);
  };

  // Effects
  useEffect(() => {
    window.addEventListener('resize', handleDocResize);
    console.log('Checking for user signin');
    props.isUserSignedIn();
    return () => {
      window.removeEventListener('resize', handleDocResize);
    };
  }, []);

  // User private channel
  useEffect(() => {
    const isSignedIn = JSON.parse(localStorage.getItem('isSignedIn'))
    if (!isSignedIn) {
      debugLogToConsole('Not subscribing to user private channel ... as user is not signed in!');
      return;
    }
    pusher = new Pusher(
      PUSHER_KEY, {
        cluster: 'us3',
        authEndpoint: `${BASE_URL}/users/pusher/auth`,
        auth: { headers: getAuthHeaders() },
        disableStats: false
      }
    );
    const usr = JSON.parse(localStorage.getItem('usr'));
    debugLogToConsole('Subscribing to private-userId-channel');
    debugLogToConsole(`usr._id = ${usr._id}`);
    if (usr) {
      channel = pusher.subscribe(`private-${usr._id}-channel`);
      channel.bind('user-alert-event', handleUserAlertEvent);
      channel.bind('user-notification-event', handleUserNotificationEvent);
      // Unbind events & unsubscribe from channel
      return () => {
        if (pusher && channel) {
          debugLogToConsole('Unsubscribing from private-userId-channel');
          channel.unbind();
          pusher.unsubscribe(channel);
        }
      };
    }
  }, []);

  // Handler methods
  const handleDocResize = () => {
    setMobileScreen(window.innerWidth <= 700);
  };
  // Styles
  const styles = StyleSheet.create({
    navbarBrandImage: {
      minHeight: '24px'
    },
    navbarBrandImageLarge: {
      display: isMobileScreen ? 'none' : 'block'
    },
    navbarBrandImageSmall: {
      display: isMobileScreen ? 'block' : ''
    },
    navIcons: {
      fontSize: '24px'
    }
  });

  const handleOnDismissAlert = (id) => {
    props.hideAlert(id);
  };

  const handleOnCloseNotification = () => {
    setEvents([]);
  };

  // Toasts
  const toasts = [];
  props.appAlerts.forEach((el) => {
    if (el.showAlert) {
      if (el.autoDismiss) {
        setTimeout(() => {
          handleOnDismissAlert(el.id);
        }, el.autoDismissTime);
      }

      toasts.push(
        <Toast
          key={el.id}
          id={el.id}
          top="41"
          bottom={null}
          headerText={el.headerText}
          headerIcon={el.headerIcon}
          bodyText={el.bodyText}
          onDismiss={handleOnDismissAlert}
          showFooter={false}
        />
      );
    }
  });

  const eventToasts = [];
  events.forEach((el) => {
    if (el.autoDismiss) {
      setTimeout(() => {
        handleOnCloseNotification();
      }, el.autoDismissTime)
    }

    eventToasts.push(
      <Toast
        key={el.id}
        id={el.id}
        top="41"
        bottom={null}
        headerText={el.headerText}
        headerIcon={el.headerIcon}
        bodyText={el.bodyText}
        onDismiss={() => {
          handleOnCloseNotification();
        }}
        showFooter={false}
      />
    );
  });

  // Stored app alerts
  if (storedAppAlert) {
    if (storedAppAlert.showAlert) {
      if (storedAppAlert.autoDismiss) {
        setTimeout(() => {
          setStoredAppAlert(null);
          localStorage.removeItem('alerts');
        }, storedAppAlert.autoDismissTime);
      }

      toasts.push(
        <Toast
          key={storedAppAlert.id}
          id={storedAppAlert.id}
          top="41"
          bottom={null}
          headerText={storedAppAlert.headerText}
          headerIcon={storedAppAlert.headerIcon}
          bodyText={storedAppAlert.bodyText}
          onDismiss={() => {
            localStorage.removeItem('alerts');
            setStoredAppAlert(null);
          }}
          showFooter={false}
        />
      );
    }
  }

  return (
    <Router history={history}>
      <Fragment>
      <PopupMenu
        top={41}
        right={0}
        visible={isUserMenuVisible}
        parent={userIcon}
        onOutsideClick={() => { setUserMenuVisibility(false); }}
        zIndex='2000'
        position="fixed"
      >
        {props.isSignedIn
          ? <UserMenu />
          : <SignInMenu onCloseMenu={setUserMenuVisibility} />
        }
      </PopupMenu>
        {/* fixed-top & sticky disabled for now */}
        <Navbar
          className='sticky-top-nav'
        >
          <NavBrand>
            <Link to="/">
              <div className="fx">
                <img
                  alt="Quantux Logo Small"
                  src={medprosLogoSmall}
                  className={css(styles.navbarBrandImage, styles.navbarBrandImageSmall)}
                />
                <div
                  className="blue-text"
                  style={{
                    marginLeft: '4px',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <img
                    alt="Quantux Logo Large"
                    src={medprosLogoLarge}
                    className={css(styles.navbarBrandImage, styles.navbarBrandImageLarge)}
                  />
                </div>
              </div>
            </Link>
          </NavBrand>
          <Nav>
            {/* Navbar sitemap */}
            <NavItem>
              <i
                className={`fa fa-bars ${css(styles.navIcons)}`}
                onClick={() => toggleSiteMap(!showSiteMap)}
              />
            </NavItem>

            {/* Navbar Search */}
            <NavItem>
              <Link to='/search'>
                <i
                  className={`fa fa-search ${css(styles.navIcons)}`}
                  style={{ color: '#355d8e' }}
                />
              </Link>
            </NavItem>

            {/* Chat messages */}
            {props.isSignedIn
              ? <NavItem>
                  <Link
                    to='/chat'
                    onClick={() => console.log('Clear chat badge!')}
                  >
                    <i
                      className={`fa fa-comments ${css(styles.navIcons)}`}
                      style={{ color: '#355d8e' }}
                    />
                    {props.showChatIconBadge
                      ? <i
                          className='fa fa-exclamation-circle'
                          style={{
                            position: 'relative',
                            top: '-7px',
                            left: '-12px',
                            fontSize: '16px',
                            color: salmon[500],
                            borderRadius: '30%',
                            cursor: 'pointer',
                            minWidth: '12px',
                            textAlign: 'center'
                          }}
                        />
                      : null
                    }
                  </Link>
              </NavItem>
              : null
            }

            {/* Navbar Notifications */}
            {props.isSignedIn
              ? <NavItem>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      paddingLeft: props.notificationsCount > 0 ? '9px' : ''
                    }}
                  >
                    <i
                      className={`fa fa-bell ${css(styles.navIcons)}`}
                      onClick={() => {
                        props.setNotificationsCount(0);
                        toggleNotificationsDrawer(!showNotificationsDrawer);
                      }}
                    />
                    {props.notificationsCount && props.notificationsCount > 0
                      ? <span
                          onClick={() => {
                            props.setNotificationsCount(0);
                            toggleNotificationsDrawer(!showNotificationsDrawer);
                          }}
                          style={{
                            position: 'relative',
                            top: '-7px',
                            left: '-12px',
                            fontSize: '10px',
                            background: blue[500],
                            color: 'white',
                            borderRadius: '30%',
                            cursor: 'pointer',
                            minWidth: '12px',
                            textAlign: 'center'
                          }}
                        >
                          {props.notificationsCount}
                        </span>
                      : null
                    }
                  </div>
                </NavItem>
              : null
            }

            {/* User icon and menu */}
            <NavItem onClick={() => { setUserMenuVisibility(true); }}>
              <div
                ref={userIcon}
                style={{ marginTop: '7px', marginRight: '4px' }}
              >
                {props.usr && props.usr.avatarImageUrl
                  ? <img
                      src={props.usr.avatarImageUrl}
                      alt="avatar"
                      style={{
                        width: 'auto',
                        height: '32px',
                        maxHeight: '32px',
                        borderRadius: '50%'
                      }}
                    />
                  : <i className={`fa fa-user-circle ${css(styles.navIcons)}`} />
                }
                {/* Popup menu was here ...... */}
              </div>
            </NavItem>
          </Nav>
        </Navbar>

        {/* Site Map */}
        <SiteMap
          showSiteMap={showSiteMap}
          closeSiteMap={() => toggleSiteMap(false)}
        />

        {/* Notificatiosn drawer */}
        {showNotificationsDrawer
          ? <NotificationsDrawer
              showNotificationsDrawer={showNotificationsDrawer}
              closeNotificationsDrawer={() => toggleNotificationsDrawer(false)}
            />
          : null
        }

        {/* Signin Dialog */}
        {props.isUserSigninDialogVisible
          ? <SignInDialog
              onCloseDialogBox={() => { props.toggleUserSigninDialog(false); }}
              isVisible={props.isUserSigninDialogVisible}
              showLoginErrorMsg={props.showLoginErrorMsg}
            />
          : null
        }

        {/* Password Reset Dialog */}
        {props.isResetPasswordDialogVisible
          ? <ResetPasswordDialog
              onCloseDialogBox={() => { props.toggleResetPasswordDialog(false); }}
              isVisible={props.isResetPasswordDialogVisible}
              showLoginErrorMsg={props.showInvalidEmailErrorMsg}
            />
          : null
        }

        {/* 2FA Auth Dialog */}
        {props.is2FaVerificationDialogVisible ? <TwoFactorAuthDialog /> : null}

        {/* App alerts */}
        {toasts}
        {eventToasts}

        <Switch>
          <Route path='/kitchensink'><KitchenSink /></Route>
          <Route path='/admin'><AdminProfile /></Route>
          <Route path='/user'><UserProfile /></Route>
          <Route path='/provider'><ProviderDashboard /></Route>
          <Route path='/practice'><PracticeDashboard /></Route>
          <Route path='/search'><Search /></Route>
          <Route path='/settings'><Settings /></Route>
          <Route path='/chat'><Chat /></Route>
          {/* <Route path='/chat'><MobileChat /></Route> */}
          <Route path='/legal/:tab'><Legal /></Route>
          <Route path='/'><Home /></Route>
        </Switch>
      </Fragment>
    </Router>
  );
};

function mapStateToProps ({ user }) {
  return { ...user };
}

export default connect(
  mapStateToProps, {
    toggleUserSigninDialog,
    isUserSignedIn,
    toggleResetPasswordDialog,
    hideAlert,
    getNotifications,
    setNotificationsCount
  })(App);
