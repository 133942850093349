import React from 'react';

const Messages = (props) => {
  return (
    <div>
      <h1>Messages Placeholder</h1>
    </div>
  );
}

export default Messages;
