import React, { useState, useEffect } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types'
import { blue, gray, salmon } from 'quantux-ui';
import { BASE_URL } from '../../utils';

const propTypes = {
  title: PropTypes.string,
  preview: PropTypes.string,
  isSelected: PropTypes.bool,
  id: PropTypes.string,
  unread: PropTypes.number,
  showAvatar: PropTypes.bool,
  avatarUserName: PropTypes.string,
  icon: PropTypes.string,
  style: PropTypes.object,
  onSelection: PropTypes.func,
  showBadge: PropTypes.bool,
  badgeText: PropTypes.string,
  isGroupChat: PropTypes.bool
};

const defaultProps = {
  title: 'Chat title',
  preview: 'The content of the last message sent or received goes here. If the length of the messag…',
  isSelected: false,
  unread: 0,
  showAvatar: true,
  icon: 'fa fa-users',
  showBadge: false,
  badgeText: '0',
  isGroupChat: false
};

const ChatMessageCard = (props) => {
  const [avatarUrl, setAvatarUrl] = useState('');

  useEffect(() => {
    const url = new URL(`${BASE_URL}/users/getUserAvatar`);
    const params = { userName: props.avatarUserName };
    url.search = new URLSearchParams(params).toString();
    fetch(url)
      .then(res => res.text())
      .then((result) => {
        const data = JSON.parse(result);
        // console.log(data);
        setAvatarUrl(data.avatar);
      })
      .catch(error => console.log('error', error))
  });

  const styles = StyleSheet.create({
    chatMessageCard: {
      display: 'flex',
      padding: '8px 4px',
      background: props.isSelected ? blue[900] : gray[900],
      borderLeft: props.isSelected ? `2px solid ${blue[500]}` : '',
      cursor: 'pointer',
      ':hover': {
        background: blue[900]
      }
    },
    iconContainer: {
      width: '48px',
      height: '48px',
      minWidth: '48px',
      // background: blue[500],
      color: 'black',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // fontSize: '48px',
      borderRadius: '5px',
      flexGrow: '0'
    },
    chatDescription: {
      paddingLeft: '8px',
      flexGrow: '1'
    },
    chatTitle: {
      color: blue[500]
    },
    chatPreview: {
      fontSize: '16px',
      marginTop: '-4px'
    },
    unread: {
      flexGrow: '0',
      background: salmon[500],
      color: 'white',
      fontSize: '12px',
      borderRadius: '5px',
      textAlign: 'center',
      margin: '10px 4px',
      padding: '4px'
    }
  });

  return (
    <div
      className={css(styles.chatMessageCard)}
      key={props.id}
      style={{ ...props.style }}
      onClick={() => {
        props.onSelection(props.id);
      }}
    >
      {/* Icon */}
      <div className={css(styles.iconContainer)}>
        {props.showAvatar
          ? <img
              src={avatarUrl}
              alt="avatar"
              loading='lazy'
              style={{
                width: 'auto',
                height: '48px',
                maxHeight: '48px',
                borderRadius: '4px'
              }}
            />
          : <div style={{ position: 'relative' }}>
              <i
                className={props.icon}
                style={{
                  fontSize: '32px',
                  color: blue[500]
                }}
              />
              {props.showBadge
                ? <div
                    style={{
                      position: 'absolute',
                      bottom: '-2px',
                      right: '-8px',
                      background: blue[700],
                      color: 'white',
                      fontSize: '12px',
                      height: '16px',
                      width: '16px',
                      textAlign: 'center',
                      borderRadius: '3px',
                      border: '1px solid black',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    {props.badgeText}
                  </div>
                : null
              }
            </div>
        }

      </div>
      {/* Text */}
      <div className={css(styles.chatDescription)}>
        {/* Title */}
        <div className={css(styles.chatTitle)}>
          {props.title}
        </div>
        {/* Chat preview */}
        <div className={css(styles.chatPreview)}>
          {props.preview}
          {props.isGroupChat
            ? <i className='ml-2 fa fa-bullhorn' />
            : null
          }
        </div>
      </div>
      {/* Unread messages */}
      {props.unread > 0
        ? <div className={css(styles.unread)}>
            {props.unread}
          </div>
        : null
      }
    </div>
  );
};

ChatMessageCard.propTypes = propTypes;
ChatMessageCard.defaultProps = defaultProps;
export default ChatMessageCard;
