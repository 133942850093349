import React from 'react';

const Placeholder = (props) => {
  return (
    <h1 className='p-4'>
      {props.text}
    </h1>
  );
};

export { Placeholder };
