import React, { useState } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { TextInput, InputGroup, TextInputWithLabel } from 'quantux-ui';
import faIcons from './fa-icons';

const IconsDemo = () => {
  const [searchTerm, setSearchTerm] = useState('');
  // const [password, setPassword] = useState('');
  const icons = [];

  faIcons.forEach((el, idx) => {
    if (el.iconClass.indexOf(searchTerm) > -1) {
      icons.push(
        <div
          className="fx fx__v-center"
          key={idx}
        >
          <i className={`fa fa-${el.iconClass}`} />
          <div
            style={{
              width: '140px',
              maxWidth: '140px'
            }}
            className="ml-1 font-three-fourth text-truncate"
          >
            {el.iconClass}
          </div>
        </div>
      );
    }
  });

  const styles = StyleSheet.create({
    iconsParentContainer: {
      width: '100%'
    },
    iconSearchContainer: {
      width: '400px',
      fontFamily: 'Lato'
    },
    iconsContainer: {
      width: '100%',
      display: 'grid',
      gridGap: '16px',
      gap: '16px',
      gridTemplateRows: 'repeat(auto-fill, 18px)',
      gridTemplateColumns: 'repeat(auto-fill,minmax(150px,1fr))'
    }
  });

  const handleOnChangeSearchTerm = (e) => {
    const { value } = e.target;
    setSearchTerm(value);
  };

  return (
    <div className={css(styles.iconsParentContainer)}>
      {/* Icon search */}
      <div className={`mt-2 mb-4 ${css(styles.iconSearchContainer)}`}>
        <InputGroup>
          <div
            className="fx fx__hv-center"
            style={{ background: '#103A60', width: '32px' }}
          >
            <i className="fa fa-search" />
          </div>
          <TextInput
            placeholder="Search icons"
            width={368}
            value={searchTerm}
            onChange={handleOnChangeSearchTerm}
          />
        </InputGroup>
        <TextInputWithLabel
          titleText="Testing ... "
          type="text"
          value={searchTerm}
          onChange={handleOnChangeSearchTerm}
        />
      </div>

      {/* Icons */}
      <div className={`mt-2 ml-2 ${css(styles.iconsContainer)}`}>
        {icons}
      </div>
    </div>
  );
};

export default IconsDemo;
