import React, { useState } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import {
  Drawer,
  Loader,
  PopupMenu,
  blue,
  gray,
  salmon,
  yellow,
  red,
  orange,
  green
} from 'quantux-ui';
import { BASE_URL, getDateString } from '../../utils';

const NotificationsDrawer = (props) => {
  const [notifications, setNotifications] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showNtTypeMenu, toggleNtTypeMenu] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(0);
  const {
    showNotificationsDrawer,
    closeNotificationsDrawer
  } = props;

  const getNotifications = () => {
    setShowLoader(true);
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('email', localStorage.getItem('usernameOrEmail'));

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${BASE_URL}/users/getNotifications`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        console.log('Checking ... notifications');
        console.log('res', res);
        if (res.success) {
          console.log(res.notifications);
          setShowLoader(false);
          setNotifications(res.notifications);
        }
      })
      .catch(error => console.log('error', error));
  };

  useState(() => {
    getNotifications();
  }, []);

  // Refs
  const ntTypeMenuParent = React.createRef();

  // Styles
  const styles = StyleSheet.create({
    drawerContainer: {
      background: gray[900],
      color: gray[300],
      height: '100%',
      position: 'relative',
      display: 'flex',
      flexFlow: 'column'
    },
    drawerHeader: {
      alignItems: 'center',
      padding: '0 8px',
      fontWeight: 'bold',
      borderBottom: `1px solid ${gray[500]}`
    },
    drawerHeaderCloseIcon: {
      color: gray[300],
      ':hover': {
        color: salmon[500]
      }
    },
    drawerContentArea: {
      overflowY: 'auto',
      color: gray[300],
      flexGrow: '1',
      cursor: 'pointer'
    },
    notificationCard: {
      margin: '8px',
      borderRadius: '5px',
      background: blue[800],
      border: `1px solid ${gray[600]}`
      // ':hover': { background: 'black' }
    },
    readCard: {
      background: gray[800]
    },
    notificationCardHeader: {
      height: '32px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: blue[500]
    },
    notificationCardHeaderInfo: {
      color: blue[500]
    },
    notificationCardHeaderSuccess: {
      color: green[500]
    },
    notificationCardHeaderWarning: {
      color: yellow[500]
    },
    notificationCardHeaderDanger: {
      color: red[500]
    },
    notificationCardHeaderError: {
      color: orange[500]
    },
    notificationCardHeaderLeft: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    notificationCardHeaderIcon: {
      minHeight: '32px',
      minWidth: '32px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '20px'
    },
    notificationCardHeaderText: {
      fontWeight: 'bold'
    },
    notificationCardBody: {
      padding: '8px 4px'
    },
    notificationCardFooter: {
      height: '40px',
      padding: '8px 4px',
      fontSize: '14px'
      // textAlign: 'right'
    },
    ntTypeMenuParent: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '8px',
      color: blue[500],
      height: '40px',
      position: 'relative'
    },
    ntTypeMenuContainer: {
      width: '200px',
      background: gray[600]
    },
    ntTypeMenuItem: {
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer'
    }
  });

  const handleOnClickMenuItem = (idx) => {
    setSelectedMenuItem(idx);
  }

  const notificationCards = [];
  notifications.forEach((el) => {
    if (selectedMenuItem === 0) {
      notificationCards.push(
        <div
          key={el._id}
          className={css(styles.notificationCard, el.status === 'Read' && styles.readCard)}
        >
          {/* Header */}
          <div
            className={css(
              styles.notificationCardHeader,
              el.alertLevel === 'Info' && styles.notificationCardHeaderInfo,
              el.alertLevel === 'Success' && styles.notificationCardHeaderSuccess,
              el.alertLevel === 'Warning' && styles.notificationCardHeaderWarning,
              el.alertLevel === 'Danger' && styles.notificationCardHeaderDanger,
              el.alertLevel === 'Error' && styles.notificationCardHeaderError
            )}
          >
            <div className={css(styles.notificationCardHeaderLeft)}>
              {/* Icon */}
              <div className={css(styles.notificationCardHeaderIcon)}>
                <i className={el.icon} />
              </div>
              {/* Heading */}
              <div className={css(styles.notificationCardHeaderText)}>
                {el.heading}
              </div>
            </div>
          </div>
          {/* Body */}
          <div className={css(styles.notificationCardBody)}>
            {el.body}
          </div>
          {el.requestMetaData
            ? <ul style={{ margin: '4px', paddingLeft: '16px' }}>
              <li>{`Browser: ${el.requestMetaData.browser.name} ${el.requestMetaData.browser.major}`}</li>
              <li>{`OS: ${el.requestMetaData.os.name} ${el.requestMetaData.os.version}`}</li>
              <li>{`IP: ${el.requestMetaData.ip}`}</li>
            </ul>
            : null
          }
          {/* Footer */}
          <div className={css(styles.notificationCardFooter)}>
            {getDateString(el.creationDate)}
          </div>
        </div>
      )
    } else if ((selectedMenuItem === 1)) {
      if (el.category === 'Security') {
        notificationCards.push(
          <div
            key={el._id}
            className={css(styles.notificationCard, el.status === 'Read' && styles.readCard)}
          >
            {/* Header */}
            <div
              className={css(
                styles.notificationCardHeader,
                el.alertLevel === 'Info' && styles.notificationCardHeaderInfo,
                el.alertLevel === 'Success' && styles.notificationCardHeaderSuccess,
                el.alertLevel === 'Warning' && styles.notificationCardHeaderWarning,
                el.alertLevel === 'Danger' && styles.notificationCardHeaderDanger,
                el.alertLevel === 'Error' && styles.notificationCardHeaderError
              )}
            >
              <div className={css(styles.notificationCardHeaderLeft)}>
                {/* Icon */}
                <div className={css(styles.notificationCardHeaderIcon)}>
                  <i className={el.icon} />
                </div>
                {/* Heading */}
                <div className={css(styles.notificationCardHeaderText)}>
                  {el.heading}
                </div>
              </div>
            </div>
            {/* Body */}
            <div className={css(styles.notificationCardBody)}>
              {el.body}
            </div>
            {el.requestMetaData
              ? <ul style={{ margin: '4px', paddingLeft: '16px' }}>
                <li>{`Browser: ${el.requestMetaData.browser.name} ${el.requestMetaData.browser.major}`}</li>
                <li>{`OS: ${el.requestMetaData.os.name} ${el.requestMetaData.os.version}`}</li>
                <li>{`IP: ${el.requestMetaData.ip}`}</li>
              </ul>
              : null
            }
            {/* Footer */}
            <div className={css(styles.notificationCardFooter)}>
              {getDateString(el.creationDate)}
            </div>
          </div>
        )
      }
    } else {
      if (el.status === 'Unread') {
        notificationCards.push(
          <div
            key={el._id}
            className={css(styles.notificationCard, el.status === 'Read' && styles.readCard)}
          >
            {/* Header */}
            <div
              className={css(
                styles.notificationCardHeader,
                el.alertLevel === 'Info' && styles.notificationCardHeaderInfo,
                el.alertLevel === 'Success' && styles.notificationCardHeaderSuccess,
                el.alertLevel === 'Warning' && styles.notificationCardHeaderWarning,
                el.alertLevel === 'Danger' && styles.notificationCardHeaderDanger,
                el.alertLevel === 'Error' && styles.notificationCardHeaderError
              )}
            >
              <div className={css(styles.notificationCardHeaderLeft)}>
                {/* Icon */}
                <div className={css(styles.notificationCardHeaderIcon)}>
                  <i className={el.icon} />
                </div>
                {/* Heading */}
                <div className={css(styles.notificationCardHeaderText)}>
                  {el.heading}
                </div>
              </div>
            </div>
            {/* Body */}
            <div className={css(styles.notificationCardBody)}>
              {el.body}
            </div>
            {el.requestMetaData
              ? <ul style={{ margin: '4px', paddingLeft: '16px' }}>
                <li>{`Browser: ${el.requestMetaData.browser.name} ${el.requestMetaData.browser.major}`}</li>
                <li>{`OS: ${el.requestMetaData.os.name} ${el.requestMetaData.os.version}`}</li>
                <li>{`IP: ${el.requestMetaData.ip}`}</li>
              </ul>
              : null
            }
            {/* Footer */}
            <div className={css(styles.notificationCardFooter)}>
              {getDateString(el.creationDate)}
            </div>
          </div>
        )
      }
    }
  });

  const activeText = ['All Notifications', 'Security', 'New'];

  return (
    <div>
      <Drawer
        placement="right"
        visible={showNotificationsDrawer}
        toggleDrawer={closeNotificationsDrawer}
        zIndex="2000"
        width={window.innerWidth <= 700 ? window.innerWidth : 358}
      >
        {/* Drawer Container */}
        <div className={css(styles.drawerContainer)}>
          <div className={css(styles.drawerHeader)}>
            <div
              style={{
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                padding: '0 8px',
                fontWeight: 'bold'
              }}
            >
              <i
                className={`mr-2 fa fa-lg fa-times-circle ${css(styles.drawerHeaderCloseIcon)}`}
                onClick={closeNotificationsDrawer}
              />
              <div>Profile Notifications</div>
            </div>
            {/* Notification type popup menu */}
            <div
              className={css(styles.ntTypeMenuParent)}
              ref={ntTypeMenuParent}
              onClick={() => toggleNtTypeMenu(!showNtTypeMenu)}
            >
              <div>{activeText[selectedMenuItem]}</div>
              <i className={`ml-2 fa ${showNtTypeMenu ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
              <PopupMenu
                top={41}
                left={0}
                visible={showNtTypeMenu}
                parent={ntTypeMenuParent}
                onOutsideClick={() => { toggleNtTypeMenu(false); }}
                zIndex='1600'
                position="absolute"
              >
                <div className={css(styles.ntTypeMenuContainer)}>
                  <div
                    className={`m-item ${css(styles.ntTypeMenuItem)} ${selectedMenuItem === 0 ? 'active' : ''}`}
                    onClick={() => handleOnClickMenuItem(0)}
                  >
                    <div>All Notifications</div>
                  </div>
                  <div
                    className={`m-item ${css(styles.ntTypeMenuItem)} ${selectedMenuItem === 1 ? 'active' : ''}`}
                    onClick={() => handleOnClickMenuItem(1)}
                  >
                    <div>Security</div>
                  </div>
                  <div
                    className={`m-item ${css(styles.ntTypeMenuItem)} ${selectedMenuItem === 2 ? 'active' : ''}`}
                    onClick={() => handleOnClickMenuItem(2)}
                  >
                    <div>New</div>
                  </div>
                </div>
              </PopupMenu>
            </div>
          </div>

          {/* Content area */}
          <div className={css(styles.drawerContentArea)}>
            {showLoader
              ? <Loader iconType="cog" iconSize="5x" text="Refreshing notifications" />
              : notificationCards
            }
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default NotificationsDrawer;
