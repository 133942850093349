import React from 'react';

const SecurityEventsLog = (props) => {
  return (
    <div>
      <h1>Security Events Log Placeholder</h1>
    </div>
  );
}

export default SecurityEventsLog;
