import React, { Component } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { clone } from 'ramda';
import {
  TabsContainer,
  TabItem,
  TextInput,
  Drawer,
  gray,
  blue
} from 'quantux-ui';
import { BASE_URL } from '../utils';
import ChatMessageCard from '../components/chat-message-card';
import ChatRoom from '../components/chat-room';

const tabsTextStyles = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};

const tabsStyles = {
  borderRight: '1px solid #082840',
  borderLeft: '1px solid #082840',
  paddingRight: '0',
  width: '140px',
  minWidth: '140px',
  maxWidth: '140px'
};

const styles = StyleSheet.create({
  chatContainer: {
    height: '100%',
    backgroundColor: 'white',
    marginTop: '40px'
  },
  chatCardsDrawer: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    backgroundColor: gray[800]
  },
  chatSearchBar: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '8px',
    height: '40px',
    borderBottom: `1px solid ${gray[400]}`
  },
  chatSearchBarIcon: {
    height: '32px',
    width: '32px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '4px',
    cursor: 'pointer',
    marginLeft: '4px'
  },
  chatCardsContainer: {
    overflowX: 'hidden',
    overflowY: 'auto',
    // flexGrow: '1',
    // height: 'calc(100% - 124px)',
    height: '300px',
    backgroundColor: 'red'
  },
  chatRoomDrawer: {
    display: 'flex',
    flexFlow: 'column',
    height: '-webkit-fill-available',
    // height: '100%',
    // backgroundColor: gray[800]
    backgroundColor: 'red'
  },
  chatRoomDrawerHeader: {
    display: 'flex',
    height: '40px',
    // background: gray[600],
    background: 'green',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  chatRoomDrawerHeaderCloseIcon: {
    height: '32px',
    width: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  chatRoom: {
    flexGrow: '1'
  }
});

class MobileChat extends Component {
  state = {
    showChatCardsDrawer: true,
    activeTab: 0,
    chatSearchText: '',
    avatarUrl: '',
    chats: [],
    activeChat: { _id: 0 },
    isLoadingChat: false,
    chatTitle: ''
  };

  // #region LifeCycle methods
  componentDidMount () {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('userId', this.usr._id);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(`${BASE_URL}/users/getChats`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        if (res.chats) {
          this.setState({ chats: res.chats });
          // if (res.chats.length > 0) {
          //   this.handleOnLoadChat(res.chats[0]._id, false);
          // }
        }
      })
      .catch(err => console.log(err));
  }
  // #endregion

  // Instance Variables
  usr = JSON.parse(localStorage.getItem('usr'));

  // #region Instance Methods
  handleOnChangeChatSearchText = (e) => {
    const { value } = e.target;
    this.setState({ chatSearchText: value });
  };

  handleOnLoadChat = async (chatId) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);
    const formdata = new FormData();
    formdata.append('chatId', chatId);
    formdata.append('userName', this.usr.userName);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    // Activate chat loader
    const updatedChats = clone(this.state.chats);
    for (let i = 0; i < updatedChats.length; i++) {
      if (updatedChats[i]._id === chatId) {
        updatedChats[i].unread = 0;
      }
    }
    this.setState({
      isLoadingChat: true,
      chats: updatedChats
    });

    const res = await fetch(`${BASE_URL}/users/getChatRoom`, requestOptions).catch(err => console.log(err));
    const data = await res.json();
    if (data.chat) {
      let title = '';
      let avatarUserName = '';
      if (this.usr.userName === data.chat.senderUserName) {
        title = `${data.chat.recipientDisplayName} @${data.chat.recipientUserName}`;
        avatarUserName = data.chat.recipientUserName;
      } else {
        title = `${data.chat.senderDisplayName} @${data.chat.senderUserName}`;
        avatarUserName = data.chat.senderUserName;
      }

      this.setState(() => ({
        isLoadingChat: false,
        activeChat: data.chat,
        chatTitle: title,
        showChatCardsDrawer: false
      }), () => {
        const url = new URL(`${BASE_URL}/users/getUserAvatar`);
        const params = { userName: avatarUserName };
        url.search = new URLSearchParams(params).toString();
        fetch(url)
          .then(res => res.text())
          .then((result) => {
            const data = JSON.parse(result);
            this.setState({ avatarUrl: data.avatar });
          })
          .catch(error => console.log('error', error))
      });
    }
  };

  toggleChatRoomDrawer = () => {
    this.setState({ showChatCardsDrawer: true });
  };

  handleOnPostMessage = () => {
    console.log('handleOnPostMessage');
  };
  // #endregion

  render () {
    const {
      showChatCardsDrawer,
      activeTab,
      chatSearchText,
      chats,
      activeChat
    } = this.state;

    // #region DM Cards
    const dmCards = [];
    if (chats.length > 0) {
      chats.forEach((el) => {
        let title = '';
        let avatarUserName = '';
        if (this.usr.userName === el.senderUserName) {
          title = `${el.recipientDisplayName} @${el.recipientUserName}`;
          avatarUserName = el.recipientUserName;
        } else {
          title = `${el.senderDisplayName} @${el.senderUserName}`;
          avatarUserName = el.senderUserName;
        }

        dmCards.push(
          <ChatMessageCard
            key={el._id}
            id={el._id}
            unread={el.unread}
            title={title}
            isSelected={activeChat._id === el._id}
            style={{ marginTop: '4px' }}
            avatarUserName={avatarUserName}
            preview=''
            onSelection={this.handleOnLoadChat}
          />
        );
      });
    }
    // #endregion

    const chatCardsDrawer = (
      <div className={css(styles.chatCardsDrawer)}>
        {/* Tabs */}
        <div>
          <TabsContainer
            dropDownId="d1"
            showPlusIcon={false}
            containerWidth={window.innerWidth}
            otherElementsWidth={0}
            minWidth={320}
            containerStyles={{ width: window.innerWidth }}
          >
            {/* DM's */}
            <TabItem
              tabKey="1"
              key='9898'
              parentKey="0111"
              text="DM's"
              isActive={activeTab === 0}
              iconClass='fa fa-comments'
              style={tabsStyles}
              textStyles={tabsTextStyles}
              onActivate={() => {
                console.log('Activating DM Tab');
                this.setState({ activeTab: 0 });
              }}
            >
              DM&#39;s
            </TabItem>
            {/* Group chats */}
            <TabItem
              tabKey="2"
              key='9899'
              parentKey="0111"
              text="Group Chats"
              isActive={activeTab === 1}
              iconClass='fa fa-users'
              style={tabsStyles}
              textStyles={tabsTextStyles}
              onActivate={() => {
                console.log('Activating Group Chat Tab');
                this.setState({ activeTab: 1 });
              }}
            >
              Group Chats
            </TabItem>
          </TabsContainer>
        </div>
        {/* Chat Search */}
        <div className={css(styles.chatSearchBar)}>
          <div> <i className='fa fa-search' /> </div>
          <TextInput
            value={chatSearchText}
            placeholder="Search Users, Groups, and Messages"
            style={{ width: '250px', fontSize: '14px' }}
            onChange={this.handleOnChangeChatSearchText}
          />
          {/* New Chat Icon */}
          <div className={css(styles.chatSearchBarIcon)}>
            <svg
              width="31"
              height="24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.275 6.585a8.332 8.332 0 0 1 1.93 2.667c.47 1.039.71 2.143.708 3.282a7.87 7.87 0 0 1-.71 3.28 8.367 8.367 0 0 1-1.93 2.666 8.983 8.983 0 0 1-2.845 1.788c-1.1.432-2.269.653-3.472.653a9.5 9.5 0 0 1-2.796-.417L3.565 21.98a.638.638 0 0 1-.594.014.626.626 0 0 1-.341-.488l-.405-3.44C.79 16.534 0 14.578 0 12.532c0-1.137.24-2.241.71-3.28a8.366 8.366 0 0 1 1.93-2.667 8.982 8.982 0 0 1 2.845-1.788 9.462 9.462 0 0 1 3.472-.652c1.204 0 2.373.22 3.473.652a8.98 8.98 0 0 1 2.845 1.788Zm-.86 10.971a7.094 7.094 0 0 0 1.642-2.265 6.654 6.654 0 0 0 .002-5.518 7.105 7.105 0 0 0-1.641-2.266 7.719 7.719 0 0 0-2.45-1.538 8.203 8.203 0 0 0-3.01-.565c-1.045 0-2.058.191-3.01.565A7.749 7.749 0 0 0 3.5 7.507a7.098 7.098 0 0 0-1.641 2.266 6.627 6.627 0 0 0-.598 2.759c0 1.783.715 3.49 2.013 4.806.098.1.161.229.177.369l.318 2.706 2.01-1.142a.63.63 0 0 1 .515-.049c.85.29 1.748.437 2.663.437a8.167 8.167 0 0 0 3.01-.565 7.748 7.748 0 0 0 2.45-1.538Z" fill="#00A0F4"/><path d="M8.439 7.766A10.524 10.524 0 0 1 10.876 4.4 11.345 11.345 0 0 1 14.47 2.14a11.952 11.952 0 0 1 4.386-.824c1.52 0 2.997.278 4.387.824 1.346.53 2.555 1.29 3.593 2.259a10.567 10.567 0 0 1 2.438 3.367 9.98 9.98 0 0 1 .897 4.144c0 2.583-.997 5.054-2.811 6.99l-.51 4.345a.79.79 0 0 1-.79.702.806.806 0 0 1-.393-.104l-3.277-1.862c-1.136.349-2.323.525-3.532.525a11.95 11.95 0 0 1-4.386-.824 11.345 11.345 0 0 1-3.593-2.258 10.566 10.566 0 0 1-2.438-3.368 9.94 9.94 0 0 1-.897-4.144 9.947 9.947 0 0 1 .895-4.146Z"
                fill={blue[500]}
              />
              <path
                d="M19.815 11.104V7.92h-1.712v3.184H14.92v1.712h3.184V16h1.712v-3.184H23v-1.712h-3.184Z"
                fill={gray[900]}
              />
            </svg>
          </div>
        </div>
        {/* DM Cards */}
        <div className={css(styles.chatCardsContainer)}>
          {dmCards}
        </div>
      </div>
    );

    const chatRoomDrawer = (
      <Drawer
        placement='right'
        visible={!showChatCardsDrawer}
        toggleDrawer={this.toggleChatRoomDrawer}
        zIndex="2000"
        width={window.innerWidth}
      >
        <div className={css(styles.chatRoomDrawer)}>
          {/* Header */}
          <div className={css(styles.chatRoomDrawerHeader)}>
            <div>
              Chat Room
            </div>
            <div
              className={css(styles.chatRoomDrawerHeaderCloseIcon)}
              onClick={this.toggleChatRoomDrawer}
            >
              <i className='fa fa-times-circle' />
            </div>
          </div>
          {/* Chat room */}
          <div className={css(styles.chatRoom)}>
            <ChatRoom
              room={activeChat}
              onPostMessage={this.handleOnPostMessage}
              onUploadFile={this.handleOnPostMessage}
            />
          </div>
        </div>
      </Drawer>
    );

    return (
      <div className={css(styles.chatContainer)}>
        {showChatCardsDrawer ? chatCardsDrawer : chatRoomDrawer}
        {/* <div
          style={{
            background: 'yellow',
            height: '1px',
            width: '200px',
            position: 'fixed',
            bottom: '0',
            right: '0'
          }}
        /> */}
      </div>
    );
  };
}

export default MobileChat;
