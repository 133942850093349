import React, { useState, useContext } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite/no-important';
import { DialogBox, TextInputWithLabel, Button, ThemeContext } from 'quantux-ui';
import { sendTempPassword, resetPassword } from '../../actions';

const propTypes = {
  isVisible: PropTypes.bool,
  showInvalidEmailErrorMsg: PropTypes.bool,
  resetPasswordStep1Active: PropTypes.bool,
  resetPasswordStep2Active: PropTypes.bool,
  onCloseDialogBox: PropTypes.func,
  initiateUserSignIn: PropTypes.func,
  finalizeSignIn: PropTypes.func,
  signinUser: PropTypes.func,
  resetPassword: PropTypes.func
};

const defaultProps = {
  isVisible: false
};

const ResetPasswordDialog = props => {
  const [email, setEmail] = useState('');
  const [tempPassword, setTempPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);
  const [isNewPasswordPristine, setIsNewPasswordPristine] = useState(true);
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isConfirmNewPasswordValid, setIsConfirmNewPasswordValid] = useState(false);
  const [isConfirmNewPasswordPristine, setIsConfirmNewPasswordPristine] = useState(true);

  const dialogWidth = window.innerWidth <= 576 ? (window.innerWidth - 16) : 350;

  const {
    isVisible,
    onCloseDialogBox,
    showInvalidEmailErrorMsg,
    resetPasswordStep1Active,
    resetPasswordStep2Active
  } = props;

  const handleOnClickStep2 = () => {
    if (email.length > 0) {
      props.sendTempPassword(email);
    }
  };

  const handleOnClickResetPassword = () => {
    props.resetPassword({
      email,
      tempPassword,
      newPassword,
      confirmNewPassword
    });
  };

  const handleOnChangeEmail = (e) => {
    const { value } = e.target;
    setEmail(value);
  };

  const handleOnChangeTempPassword = (e) => {
    const { value } = e.target;
    setTempPassword(value);
  };

  const handleOnChangeNewPassword = (e) => {
    const { value } = e.target;
    setNewPassword(value);
    setIsNewPasswordPristine(false);
    setIsNewPasswordValid(value.indexOf(' ') < 0);
  };

  const handleOnChangeConfirmNewPassword = (e) => {
    const { value } = e.target;
    setConfirmNewPassword(value);
    setIsConfirmNewPasswordPristine(false);
    setIsConfirmNewPasswordValid(value === newPassword);
  };

  const context = useContext(ThemeContext);
  const { gray, blue, salmon } = context.colors;

  const styles = StyleSheet.create({
    stepsContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    stepHeading: {
      color: gray[400],
      fontSize: '1rem'
    },
    activeStepHeading: {
      color: blue[500]
    },
    errMsg: {
      color: salmon[500],
      minHeight: '40px',
      maxHeight: '40px',
      height: '40px'
    }
  });

  return (
    <DialogBox
      initWidth={dialogWidth}
      top={window.innerWidth <= 576 ? 48 : null}
      left={window.innerWidth <= 576 ? 8 : null}
      initHeight={378}
      isOpen={isVisible}
      onClose={() => { onCloseDialogBox(false); }}
      maskBackground="#004080"
      maskOpacity={0.5}
      closeOnEscKey
      closeOnMaskClick
      header={{
        text: 'Set/Reset Password',
        backColor: '#082840',
        foreColor: '#5080B0',
        closeColor: '#FF6060',
        fontStyle: 'bold',
        fontSize: '20px',
        icon: 'fa fa-lock'
      }}
    >
      {/* Dialogbox container */}
      <div className="p-2">
        {/* Step 1 & Step 2 heading */}
        <div className={`mt-2 mb-2 ${css(styles.stepsContainer)}`}>
          <div className={`mr-3 ${css(styles.stepHeading, resetPasswordStep1Active && styles.activeStepHeading)}`}>
            Step 1
          </div>
          <div className={css(styles.stepHeading, resetPasswordStep2Active && styles.activeStepHeading)}>
            Step 2
          </div>
        </div>
        {resetPasswordStep1Active
          ? <>
              {/* Email */}
              <div style={{ marginTop: '10px' }}>
                <TextInputWithLabel
                  value={email}
                  type='email'
                  titleText="Email"
                  isCompactLayout={false}
                  titleColor="#5079ab"
                  width={dialogWidth - 16}
                  showPreIcon
                  preIcon="fa fa-envelope"
                  onChange={handleOnChangeEmail}
                />
              </div>
              {/* Error msg */}
              <div className={css(styles.errMsg)}>
                {showInvalidEmailErrorMsg ? 'Email not in system' : ''}
              </div>
              {/* Proceed to Step 2 Btn */}
              <div style={{ marginTop: '32px' }}>
                <Button
                  btnType="primary"
                  isDisabled={email.length === 0}
                  style={{
                    minWidth: `${dialogWidth - 16}px`,
                    fontWeight: 'bold',
                    borderRadius: '4px'
                  }}
                  onClick={handleOnClickStep2}
                >
                  Proceed to Step 2
                </Button>
              </div>
            </>
          : null
          }
          {resetPasswordStep2Active
            ? <>
                {/* Temp password */}
                <div className='mt-3'>
                  <TextInputWithLabel
                    value={tempPassword}
                    type="password"
                    titleText="Temporary Password"
                    isCompactLayout={false}
                    titleColor="#5079ab"
                    width={dialogWidth - 16}
                    showPreIcon
                    preIcon="fa fa-lock"
                    showPostIcon
                    postIcon="fa fa-eye"
                    onChange={handleOnChangeTempPassword}
                  />
                </div>
                {/* New password */}
                <div style={{ marginTop: '50px' }}>
                  <TextInputWithLabel
                    value={newPassword}
                    type="password"
                    titleText="New Password"
                    isCompactLayout={false}
                    titleColor="#5079ab"
                    width={dialogWidth - 16}
                    showPreIcon
                    preIcon="fa fa-lock"
                    showPostIcon
                    postIcon="fa fa-eye"
                    onChange={handleOnChangeNewPassword}
                  />
                </div>
                {/* Error msg */}
                <div className={css(styles.errMsg)}>
                  {!isNewPasswordPristine && !isNewPasswordValid ? 'Invalid password' : ''}
                </div>
                {/* Confirm new password */}
                <div className='mt-3'>
                  <TextInputWithLabel
                    value={confirmNewPassword}
                    type="password"
                    titleText="Confirm New Password"
                    isCompactLayout={false}
                    titleColor="#5079ab"
                    width={dialogWidth - 16}
                    showPreIcon
                    preIcon="fa fa-lock"
                    showPostIcon
                    postIcon="fa fa-eye"
                    onChange={handleOnChangeConfirmNewPassword}
                  />
                </div>
                {/* Error msg */}
                <div className={css(styles.errMsg)}>
                  {!isConfirmNewPasswordPristine && !isConfirmNewPasswordValid ? 'Password Does Not Match' : ''}
                </div>
                {/* Reset Password Btn */}
                <div style={{ marginTop: '5px' }}>
                  <Button
                    btnType="primary"
                    isDisabled={!(newPassword.length > 0 && newPassword === confirmNewPassword)}
                    style={{
                      minWidth: `${dialogWidth - 16}px`,
                      fontWeight: 'bold',
                      borderRadius: '4px'
                    }}
                    onClick={handleOnClickResetPassword}
                  >
                    Reset Password
                  </Button>
                </div>
              </>
            : null
          }
      </div>
    </DialogBox>
  );
};

function mapStateToProps ({ user }) {
  return { ...user };
}

ResetPasswordDialog.propTypes = propTypes;
ResetPasswordDialog.defaultProps = defaultProps;

export default connect(
  mapStateToProps, {
    sendTempPassword,
    resetPassword
  })(ResetPasswordDialog);
