import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Link } from 'react-router-dom';
import { blue, Drawer, gray, salmon } from 'quantux-ui';

const SiteMap = (props) => {
  const {
    showSiteMap,
    closeSiteMap
  } = props;

  const styles = StyleSheet.create({
    drawerContainer: {
      background: gray[800],
      color: gray[300],
      height: '100%',
      position: 'relative',
      display: 'flex',
      flexFlow: 'column'
    },
    drawerHeader: {
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 8px',
      fontWeight: 'bold',
      borderBottom: `1px solid ${gray[500]}`,
      flexShrink: '0'
    },
    drawerHeaderCloseIcon: {
      color: gray[300],
      ':hover': {
        color: salmon[500]
      }
    },
    drawerContentArea: {
      overflowY: 'auto',
      color: gray[300],
      flexGrow: '1',
      cursor: 'pointer'
    },
    siteMapItem: {
      color: blue[500],
      display: 'flex',
      alignItems: 'center',
      height: '40px',
      paddingLeft: '8px',
      ':hover': {
        color: blue[500],
        background: gray[700]
      }
    },
    siteMapItemIcon: {
      fontSize: '28px',
      marginRight: '8px',
      width: '32px',
      minWidth: '32px',
      maxWidth: '32px'
    },
    siteMapItemText: {
      fontSize: '16px',
      fontWeight: '700'
    },
    siteMapSeparator: {
      border: `1px solid ${gray[500]}`
    },
    siteMapSectionHeading: {
      paddingLeft: '8px',
      fontSize: '20px',
      fontWeight: '700',
      margin: '8px 0'
    }
  });

  const addressUrl = 'https://www.google.com/maps/search/?api=1&query=' + encodeURI('9375 East Shea BLVD Suite 100 Scottsdale, AZ 85260');

  return (
    <div>
      <Drawer
        placement="right"
        visible={showSiteMap}
        toggleDrawer={closeSiteMap}
        zIndex="2000"
        width={window.innerWidth <= 700 ? window.innerWidth : 358}
      >
        {/* Drawer Container */}
        <div className={css(styles.drawerContainer)}>
          <div className={css(styles.drawerHeader)}>
            <div className='fx fx__hv-center'>
              <i
                className={`mr-2 fa fa-lg fa-times-circle ${css(styles.drawerHeaderCloseIcon)}`}
                onClick={closeSiteMap}
              />
              <div>MedPros</div>
            </div>
          </div>
          {/* Site map content area */}
          <div className={css(styles.drawerContentArea)}>
            {/* Home */}
            <Link to='/'>
              <div className={css(styles.siteMapItem)}>
                <i className={`fa fa-home ${css(styles.siteMapItemIcon)}`} />
                <div className={css(styles.siteMapItemText)}>
                  <div>Home</div>
                </div>
              </div>
            </Link>
            {/* Search */}
            <Link to='/search'>
              <div className={css(styles.siteMapItem)}>
                <div className={css(styles.siteMapItemIcon)}>
                  <i className='fa fa-search' />
                </div>
                <div className={css(styles.siteMapItemText)}>
                  <div>Search</div>
                </div>
              </div>
            </Link>
            {/* Separator */}
            <div className={css(styles.siteMapSeparator)} />
            {/* Contact Us */}
            <div>
              <div className={css(styles.siteMapSectionHeading)}>
                Contact Us
              </div>
              {/* Address */}
              <div
                className={css(styles.siteMapItem)}
                style={{
                  height: '100px',
                  minHeight: '100px',
                  maxHeight: '100px',
                  alignItems: 'flex-start'
                }}>
                <div className={css(styles.siteMapItemIcon)}>
                  <i className='fa fa-map-marker' />
                </div>
                <div className={css(styles.siteMapItemText)}>
                  <a
                    target="_blank"
                    href={addressUrl}
                    rel="noreferrer"
                  >
                    <span>MedPros</span><br />
                    <span>9375 East Shea BLVD</span><br />
                    <span>Suite 100</span><br />
                    <span>Scottsdale, AZ 85260</span><br />
                  </a>
                </div>
              </div>
              {/* Phone */}
              <div className={css(styles.siteMapItem)}>
                <div className={css(styles.siteMapItemIcon)}>
                  <i className='fa fa-phone' />
                </div>
                <div className={css(styles.siteMapItemText)}>
                  <a
                    href='tel:+18447826889'
                    rel="noreferrer"
                  >
                    <div>844.782.6889</div>
                  </a>
                </div>
              </div>
              {/* Email */}
              <div className={css(styles.siteMapItem)}>
                <div className={css(styles.siteMapItemIcon)}>
                  <i className='fa fa-envelope' />
                </div>
                <div className={css(styles.siteMapItemText)}>
                  <a href='mailto:medprosinc@gmail.com' rel="noreferrer">
                    <div>medprosinc@gmail.com</div>
                  </a>
                </div>
              </div>
            </div>
            {/* Separator */}
            <div className={css(styles.siteMapSeparator)} />
            {/* Find Us */}
            <div>
              <div className={css(styles.siteMapSectionHeading)}>
                Find Us
              </div>
              {/* Linked In */}
              <div className={css(styles.siteMapItem)}>
                <div className={css(styles.siteMapItemIcon)}>
                  <i className='fa fa-linkedin' />
                </div>
                <div className={css(styles.siteMapItemText)}>
                  <a
                    href='https://www.linkedin.com/in/med-pros-b12060208/'
                    rel="noreferrer"
                    target="_blank"
                  >
                    <div>LinkedIn</div>
                  </a>
                </div>
              </div>
              {/* Twitter */}
              <div className={css(styles.siteMapItem)}>
                <div className={css(styles.siteMapItemIcon)}>
                  <i className='fa fa-twitter' />
                </div>
                <div className={css(styles.siteMapItemText)}>
                  <a
                    href='https://twitter.com/medprosinc'
                    rel="noreferrer"
                    target="_blank"
                  >
                    <div>Twitter</div>
                  </a>
                </div>
              </div>
            </div>
            {/* Separator */}
            <div className={css(styles.siteMapSeparator)} />
            {/* Legal */}
            <div>
              <div className={css(styles.siteMapSectionHeading)}>
                Legal
              </div>
              {/* Terms of Service */}
              <Link to='/legal/tos'>
                <div className={css(styles.siteMapItem)}>
                  <div className={css(styles.siteMapItemText)}>
                    <div className='pl-2'>Terms of Service</div>
                  </div>
                </div>
              </Link>
              {/* Privacy Policy */}
              <Link to='/legal/pPolicy'>
                <div className={css(styles.siteMapItem)}>
                  <div className={css(styles.siteMapItemText)}>
                    <div className='pl-2'>Privacy Policy</div>
                  </div>
                </div>
              </Link>
              {/* Cookie Policy */}
              <Link to='/legal/cPolicy'>
                <div className={css(styles.siteMapItem)}>
                  <div className={css(styles.siteMapItemText)}>
                    <div className='pl-2'>Cookie Policy</div>
                  </div>
                </div>
              </Link>
              {/* HIPPA Policy */}
              <Link to='/legal/hPolicy'>
                <div className={css(styles.siteMapItem)}>
                  <div className={css(styles.siteMapItemText)}>
                    <div className='pl-2'>HIPPA Policy</div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default SiteMap;
