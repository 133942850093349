const faIcons = [
  { iconClass: 'address-book' },
  { iconClass: 'address-book-o' },
  { iconClass: 'address-card' },
  { iconClass: 'address-card-o' },
  { iconClass: 'bandcamp' },
  { iconClass: 'bath' },
  { iconClass: 'eercast' },
  { iconClass: 'bathtub' },
  { iconClass: 'drivers-license' },
  { iconClass: 'drivers-license-o' },
  { iconClass: 'envelope-open' },
  { iconClass: 'envelope-open-o' },
  { iconClass: 'etsy' },
  { iconClass: 'free-code-camp' },
  { iconClass: 'grav' },
  { iconClass: 'handshake-o' },
  { iconClass: 'id-badge' },
  { iconClass: 'id-card' },
  { iconClass: 'id-card-o' },
  { iconClass: 'imdb' },
  { iconClass: 'linode' },
  { iconClass: 'meetup' },
  { iconClass: 'microchip' },
  { iconClass: 'podcast' },
  { iconClass: 'quora' },
  { iconClass: 'ravelry' },
  { iconClass: 's15' },
  { iconClass: 'shower' },
  { iconClass: 'snowflake-o' },
  { iconClass: 'superpowers' },
  { iconClass: 'telegram' },
  { iconClass: 'thermometer' },
  { iconClass: 'thermometer-0' },
  { iconClass: 'thermometer-1' },
  { iconClass: 'thermometer-2' },
  { iconClass: 'thermometer-3' },
  { iconClass: 'thermometer-4' },
  { iconClass: 'thermometer-empty' },
  { iconClass: 'thermometer-full' },
  { iconClass: 'thermometer-half' },
  { iconClass: 'thermometer-quarter' },
  { iconClass: 'thermometer-three-quarters' },
  { iconClass: 'times-rectangle' },
  { iconClass: 'times-rectangle-o' },
  { iconClass: 'user-circle' },
  { iconClass: 'user-circle-o' },
  { iconClass: 'user-o' },
  { iconClass: 'vcard' },
  { iconClass: 'vcard-o' },
  { iconClass: 'window-close' },
  { iconClass: 'window-close-o' },
  { iconClass: 'window-maximize' },
  { iconClass: 'window-minimize' },
  { iconClass: 'window-restore' },
  { iconClass: 'wpexplorer' },
  { iconClass: 'address-book' },
  { iconClass: 'address-book-o' },
  { iconClass: 'address-card' },
  { iconClass: 'address-card-o' },
  { iconClass: 'adjust' },
  { iconClass: 'american-sign-language-interpreting' },
  { iconClass: 'anchor' },
  { iconClass: 'archive' },
  { iconClass: 'area-chart' },
  { iconClass: 'arrows' },
  { iconClass: 'arrows-h' },
  { iconClass: 'arrows-v' },
  { iconClass: 'asl-interpreting' },
  { iconClass: 'assistive-listening-systems' },
  { iconClass: 'asterisk' },
  { iconClass: 'at' },
  { iconClass: 'audio-description' },
  { iconClass: 'automobile' },
  { iconClass: 'balance-scale' },
  { iconClass: 'ban' },
  { iconClass: 'bank' },
  { iconClass: 'bar-chart' },
  { iconClass: 'bar-chart-o' },
  { iconClass: 'barcode' },
  { iconClass: 'bars' },
  { iconClass: 'bath' },
  { iconClass: 'bathtub' },
  { iconClass: 'battery' },
  { iconClass: 'battery-0' },
  { iconClass: 'battery-1' },
  { iconClass: 'battery-2' },
  { iconClass: 'battery-3' },
  { iconClass: 'battery-4' },
  { iconClass: 'battery-empty' },
  { iconClass: 'battery-full' },
  { iconClass: 'battery-half' },
  { iconClass: 'battery-quarter' },
  { iconClass: 'battery-three-quarters' },
  { iconClass: 'bed' },
  { iconClass: 'beer' },
  { iconClass: 'bell' },
  { iconClass: 'bell-o' },
  { iconClass: 'bell-slash' },
  { iconClass: 'bell-slash-o' },
  { iconClass: 'bicycle' },
  { iconClass: 'binoculars' },
  { iconClass: 'birthday-cake' },
  { iconClass: 'blind' },
  { iconClass: 'bluetooth' },
  { iconClass: 'bluetooth-b' },
  { iconClass: 'bolt' },
  { iconClass: 'bomb' },
  { iconClass: 'book' },
  { iconClass: 'bookmark' },
  { iconClass: 'bookmark-o' },
  { iconClass: 'braille' },
  { iconClass: 'briefcase' },
  { iconClass: 'bug' },
  { iconClass: 'building' },
  { iconClass: 'building-o' },
  { iconClass: 'bullhorn' },
  { iconClass: 'bullseye' },
  { iconClass: 'bus' },
  { iconClass: 'cab' },
  { iconClass: 'calculator' },
  { iconClass: 'calendar' },
  { iconClass: 'calendar-check-o' },
  { iconClass: 'calendar-minus-o' },
  { iconClass: 'calendar-o' },
  { iconClass: 'calendar-plus-o' },
  { iconClass: 'calendar-times-o' },
  { iconClass: 'camera' },
  { iconClass: 'camera-retro' },
  { iconClass: 'car' },
  { iconClass: 'caret-square-o-down' },
  { iconClass: 'caret-square-o-left' },
  { iconClass: 'caret-square-o-right' },
  { iconClass: 'caret-square-o-up' },
  { iconClass: 'cart-arrow-down' },
  { iconClass: 'cart-plus' },
  { iconClass: 'cc' },
  { iconClass: 'certificate' },
  { iconClass: 'check' },
  { iconClass: 'check-circle' },
  { iconClass: 'check-circle-o' },
  { iconClass: 'check-square' },
  { iconClass: 'check-square-o' },
  { iconClass: 'child' },
  { iconClass: 'circle' },
  { iconClass: 'circle-o' },
  { iconClass: 'circle-o-notch' },
  { iconClass: 'circle-thin' },
  { iconClass: 'clock-o' },
  { iconClass: 'clone' },
  { iconClass: 'close' },
  { iconClass: 'cloud' },
  { iconClass: 'cloud-download' },
  { iconClass: 'cloud-upload' },
  { iconClass: 'code' },
  { iconClass: 'code-fork' },
  { iconClass: 'coffee' },
  { iconClass: 'cog' },
  { iconClass: 'cogs' },
  { iconClass: 'comment' },
  { iconClass: 'comment-o' },
  { iconClass: 'commenting' },
  { iconClass: 'commenting-o' },
  { iconClass: 'comments' },
  { iconClass: 'comments-o' },
  { iconClass: 'compass' },
  { iconClass: 'copyright' },
  { iconClass: 'creative-commons' },
  { iconClass: 'credit-card' },
  { iconClass: 'credit-card-alt' },
  { iconClass: 'crop' },
  { iconClass: 'crosshairs' },
  { iconClass: 'cube' },
  { iconClass: 'cubes' },
  { iconClass: 'cutlery' },
  { iconClass: 'dashboard' },
  { iconClass: 'database' },
  { iconClass: 'deaf' },
  { iconClass: 'deafness' },
  { iconClass: 'desktop' },
  { iconClass: 'diamond' },
  { iconClass: 'dot-circle-o' },
  { iconClass: 'download' },
  { iconClass: 'drivers-license' },
  { iconClass: 'drivers-license-o' },
  { iconClass: 'edit' },
  { iconClass: 'ellipsis-h' },
  { iconClass: 'ellipsis-v' },
  { iconClass: 'envelope' },
  { iconClass: 'envelope-o' },
  { iconClass: 'envelope-open' },
  { iconClass: 'envelope-open-o' },
  { iconClass: 'envelope-square' },
  { iconClass: 'eraser' },
  { iconClass: 'exchange' },
  { iconClass: 'exclamation' },
  { iconClass: 'exclamation-circle' },
  { iconClass: 'exclamation-triangle' },
  { iconClass: 'external-link' },
  { iconClass: 'external-link-square' },
  { iconClass: 'eye' },
  { iconClass: 'eye-slash' },
  { iconClass: 'eyedropper' },
  { iconClass: 'fax' },
  { iconClass: 'feed' },
  { iconClass: 'female' },
  { iconClass: 'fighter-jet' },
  { iconClass: 'file-archive-o' },
  { iconClass: 'file-audio-o' },
  { iconClass: 'file-code-o' },
  { iconClass: 'file-excel-o' },
  { iconClass: 'file-image-o' },
  { iconClass: 'file-movie-o' },
  { iconClass: 'file-pdf-o' },
  { iconClass: 'file-photo-o' },
  { iconClass: 'file-picture-o' },
  { iconClass: 'file-powerpoint-o' },
  { iconClass: 'file-sound-o' },
  { iconClass: 'file-video-o' },
  { iconClass: 'file-word-o' },
  { iconClass: 'file-zip-o' },
  { iconClass: 'film' },
  { iconClass: 'filter' },
  { iconClass: 'fire' },
  { iconClass: 'fire-extinguisher' },
  { iconClass: 'flag' },
  { iconClass: 'flag-checkered' },
  { iconClass: 'flag-o' },
  { iconClass: 'flash' },
  { iconClass: 'flask' },
  { iconClass: 'folder' },
  { iconClass: 'folder-o' },
  { iconClass: 'folder-open' },
  { iconClass: 'folder-open-o' },
  { iconClass: 'frown-o' },
  { iconClass: 'futbol-o' },
  { iconClass: 'gamepad' },
  { iconClass: 'gavel' },
  { iconClass: 'gear' },
  { iconClass: 'gears' },
  { iconClass: 'gift' },
  { iconClass: 'glass' },
  { iconClass: 'globe' },
  { iconClass: 'graduation-cap' },
  { iconClass: 'group' },
  { iconClass: 'hand-grab-o' },
  { iconClass: 'hand-lizard-o' },
  { iconClass: 'hand-paper-o' },
  { iconClass: 'hand-peace-o' },
  { iconClass: 'hand-pointer-o' },
  { iconClass: 'hand-rock-o' },
  { iconClass: 'hand-scissors-o' },
  { iconClass: 'hand-spock-o' },
  { iconClass: 'hand-stop-o' },
  { iconClass: 'handshake-o' },
  { iconClass: 'hard-of-hearing' },
  { iconClass: 'hashtag' },
  { iconClass: 'hdd-o' },
  { iconClass: 'headphones' },
  { iconClass: 'heart' },
  { iconClass: 'heart-o' },
  { iconClass: 'heartbeat' },
  { iconClass: 'history' },
  { iconClass: 'home' },
  { iconClass: 'hotel' },
  { iconClass: 'hourglass' },
  { iconClass: 'hourglass-1' },
  { iconClass: 'hourglass-2' },
  { iconClass: 'hourglass-3' },
  { iconClass: 'hourglass-end' },
  { iconClass: 'hourglass-half' },
  { iconClass: 'hourglass-o' },
  { iconClass: 'hourglass-start' },
  { iconClass: 'i-cursor' },
  { iconClass: 'id-badge' },
  { iconClass: 'id-card' },
  { iconClass: 'id-card-o' },
  { iconClass: 'image' },
  { iconClass: 'inbox' },
  { iconClass: 'industry' },
  { iconClass: 'info' },
  { iconClass: 'info-circle' },
  { iconClass: 'institution' },
  { iconClass: 'key' },
  { iconClass: 'keyboard-o' },
  { iconClass: 'language' },
  { iconClass: 'laptop' },
  { iconClass: 'leaf' },
  { iconClass: 'legal' },
  { iconClass: 'lemon-o' },
  { iconClass: 'level-down' },
  { iconClass: 'level-up' },
  { iconClass: 'life-bouy' },
  { iconClass: 'life-buoy' },
  { iconClass: 'life-ring' },
  { iconClass: 'life-saver' },
  { iconClass: 'lightbulb-o' },
  { iconClass: 'line-chart' },
  { iconClass: 'location-arrow' },
  { iconClass: 'lock' },
  { iconClass: 'low-vision' },
  { iconClass: 'magic' },
  { iconClass: 'magnet' },
  { iconClass: 'mail-forward' },
  { iconClass: 'mail-reply' },
  { iconClass: 'mail-reply-all' },
  { iconClass: 'male' },
  { iconClass: 'map' },
  { iconClass: 'map-marker' },
  { iconClass: 'map-o' },
  { iconClass: 'map-pin' },
  { iconClass: 'map-signs' },
  { iconClass: 'meh-o' },
  { iconClass: 'microchip' },
  { iconClass: 'microphone' },
  { iconClass: 'microphone-slash' },
  { iconClass: 'minus' },
  { iconClass: 'minus-circle' },
  { iconClass: 'minus-square' },
  { iconClass: 'minus-square-o' },
  { iconClass: 'mobile' },
  { iconClass: 'mobile-phone' },
  { iconClass: 'money' },
  { iconClass: 'moon-o' },
  { iconClass: 'mortar-board' },
  { iconClass: 'motorcycle' },
  { iconClass: 'mouse-pointer' },
  { iconClass: 'music' },
  { iconClass: 'navicon' },
  { iconClass: 'newspaper-o' },
  { iconClass: 'object-group' },
  { iconClass: 'object-ungroup' },
  { iconClass: 'paint-brush' },
  { iconClass: 'paper-plane' },
  { iconClass: 'paper-plane-o' },
  { iconClass: 'paw' },
  { iconClass: 'pencil' },
  { iconClass: 'pencil-square' },
  { iconClass: 'pencil-square-o' },
  { iconClass: 'percent' },
  { iconClass: 'phone' },
  { iconClass: 'phone-square' },
  { iconClass: 'photo' },
  { iconClass: 'picture-o' },
  { iconClass: 'pie-chart' },
  { iconClass: 'plane' },
  { iconClass: 'plug' },
  { iconClass: 'plus' },
  { iconClass: 'plus-circle' },
  { iconClass: 'plus-square' },
  { iconClass: 'plus-square-o' },
  { iconClass: 'podcast' },
  { iconClass: 'power-off' },
  { iconClass: 'print' },
  { iconClass: 'puzzle-piece' },
  { iconClass: 'qrcode' },
  { iconClass: 'question' },
  { iconClass: 'question-circle' },
  { iconClass: 'question-circle-o' },
  { iconClass: 'quote-left' },
  { iconClass: 'quote-right' },
  { iconClass: 'random' },
  { iconClass: 'recycle' },
  { iconClass: 'refresh' },
  { iconClass: 'registered' },
  { iconClass: 'remove' },
  { iconClass: 'reorder' },
  { iconClass: 'reply' },
  { iconClass: 'reply-all' },
  { iconClass: 'retweet' },
  { iconClass: 'road' },
  { iconClass: 'rocket' },
  { iconClass: 'rss' },
  { iconClass: 'rss-square' },
  { iconClass: 's15' },
  { iconClass: 'search' },
  { iconClass: 'search-minus' },
  { iconClass: 'search-plus' },
  { iconClass: 'send' },
  { iconClass: 'send-o' },
  { iconClass: 'server' },
  { iconClass: 'share' },
  { iconClass: 'share-alt' },
  { iconClass: 'share-alt-square' },
  { iconClass: 'share-square' },
  { iconClass: 'share-square-o' },
  { iconClass: 'shield' },
  { iconClass: 'ship' },
  { iconClass: 'shopping-bag' },
  { iconClass: 'shopping-basket' },
  { iconClass: 'shopping-cart' },
  { iconClass: 'shower' },
  { iconClass: 'sign-in' },
  { iconClass: 'sign-language' },
  { iconClass: 'sign-out' },
  { iconClass: 'signal' },
  { iconClass: 'signing' },
  { iconClass: 'sitemap' },
  { iconClass: 'sliders' },
  { iconClass: 'smile-o' },
  { iconClass: 'snowflake-o' },
  { iconClass: 'soccer-ball-o' },
  { iconClass: 'sort' },
  { iconClass: 'sort-alpha-asc' },
  { iconClass: 'sort-alpha-desc' },
  { iconClass: 'sort-amount-asc' },
  { iconClass: 'sort-amount-desc' },
  { iconClass: 'sort-asc' },
  { iconClass: 'sort-desc' },
  { iconClass: 'sort-down' },
  { iconClass: 'sort-numeric-asc' },
  { iconClass: 'sort-numeric-desc' },
  { iconClass: 'sort-up' },
  { iconClass: 'space-shuttle' },
  { iconClass: 'spinner' },
  { iconClass: 'spoon' },
  { iconClass: 'square' },
  { iconClass: 'square-o' },
  { iconClass: 'star' },
  { iconClass: 'star-half' },
  { iconClass: 'star-half-empty' },
  { iconClass: 'star-half-full' },
  { iconClass: 'star-half-o' },
  { iconClass: 'star-o' },
  { iconClass: 'sticky-note' },
  { iconClass: 'sticky-note-o' },
  { iconClass: 'street-view' },
  { iconClass: 'suitcase' },
  { iconClass: 'sun-o' },
  { iconClass: 'support' },
  { iconClass: 'tablet' },
  { iconClass: 'tachometer' },
  { iconClass: 'tag' },
  { iconClass: 'tags' },
  { iconClass: 'tasks' },
  { iconClass: 'taxi' },
  { iconClass: 'television' },
  { iconClass: 'terminal' },
  { iconClass: 'thermometer' },
  { iconClass: 'thermometer-0' },
  { iconClass: 'thermometer-1' },
  { iconClass: 'thermometer-2' },
  { iconClass: 'thermometer-3' },
  { iconClass: 'thermometer-4' },
  { iconClass: 'thermometer-empty' },
  { iconClass: 'thermometer-full' },
  { iconClass: 'thermometer-half' },
  { iconClass: 'thermometer-quarter' },
  { iconClass: 'thermometer-three-quarters' },
  { iconClass: 'thumb-tack' },
  { iconClass: 'thumbs-down' },
  { iconClass: 'thumbs-o-down' },
  { iconClass: 'thumbs-o-up' },
  { iconClass: 'thumbs-up' },
  { iconClass: 'ticket' },
  { iconClass: 'times' },
  { iconClass: 'times-circle' },
  { iconClass: 'times-circle-o' },
  { iconClass: 'times-rectangle' },
  { iconClass: 'times-rectangle-o' },
  { iconClass: 'tint' },
  { iconClass: 'toggle-down' },
  { iconClass: 'toggle-left' },
  { iconClass: 'toggle-off' },
  { iconClass: 'toggle-on' },
  { iconClass: 'toggle-right' },
  { iconClass: 'toggle-up' },
  { iconClass: 'trademark' },
  { iconClass: 'trash' },
  { iconClass: 'trash-o' },
  { iconClass: 'tree' },
  { iconClass: 'trophy' },
  { iconClass: 'truck' },
  { iconClass: 'tty' },
  { iconClass: 'tv' },
  { iconClass: 'umbrella' },
  { iconClass: 'universal-access' },
  { iconClass: 'university' },
  { iconClass: 'unlock' },
  { iconClass: 'unlock-alt' },
  { iconClass: 'unsorted' },
  { iconClass: 'upload' },
  { iconClass: 'user' },
  { iconClass: 'user-circle' },
  { iconClass: 'user-circle-o' },
  { iconClass: 'user-o' },
  { iconClass: 'user-plus' },
  { iconClass: 'user-secret' },
  { iconClass: 'user-times' },
  { iconClass: 'users' },
  { iconClass: 'vcard' },
  { iconClass: 'vcard-o' },
  { iconClass: 'video-camera' },
  { iconClass: 'volume-control-phone' },
  { iconClass: 'volume-down' },
  { iconClass: 'volume-off' },
  { iconClass: 'volume-up' },
  { iconClass: 'warning' },
  { iconClass: 'wheelchair' },
  { iconClass: 'wheelchair-alt' },
  { iconClass: 'wifi' },
  { iconClass: 'window-close' },
  { iconClass: 'window-close-o' },
  { iconClass: 'window-maximize' },
  { iconClass: 'window-minimize' },
  { iconClass: 'window-restore' },
  { iconClass: 'wrench' },
  { iconClass: 'ccessibility Icons' },
  { iconClass: 'american-sign-language-interpreting' },
  { iconClass: 'asl-interpreting' },
  { iconClass: 'assistive-listening-systems' },
  { iconClass: 'audio-description' },
  { iconClass: 'blind' },
  { iconClass: 'braille' },
  { iconClass: 'cc' },
  { iconClass: 'deaf' },
  { iconClass: 'deafness' },
  { iconClass: 'hard-of-hearing' },
  { iconClass: 'low-vision' },
  { iconClass: 'question-circle-o' },
  { iconClass: 'sign-language' },
  { iconClass: 'signing' },
  { iconClass: 'tty' },
  { iconClass: 'universal-access' },
  { iconClass: 'volume-control-phone' },
  { iconClass: 'wheelchair' },
  { iconClass: 'wheelchair-alt' },
  { iconClass: 'hand-grab-o' },
  { iconClass: 'hand-lizard-o' },
  { iconClass: 'hand-o-down' },
  { iconClass: 'hand-o-left' },
  { iconClass: 'hand-o-right' },
  { iconClass: 'hand-o-up' },
  { iconClass: 'hand-paper-o' },
  { iconClass: 'hand-peace-o' },
  { iconClass: 'hand-pointer-o' },
  { iconClass: 'hand-rock-o' },
  { iconClass: 'hand-scissors-o' },
  { iconClass: 'hand-spock-o' },
  { iconClass: 'hand-stop-o' },
  { iconClass: 'thumbs-down' },
  { iconClass: 'thumbs-o-down' },
  { iconClass: 'thumbs-o-up' },
  { iconClass: 'thumbs-up' },
  { iconClass: 'ransportation Icons' },
  { iconClass: 'ambulance' },
  { iconClass: 'automobile' },
  { iconClass: 'bicycle' },
  { iconClass: 'bus' },
  { iconClass: 'cab' },
  { iconClass: 'car' },
  { iconClass: 'fighter-jet' },
  { iconClass: 'motorcycle' },
  { iconClass: 'plane' },
  { iconClass: 'rocket' },
  { iconClass: 'ship' },
  { iconClass: 'space-shuttle' },
  { iconClass: 'subway' },
  { iconClass: 'taxi' },
  { iconClass: 'train' },
  { iconClass: 'truck' },
  { iconClass: 'wheelchair' },
  { iconClass: 'wheelchair-alt' },
  { iconClass: 'genderless' },
  { iconClass: 'intersex' },
  { iconClass: 'mars' },
  { iconClass: 'mars-double' },
  { iconClass: 'mars-stroke' },
  { iconClass: 'mars-stroke-h' },
  { iconClass: 'mars-stroke-v' },
  { iconClass: 'mercury' },
  { iconClass: 'neuter' },
  { iconClass: 'transgender' },
  { iconClass: 'transgender-alt' },
  { iconClass: 'venus' },
  { iconClass: 'venus-double' },
  { iconClass: 'venus-mars' },
  { iconClass: 'file' },
  { iconClass: 'file-archive-o' },
  { iconClass: 'file-audio-o' },
  { iconClass: 'file-code-o' },
  { iconClass: 'file-excel-o' },
  { iconClass: 'file-image-o' },
  { iconClass: 'file-movie-o' },
  { iconClass: 'file-o' },
  { iconClass: 'file-pdf-o' },
  { iconClass: 'file-photo-o' },
  { iconClass: 'file-picture-o' },
  { iconClass: 'file-powerpoint-o' },
  { iconClass: 'file-sound-o' },
  { iconClass: 'file-text' },
  { iconClass: 'file-text-o' },
  { iconClass: 'file-video-o' },
  { iconClass: 'file-word-o' },
  { iconClass: 'file-zip-o' },
  { iconClass: 'circle-o-notch' },
  { iconClass: 'cog' },
  { iconClass: 'gear' },
  { iconClass: 'refresh' },
  { iconClass: 'spinner' },
  { iconClass: 'check-square' },
  { iconClass: 'check-square-o' },
  { iconClass: 'circle' },
  { iconClass: 'circle-o' },
  { iconClass: 'dot-circle-o' },
  { iconClass: 'minus-square' },
  { iconClass: 'minus-square-o' },
  { iconClass: 'plus-square' },
  { iconClass: 'plus-square-o' },
  { iconClass: 'square' },
  { iconClass: 'square-o' },
  { iconClass: 'ayment Icons' },
  { iconClass: 'cc-amex' },
  { iconClass: 'cc-diners-club' },
  { iconClass: 'cc-discover' },
  { iconClass: 'cc-jcb' },
  { iconClass: 'cc-mastercard' },
  { iconClass: 'cc-paypal' },
  { iconClass: 'cc-stripe' },
  { iconClass: 'cc-visa' },
  { iconClass: 'credit-card' },
  { iconClass: 'credit-card-alt' },
  { iconClass: 'google-wallet' },
  { iconClass: 'paypal' },
  { iconClass: 'area-chart' },
  { iconClass: 'bar-chart' },
  { iconClass: 'bar-chart-o' },
  { iconClass: 'line-chart' },
  { iconClass: 'pie-chart' },
  { iconClass: 'bitcoin' },
  { iconClass: 'btc' },
  { iconClass: 'cny' },
  { iconClass: 'dollar' },
  { iconClass: 'eur' },
  { iconClass: 'euro' },
  { iconClass: 'gbp' },
  { iconClass: 'gg' },
  { iconClass: 'gg-circle' },
  { iconClass: 'ils' },
  { iconClass: 'inr' },
  { iconClass: 'jpy' },
  { iconClass: 'krw' },
  { iconClass: 'money' },
  { iconClass: 'rmb' },
  { iconClass: 'rouble' },
  { iconClass: 'rub' },
  { iconClass: 'ruble' },
  { iconClass: 'rupee' },
  { iconClass: 'shekel' },
  { iconClass: 'sheqel' },
  { iconClass: 'try' },
  { iconClass: 'turkish-lira' },
  { iconClass: 'usd' },
  { iconClass: 'viacoin' },
  { iconClass: 'won' },
  { iconClass: 'yen' },
  { iconClass: 'align-center' },
  { iconClass: 'align-justify' },
  { iconClass: 'align-left' },
  { iconClass: 'align-right' },
  { iconClass: 'bold' },
  { iconClass: 'chain' },
  { iconClass: 'chain-broken' },
  { iconClass: 'clipboard' },
  { iconClass: 'columns' },
  { iconClass: 'copy' },
  { iconClass: 'cut' },
  { iconClass: 'dedent' },
  { iconClass: 'eraser' },
  { iconClass: 'file' },
  { iconClass: 'file-o' },
  { iconClass: 'file-text' },
  { iconClass: 'file-text-o' },
  { iconClass: 'files-o' },
  { iconClass: 'floppy-o' },
  { iconClass: 'font' },
  { iconClass: 'header' },
  { iconClass: 'indent' },
  { iconClass: 'italic' },
  { iconClass: 'link' },
  { iconClass: 'list' },
  { iconClass: 'list-alt' },
  { iconClass: 'list-ol' },
  { iconClass: 'list-ul' },
  { iconClass: 'outdent' },
  { iconClass: 'paperclip' },
  { iconClass: 'paragraph' },
  { iconClass: 'paste' },
  { iconClass: 'repeat' },
  { iconClass: 'rotate-left' },
  { iconClass: 'rotate-right' },
  { iconClass: 'save' },
  { iconClass: 'scissors' },
  { iconClass: 'strikethrough' },
  { iconClass: 'subscript' },
  { iconClass: 'superscript' },
  { iconClass: 'table' },
  { iconClass: 'text-height' },
  { iconClass: 'text-width' },
  { iconClass: 'th' },
  { iconClass: 'th-large' },
  { iconClass: 'th-list' },
  { iconClass: 'underline' },
  { iconClass: 'undo' },
  { iconClass: 'unlink' },
  { iconClass: 'angle-double-down' },
  { iconClass: 'angle-double-left' },
  { iconClass: 'angle-double-right' },
  { iconClass: 'angle-double-up' },
  { iconClass: 'angle-down' },
  { iconClass: 'angle-left' },
  { iconClass: 'angle-right' },
  { iconClass: 'angle-up' },
  { iconClass: 'arrow-circle-down' },
  { iconClass: 'arrow-circle-left' },
  { iconClass: 'arrow-circle-o-down' },
  { iconClass: 'arrow-circle-o-left' },
  { iconClass: 'arrow-circle-o-right' },
  { iconClass: 'arrow-circle-o-up' },
  { iconClass: 'arrow-circle-right' },
  { iconClass: 'arrow-circle-up' },
  { iconClass: 'arrow-down' },
  { iconClass: 'arrow-left' },
  { iconClass: 'arrow-right' },
  { iconClass: 'arrow-up' },
  { iconClass: 'arrows' },
  { iconClass: 'arrows-alt' },
  { iconClass: 'arrows-h' },
  { iconClass: 'arrows-v' },
  { iconClass: 'caret-down' },
  { iconClass: 'caret-left' },
  { iconClass: 'caret-right' },
  { iconClass: 'caret-square-o-down' },
  { iconClass: 'caret-square-o-left' },
  { iconClass: 'caret-square-o-right' },
  { iconClass: 'caret-square-o-up' },
  { iconClass: 'caret-up' },
  { iconClass: 'chevron-circle-down' },
  { iconClass: 'chevron-circle-left' },
  { iconClass: 'chevron-circle-right' },
  { iconClass: 'chevron-circle-up' },
  { iconClass: 'chevron-down' },
  { iconClass: 'chevron-left' },
  { iconClass: 'chevron-right' },
  { iconClass: 'chevron-up' },
  { iconClass: 'exchange' },
  { iconClass: 'hand-o-down' },
  { iconClass: 'hand-o-left' },
  { iconClass: 'hand-o-right' },
  { iconClass: 'hand-o-up' },
  { iconClass: 'long-arrow-down' },
  { iconClass: 'long-arrow-left' },
  { iconClass: 'long-arrow-right' },
  { iconClass: 'long-arrow-up' },
  { iconClass: 'toggle-down' },
  { iconClass: 'toggle-left' },
  { iconClass: 'toggle-right' },
  { iconClass: 'toggle-up' },
  { iconClass: 'arrows-alt' },
  { iconClass: 'backward' },
  { iconClass: 'compress' },
  { iconClass: 'eject' },
  { iconClass: 'expand' },
  { iconClass: 'fast-backward' },
  { iconClass: 'fast-forward' },
  { iconClass: 'forward' },
  { iconClass: 'pause' },
  { iconClass: 'pause-circle' },
  { iconClass: 'pause-circle-o' },
  { iconClass: 'play' },
  { iconClass: 'play-circle' },
  { iconClass: 'play-circle-o' },
  { iconClass: 'random' },
  { iconClass: 'step-backward' },
  { iconClass: 'step-forward' },
  { iconClass: 'stop' },
  { iconClass: 'stop-circle' },
  { iconClass: 'stop-circle-o' },
  { iconClass: 'youtube-play' },
  { iconClass: '500px' },
  { iconClass: 'adn' },
  { iconClass: 'amazon' },
  { iconClass: 'android' },
  { iconClass: 'angellist' },
  { iconClass: 'apple' },
  { iconClass: 'bandcamp' },
  { iconClass: 'behance' },
  { iconClass: 'behance-square' },
  { iconClass: 'bitbucket' },
  { iconClass: 'bitbucket-square' },
  { iconClass: 'bitcoin' },
  { iconClass: 'black-tie' },
  { iconClass: 'bluetooth' },
  { iconClass: 'bluetooth-b' },
  { iconClass: 'btc' },
  { iconClass: 'buysellads' },
  { iconClass: 'cc-amex' },
  { iconClass: 'cc-diners-club' },
  { iconClass: 'cc-discover' },
  { iconClass: 'cc-jcb' },
  { iconClass: 'cc-mastercard' },
  { iconClass: 'cc-paypal' },
  { iconClass: 'cc-stripe' },
  { iconClass: 'cc-visa' },
  { iconClass: 'chrome' },
  { iconClass: 'codepen' },
  { iconClass: 'codiepie' },
  { iconClass: 'connectdevelop' },
  { iconClass: 'contao' },
  { iconClass: 'css3' },
  { iconClass: 'dashcube' },
  { iconClass: 'delicious' },
  { iconClass: 'deviantart' },
  { iconClass: 'digg' },
  { iconClass: 'dribbble' },
  { iconClass: 'dropbox' },
  { iconClass: 'drupal' },
  { iconClass: 'edge' },
  { iconClass: 'eercast' },
  { iconClass: 'empire' },
  { iconClass: 'envira' },
  { iconClass: 'etsy' },
  { iconClass: 'expeditedssl' },
  { iconClass: 'fa' },
  { iconClass: 'facebook' },
  { iconClass: 'facebook-f' },
  { iconClass: 'facebook-official' },
  { iconClass: 'facebook-square' },
  { iconClass: 'firefox' },
  { iconClass: 'first-order' },
  { iconClass: 'flickr' },
  { iconClass: 'font-awesome' },
  { iconClass: 'fonticons' },
  { iconClass: 'fort-awesome' },
  { iconClass: 'forumbee' },
  { iconClass: 'foursquare' },
  { iconClass: 'free-code-camp' },
  { iconClass: 'ge' },
  { iconClass: 'get-pocket' },
  { iconClass: 'gg' },
  { iconClass: 'gg-circle' },
  { iconClass: 'git' },
  { iconClass: 'git-square' },
  { iconClass: 'github' },
  { iconClass: 'github-alt' },
  { iconClass: 'github-square' },
  { iconClass: 'gitlab' },
  { iconClass: 'gittip' },
  { iconClass: 'glide' },
  { iconClass: 'glide-g' },
  { iconClass: 'google' },
  { iconClass: 'google-plus' },
  { iconClass: 'google-plus-circle' },
  { iconClass: 'google-plus-official' },
  { iconClass: 'google-plus-square' },
  { iconClass: 'google-wallet' },
  { iconClass: 'gratipay' },
  { iconClass: 'grav' },
  { iconClass: 'hacker-news' },
  { iconClass: 'houzz' },
  { iconClass: 'html5' },
  { iconClass: 'imdb' },
  { iconClass: 'instagram' },
  { iconClass: 'internet-explorer' },
  { iconClass: 'ioxhost' },
  { iconClass: 'joomla' },
  { iconClass: 'jsfiddle' },
  { iconClass: 'lastfm' },
  { iconClass: 'lastfm-square' },
  { iconClass: 'leanpub' },
  { iconClass: 'linkedin' },
  { iconClass: 'linkedin-square' },
  { iconClass: 'linode' },
  { iconClass: 'linux' },
  { iconClass: 'maxcdn' },
  { iconClass: 'meanpath' },
  { iconClass: 'medium' },
  { iconClass: 'meetup' },
  { iconClass: 'mixcloud' },
  { iconClass: 'modx' },
  { iconClass: 'odnoklassniki' },
  { iconClass: 'odnoklassniki-square' },
  { iconClass: 'opencart' },
  { iconClass: 'openid' },
  { iconClass: 'opera' },
  { iconClass: 'optin-monster' },
  { iconClass: 'pagelines' },
  { iconClass: 'paypal' },
  { iconClass: 'pied-piper' },
  { iconClass: 'pied-piper-alt' },
  { iconClass: 'pied-piper-pp' },
  { iconClass: 'pinterest' },
  { iconClass: 'pinterest-p' },
  { iconClass: 'pinterest-square' },
  { iconClass: 'product-hunt' },
  { iconClass: 'qq' },
  { iconClass: 'quora' },
  { iconClass: 'ra' },
  { iconClass: 'ravelry' },
  { iconClass: 'rebel' },
  { iconClass: 'reddit' },
  { iconClass: 'reddit-alien' },
  { iconClass: 'reddit-square' },
  { iconClass: 'renren' },
  { iconClass: 'resistance' },
  { iconClass: 'safari' },
  { iconClass: 'scribd}' },
  { iconClass: 'sellsy' },
  { iconClass: 'share-alt' },
  { iconClass: 'share-alt-square' },
  { iconClass: 'shirtsinbulk' },
  { iconClass: 'simplybuilt' },
  { iconClass: 'skyatlas' },
  { iconClass: 'skype' },
  { iconClass: 'slack' },
  { iconClass: 'slideshare' },
  { iconClass: 'snapchat' },
  { iconClass: 'snapchat-ghost' },
  { iconClass: 'snapchat-square' },
  { iconClass: 'soundcloud' },
  { iconClass: 'spotify' },
  { iconClass: 'stack-exchange' },
  { iconClass: 'stack-overflow' },
  { iconClass: 'steam' },
  { iconClass: 'steam-square' },
  { iconClass: 'stumbleupon' },
  { iconClass: 'stumbleupon-circle' },
  { iconClass: 'superpowers' },
  { iconClass: 'telegram' },
  { iconClass: 'tencent-weibo' },
  { iconClass: 'themeisle' },
  { iconClass: 'trello' },
  { iconClass: 'tripadvisor' },
  { iconClass: 'tumblr' },
  { iconClass: 'tumblr-square' },
  { iconClass: 'twitch' },
  { iconClass: 'twitter' },
  { iconClass: 'twitter-square' },
  { iconClass: 'usb' },
  { iconClass: 'viacoin' },
  { iconClass: 'viadeo' },
  { iconClass: 'viadeo-square' },
  { iconClass: 'vimeo' },
  { iconClass: 'vimeo-square' },
  { iconClass: 'vine' },
  { iconClass: 'vk' },
  { iconClass: 'wechat' },
  { iconClass: 'weibo' },
  { iconClass: 'weixin' },
  { iconClass: 'whatsapp' },
  { iconClass: 'wikipedia-w' },
  { iconClass: 'windows' },
  { iconClass: 'wordpress' },
  { iconClass: 'wpbeginner' },
  { iconClass: 'wpexplorer' },
  { iconClass: 'wpforms' },
  { iconClass: 'xing' },
  { iconClass: 'xing-square' },
  { iconClass: 'y-combinator' },
  { iconClass: 'y-combinator-square' },
  { iconClass: 'yahoo' },
  { iconClass: 'yc' },
  { iconClass: 'yc-square' },
  { iconClass: 'yelp' },
  { iconClass: 'yoast' },
  { iconClass: 'youtube' },
  { iconClass: 'youtube-play' },
  { iconClass: 'youtube-square' },
  { iconClass: 'ambulance' },
  { iconClass: 'h-square' },
  { iconClass: 'heart' },
  { iconClass: 'heart-o' },
  { iconClass: 'heartbeat' },
  { iconClass: 'hospital-o' },
  { iconClass: 'medkit' },
  { iconClass: 'plus-square' },
  { iconClass: 'stethoscope' },
  { iconClass: 'user-md' },
  { iconClass: 'wheelchair' },
  { iconClass: 'wheelchair-alt' }
];
export default faIcons;
