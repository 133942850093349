import React, { useState, useEffect, useRef } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import {
  DialogBox,
  TextInputWithLabel,
  Button,
  gray,
  blue,
  salmon
} from 'quantux-ui';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { BASE_URL } from '../../utils';

const styles = StyleSheet.create({
  stepsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  stepHeading: {
    color: gray[400]
  },
  activeStepHeading: {
    color: blue[500]
  },
  errMsg: {
    color: salmon[500],
    minHeight: '40px',
    maxHeight: '40px',
    height: '40px'
  },
  txtBtn: {
    color: blue[500],
    cursor: 'pointer'
  },
  disabledTxtBtn: {
    cursor: 'not-allowed',
    color: blue[700]
  },
  statusMsg: {
    color: blue[500],
    textAlign: 'center'
  }
});

const ChangeMobileDialog = (props) => {
  const isFirstRender = useRef(true);
  const [password, setPassword] = useState('');
  const [showInvalidPasswordErrorMsg, setShowInvalidPasswordErrorMsg] = useState(false);
  const [step, setStep] = useState(1);
  const [mobile, setMobile] = useState('');
  const [isMobilePristine, setMobilePristineState] = useState(true);
  const [isMobileValid, setMobileValidState] = useState(true);
  const [isMobileDuplicate, setMobileDuplicateState] = useState(false);
  const [verificationCode, setVerificationCode] = useState('')
  const [isVerificationCodePristine, setVerificationCodePristineState] = useState(true);
  const [isVerificationCodeValid, setVerificationCodeValidState] = useState(true);
  const [showStatusMsg, setShowStatusMsg] = useState(false);
  const [statusMsg, setStatusMsg] = useState('Status message!');
  const [showLoader, setShowLoader] = useState(false);
  const [canUpdateMobile, setCanUpdateMobile] = useState(false);

  // Check for duplicate mobile
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (isMobilePhone(mobile, 'en-US') || isMobilePhone(mobile, 'en-PK')) {
      const url = new URL(`${BASE_URL}/users/exists/mobile/${mobile}`);
      fetch(url)
        .then(res => res.text())
        .then((result) => {
          const data = JSON.parse(result);
          setMobileDuplicateState(data.isDuplicate);
        })
        .catch(error => console.log('error', error))
    }
  }, [mobile]);

  // Check canUserUpdateMobile
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    setCanUpdateMobile(!isMobilePristine && isMobileValid && !isMobileDuplicate && verificationCode.length > 0);
  }, [mobile, verificationCode]);

  const handleOnChangePassword = (e) => {
    const { value } = e.target;
    setPassword(value);
  };

  const handleOnClickProceedToStep2 = () => {
    if (password.length > 0) {
      const formData = new FormData();
      const options = { method: 'POST', body: formData, redirect: 'follow' };
      formData.append('email', props.usr.email);
      formData.append('password', password);
      fetch(`${BASE_URL}/users/reauthenticate`, options)
        .then(res => res.text())
        .then((result) => {
          const data = JSON.parse(result);
          const { success } = data;
          if (success) {
            setStep(2);
          } else {
            setShowInvalidPasswordErrorMsg(true);
          }
        })
        .catch(error => console.log('error', error))
    }
  };

  const handleOnChangeMobile = (e) => {
    const { value } = e.target;
    setMobilePristineState(false);
    setMobileValidState(isMobilePhone(value, 'en-US') || isMobilePhone(value, 'en-PK'));
    setMobile(value);
  };

  const handleOnClickSend2Fa = () => {
    setShowStatusMsg(true);
    setShowLoader(true);
    setStatusMsg('Sending 2FA code');

    const url = new URL(`${BASE_URL}/users/send2FAVerificationCode`);
    const params = {
      email: props.usr.email,
      mobile
    };
    url.search = new URLSearchParams(params).toString();
    fetch(url)
      .then(res => res.text())
      .then((result) => {
        const data = JSON.parse(result);
        // console.log(data);
        if (data.success) {
          setStatusMsg('2FA verification code sent!');
          setShowLoader(false);
        } else {
          setStatusMsg(data.msg);
          setShowLoader(false);
        }
      })
      .catch(error => console.log('error', error))
  };

  const handleOnChangeVerificationCode = (e) => {
    const { value } = e.target;
    setVerificationCodePristineState(false);
    setVerificationCode(value);
  }

  const handleOnClickUpdateMobile = () => {
    if (canUpdateMobile) {
      setShowLoader(true);
      setStatusMsg('Updating mobile #!');
      const formData = new FormData();
      formData.append('email', props.usr.email);
      formData.append('mobile', mobile);
      formData.append('code', verificationCode);
      const options = { method: 'POST', body: formData, redirect: 'follow' };
      fetch(`${BASE_URL}/users/verifyMobileVerificationCode`, options)
        .then(response => response.text())
        .then(result => {
          const res = JSON.parse(result);
          if (res.success) {
            console.log('Mobile # successfully updated');
            setShowLoader(false);
            setShowStatusMsg(false);
            props.onCloseDialogBox(false);
            props.processLogout();
          } else {
            setShowLoader(false);
            setShowStatusMsg(false);
            setVerificationCodeValidState(false);
          }
        })
        .catch(error => console.log('error', error));
    }
  };

  return (
    <DialogBox
      initWidth={window.innerWidth <= 576 ? (window.innerWidth - 16) : 350}
      top={window.innerWidth <= 576 ? 48 : null}
      left={window.innerWidth <= 576 ? 8 : null}
      initHeight={378}
      isOpen={props.isVisible}
      onClose={() => { props.onCloseDialogBox(false); }}
      maskBackground="#004080"
      maskOpacity={0.5}
      closeOnEscKey
      closeOnMaskClick
      header={{
        text: ' Change Mobile',
        backColor: '#082840',
        foreColor: '#5080B0',
        closeColor: '#FF6060',
        fontStyle: 'bold',
        fontSize: '20px',
        icon: 'fa fa-mobile-phone'
      }}
    >
      <div className="p-2">
        {/* Step 1 & Step 2 heading */}
        <div className={`mt-2 mb-2 ${css(styles.stepsContainer)}`}>
          <div className={`mr-3 ${css(styles.stepHeading, step === 1 && styles.activeStepHeading)}`}>
            Step 1
          </div>
          <div className={css(styles.stepHeading, step === 2 && styles.activeStepHeading)}>
            Step 2
          </div>
        </div>
        {/* Step 1 */}
        {step === 1
          ? <>
              {/* Password */}
              <div style={{ marginTop: '10px' }}>
                <TextInputWithLabel
                  value={password}
                  titleText="Enter Password"
                  type='password'
                  isCompactLayout={false}
                  titleColor="#5079ab"
                  width={328}
                  showPreIcon
                  preIcon="fa fa-lock"
                  showPostIcon
                  postIcon="fa fa-eye"
                  onChange={handleOnChangePassword}
                />
              </div>
              {/* Invalid password error msg */}
              <div className={css(styles.errMsg)}>
                {showInvalidPasswordErrorMsg ? 'Invalid password' : ''}
              </div>
              {/* Forgot Password */}
              <div
                className='mt-4'
                style={{ textAlign: 'center' }}
              >
                Forgot Password? <span className={css(styles.txtBtn)}>RESET IT HERE</span>
              </div>
              {/* Proceed to Step 2 Btn */}
              <div style={{ marginTop: '32px' }}>
                <Button
                  btnType="primary"
                  isDisabled={password.length === 0}
                  style={{ minWidth: '330px', fontWeight: 'bold', borderRadius: '4px' }}
                  onClick={handleOnClickProceedToStep2}
                >
                  Proceed to Step 2
                </Button>
              </div>
            </>
          : null
        }
        {/* Step 2 */}
        {step === 2
          ? <>
              {/* New Mobile # */}
              <div style={{ marginTop: '10px' }}>
                <TextInputWithLabel
                  value={mobile}
                  titleText="Enter New Mobile #"
                  isCompactLayout={false}
                  titleColor="#5079ab"
                  width={328}
                  showPreIcon
                  preIcon="fa fa-mobile-phone"
                  onChange={handleOnChangeMobile}
                />
              </div>
              {/* Invalid mobile # error msg */}
              {(!isMobilePristine && !isMobileValid)
                ? <div className={css(styles.errMsg)}>Invalid mobile #</div>
                : null
              }
              {/* Duplicate mobile error msg */}
              {(!isMobilePristine && isMobileValid && isMobileDuplicate)
                ? <div className={css(styles.errMsg)}>Account with this mobile # already exists</div>
                : null
              }

              {/* Send 2FA code */}
              <div
                className={`mt-4 noselect ${css(styles.disabledTxtBtn, isMobileValid && !isMobileDuplicate && styles.txtBtn)}`}
                style={{ textAlign: 'center' }}
                onClick={handleOnClickSend2Fa}
              >
                Send 2FA Code to New Mobile # ▶
              </div>

              {/* Verification Code */}
              <div className='mt-2'>
                <TextInputWithLabel
                  value={verificationCode}
                  titleText="Verification Code From New Mobile #"
                  isCompactLayout={false}
                  titleColor="#5079ab"
                  width={328}
                  showPreIcon
                  preIcon="fa fa-shield"
                  onChange={handleOnChangeVerificationCode}
                />
              </div>
              {/* Invalid code error msg */}
              {(!isVerificationCodePristine && !isVerificationCodeValid)
                ? <div className={css(styles.errMsg)}>Invalid Code</div>
                : null
              }
              {/* Status message */}
              {showStatusMsg
                ? <div className={`mt-4 mb-2 noselect ${css(styles.statusMsg)}`}>
                    {statusMsg}
                    <i
                      className="ml-2 fa fa-circle-o-notch fa-spin fa-fw"
                      style={{
                        display: showLoader ? '' : 'none'
                      }}
                    />
                  </div>
                : null
              }
              {/* Update Mobile Btn */}
              <div style={{ marginTop: '32px' }}>
                <Button
                  btnType="primary"
                  isDisabled={!canUpdateMobile}
                  style={{ minWidth: '330px', fontWeight: 'bold', borderRadius: '4px' }}
                  onClick={handleOnClickUpdateMobile}
                >
                  Update Mobile
                </Button>
              </div>
            </>
          : null
        }
      </div>
    </DialogBox>
  );
};

export default ChangeMobileDialog;
