/* eslint-disable react/display-name */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clone } from 'ramda';
import { css, StyleSheet } from 'aphrodite/no-important';
import debouce from 'lodash.debounce';
import axios from 'axios';
import { Button, DataGrid, Drawer, PopupMenu, ThemeContext, TextInputWithLabel } from 'quantux-ui';
import { addFacility } from '../../actions';
import noPinIcon from '../../imgs/no-pin.svg';
import leftPinIcon from '../../imgs/left-pin.svg';
import rightPinIcon from '../../imgs/right-pin.svg';
import { BASE_URL } from '../../utils';

const DOCS_PER_PAGE = 10;

const defaultCellStyle = {
  font: '12px Lato',
  padding: '8px',
  display: 'flex',
  alignItems: 'center',
  borderBottom: '0.5px solid lime',
  borderRight: '0.5px solid lime',
  lineHeight: '14px'
};

const icons = {
  filter: { isSvg: false, path: '', class: 'fa fa-filter' },
  stringSortAscending: { isSvg: false, class: 'fa fa-alpha-asc' },
  stringSortDescending: { isSvg: false, class: 'fa fa-alpha-desc' },
  numberSortAscending: { isSvg: false, class: 'fa fa-numeric-asc' },
  numberSortDescending: { isSvg: false, class: 'fa fa-numeric-desc' },
  dateSortAscending: { isSvg: false, class: 'fa fa-alpha-asc' },
  dateSortDescending: { isSvg: false, class: 'fa fa-alpha-desc' },
  leftPin: { isSvg: true, path: leftPinIcon },
  rightPin: { isSvg: true, path: rightPinIcon },
  noPin: { isSvg: true, path: noPinIcon },
  menuIcon: { isSvg: false, class: 'fa fa-ellipsis-v' }
};

const initHeaderDefs = [
  // NPI Number (npi)
  {
    visible: true,
    dataType: 'string',
    pin: 'left',
    sortable: false,
    sortOrder: 0,
    enableCellClick: true,
    text: 'NPI',
    field: 'npi',
    key: 'npi',

    isCustomFilter: false,
    showFilterHint: false,
    filter: true,
    filterType: 'exactMatch',
    filterMenuType: 'basic', // possible  options => basic, advanced, none
    basicFilterType: 'exactMatch', // possible values are => startsWith, contains, exactMatch, endsWith
    advancedFilterType: 'containsAny', // possible values are => select, containsAll, conainsAny
    disabledFilterTypes: ['startsWith', 'contains', 'endsWith', 'advanced'],
    colStyles: { width: '130px', minWidth: 130, textAlign: 'left' },
    headerCellStyle: {},
    colCellsStyles: { textAlign: 'left' },
    footerCellStyle: {},
    cellRenderer: (text) => {
      return (
        <div style={{ color: '#0080FF', cursor: 'pointer' }}>
          {text}
        </div>
      );
    }
  },
  // Last Name (lastName)
  {
    visible: true,
    dataType: 'string',
    pin: 'none',
    sortable: true,
    sortOrder: 0,
    enableCellClick: false,
    text: 'Last Name',
    field: 'lastName',
    key: 'lastName',
    isCustomFilter: false,
    showFilterHint: false,
    filter: true,
    filterType: 'exactMatch',
    filterMenuType: 'basic', // possible  options => basic, advanced, none
    basicFilterType: 'contains', // possible values are => startsWith, contains, exactMatch, endsWith
    advancedFilterType: 'containsAny', // possible values are => select, containsAll, conainsAny
    disabledFilterTypes: ['startsWith', 'endsWith', 'advanced'],
    colStyles: { width: '123px', minWidth: 123, textAlign: 'left' },
    headerCellStyle: {},
    colCellsStyles: { textAlign: 'left' },
    footerCellStyle: {}
  },
  // Middle Name (middleName)
  {
    visible: true,
    dataType: 'string',
    pin: 'none',
    sortable: true,
    sortOrder: 0,
    enableCellClick: false,
    text: 'Middle Name',
    field: 'middleName',
    key: 'middleName',
    isCustomFilter: false,
    showFilterHint: false,
    filter: true,
    filterType: 'exactMatch',
    filterMenuType: 'basic', // possible  options => basic, advanced, none
    basicFilterType: 'contains', // possible values are => startsWith, contains, exactMatch, endsWith
    advancedFilterType: 'containsAny', // possible values are => select, containsAll, conainsAny
    disabledFilterTypes: ['startsWith', 'endsWith', 'advanced'],
    colStyles: { width: '123px', minWidth: 123, textAlign: 'left' },
    headerCellStyle: {},
    colCellsStyles: { textAlign: 'left' },
    footerCellStyle: {}
  },
  // First Name (firstName)
  {
    visible: true,
    dataType: 'string',
    pin: 'none',
    sortable: true,
    sortOrder: 1,
    enableCellClick: false,
    text: 'First Name',
    field: 'firstName',
    key: 'firstName',
    isCustomFilter: false,
    showFilterHint: false,
    filter: true,
    filterType: 'exactMatch',
    filterMenuType: 'basic', // possible  options => basic, advanced, none
    basicFilterType: 'exactMatch', // possible values are => startsWith, contains, exactMatch, endsWith
    advancedFilterType: 'containsAny', // possible values are => select, containsAll, conainsAny
    disabledFilterTypes: ['startsWith', 'endsWith', 'advanced'],
    colStyles: { width: '123px', minWidth: 123, textAlign: 'left' },
    headerCellStyle: {},
    colCellsStyles: { textAlign: 'left' },
    footerCellStyle: {}
  },
  // Mailing address ()
  {
    visible: true,
    dataType: 'string',
    pin: 'none',
    sortable: false,
    sortOrder: 0,
    enableCellClick: false,
    text: 'Primary Practice Address',
    field: 'mailingAddress',
    key: 'mailingAddress',
    showFilterHint: true,
    isCustomFilter: true,
    filterHint: 'Enter city, state code, or ZIP',
    filter: true,
    filterType: 'exactMatch',
    filterMenuType: 'basic', // possible  options => basic, advanced, none
    basicFilterType: 'exactMatch', // possible values are => startsWith, contains, exactMatch, endsWith
    advancedFilterType: 'containsAny', // possible values are => select, containsAll, conainsAny
    disabledFilterTypes: ['startsWith', 'exactMatch', 'contains', 'endsWith', 'advanced'],
    colStyles: { width: '250px', minWidth: 250, textAlign: 'left' },
    headerCellStyle: {},
    colCellsStyles: { textAlign: 'left' },
    footerCellStyle: {},
    cellRenderer: text => {
      // Format address and zip
      const addTmp = text.split(' ');
      let zip = addTmp.pop();
      zip = zip.length <= 5 ? zip : zip.substr(0, 5) + '-' + zip.substr(5, 9);
      // Generate Google map url
      // const addressUrl = "https://www.google.com/maps/search/?api=1&query=" + encodeURI(text);
      const addressUrl = 'https://www.google.com/maps/search/?api=1&query=' + encodeURI(`${addTmp.join(' ')} ${zip.substr(0, 5)}`);
      return (
        <div className="fx fx__v-center">
          <a
            target="_blank"
            href={addressUrl}
            rel="noreferrer"
            className="mr-2"
            style={{ fontSize: '16px' }}
          >
            <i className="fa fa-map-marker"/>
          </a>
          <a
            target="_blank"
            href={addressUrl}
            rel="noreferrer"
            className="mr-2"
          >
            <div>
              {addTmp.join(' ') + ' ' + zip}
            </div>
          </a>
        </div>
      );
    }
  },
  // Mobile (mobile)
  {
    visible: true,
    dataType: 'string',
    pin: 'none',
    sortable: false,
    sortOrder: 0,
    enableCellClick: false,
    text: 'Mobile',
    field: 'mobile',
    key: 'mobile',
    isCustomFilter: false,
    showFilterHint: false,
    filter: false,
    filterType: 'none',
    disabledFilterTypes: ['startsWith', 'exactMatch', 'contains', 'endsWith', 'advanced'],
    filterMenuType: 'advanced',
    basicFilterType: 'contains',
    advancedFilterType: 'containsAny',
    colStyles: { width: '123px', minWidth: 123, textAlign: 'left' },
    headerCellStyle: {},
    colCellsStyles: { textAlign: 'left' },
    footerCellStyle: {},
    cellRenderer: text => (
      <div>
        <a
          href={`tel:+1${text}`}
        >
          {`${text.substr(0, 3)}-${text.substr(3, 3)}-${text.substr(6, 4)}`}
        </a>
      </div>
    )
  },
  // Phone (phone)
  {
    visible: true,
    dataType: 'string',
    pin: 'none',
    sortable: false,
    sortOrder: 0,
    enableCellClick: false,
    text: 'Phone',
    field: 'phone',
    key: 'phone',
    isCustomFilter: false,
    showFilterHint: false,
    filter: false,
    filterType: 'none',
    disabledFilterTypes: ['startsWith', 'exactMatch', 'contains', 'endsWith', 'advanced'],
    filterMenuType: 'advanced',
    basicFilterType: 'contains',
    advancedFilterType: 'containsAny',
    colStyles: { width: '123px', minWidth: 123, textAlign: 'left' },
    headerCellStyle: {},
    colCellsStyles: { textAlign: 'left' },
    footerCellStyle: {},
    cellRenderer: text => (
      <div>
        <a
          href={`tel:+1${text}`}
        >
          {`${text.substr(0, 3)}-${text.substr(3, 3)}-${text.substr(6, 4)}`}
        </a>
      </div>
    )
  }
];

const styles = StyleSheet.create({
  sideDrawer: {
    color: '#60A0E0'
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    height: '40px'
  },
  drawerHeaderIcon: {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '40px',
    fontSize: '24px'
  },
  detailHeading: {
    color: '#2060A0',
    marginRight: '4px',
    fontWeight: 'bold'
  },
  filterTypeDropdownContainer: {
    width: '180px',
    height: '34px',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '1px solid #082840'
  },
  gridToolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '52px',
    paddingRight: '8px',
    border: '1px solid black',
    paddingLeft: '4px'
  },
  addProviderMenu: {
    width: '184px',
    position: 'relative',
    marginBottom: '8px'
  },
  filterDropdownBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '32px',
    height: '32px',
    borderLeft: '1px solid #082840'
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    width: '100%',
    background: '#103A60',
    fontSize: '16px'
  },
  menuItemText: {
    fontSize: '14px'
  },
  popupMenuParent: {
    position: 'fixed',
    height: '64px',
    width: '140px',
    background: 'black',
    zIndex: '100'
  },
  popupMenuContainer: {
    minWidth: '140px'
  },
  popupMenuItem: {
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    width: '138px',
    paddingLeft: '8px',
    background: '#103A60',
    ':hover': {
      background: '#0066CC'
    }
  }
});

class FacilitiesDataGrid extends Component {
  constructor (props) {
    super(props);
    this.state = {
      rawData: [],
      gridData: [],
      filters: {},
      headerDefs: clone(initHeaderDefs),
      docsCount: 0,
      currentPage: 1,
      showLoader: true,
      showDrawer: false,
      activeRecord: {},
      showEllipsisMenu: false,
      cursorX: 0,
      cursorY: 0,

      selectedFacility: {},
      showAddFacilityDrawer: false,

      search: '',
      nppesSearchResults: [],
      searching: false,
      showAddMsg: false,
      showSearchPopupMenu: false,

      npi: '',
      firstName: '',
      middleName: '',
      lastName: '',
      mailingAddressOrg: '',
      mailingAddressStreet: '',
      mailingAddressSuite: '',
      mailingAddressCity: '',
      mailingAddressState: '',
      mailingAddressZip: '',
      email: '',
      mobile: '',
      phone: '',
      fax: ''
    };
    this.getTotalRecords();
    this.getData();
  }

  getData = () => {
    let adminEmail = '';
    if (this.props.usr && this.props.usr.email) {
      console.log('props');
      adminEmail = this.props.usr.email;
    } else {
      console.log('localstorage');
      adminEmail = JSON.parse(localStorage.getItem('usr')).email;
    }
    const url = new URL(`${BASE_URL}/users/allFacilities`);
    const params = { adminEmail };
    url.search = new URLSearchParams(params).toString();

    fetch(url)
      .then(response => response.text())
      .then(result => {
        const data = [];
        const reqData = JSON.parse(result);
        for (let i = 0; i < reqData.facilities.length; i++) {
          const activeRecord = reqData.facilities[i];
          const add1 = activeRecord.mailingAddress.street;
          const suite = activeRecord.mailingAddress.suite;
          const city = activeRecord.mailingAddress.city;
          const state = activeRecord.mailingAddress.state;
          const zip = activeRecord.mailingAddress.zip;

          data.push({
            id: activeRecord._id,
            npi: activeRecord.npi,
            lastName: activeRecord.contactPersonLastName,
            middleName: activeRecord.contactPersonMiddleName,
            firstName: activeRecord.contactPersonFirstName,
            mailingAddress: `${add1} ${suite} ${city}, ${state} ${zip}`,
            mobile: activeRecord.mobile,
            phone: activeRecord.phone
          });
        }
        this.setState({
          rawData: reqData,
          gridData: data,
          showLoader: false
        });
      })
      .catch(error => console.log('error', error));
  };

  getTotalRecords = () => {
    let adminEmail = '';
    if (this.props.usr && this.props.usr.email) {
      console.log('props');
      adminEmail = this.props.usr.email;
    } else {
      console.log('localstorage');
      adminEmail = JSON.parse(localStorage.getItem('usr')).email;
    }

    const url = new URL(`${BASE_URL}/users/facility/count`);
    const params = { adminEmail };
    url.search = new URLSearchParams(params).toString();

    fetch(url)
      .then(response => response.text())
      .then(result => {
        const reqData = JSON.parse(result);
        this.setState({
          docsCount: reqData.count
        });
      })
      .catch(error => console.log('error', error));
  };

  getSorting = () => {
    return {};
  };

  handleOnApplyFilter = (filterType, field, value) => {
    console.log('filterType', filterType);
    console.log('field', field);
    console.log('value', value);

    // TODO: Better algo for resetting number range filters
    const filters = clone(this.state.filters);
    if (value !== '' && value !== ':') {
      filters[field] = {
        filterType,
        value
      };
    } else {
      delete filters[field];
    }

    this.setState(() => ({
      filters,
      docsCount: 0,
      currentPage: 1,
      showLoader: true
    }), () => {
      this.getTotalRecords();
      this.getData();
    });
  }

  handleOnResetColumn = (col) => {
    const filters = clone(this.state.filters);

    // Delete filter for the column
    if (filters[col]) {
      delete filters[col];
    }

    this.setState(() => ({
      filters,
      docsCount: 0,
      currentPage: 1,
      showLoader: true
    }), () => {
      this.getTotalRecords();
      this.getData();
    });
  };

  handleOnLoadPage = (page = 1) => {
    this.setState(() => ({
      currentPage: page,
      showLoader: true
    }), () => {
      this.getData();
    });
  };

  handleOnClickCell = (col, value) => {
    console.log('Cell click function is currently not available.');
    // const activeRecord = this.state.rawData.npi.filter(el => el.npi === value)[0];
    // this.setState(() => ({ activeRecord }), () => {
    //   this.toggleDrawer(true);
    // });
  };

  toggleDrawer = (__bool) => {
    this.setState({ showDrawer: __bool });
  };

  toogleSearchPopupMenu = () => {
    this.setState({
      showSearchPopupMenu: !this.state.showSearchPopupMenu
    });
  }

  handleNpiChange = (npiType) => {
    const headerDefs = clone(this.state.headerDefs);
    // const filters = clone(this.state.filters);

    headerDefs.forEach((el) => {
      // providerFirstName
      // organizationName
      if (npiType === 1) {
        if (el.field === 'providerLastName' ||
            el.field === 'providerFirstName' ||
            el.field === 'providerCredentialText' ||
            // el.field === 'providerEnumerationDate' ||
            // el.field === 'providerLicenseNumber_1' ||
            // el.field === 'taxonomyDesc' ||
            el.field === 'providerLicenseNumberStateCode_1' ||
            el.field === 'isSoleProprietor') {
          el.visible = true;
          el.sortOrder = 0;
        }
        if (el.field === 'providerOrganizationName' || el.field === 'isOrganizationSubpart') {
          el.visible = false;
          el.sortOrder = 0;
        }
      } else {
        if (el.field === 'providerLastName' ||
        el.field === 'providerFirstName' ||
        el.field === 'providerCredentialText' ||
        // el.field === 'providerEnumerationDate' ||
        // el.field === 'providerLicenseNumber_1' ||
        // el.field === 'taxonomyDesc' ||
        el.field === 'providerLicenseNumberStateCode_1' ||
        el.field === 'isSoleProprietor') {
          el.visible = false;
          el.sortOrder = 0;
        }
        if (el.field === 'providerOrganizationName' || el.field === 'isOrganizationSubpart') {
          el.visible = true;
          el.sortOrder = 0;
        }
      }
    });

    const filters = {};
    if (npiType === 1) {
      filters.entityTypeCode = 1;
    } else {
      filters.entityTypeCode = 2;
    }

    this.setState(() => ({
      npiType,
      headerDefs,
      filters,
      showLoader: true
    }), () => {
      this.getTotalRecords();
      this.getData();
    });
  };

  handleOnClickResetFilters = () => {
    const headerDefs = clone(this.state.headerDefs);
    headerDefs.forEach((el) => {
      if (el.field === 'providerLastName' || el.field === 'providerFirstName') {
        el.visible = true;
      }
      if (el.field === 'organizationName') {
        el.visible = false;
      }
    });
    this.setState(() => ({
      filters: { entityTypeCode: 1 },
      headerDefs,
      docsCount: 0,
      currentPage: 1,
      showLoader: true,
      npiType: 1
    }), () => {
      this.getTotalRecords();
      this.getData();
    });
  };

  handleUpdateFilterType = (field, val) => {
    const newHeaderDefs = this.state.headerDefs.concat();
    console.log('newHeaderdef', newHeaderDefs);
    newHeaderDefs.forEach((el) => {
      if (el.field === field) {
        el.filterType = val;
        el.filter = true;
      }
    });

    this.setState(() => ({
      headerDefs: newHeaderDefs
    }), this.handleOnClickResetFilters);
  }

  handleColFilterToggle = (field, val) => {
    // const newHeaderDefs = this.state.headerDefs.concat();
    const newHeaderDefs = clone(this.state.headerDefs);
    const filters = clone(this.state.filters);
    delete filters[field];

    newHeaderDefs.forEach((el) => {
      if (el.field === field) {
        el.filter = val;
        el.filterType = !val ? 'none' : el.filterType;
      }
    });

    this.setState(() => ({
      headerDefs: newHeaderDefs,
      filters
    }), () => {
      this.getTotalRecords();
      this.getData();
    });
  }

  handleOnChangeSortOrder = (field) => {
    const headerDefs = clone(this.state.headerDefs);
    for (let i = 0; i < headerDefs.length; i++) {
      if (headerDefs[i].field === field) {
        headerDefs[i].sortOrder = headerDefs[i].sortOrder === 0
          ? headerDefs[i].sortOrder + 1
          : headerDefs[i].sortOrder + (-headerDefs[i].sortOrder * 2);
      } else {
        headerDefs[i].sortOrder = 0;
      }
    }

    this.setState(() => ({
      headerDefs,
      showLoader: true
    }), () => {
      this.getTotalRecords();
      this.getData();
    });
  };

  handleOnColPinChange = (field, pin) => {
    const headerDefs = clone(this.state.headerDefs);
    headerDefs.forEach((el) => {
      if (el.field === field) {
        el.pin = pin;
      }
    });
    this.setState({ headerDefs });
  }

  handleOnResize = (action) => {
    let newHeaderDefs = this.state.headerDefs.concat();
    switch (action) {
      case 'noPin':
        newHeaderDefs.forEach((el) => {
          if (el.pin !== 'none') {
            el.pin = 'none';
          }
        });
        break;
      case 'removeRightPin':
        newHeaderDefs.forEach((el) => {
          if (el.pin === 'right') {
            el.pin = 'none';
          }
        });
        break;
      case 'removeLeftPin':
        newHeaderDefs.forEach((el) => {
          if (el.pin === 'left') {
            el.pin = 'none';
          }
        });
        break;
      default:
        // newHeaderDefs = this.pristineHeaderDefs;
        newHeaderDefs = clone(initHeaderDefs);
        break;
    }

    this.setState({
      headerDefs: newHeaderDefs
    });
  };

  handleChangeTextAlignment = (field, val) => {
    const newHeaderDefs = this.state.headerDefs.concat();
    newHeaderDefs.forEach((el) => {
      if (el.field === field) {
        el.colCellsStyles = Object.assign(el.colCellsStyles, { textAlign: val });
      }
    });

    this.setState({
      headerDefs: newHeaderDefs
    });
  }

  handleOnPopupMenu = (e) => {
    console.log(e);
    console.log(e.pageX, e.pageY);
    this.setState({
      showEllipsisMenu: true,
      cursorX: e.pageX,
      cursorY: e.pageY
    });
  };

  handldeOutsideClick = () => {
    this.setState({
      showEllipsisMenu: false
    });
  };

  searchNppes = async () => {
    const BASE_URL = process.env.NODE_ENV === 'development'
      ? 'http://localhost:8000'
      : window.location.origin;

    const options = {
      method: 'GET',
      url: '/api/v1/npi/search',
      baseURL: BASE_URL,
      params: {
        searchTerm: this.state.search
      }
    };

    this.setState({
      searching: true,
      showAddMsg: false
    });
    const req = await axios(options).catch((err) => console.log(err));

    if (req && req.status === 200 && req.data) {
      this.setState({
        nppesSearchResults: req.data.providers,
        searching: false,
        showAddMsg: req.data.providers.length === 0
      });
    }
  };

  handleOnClickAddFacilityFromList = async () => {
    const options = {
      method: 'GET',
      url: `${BASE_URL}/npi/searchNppes?npi=${this.state.selectedFacility.npi}`
    };

    const req = await axios(options).catch(err => console.log(err));

    if (req && req.status === 200) {
      // console.log(req.data);
      const { basic, number, addresses, endpoints } = req.data.providerData;
      this.setState({
        showAddFacilityDrawer: true,
        npi: `${number}`,
        firstName: basic.first_name,
        middleName: basic.middle_name,
        lastName: basic.last_name,
        mailingAddressOrg: '',
        mailingAddressStreet: addresses[1].address_1,
        mailingAddressSuite: addresses[1].address_2,
        mailingAddressCity: addresses[1].city,
        mailingAddressState: addresses[1].state,
        mailingAddressZip: addresses[1].postal_code,
        email: (endpoints && endpoints.length > 0) ? endpoints[0].endpoint : '',
        mobile: '',
        phone: '',
        fax: ''
      });
    }
  };

  toggleAddFacilityDrawer = () => {
    this.setState({
      showAddFacilityDrawer: !this.state.showAddFacilityDrawer
    });
  };

  handleOnChangeNpi = (e) => {
    const { value } = e.target;
    this.setState({ npi: value });
  };

  handleOnChangeFirstName = (e) => {
    const { value } = e.target;
    this.setState({ firstName: value });
  };

  handleOnChangemiddleName = (e) => {
    const { value } = e.target;
    this.setState({ middleName: value });
  };

  handleOnChangeLastName = (e) => {
    const { value } = e.target;
    this.setState({ lastName: value });
  };

  handleOnChangemailingAddressOrg = (e) => {
    const { value } = e.target;
    this.setState({ mailingAddressOrg: value });
  };

  handleOnChangeMailingAddressStreet = (e) => {
    const { value } = e.target;
    this.setState({ mailingAddressStreet: value });
  };

  handleOnChangeMailingAddressSuite = (e) => {
    const { value } = e.target;
    this.setState({ mailingAddressSuite: value });
  };

  handleOnChangeMailingAddressCity = (e) => {
    const { value } = e.target;
    this.setState({ mailingAddressCity: value });
  };

  handleOnChangeMailingAddressState = (e) => {
    const { value } = e.target;
    this.setState({ mailingAddressState: value });
  };

  handleOnChangeMailingAddressZip = (e) => {
    const { value } = e.target;
    this.setState({ mailingAddressZip: value });
  };

  handleOnChangeEmail = (e) => {
    const { value } = e.target;
    this.setState({ email: value });
  };

  handleOnChangeMobile = (e) => {
    const { value } = e.target;
    this.setState({ mobile: value });
  };

  handleOnChangePhone = (e) => {
    const { value } = e.target;
    this.setState({ phone: value });
  };

  handleOnChangeFax = (e) => {
    const { value } = e.target;
    this.setState({ fax: value });
  };

  handleOnClickAddFacility = () => {
    this.props.addFacility({
      adminEmail: this.props.usr.email,
      npi: this.state.npi,
      contactPersonFirstName: this.state.firstName,
      contactPersonMiddleName: this.state.middleName,
      contactPersonLastName: this.state.lastName,
      mailingAddressOrgName: this.state.mailingAddressOrg,
      mailingAddressStreet: this.state.mailingAddressStreet,
      mailingAddressSuite: this.state.mailingAddressSuite,
      mailingAddressCity: this.state.mailingAddressCity,
      mailingAddressState: this.state.mailingAddressState,
      mailingAddressZip: this.state.mailingAddressZip,
      email: this.state.email,
      mobile: this.state.mobile,
      phone: this.state.phone,
      fax: this.state.fax
    });
    location.reload();
  };

  // Refs
  addProvider = React.createRef();
  gridPopupMenu = React.createRef();

  static contextType = ThemeContext;

  render () {
    const {
      showAddFacilityDrawer,
      npi,
      firstName,
      middleName,
      lastName,
      mailingAddressOrg,
      mailingAddressStreet,
      mailingAddressSuite,
      mailingAddressCity,
      mailingAddressState,
      mailingAddressZip,
      email,
      mobile,
      phone,
      fax,
      search,
      showSearchPopupMenu,
      searching,
      showAddMsg,
      nppesSearchResults
    } = this.state;
    const { blue, gray } = this.context.colors;

    // Facility search results
    const items = [];
    if (nppesSearchResults.length > 0) {
      nppesSearchResults.forEach((el) => {
        items.push(
          <div
            className="m-item"
            style={{
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #001824',
              cursor: 'pointer',
              background: gray[700]
            }}
          >
            <div
              onClick={() => {
                this.setState({
                  selectedFacility: el
                }, this.handleOnClickAddFacilityFromList);
              }}
            >
              {`${el.providerLastName}, ${el.providerFirstName} (${el.npi})`}
            </div>
          </div>
        );
      });
    }

    return (
      <div
        className="grid-parent"
        style={{
          overflow: 'hidden'
        }}
      >
        {/* Add provider drawer */}
        {showAddFacilityDrawer
          ? <Drawer
              placement="right"
              visible={showAddFacilityDrawer}
              toggleDrawer={() => this.toggleAddFacilityDrawer(false)}
              zIndex="2000"
              width={window.innerWidth <= 700 ? window.innerWidth : 500}
            >
              {/* Drawer Container */}
              <div
                style={{
                  display: 'flex',
                  flexFlow: 'column',
                  color: '#60A0E0',
                  height: '100%',
                  position: 'relative'
                }}
              >
                {/* Header */}
                <div
                  style={{
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0 8px',
                    fontWeight: 'bold',
                    borderBottom: '1px solid #0a3250',
                    flexShrink: '0'
                  }}
                >
                  <div>
                    Create New Facility Profile
                  </div>
                  <div>
                    <i
                      className='fa fa-times-circle'
                      style={{ fontSize: '20px' }}
                      onClick={() => this.toggleAddProviderDrawer(false)}
                    />
                  </div>
                </div>
                {/* Middle Section */}
                <div
                  className="pl-2 pr-2"
                  style={{
                    flexGrow: '1',
                    // minHeight: '700px',
                    overflowX: 'hidden',
                    overflowY: 'auto'
                  }}
                >
                  {/* NPI */}
                  <div>
                    <TextInputWithLabel
                      value={npi}
                      titleText="NPI #"
                      isCompactLayout={true}
                      titleColor="#60A0E0"
                      textColor="#0080FF"
                      selectionColor="white"
                      selectionBackground="#0080FF"
                      spellcheck={false}
                      width={480}
                      onChange={this.handleOnChangeNpi}
                    />
                  </div>
                  {/* Firstname, middlename and lastname */}
                  <div
                    className='fx mt-4'
                    style={{ justifyContent: 'space-between' }}
                  >
                    {/* First name */}
                    <div>
                      <TextInputWithLabel
                        value={firstName}
                        titleText="First Name"
                        isCompactLayout={true}
                        titleColor="#60A0E0"
                        textColor="#0080FF"
                        selectionColor="white"
                        selectionBackground="#0080FF"
                        spellcheck={false}
                        width={145}
                        onChange={this.handleOnChangeFirstName}
                    />
                    </div>
                    {/* Middle name */}
                    <div>
                      <TextInputWithLabel
                        value={middleName}
                        titleText="Middle Name"
                        isCompactLayout={true}
                        titleColor="#60A0E0"
                        textColor="#0080FF"
                        selectionColor="white"
                        selectionBackground="#0080FF"
                        spellcheck={false}
                        width={145}
                        onChange={this.handleOnChangemiddleName}
                      />
                    </div>
                    {/* Last name */}
                    <div>
                        <TextInputWithLabel
                          value={lastName}
                          titleText="Last Name"
                          isCompactLayout={true}
                          titleColor="#60A0E0"
                          textColor="#0080FF"
                          selectionColor="white"
                          selectionBackground="#0080FF"
                          spellcheck={false}
                          width={145}
                          onChange={this.handleOnChangeLastName}
                      />
                      </div>
                  </div>
                  {/* Mailing Address */}
                  <div className='mt-4'>
                    {/* Heading */}
                    <div className='fx'>
                      <i className='mr-2 fa fa-map-marker' style={{ fontSize: '24px' }} />
                      <div>Mailing Address</div>
                    </div>
                    {/* Organization */}
                    <div className='pl-2'>
                      <TextInputWithLabel
                        value={mailingAddressOrg}
                        titleText="Organization (If Applicable)"
                        isCompactLayout={true}
                        titleColor="#60A0E0"
                        textColor="#0080FF"
                        selectionColor="white"
                        selectionBackground="#0080FF"
                        spellcheck={false}
                        width={472}
                        onChange={this.handleOnChangemailingAddressOrg}
                      />
                    </div>
                    {/* Street & Suite */}
                    <div className='fx pl-2 mt-2' style={{ justifyContent: 'space-between' }}>
                      <div>
                        <TextInputWithLabel
                          value={mailingAddressStreet}
                          titleText="Street"
                          isCompactLayout={true}
                          titleColor="#60A0E0"
                          textColor="#0080FF"
                          selectionColor="white"
                          selectionBackground="#0080FF"
                          spellcheck={false}
                          width={400}
                          onChange={this.handleOnChangeMailingAddressStreet}
                        />
                      </div>
                      <div>
                        <TextInputWithLabel
                          value={mailingAddressSuite}
                          titleText="Suite"
                          isCompactLayout={true}
                          titleColor="#60A0E0"
                          textColor="#0080FF"
                          selectionColor="white"
                          selectionBackground="#0080FF"
                          spellcheck={false}
                          width={55}
                          onChange={this.handleOnChangeMailingAddressSuite}
                        />
                      </div>
                    </div>
                    {/* City, State and Zip */}
                    <div className='fx pl-2 mt-2' style={{ justifyContent: 'space-between' }}>
                      <div>
                        <TextInputWithLabel
                          value={mailingAddressCity}
                          titleText="City"
                          isCompactLayout={true}
                          titleColor="#60A0E0"
                          textColor="#0080FF"
                          selectionColor="white"
                          selectionBackground="#0080FF"
                          spellcheck={false}
                          width={322}
                          onChange={this.handleOnChangeMailingAddressCity}
                        />
                      </div>
                      <div>
                        <TextInputWithLabel
                          value={mailingAddressState}
                          titleText="State"
                          isCompactLayout={true}
                          titleColor="#60A0E0"
                          textColor="#0080FF"
                          selectionColor="white"
                          selectionBackground="#0080FF"
                          spellcheck={false}
                          width={55}
                          onChange={this.handleOnChangeMailingAddressState}
                        />
                      </div>
                      <div>
                        <TextInputWithLabel
                          value={mailingAddressZip}
                          titleText="Zip"
                          isCompactLayout={true}
                          titleColor="#60A0E0"
                          textColor="#0080FF"
                          selectionColor="white"
                          selectionBackground="#0080FF"
                          spellcheck={false}
                          width={55}
                          onChange={this.handleOnChangeMailingAddressZip}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Email */}
                  <div className='mt-4'>
                    <TextInputWithLabel
                      value={email}
                      titleText="Email"
                      isCompactLayout={false}
                      showPreIcon
                      preIcon='fa fa-envelope'
                      titleColor="#60A0E0"
                      textColor="#0080FF"
                      selectionColor="white"
                      selectionBackground="#0080FF"
                      spellcheck={false}
                      width={482}
                      onChange={this.handleOnChangeEmail}
                    />
                  </div>
                  {/* Mobile # */}
                  <div className='mt-4'>
                    <TextInputWithLabel
                      value={mobile}
                      titleText="Mobile #"
                      isCompactLayout={false}
                      showPreIcon
                      preIcon='fa fa-mobile'
                      titleColor="#60A0E0"
                      textColor="#0080FF"
                      selectionColor="white"
                      selectionBackground="#0080FF"
                      spellcheck={false}
                      width={482}
                      onChange={this.handleOnChangeMobile}
                    />
                  </div>
                  {/* Phone # */}
                  <div className='mt-4'>
                    <TextInputWithLabel
                      value={phone}
                      titleText="Phone #"
                      isCompactLayout={false}
                      showPreIcon
                      preIcon='fa fa-phone'
                      titleColor="#60A0E0"
                      textColor="#0080FF"
                      selectionColor="white"
                      selectionBackground="#0080FF"
                      spellcheck={false}
                      width={482}
                      onChange={this.handleOnChangePhone}
                    />
                  </div>
                  {/* Fax # */}
                  <div className='mt-4'>
                    <TextInputWithLabel
                      value={fax}
                      titleText="Fax #"
                      isCompactLayout={false}
                      showPreIcon
                      preIcon='fa fa-fax'
                      titleColor="#60A0E0"
                      textColor="#0080FF"
                      selectionColor="white"
                      selectionBackground="#0080FF"
                      spellcheck={false}
                      width={482}
                      onChange={this.handleOnChangeFax}
                    />
                  </div>
                </div>
                {/* Footer */}
                <div className="p-2">
                  <Button
                    btnType='primary'
                    style={{ width: '100%' }}
                    onClick={this.handleOnClickAddFacility}
                  >
                    Add Facility
                  </Button>
                </div>
              </div>
            </Drawer>
          : null
        }

        <div style={{ width: '1030px' }}>
          {/* Grid toolbar */}
          <div
            className={css(styles.gridToolbar)}
            style={{ background: gray[500] }}
          >
            {/* Add facility menu */}
            <div
              className={css(styles.addProviderMenu)}
              ref={this.addProvider}
            >
              <TextInputWithLabel
                value={search}
                titleText="Add Facility"
                isCompactLayout={false}
                showPreIcon
                preIcon='fa fa-user-plus'
                titleColor="#60A0E0"
                textColor="#0080FF"
                selectionColor="white"
                selectionBackground="#0080FF"
                spellcheck={false}
                width={125}
                onClick={() => {
                  this.setState({
                    showSearchPopupMenu: true,
                    showAddMsg: true,
                    searching: false
                  });
                }}
                onChange={(e) => {
                  const { value } = e.target;
                  if (search.length === 0) {
                    this.setState({ searchProviders: true });
                  }
                  this.setState({
                    search: value
                  }, debouce(this.searchNppes, 300));
                }}
              />
              <PopupMenu
                top={44}
                left={0}
                zIndex='1550'
                position="absolute"
                visible={showSearchPopupMenu}
                parent={this.addProvider}
                onOutsideClick={this.toogleSearchPopupMenu}
              >
                <div
                  style={{
                    width: '300px',
                    background: gray[700]
                  }}
                >
                  <div style={{ padding: '8px 0', textAlign: 'center' }}>
                    Search Using Name(Last, First)
                  </div>
                  {searching
                    ? <div style={{ color: blue[500], fontSize: '1.25rem', textAlign: 'center', padding: '24px' }}>
                        Searching...
                      </div>
                    : items
                  }
                  {showAddMsg
                    ? <div
                        style={{
                          display: 'flex',
                          flexFlow: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '120px'
                        }}
                      >
                        <div>No Results...</div>
                        <div>
                          Click&nbsp;
                          <span
                            onClick={this.toggleAddFacilityDrawer}
                            style={{
                              color: blue[500],
                              cursor: 'pointer'
                            }}
                          >
                            HERE
                          </span> to create profile</div>
                      </div>
                    : null
                  }
                </div>
              </PopupMenu>
            </div>

            <div>
              {/* Reset button */}
              <div
                onClick={this.handleOnClickResetFilters}
              >
                <i className="fa fa-refresh" />
              </div>
            </div>
          </div>

          {/* Data grid popup menu */}
          <div
            ref={this.gridPopupMenu}
            className={`pop-up-container-test ${css(styles.popupMenuParent)}`}
            style={{
              top: this.state.cursorY,
              left: this.state.cursorX - 140,
              display: this.state.showEllipsisMenu ? 'block' : 'none'
            }}
          >
            <PopupMenu
              top={0}
              left={0}
              position="relative"
              visible={this.state.showEllipsisMenu}
              parent={this.gridPopupMenu}
              onOutsideClick={this.handldeOutsideClick}
            >
              <div className={css(styles.popupMenuContainer)}>
                <div
                  className={css(styles.popupMenuItem)}
                  // onClick={() => { this.setState({ showDialogBox: true, showEllipsisMenu: false }); }}
                >
                  Edit Record
                </div>
                <div className={css(styles.popupMenuItem)}>
                  Show Details
                </div>
                <div className={css(styles.popupMenuItem)}>Add to List</div>
                <div className={css(styles.popupMenuItem)}>Delete Record</div>
              </div>
            </PopupMenu>
          </div>

          {/* Data grid */}
          <DataGrid
            loader={this.state.showLoader}
            icons={icons}
            headerDefs={this.state.headerDefs}
            rowData={this.state.gridData}
            filters={this.state.filters}
            gridWidth={1030}
            gridHeight={300}
            gridHeaderHeight={40}
            gridFooterHeight={0}
            gridContainerHeight="730px"
            rowHeight={40}
            rowsPerPage={DOCS_PER_PAGE}
            defaultCellStyle={defaultCellStyle}
            showFooter={false}
            showEllipsisCol
            enableHover
            totalPages={Math.ceil(this.state.docsCount / DOCS_PER_PAGE)}
            currentPage={this.state.currentPage}
            onApplyFilter={this.handleOnApplyFilter}
            onResetCol={this.handleOnResetColumn}
            onLoadPage={this.handleOnLoadPage}
            onClickCell={this.handleOnClickCell}
            onColFilterToggle={this.handleColFilterToggle}
            onFilterTypeUpdate={this.handleUpdateFilterType}
            onColPinChange={this.handleOnColPinChange}
            onResize={this.handleOnResize}
            onPopupMenu={this.handleOnPopupMenu}
            onColVisibilityChange={() => console.log('on col visibility changed')}
            onFilterChange={() => console.log('on filter changed')}
            onChangeTextAlignment={this.handleChangeTextAlignment}
            onResetGrid={() => console.log('on reset grid')}
            onSort={this.handleOnChangeSortOrder}
            onColConfig={() => console.log('on col config changed')}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps ({ user }) {
  return { ...user };
}

export default connect(mapStateToProps, { addFacility })(FacilitiesDataGrid);
