import React, { Fragment } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import PropTypes from 'prop-types';

const propTypes = {
  organization: PropTypes.string,
  mailingAddress: PropTypes.object,
  email: PropTypes.string,
  mobile: PropTypes.string,
  phone: PropTypes.string,
  fax: PropTypes.string,
  hideName: PropTypes.bool,
  profileDescription: PropTypes.string
};

const styles = StyleSheet.create({
  detailIconContainer: { width: '40px' },
  detailIcon: { fontSize: '24px' }
});

const Contact = (props) => {
  const {
    organization,
    mailingAddress,
    email,
    mobile,
    phone,
    fax,
    website,
    hideName
    // profileDescription
  } = props;
  const addTmp = `${mailingAddress.street} ${mailingAddress.city}, ${mailingAddress.state} ${mailingAddress.zip} `;
  const addressUrl = 'https://www.google.com/maps/search/?api=1&query=' + encodeURI(addTmp);

  return (
    <Fragment>
      {/* Address */}
      <div className="mt-4 fx">
        <div className={css(styles.detailIconContainer)}>
          <i className={`fa fa-map-marker ${css(styles.detailIcon)}`} />
        </div>
        <div className="ml-2">
          {!hideName
            ? <div>{organization}</div>
            : null
          }
          <a
            target="_blank"
            href={addressUrl}
            rel="noreferrer"
          >
            <div>{mailingAddress.street}</div>
            {(mailingAddress.suite !== null && mailingAddress.suite !== '')
              ? <div>{`Suite ${mailingAddress.suite}`}</div>
              : null
            }
            <div>{`${mailingAddress.city}, ${mailingAddress.state} ${mailingAddress.zip}`}</div>
          </a>
        </div>
      </div>
      {/* Email */}
      <div className="fx mt-2">
        <div className={css(styles.detailIconContainer)}>
          <i className={`fa fa-envelope ${css(styles.detailIcon)}`} />
        </div>
        <div>
          <a
            href={`mailto:${email}`}
            rel="noreferrer"
          >
            <div>{email}</div>
          </a>
        </div>
      </div>
      {/* Mobile */}
      {(mobile !== null && mobile !== '')
        ? <div className="fx mt-2">
            <div className={css(styles.detailIconContainer)}>
              <i className={`fa fa-mobile ${css(styles.detailIcon)}`} />
            </div>
            <div>
              <a
                href={`tel:+1${mobile}`}
                rel="noreferrer"
              >
                <div>{mobile}</div>
              </a>
            </div>
          </div>
        : null
      }
      {/* Telephone */}
      {phone !== null && phone !== ''
        ? <div className="fx mt-2">
            <div className={css(styles.detailIconContainer)}>
              <i className={`fa fa-phone ${css(styles.detailIcon)}`} />
            </div>
            <div>
              <a
                href={`tel:+1${phone}`}
                rel="noreferrer"
              >
                <div>{phone}</div>
              </a>
            </div>
          </div>
        : null
      }
      {/* Fax */}
      {(fax !== null && fax !== '')
        ? <div className="fx mt-2">
            <div className={css(styles.detailIconContainer)}>
              <i className={`fa fa-fax ${css(styles.detailIcon)}`} />
            </div>
            <div>
              <div>{fax}</div>
            </div>
          </div>
        : null
      }
      {(website !== null && website !== '')
        ? <div className="fx mt-2">
            <div className={css(styles.detailIconContainer)}>
              <i className={`fa fa-globe ${css(styles.detailIcon)}`} />
            </div>
            <div>
              <a
                target="_blank"
                href={website}
                rel="noreferrer"
              >
                <div>{website}</div>
              </a>
            </div>
          </div>
        : null
      }
    </Fragment>
  );
};

Contact.propTypes = propTypes;
Contact.displayName = 'Contact';
export default Contact;
