import React from 'react';

const Invoices = (props) => {
  return (
    <div>
      <h1>Invoices Placeholder</h1>
    </div>
  );
}

export default Invoices;
