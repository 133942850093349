import React, { useState } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Switch, Route, Link, useRouteMatch, useLocation } from 'react-router-dom';
import { blue, gray } from 'quantux-ui';
import LoginCredentials from '../components/settings/login-credentials';
import SecurityEventsLog from '../components/settings/security-events-log';
import ProfileAccessManager from '../components/settings/profile-access-manager';
import Messages from '../components/settings/messages';
import Notifications from '../components/settings/notifications';
import ColorTheme from '../components/settings/color-theme';
import PaymentMethods from '../components/settings/payment-methods';
import Invoices from '../components/settings/invoices';

const styles = StyleSheet.create({
  splitPanel: {
    display: 'flex',
    flexFlow: 'row nowrap',
    // position: 'relative',
    background: '#002030',
    height: 'calc(100vh - 399px)',
    width: '100%',
    overflow: 'hidden',
    fontFamily: 'Lato'
  },
  drawer: {
    position: 'relative',
    height: '100%',
    minWidth: '320px',
    width: '320px',
    borderRight: '1px solid #082840',
    transition: '0.3s',
    left: '0',
    padding: '0',
    overflowY: 'auto',
    zIndex: '1400'
  },
  hideDrawer: {
    transition: '0.3s',
    left: 'calc(-1 * 320px)'
  },
  drawerHeader: {
    height: '40px',
    fontSize: '16px',
    background: '#001824',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    borderBottom: '0.5px solid #082840',
    marginLeft: '8px',
    cursor: 'pointer'
  },
  drawerHeaderIconContainer: {
    height: '32px',
    width: '32px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '4px',
    cursor: 'pointer'
  },
  drawerHeaderIcon: {
    fontSize: '24px',
    cursor: 'pointer'
  },
  drawerHeaderTextContainer: {
    textAlign: 'left',
    lineHeight: '14px',
    cursor: 'pointer'
  },
  drawerHeaderText: {
    textAlign: 'center',
    fontSize: '18px',
    color: '#60A0E0',
    cursor: 'pointer',
    paddingLeft: '4px'
  },
  drawerHeaderTextSub: {
    color: '#0080FF',
    fontSize: '14px',
    cursor: 'pointer'
  },
  drawerTabsContainer: {
    height: 'calc(100% - 64px)',
    overflowY: 'auto',
    paddingTop: '8px'
  },
  drawerAccordian: {
    position: 'relative',
    display: 'block',
    transition: '0.5s ease',
    maxHeight: '2000px'
  },
  menuItem: {
    position: 'relative',
    left: '-3px',
    color: gray[300]
  },
  detail: {
    // position: 'relative',
    transition: '0s',
    height: '100%',
    width: 'calc(100vw - 320px)',
    left: '0'
  },
  detailExapnd: {
    transition: '0.2s',
    left: 'calc(-1 * 320px)',
    width: '100vw'
  },
  detailHeader: {
    height: '40px',
    background: '#001824',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '0.5px solid #082840',
    padding: '0 8px'
  },
  profileBanner: {
    borderBottom: '1px solid transparent'
  },
  profileBannerImg: {
    width: '100%',
    height: 'auto',
    maxHeight: '220px'
  },
  bigUserCardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid transparent'
  },
  tabs: {
    // borderBottom: '1px solid red'
  },
  popupMenuContainer: {
    background: '#083050',
    width: '150px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'
  },
  popupMenuItem: {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#5080B0'
  },
  menuItemIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    width: '40px'
  },
  menuItemIcon: {
    fontSize: '20px'
  },
  accordianRightBorder: {
    position: 'absolute',
    width: '1px',
    height: 'calc(100% - 40px)',
    background: '#305C82',
    left: '13px',
    top: '40px'
  },
  linkStyles: {
    display: 'flex',
    alignItems: 'center',
    color: gray[300]
  },
  selectedLink: {
    color: blue[500]
  },
  drawerFile: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '4px',
    color: blue[500],
    height: '40px'
  },
  drawerFileIcon: {
    fontSize: '32px',
    marginRight: '8px'
  },
  activeDrawerMenuItem: {
    borderLeft: `1px solid ${blue[500]}`,
    color: blue[500],
    background: gray[700]
  }
});

const Settings = () => {
  const [isDrawerOpen, toggleDrawer] = useState(true);
  const { path } = useRouteMatch();
  const location = useLocation();
  let activeText = 'Login Credentials';
  let activeTab = 'loginCredentials';

  if (location.pathname.endsWith('/loginCredentials')) {
    activeText = 'Login Credentials';
    activeTab = 'loginCredentials';
  } else if (location.pathname.endsWith('/securityEventsLog')) {
    activeText = 'Security Events Log';
    activeTab = 'securityEventsLog';
  } else if (location.pathname.endsWith('/profileAccessManager')) {
    activeText = 'Profile Access Manager';
    activeTab = 'profileAccessManager';
  } else if (location.pathname.endsWith('/messages')) {
    activeText = 'Messages';
    activeTab = 'messages';
  } else if (location.pathname.endsWith('/notifications')) {
    activeText = 'Notifications & Alerts';
    activeTab = 'notifications';
  } else if (location.pathname.endsWith('/colorTheme')) {
    activeText = 'Color Theme';
    activeTab = 'colorTheme';
  } else if (location.pathname.endsWith('/paymentMethods')) {
    activeText = 'Payment Methods';
    activeTab = 'paymentMethods';
  } else if (location.pathname.endsWith('/invoices')) {
    activeText = 'Invoices';
    activeTab = 'invoices';
  }

  return (
    <div
      style={{ marginTop: '40px' }}
    >
      <div className={css(styles.splitPanel)}>
        {/* Panel Drawer */}
        <div className={css(styles.drawer, !isDrawerOpen && styles.hideDrawer)}>
          {/* Drawer header */}
          <div className={css(styles.drawerHeader)}>
            {/* Header icon */}
            <div className={css(styles.drawerHeaderIconContainer)}>
              <i className={`fa fa-gear ${css(styles.drawerHeaderIcon)}`} />
            </div>
            {/* Header text */}
            <div className={css(styles.drawerHeaderTextContainer)}>
              <div className={css(styles.drawerHeaderText)}>
                Settings
              </div>
            </div>
          </div>
          {/* Drawer tabs */}
          <div className={css(styles.drawerTabsContainer)}>
            {/* Security */}
            <div className={css(styles.drawerAccordian)}>
              {/* Accordian right border */}
              <div className={css(styles.accordianRightBorder)} />
              <div className={css(styles.drawerFile)}>
                <i className={`fa fa-caret-down ${css(styles.drawerFileIcon)}`} />
                <div>
                  Security
                </div>
              </div>
              {/* Login Credentials */}
              <Link to={`${path}/loginCredentials`}>
                <div
                  className={`noselect ml-3 drawer-menu-item ${css(styles.menuItem, activeTab === 'loginCredentials' && styles.activeDrawerMenuItem)}`}
                >
                  <div
                    className="text"
                    style={{ fontSize: '1rem', lineHeight: '14px' }}
                  >
                    Login Credentials
                  </div>
                </div>
              </Link>
              {/* Security Events Log */}
              <Link to={`${path}/securityEventsLog`}>
                <div
                  className={`noselect ml-3 drawer-menu-item ${css(styles.menuItem, activeTab === 'securityEventsLog' && styles.activeDrawerMenuItem)}`}
                >
                  <div
                    className="text"
                    style={{ fontSize: '1rem', lineHeight: '14px' }}
                  >
                    Security Events Log
                  </div>
                </div>
              </Link>
              {/* Profile Access Manager */}
              <Link to={`${path}/profileAccessManager`}>
                <div
                  className={`noselect ml-3 drawer-menu-item ${css(styles.menuItem, activeTab === 'profileAccessManager' && styles.activeDrawerMenuItem)}`}
                >
                  <div
                    className="text"
                    style={{ fontSize: '1rem', lineHeight: '14px' }}
                  >
                    Profile Access Manager
                  </div>
                </div>
              </Link>
            </div>
            {/* App Settings */}
            <div className={css(styles.drawerAccordian)}>
              {/* Accordian right border */}
              <div className={css(styles.accordianRightBorder)} />
              <div className={css(styles.drawerFile)}>
                <i className={`fa fa-caret-down ${css(styles.drawerFileIcon)}`} />
                <div>
                  App Settings
                </div>
              </div>
              {/* Messages */}
              <Link to={`${path}/messages`}>
                <div
                  className={`noselect ml-3 drawer-menu-item ${css(styles.menuItem, activeTab === 'messages' && styles.activeDrawerMenuItem)}`}
                >
                  <div
                    className="text"
                    style={{ fontSize: '1rem', lineHeight: '14px' }}
                  >
                    Messages
                  </div>
                </div>
              </Link>
              {/* Notifications & Alers */}
              <Link to={`${path}/notifications`}>
                <div
                  className={`noselect ml-3 drawer-menu-item ${css(styles.menuItem, activeTab === 'notifications' && styles.activeDrawerMenuItem)}`}
                >
                  <div
                    className="text"
                    style={{ fontSize: '1rem', lineHeight: '14px' }}
                  >
                    Notifications & Alers
                  </div>
                </div>
              </Link>
              {/* Color Theme */}
              <Link to={`${path}/colorTheme`}>
                <div
                  className={`noselect ml-3 drawer-menu-item ${css(styles.menuItem, activeTab === 'colorTheme' && styles.activeDrawerMenuItem)}`}
                >
                  <div
                    className="text"
                    style={{ fontSize: '1rem', lineHeight: '14px' }}
                  >
                    Color Theme
                  </div>
                </div>
              </Link>
            </div>
            {/* Payment Methods & Invoices */}
            <div className={css(styles.drawerAccordian)}>
              {/* Accordian right border */}
              <div className={css(styles.accordianRightBorder)} />
              <div className={css(styles.drawerFile)}>
                <i className={`fa fa-caret-down ${css(styles.drawerFileIcon)}`} />
                <div>
                  Payment Methods & Invoices
                </div>
              </div>
              {/* Payment Methods */}
              <Link to={`${path}/paymentMethods`}>
                <div
                  className={`noselect ml-3 drawer-menu-item ${css(styles.menuItem, activeTab === 'paymentMethods' && styles.activeDrawerMenuItem)}`}
                >
                  <div
                    className="text"
                    style={{ fontSize: '1rem', lineHeight: '14px' }}
                  >
                    Payment Methods
                  </div>
                </div>
              </Link>
              {/* Invoices */}
              <Link to={`${path}/invoices`}>
                <div
                  className={`noselect ml-3 drawer-menu-item ${css(styles.menuItem, activeTab === 'invoices' && styles.activeDrawerMenuItem)}`}
                >
                  <div
                    className="text"
                    style={{ fontSize: '1rem', lineHeight: '14px' }}
                  >
                    Invoices
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* Panel details */}
        <div className={css(styles.detail, !isDrawerOpen && styles.detailExapnd)}>
          <div className={css(styles.detailHeader)}>
            <div>{activeText}</div>
          </div>
          {/* Active component */}
          <Switch>
            <Route path={`${path}/loginCredentials`}>
              <LoginCredentials />
            </Route>
            <Route path={`${path}/securityEventsLog`}>
              <SecurityEventsLog />
            </Route>
            <Route path={`${path}/profileAccessManager`}>
              <ProfileAccessManager />
            </Route>
            <Route path={`${path}/messages`}>
              <Messages />
            </Route>
            <Route path={`${path}/notifications`}>
              <Notifications />
            </Route>
            <Route path={`${path}/colorTheme`}>
              <ColorTheme />
            </Route>
            <Route path={`${path}/paymentMethods`}>
              <PaymentMethods />
            </Route>
            <Route path={`${path}/invoices`}>
              <Invoices />
            </Route>
        </Switch>
        </div>
      </div>
    </div>
  );
};

export default Settings;
