import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Link } from 'react-router-dom';
import { gray, blue } from 'quantux-ui';
import medprosLogoSmall from '../../imgs/medProsLogoSmall.svg';
import medprosLogoLarge from '../../imgs/medProsLogoLarge.svg';

const styles = StyleSheet.create({
  footer: {
    width: '100%',
    background: gray[900],
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '40px'
  },
  footerSections: {
    display: 'flex',
    width: '700px',
    '@media (max-width: 712px)': {
      width: '100vw',
      display: 'block'
    }
  },
  logo: {
    width: '700px',
    '@media (max-width: 712px)': {
      width: '100vw',
      paddingLeft: '8px'
    }
  },
  contactUs: {
    width: '300px',
    borderRight: `1px solid ${blue[700]}`,
    '@media (max-width: 712px)': {
      borderRight: 'none',
      paddingLeft: '8px'
    }
  },
  findUs: {
    width: '200px',
    borderRight: `1px solid ${blue[700]}`,
    paddingLeft: '16px',
    '@media (max-width: 712px)': {
      borderRight: 'none',
      paddingLeft: '8px'
    }
  },
  legal: {
    width: '200px',
    paddingLeft: '16px',
    '@media (max-width: 712px)': {
      paddingLeft: '8px'
    }
  },
  footerSectionHeading: {
    color: gray[300],
    fontSize: '18px',
    marginBottom: '8px',
    marginTop: '16px',
    paddingLeft: '8px'
  },
  footerItem: {
    color: blue[500],
    paddingTop: '4px',
    fontSize: '16px'
  },
  footerItemIcon: {
    minWidth: '40px',
    minHeight: '40px',
    maxHeight: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '24px',
    // marginRight: '8px',
    color: blue[500]
  },
  copyRight: {
    width: '700px',
    textAlign: 'center',
    margin: '16px 0',
    '@media (max-width: 712px)': {
      width: '100vw'
    }
  }
});

const addressUrl = 'https://www.google.com/maps/search/?api=1&query=' + encodeURI('9375 East Shea BLVD Suite 100 Scottsdale, AZ 85260');

const Footer = () => (
  <div className={css(styles.footer)}>
    {/* Logo */}
    <div className={css(styles.logo)}>
      <img className='mr-1' src={medprosLogoSmall} alt="logo small" />
      <img src={medprosLogoLarge} alt="logo large" />
    </div>
    <div className={css(styles.footerSections)}>
      {/* Contact Us */}
      <div className={css(styles.contactUs)}>
        <div className={css(styles.footerSectionHeading)}>
          Contact Us
        </div>
        <div className="fx">
          <div className={css(styles.footerItemIcon)}>
            <i className="fa fa-map-marker" />
          </div>
          <div className={css(styles.footerItem)}>
            <a
              target="_blank"
              href={addressUrl}
              rel="noreferrer"
            >
              <span>MedPros</span><br />
              <span>9375 East Shea BLVD</span><br />
              <span>Suite 100</span><br />
              <span>Scottsdale, AZ 85260</span><br />
            </a>
          </div>
        </div>
        <div className="fx">
          <div className={css(styles.footerItemIcon)}>
            <i className="fa fa-headphones" />
          </div>
          <div className={css(styles.footerItem)}>
            <a
              href='tel:+18447826889'
              rel="noreferrer"
            >
              <div>844.782.6889</div>
            </a>
          </div>
        </div>
        <div className="fx">
          <div className={css(styles.footerItemIcon)}>
            <i className="fa fa-envelope" />
          </div>
          <div className={css(styles.footerItem)}>
            <a href='mailto:medprosinc@gmail.com' rel="noreferrer">
              <div>medprosinc@gmail.com</div>
            </a>
          </div>
        </div>
      </div>
      {/* Find Us On */}
      <div className={css(styles.findUs)}>
        <div className={css(styles.footerSectionHeading)}>
          Find Us
        </div>
        <div className="fx">
          <a
            target="_blank"
            href='https://www.linkedin.com/in/med-pros-b12060208/'
            rel="noreferrer"
          >
            <div className='fx fx__hv-center'>
              <div className={css(styles.footerItemIcon)}>
                <i className="fa fa-linkedin" />
              </div>
              <div className={css(styles.footerItem)}>
                LinkedIn
              </div>
            </div>
          </a>
        </div>
        <div className="fx">
          <a
            target="_blank"
            href='https://twitter.com/medprosinc'
            rel="noreferrer"
          >
            <div className='fx fx__hv-center'>
              <div className={css(styles.footerItemIcon)}>
                <i className="fa fa-twitter" />
              </div>
              <div className={css(styles.footerItem)}>
                Twitter
              </div>
            </div>
          </a>
        </div>
      </div>
      {/* Legal */}
      <div className={css(styles.legal)}>
        <div className={css(styles.footerSectionHeading)}>
          Legal
        </div>
        <div className="fx pl-2">
          <Link to='/legal/tos'>
            <div className={css(styles.footerItem)}>
              Terms of Service
            </div>
          </Link>
        </div>
        <div className="fx pl-2">
          <Link to='/legal/pPolicy'>
            <div className={css(styles.footerItem)}>Privacy Policy</div>
          </Link>
        </div>
        <div className="fx pl-2">
          <Link to='/legal/cPolicy'>
            <div className={css(styles.footerItem)}>Cookie Policy</div>
          </Link>
        </div>
      </div>
    </div>
    <div className={css(styles.copyRight)}>
      © 2021 Quantux Technologies, Inc. All rights reserved
    </div>
  </div>
);

export default Footer;
