import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Cropper } from 'react-cropper';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isURL from 'validator/lib/isURL';
import {
  Drawer,
  PopupMenu,
  TabsContainer,
  TabItem,
  Button,
  TextInputWithLabel,
  Loader,
  FileUploader,
  blue,
  gray,
  salmon
} from 'quantux-ui';
import { titleCase, BASE_URL, DRAWER_BREAK_POINT } from '../../utils';
import About from '../about';
import Contact from '../contact';

const propTypes = {
  userData: PropTypes.object
};

const drawerWidth = window.innerWidth <= DRAWER_BREAK_POINT ? window.innerWidth : 320;

/*
 * AboutUser component
 * @return - JSX.ElementType
 */
const AboutUser = (props) => {
  // Props variables
  const {
    lastName: userLastName = '',
    middleName: userMiddleName = '',
    firstName: userFirstName = '',
    email: userEmail = '',
    mobile: userMobile = '',
    phone: userPhone = '',
    fax: userFax = '',
    profileDescription: userProfileDescription = '',
    mailingAddress = {},
    bannerImageUrl: userBannerImageUrl = '',
    avatarImageUrl: userAvatarImageUrl = '',
    displayName: userDisplayName = '',
    userName: userUserName = '',
    npi: userNpi = '',
    website: userWebsite = '',
    credentials,
    specialty
  } = props.userData;

  const {
    streetAddress1: userStreetAddress = '',
    suite: userSuite = '',
    city: userCity = '',
    state: userState = '',
    zip: userZip = ''
  } = mailingAddress;

  // #region State variables
  const isFirstRender = useRef(true);
  const [isPopupMenuOpen, togglePopupMenu] = useState(false);
  const [isBannerCropperDrawerOpen, toggleBannerCropperDrawer] = useState(false);
  const [isAvatarCropperDrawerOpen, toggleAvatarCropperDrawer] = useState(false);
  const [isEditProfileDrawerVisible, toggleEditProfileDrawer] = useState(false);
  const [displayName, setDisplayName] = useState(userDisplayName);
  const [isDisplayNameValid, checkDisplayNameValidity] = useState(false);
  const [isDisplayNamePristine, setDisplayNamePristineState] = useState(true);
  const [userName, setUserName] = useState(userUserName);
  const [isUserNameValid, checkUserNameValidity] = useState(false);
  const [isUserNamePristine, setUserNamePristineState] = useState(true);
  const [isUserNameDuplicate, checkUserNameDuplicateStatus] = useState(false);
  const [streetAddress, setStreetAddress] = useState(userStreetAddress);
  const [suite, setSuite] = useState(userSuite);
  const [city, setCity] = useState(userCity);
  const [state, setState] = useState(userState);
  const [zip, setZip] = useState(userZip);
  const [phone, setPhone] = useState(userPhone);
  const [isPhoneValid, checkPhoneValidity] = useState(false);
  const [isPhonePristine, setPhonePristineState] = useState(true);
  const [fax, setFax] = useState(userFax);
  const [website, setWebsite] = useState(userWebsite);
  const [profileDescription, setProfileDescription] = useState(userProfileDescription);
  const [bannerImageUrl, setBannerImageUrl] = useState('https://quantumtrader.s3.amazonaws.com/dwMKF8A8HUKA1_7_5Xns8.png');
  const [avatarImageUrl, setAvatarImageUrl] = useState('');
  const [activeTab, setActiveTab] = useState('about');
  const [showBannerCropper, setShowBannerCropper] = useState(false);
  const [isBannerUploading, setIsBannerUploading] = useState(false);
  const [showAvatarCropper, setShowAvatarCropper] = useState(false);
  const [isAvatarUploading, setIsAvatarUploading] = useState(false);
  const [canSaveUserProfile, setCanSaveUserProfile] = useState(false);
  // #endregion

  // Check for duplicate username
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (userName.length >= 2 && userName.length <= 20 && userName.indexOf(' ') < 0) {
      const url = new URL(`${BASE_URL}/users/exists/username/${userName}`);
      fetch(url)
        .then(res => res.text())
        .then((result) => {
          const data = JSON.parse(result);
          checkUserNameDuplicateStatus(data.isDuplicate);
        })
        .catch(error => console.log('error', error))
    }
    // const ddF = setTimeout(() => { searchUsersByLastName(); }, 300);
    // return () => clearTimeout(ddF);
  }, [userName]);

  // Valite user profile data before sending update request
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    console.log('displayName.length >= 2 && displayName.length <= 40', displayName.length >= 2 && displayName.length <= 40);
    console.log("userName.length >= 2 && userName.length <= 20 && userName.indexOf(' ') < 0", userName.length >= 2 && userName.length <= 20 && userName.indexOf(' ') < 0);
    console.log('!isUserNameDuplicate', !isUserNameDuplicate);
    console.log('streetAddress.length > 5', streetAddress.length > 5);
    console.log('city.length >= 2', city.length >= 2);
    console.log('state.length === 2', state.length === 2);
    console.log('zip.length >= 5', zip.length >= 5);
    console.log("isMobilePhone(phone, 'en-US')", isMobilePhone(phone, 'en-US'));

    const value =
      displayName.length >= 2 && displayName.length <= 40 &&
      userName.length >= 2 && userName.length <= 20 && userName.indexOf(' ') < 0 &&
      !isUserNameDuplicate &&
      streetAddress.length > 5 &&
      city.length >= 2 &&
      state.length === 2 &&
      zip.length >= 5 &&
      isMobilePhone(phone, 'en-US');
    setCanSaveUserProfile(value);
  }, [
    displayName,
    userName,
    isUserNameDuplicate,
    streetAddress,
    suite,
    city,
    state,
    zip,
    phone,
    fax,
    website
  ]);

  // Refs
  const popupRef = React.createRef();
  const drawerBannerRef = React.createRef();
  const drawerAvatarRef = React.createRef();
  const bannerCropperRef = React.createRef();
  const bannerFileInputRef = React.createRef();
  const avatarCropperRef = React.createRef();
  const avatarFileInputRef = React.createRef();

  const styles = StyleSheet.create({
    userCard: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '80px',
      height: '80px',
      borderRadius: '4px'
    },
    userCardIcon: {
      color: 'white',
      fontSize: '70px'
    },
    userCardAvatarImg: {
      width: 'auto',
      height: '80px',
      maxHeight: '80px',
      borderRadius: '4px'
    },
    userCardDisplayName: {
      color: blue[500]
    },
    userCardEllipsisMenuBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#60A0E0',
      height: '24px',
      width: '24px',
      borderRadius: '50%',
      color: '#001824',
      position: 'relative'
    },
    profileBanner: {
      borderBottom: '1px solid transparent'
    },
    profileBannerImg: {
      width: '100%',
      height: 'auto',
      maxHeight: '220px'
    },
    bigUserCardContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid transparent'
    },
    tabs: {
      // borderBottom: '1px solid red'
    },
    popupMenuContainer: {
      background: '#083050',
      width: '150px',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'
    },
    popupMenuItem: {
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      color: '#5080B0'
    },
    menuItemIconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '40px',
      width: '40px'
    },
    menuItemIcon: {
      fontSize: '20px'
    },
    criticalSmallText: {
      fontSize: '12px',
      color: salmon[500],
      paddingLeft: '8px',
      fontWeight: 'bold',
      width: '140px'
    },
    drawerContainer: {
      background: gray[500],
      color: gray[300],
      height: '100%',
      position: 'relative',
      display: 'flex',
      flexFlow: 'column'
    },
    drawerHeader: {
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 8px',
      fontWeight: 'bold',
      borderBottom: '1px solid black',
      flexShrink: '0'
    },
    drawerHeaderCloseIcon: {
      color: gray[300],
      ':hover': {
        color: salmon[500]
      }
    },
    drawerBanner: {
      position: 'relative',
      padding: '8px',
      flexShrink: '0'
    },
    drawerBannerImg: {
      width: '100%',
      height: 'auto'
    },
    drawerBannerCameraIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: gray[500],
      color: 'white',
      position: 'absolute',
      right: '0',
      bottom: '8px',
      fontSize: '11px',
      width: '24px',
      height: '24px',
      cursor: 'pointer',
      borderRadius: '50%'
    },
    drawerContentArea: {
      // height: 'calc(100vh - 186px)',
      overflowY: 'auto',
      color: gray[300],
      flexGrow: '1'
    },
    drawerUserCardAvatar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '80px',
      height: '80px',
      color: 'white',
      borderRadius: '4px',
      position: 'relative'
    },
    drawerUserCardAvatarImg: {
      width: 'auto',
      height: '80px',
      maxHeight: '80px'
    },
    drawerAvatarCamera: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: gray[500],
      color: 'white',
      position: 'absolute',
      left: '-3px',
      top: '58px',
      fontSize: '11px',
      width: '24px',
      height: '24px',
      cursor: 'pointer',
      borderRadius: '50%'
    },
    drawerUserCardText: {
      fontSize: '12px'
    },
    profileTextAreaContainer: {
      paddingRight: '8px'
    },
    profileTextArea: {
      width: '100%',
      background: gray[900],
      height: '100px',
      color: gray[100],
      border: 'none',
      resize: 'none',
      overflow: 'auto',
      fontFamily: 'inherit',
      ':focus': {
        outlineColor: 'transparent',
        outlineStyle: 'none'
      }
    },
    drawerFooter: {
      alignItems: 'center',
      justifyContent: 'flex-end',
      background: gray[700],
      height: '56px',
      width: '100%',
      borderTop: `1px solid ${gray[500]}`,
      flexShrink: '0'
    },
    imageUploaderContainer: {
      position: 'relative',
      maxHeight: '454px'
    },
    imageUploadLoader: {
      position: 'relative',
      height: '454px',
      top: '-400px',
      background: gray[800]
    },
    errMsg: {
      color: '#FF6060',
      fontSize: '12px'
    }
  });

  const tabsTextStyles = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  };

  const activeComp = activeTab === 'about'
    ? <About description={userProfileDescription}/>
    : <Contact
        organization={`${props.userData.lastName}, ${props.userData.firstName}`}
        hideName
        mailingAddress={{
          street: userStreetAddress,
          suite: userSuite,
          city: userCity,
          state: userState,
          zip: userZip
        }}
        email={userEmail}
        mobile={userMobile}
        phone={userPhone}
        fax={userFax}
        profileDescription={userProfileDescription}
        website={userWebsite}
      />

  // #region Edit Profile Form (Edit Handlers) & Save Profile
  const handleOnEditDisplayName = (e) => {
    const { value } = e.target;
    setDisplayNamePristineState(false);
    checkDisplayNameValidity(value.length >= 2 && value.length <= 40);
    setDisplayName(value);
  };

  const handleOnEditUserName = (e) => {
    const { value } = e.target;
    setUserNamePristineState(false);
    checkUserNameValidity(value.length >= 2 && value.length <= 20 && value.indexOf(' ') < 0);
    setUserName(value);
  };

  const handleOnEditStreetAddress = (e) => {
    const { value } = e.target;
    setStreetAddress(value);
  };

  const handleOnEditSuite = (e) => {
    const { value } = e.target;
    setSuite(value);
  };

  const handleOnEditCity = (e) => {
    const { value } = e.target;
    setCity(value);
  };

  const handleOnEditState = (e) => {
    const { value } = e.target;
    setState(value);
  };

  const handleOnEditZip = (e) => {
    const { value } = e.target;
    setZip(value);
  };

  const handleOnEditPhone = (e) => {
    const { value } = e.target;
    setPhonePristineState(false);
    checkPhoneValidity(isMobilePhone(value, 'en-US'));
    setPhone(value);
  };

  const handleOnEditFax = (e) => {
    const { value } = e.target;
    setFax(value);
  };

  const handleOnEditWebsite = (e) => {
    const { value } = e.target;
    setWebsite(value);
  };

  const handleOnChangeProfileDescription = (e) => {
    const { value } = e.target;
    setProfileDescription(value);
  };
  // #endregion

  // #region Banner image crop and file upload
  const uploadBanner = () => {
    const formData = new FormData();
    const img = bannerFileInputRef.current.files[0];
    formData.append('file', img);
    const options = { method: 'POST', body: formData, redirect: 'follow' };

    fetch(`${BASE_URL}/users/uploadImg`, options)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        console.log(res);
        if (res.success) {
          setBannerImageUrl(res.fileUrl);
          setIsBannerUploading(false);
          setShowBannerCropper(true);
        }
      })
      .catch(error => console.log('error', error));
  };

  const handleOnBannerFileDrop = (img) => {
    const formData = new FormData();
    formData.append('file', img);
    const options = { method: 'POST', body: formData, redirect: 'follow' };
    setIsBannerUploading(true);
    fetch(`${BASE_URL}/users/uploadImg`, options)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        console.log(res);
        if (res.success) {
          setBannerImageUrl(res.fileUrl);
          setIsBannerUploading(false);
          setShowBannerCropper(true);
        }
      })
      .catch(error => console.log('error', error));
  };

  const handleOnBannerFileSelected = () => {
    if (bannerFileInputRef.current.files.length > 0) {
      console.log('Selected file = ', bannerFileInputRef.current.files[0]);
      console.log('typeof img', typeof bannerFileInputRef.current.files[0]);
      setIsBannerUploading(true);
      uploadBanner();
    } else {
      console.log('No file is selected!');
    }
  };

  const uploadCroppedBanner = (blob) => {
    const headers = new Headers();
    const token = localStorage.getItem('token');
    headers.append('Authorization', `Bearer ${token}`);

    const formData = new FormData();
    formData.append('file', blob);
    formData.append('userId', props.userData._id);
    const requestOptions = { method: 'POST', headers, body: formData, redirect: 'follow' };

    fetch(`${BASE_URL}/users/updateUserBanner`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        // console.log(res);
        if (res.success) {
          console.log('Cropped banner image url', res.fileUrl);
          props.reloadUserData(res.usr);
        }
      })
      .catch(error => console.log('error', error));
  };

  const closeBannerCropperDrawer = () => {
    setShowBannerCropper(false);
    setIsBannerUploading(false);
    toggleBannerCropperDrawer(false);
  };

  const handleOnClickBannerCrop = () => {
    const imageElement = bannerCropperRef.current;
    const { cropper } = imageElement;
    cropper.getCroppedCanvas().toBlob((blob) => {
      const url = URL.createObjectURL(blob);
      drawerBannerRef.current.src = url;
      uploadCroppedBanner(blob);
      closeBannerCropperDrawer();
    });
  };
  // #endregion

  // #region Avatar image crop and file upload

  const uploadAvatar = () => {
    const formData = new FormData();
    const img = bannerFileInputRef.current.files[0];
    formData.append('file', img);
    const options = { method: 'POST', body: formData, redirect: 'follow' };

    fetch(`${BASE_URL}/users/uploadImg`, options)
      .then(response => {
        console.log('Uploading avatar file for cropper');
        console.log(response);
        return response.text();
      })
      .then(result => {
        const res = JSON.parse(result);
        console.log('Avatar file for cropper uploaded!');
        console.log(res);
        if (res.success) {
          setBannerImageUrl(res.fileUrl);
          setIsBannerUploading(false);
          setShowBannerCropper(true);
        }
      })
      .catch(error => console.log('error', error));
  };

  const handleOnAvatarFileDrop = (img) => {
    const formData = new FormData();
    formData.append('file', img);
    const options = { method: 'POST', body: formData, redirect: 'follow' };
    setIsAvatarUploading(true);
    fetch(`${BASE_URL}/users/uploadImg`, options)
      .then(response => {
        console.log('Uploading avatar file for cropper');
        console.log(response);
        return response.text();
      })
      .then(result => {
        const res = JSON.parse(result);
        console.log('Avatar file for cropper uploaded!');
        console.log(res);
        if (res.success) {
          setAvatarImageUrl(res.fileUrl);
          setIsAvatarUploading(false);
          setShowAvatarCropper(true);
        }
      })
      .catch(error => console.log('error', error));
  };

  const handleOnAvatarFileSelected = () => {
    if (avatarFileInputRef.current.files.length > 0) {
      console.log('Selected file = ', avatarFileInputRef.current.files[0]);
      console.log('typeof img', typeof avatarFileInputRef.current.files[0]);
      setIsAvatarUploading(true);
      uploadAvatar();
    } else {
      console.log('No file is selected!');
    }
  };

  const uploadCroppedAvatar = (blob) => {
    const headers = new Headers();
    const token = localStorage.getItem('token');
    headers.append('Authorization', `Bearer ${token}`);

    const formData = new FormData();
    formData.append('file', blob);
    formData.append('userId', props.userData._id);
    const requestOptions = { method: 'POST', headers, body: formData, redirect: 'follow' };

    fetch(`${BASE_URL}/users/updateUserAvatar`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        // console.log(res);
        if (res.success) {
          console.log('Cropped avatar image url', res.fileUrl);
          props.reloadUserData(res.usr);
        }
      })
      .catch(error => console.log('error', error));
  };

  const closeAvatarCropperDrawer = () => {
    setShowAvatarCropper(false);
    setIsAvatarUploading(false);
    toggleAvatarCropperDrawer(false);
  };

  const handleOnClickAvatarCrop = () => {
    const imageElement = avatarCropperRef.current;
    const { cropper } = imageElement;
    cropper.getCroppedCanvas().toBlob((blob) => {
      const url = URL.createObjectURL(blob);
      drawerAvatarRef.current.src = url;
      uploadCroppedAvatar(blob);
      closeAvatarCropperDrawer();
    });
  };
  // #endregion

  // Sync current state to previous props, so that any unsaved changes are discarded
  const syncStateToPreviousProps = () => {
    setDisplayName(userDisplayName);
    setUserName(userUserName);
    setStreetAddress(userStreetAddress);
    setSuite(userSuite);
    setCity(userCity);
    setState(userState);
    setZip(userZip);
    setPhone(userPhone);
    setFax(userFax);
    setWebsite(userWebsite);
    setProfileDescription(userProfileDescription);
    setBannerImageUrl(props.userData.bannerImageUrl);
    setAvatarImageUrl(props.userData.avatarImageUrl);
  };

  // Toggle 'Edit Profile' drawer
  const handleOnClickEditProfile = () => {
    syncStateToPreviousProps();
    toggleEditProfileDrawer(true);
  };

  // Save practice profile changes
  const handleOnClickSavePracticeProfile = () => {
    if (!canSaveUserProfile) {
      return;
    }

    const headers = new Headers();
    const token = localStorage.getItem('token');
    headers.append('Authorization', `Bearer ${token}`);

    const formData = new FormData();
    formData.append('userId', props.userData._id);
    formData.append('displayName', displayName);
    formData.append('userName', userName);
    formData.append('streetAddress', streetAddress);
    formData.append('suite', suite);
    formData.append('city', city);
    formData.append('state', state);
    formData.append('zip', zip);
    formData.append('phone', phone);
    formData.append('fax', fax);
    formData.append('profileDescription', profileDescription);
    formData.append('website', website);

    const requestOptions = { method: 'POST', headers, body: formData, redirect: 'follow' };

    fetch(`${BASE_URL}/users/updateUserProfile`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        // console.log(res);
        if (res.success) {
          console.log('User profile updated!');
          props.reloadUserData(res.usr);
          closeEditProfileDrawer();
        }
      })
      .catch(error => console.log('error', error));
  };

  const resetState = () => {
    toggleBannerCropperDrawer(false);
    toggleAvatarCropperDrawer(false);
    toggleEditProfileDrawer(false);
    setDisplayName(userDisplayName);
    checkDisplayNameValidity(false);
    setDisplayNamePristineState(true);
    setUserName(userUserName);
    checkUserNameValidity(false);
    setUserNamePristineState(true);
    checkUserNameDuplicateStatus(false);
    setStreetAddress(userStreetAddress);
    setSuite(userSuite);
    setCity(userCity);
    setState(userState);
    setZip(userZip);
    setPhone(userPhone);
    checkPhoneValidity(false);
    setPhonePristineState(true);
    setFax(userFax);
    setWebsite(userWebsite);
    setProfileDescription(userProfileDescription);
    setBannerImageUrl('https://quantumtrader.s3.amazonaws.com/dwMKF8A8HUKA1_7_5Xns8.png');
    setAvatarImageUrl('');
    setActiveTab('about');
    setShowBannerCropper(false);
    setIsBannerUploading(false);
    setShowAvatarCropper(false);
    setIsAvatarUploading(false);
    setCanSaveUserProfile(false);
  };

  const closeEditProfileDrawer = () => {
    toggleEditProfileDrawer(false);
    resetState();
  };

  console.log('props.userData', props.userData);

  return (
    <div className='p-2'>
      {/* Edit profile drawer */}
      {isEditProfileDrawerVisible
        ? <Drawer
            placement="right"
            visible={isEditProfileDrawerVisible}
            toggleDrawer={closeEditProfileDrawer}
            zIndex="2000"
            width={window.innerWidth <= 700 ? window.innerWidth : 358}
          >
            {/* Drawer Container */}
            <div className={css(styles.drawerContainer)}>
              {/* Header */}
              <div className={css(styles.drawerHeader)}>
                <div className='fx fx__hv-center'>
                  <i className='mr-2 fa fa-lg fa-user' />
                  <div>Edit User Profile</div>
                </div>
                <i
                  className={`fa fa-lg fa-times-circle ${css(styles.drawerHeaderCloseIcon)}`}
                  onClick={closeEditProfileDrawer}
                />
              </div>
              {/* Banner */}
              <div className={css(styles.drawerBanner)}>
                <img
                  ref={drawerBannerRef}
                  src={bannerImageUrl}
                  alt="cropped-banner"
                  className={css(styles.drawerBannerImg)}
                />
                {/* Camera icon */}
                <div
                  onClick={() => { toggleBannerCropperDrawer(true); }}
                  className={css(styles.drawerBannerCameraIcon)}
                >
                  <i className="fa fa-camera" />
                </div>
              </div>

              {/* Middle area */}
              <div className={css(styles.drawerContentArea)}>
                {/* Name and Npi */}
                <div
                  className='pl-2 mb-2'
                  style={{
                    fontSize: '14px',
                    color: gray[200],
                    lineHeight: '14px',
                    fontWeight: '700'
                  }}
                >
                  <div>
                    {`${(userLastName !== '' && userFirstName !== '') ? `${titleCase(userFirstName)} ${titleCase(userLastName)}` : ''}${credentials !== '' ? `, ${credentials}` : ''}`}
                  </div>
                  {(userNpi && userNpi !== '')
                    ? <div>{`NPI: ${userNpi}`}</div>
                    : null
                  }
                </div>
                {/* User card */}
                <div className="fx ml-2">
                  {/* Avatar */}
                  <div className={css(styles.drawerUserCardAvatar)}>
                    <img
                      src={userAvatarImageUrl}
                      ref={drawerAvatarRef}
                      alt="avatar"
                      className={css(styles.drawerUserCardAvatarImg)}
                      onClick={() => toggleAvatarCropperDrawer(true)}
                    />
                    <div
                      onClick={() => { toggleAvatarCropperDrawer(true); }}
                      className={css(styles.drawerAvatarCamera)}
                    >
                      <i className="fa fa-camera" />
                    </div>
                  </div>
                  {/* User card displayName & userName */}
                  <div className={`ml-2 ${css(styles.drawerUserCardText)}`}>
                    {/* Display Name */}
                    <div>
                      <TextInputWithLabel
                        value={displayName}
                        titleText="Display Name (2 to 40 characters)*"
                        titleFontSize={12}
                        isCompactLayout={true}
                        titleColor={gray[200]}
                        textColor="white"
                        selectionColor="white"
                        selectionBackground="#0080FF"
                        spellcheck={false}
                        width={248}
                        onChange={handleOnEditDisplayName}
                      />
                      {/* Error message */}
                      {!isDisplayNameValid && !isDisplayNamePristine
                        ? <div className={css(styles.errMsg)}>
                            Invalid Display Name
                          </div>
                        : null
                      }
                    </div>
                    {/* UserName */}
                    <div
                      style={{
                        marginTop: (!isDisplayNameValid && !isDisplayNamePristine) ? '0px' : '4px'
                      }}
                    >
                      <TextInputWithLabel
                        value={userName}
                        titleText="Username (2 to 20 characters, no spaces) *"
                        titleFontSize={12}
                        isCompactLayout={true}
                        titleColor={gray[200]}
                        textColor={blue[500]}
                        selectionColor="white"
                        selectionBackground="#0080FF"
                        spellcheck={false}
                        width={248}
                        onChange={handleOnEditUserName}
                      />
                      {/* Error message */}
                      {!isUserNameValid && !isUserNamePristine
                        ? <div className={css(styles.errMsg)}>
                            Invalid User Name
                          </div>
                        : null
                      }
                      {!isUserNamePristine && isUserNameDuplicate
                        ? <div className={css(styles.errMsg)}>
                            User Name Taken
                          </div>
                        : null
                      }
                    </div>
                  </div>
                </div>
                {/* Mailing Address */}
                <div className="mt-4 ml-2">
                  {/* Heading */}
                  <div className="fx fx__v-center">
                    <i className="fa fa-map-marker" style={{ fontSize: '28px' }} />
                    <div className="ml-2">
                      Mailing Address
                    </div>
                  </div>
                  {/* Form */}
                  <div>
                    {/* Address */}
                    <div className="fx">
                      <div>
                        <TextInputWithLabel
                          value={streetAddress}
                          titleText="Street"
                          titleFontSize={12}
                          isCompactLayout={true}
                          titleColor={gray[200]}
                          textColor="white"
                          selectionColor="white"
                          selectionBackground="#0080FF"
                          spellcheck={false}
                          width={284}
                          onChange={handleOnEditStreetAddress}
                        />
                      </div>
                      <div className="ml-2">
                        <TextInputWithLabel
                          value={suite}
                          titleText="Suite"
                          titleFontSize={12}
                          isCompactLayout={true}
                          titleColor={gray[200]}
                          textColor="white"
                          selectionColor="white"
                          selectionBackground="#0080FF"
                          spellcheck={false}
                          width={45}
                          onChange={handleOnEditSuite}
                        />
                      </div>
                    </div>
                    {/* City, State and Zip */}
                    <div className="fx mt-2">
                      <div>
                        <TextInputWithLabel
                          value={city}
                          titleText="City"
                          titleFontSize={12}
                          isCompactLayout={true}
                          titleColor={gray[200]}
                          textColor="white"
                          selectionColor="white"
                          selectionBackground="#0080FF"
                          spellcheck={false}
                          width={230}
                          onChange={handleOnEditCity}
                        />
                      </div>
                      <div className="ml-2">
                        <TextInputWithLabel
                          value={state}
                          titleText="State"
                          titleFontSize={12}
                          isCompactLayout={true}
                          titleColor={gray[200]}
                          textColor="white"
                          selectionColor="white"
                          selectionBackground="#0080FF"
                          spellcheck={false}
                          width={45}
                          onChange={handleOnEditState}
                        />
                      </div>
                      <div className="ml-2">
                        <TextInputWithLabel
                          value={zip}
                          titleText="Zip"
                          titleFontSize={12}
                          isCompactLayout={true}
                          titleColor={gray[200]}
                          textColor="white"
                          selectionColor="white"
                          selectionBackground="#0080FF"
                          spellcheck={false}
                          width={45}
                          onChange={handleOnEditZip}
                        />
                      </div>
                    </div>
                    {/* Email - message */}
                    <div
                      className='mt-4 fx fx__v-center'
                      style={{ color: gray[200] }}
                    >
                      <div style={{ width: '30px' }}>
                        <i className='fa fa-lg fa-envelope' />
                      </div>
                      <div style={{ fontSize: '12px' }}>
                        <div>
                          Email&nbsp;
                          <span>
                            (Go to <span style={{ color: blue[500] }}>Login Credentials</span> to edit)
                          </span>
                        </div>
                        <div>{userEmail}</div>
                      </div>
                    </div>
                    {/* Mobile - message */}
                    <div
                      className='mt-2 fx fx__v-center'
                      style={{ color: gray[200] }}
                    >
                      <div style={{ width: '30px' }}>
                        <i
                          className='fa fa-lg fa-mobile'
                          style={{ fontSize: '40px' }}
                        />
                      </div>
                      <div style={{ fontSize: '12px' }}>
                        <div>
                          Mobile&nbsp;
                          <span>
                            (Go to <span style={{ color: blue[500] }}>Login Credentials</span> to edit)
                          </span>
                        </div>
                        <div>{userMobile}</div>
                      </div>
                    </div>
                    {/* Phone */}
                    <div className="mt-3">
                      <TextInputWithLabel
                        value={phone}
                        titleText="Phone # *"
                        titleFontSize={12}
                        isCompactLayout={false}
                        titleColor={gray[200]}
                        textColor="white"
                        selectionColor="white"
                        selectionBackground="#0080FF"
                        spellcheck={false}
                        width={337}
                        showPreIcon
                        preIcon='fa fa-phone'
                        onChange={handleOnEditPhone}
                      />
                      {!isPhonePristine && !isPhoneValid
                        ? <div className={css(styles.errMsg)}>
                            Invalid Phone #
                          </div>
                        : null
                      }
                    </div>
                    {/* Fax */}
                    <div className="mt-3">
                      <TextInputWithLabel
                        value={fax}
                        titleText="Fax #"
                        titleFontSize={12}
                        isCompactLayout={false}
                        titleColor={gray[200]}
                        textColor="white"
                        selectionColor="white"
                        selectionBackground="#0080FF"
                        spellcheck={false}
                        width={337}
                        showPreIcon
                        preIcon='fa fa-fax'
                        onChange={handleOnEditFax}
                      />
                    </div>
                    { /* Website */}
                    <div className="mt-3">
                      <TextInputWithLabel
                        value={website}
                        titleText="Website"
                        titleFontSize={12}
                        isCompactLayout={false}
                        titleColor={gray[200]}
                        textColor="white"
                        selectionColor="white"
                        selectionBackground="#0080FF"
                        spellcheck={false}
                        width={337}
                        showPreIcon
                        preIcon='fa fa-globe'
                        onChange={handleOnEditWebsite}
                      />
                    </div>
                  </div>
                </div>
                {/* Profile descriptiom */}
                <div className="mt-4 ml-2">
                  <div>Profile Description</div>
                  <div className={css(styles.profileTextAreaContainer)}>
                    <textarea
                      className={css(styles.profileTextArea)}
                      placeholder="Write a description"
                      value={profileDescription}
                      onChange={handleOnChangeProfileDescription}
                    />
                  </div>
                </div>
              </div>
              {/* Footer */}
              <div className={`fx pr-2 ${css(styles.drawerFooter)}`} >
                {/* Required fields msg */}
                <div className={css(styles.criticalSmallText)} >
                  *Required fields
                </div>
                <Button
                  btnType="primary"
                  isDisabled={!canSaveUserProfile}
                  className="fx fx__hv-center"
                  onClick={handleOnClickSavePracticeProfile}
                  style={{
                    height: '32px',
                    width: '340px',
                    padding: '3px 0'
                  }}
                >
                  Save User Profile
                </Button>
              </div>
            </div>
          </Drawer>
        : null
      }
      {/* Profile banner image cropper */}
      <Drawer
        placement="right"
        visible={isBannerCropperDrawerOpen}
        toggleDrawer={closeBannerCropperDrawer}
        zIndex="2050"
        width={window.innerWidth <= 700 ? window.innerWidth : 358}
      >
        <div className={css(styles.drawerContainer)}>
          {/* Header */}
          <div className={css(styles.drawerHeader)}>
            <div>Upload Profile Banner</div>
            <i
              className={`fa fa-times-circle ${css(styles.drawerHeaderCloseIcon)}`}
              onClick={() => { toggleBannerCropperDrawer(false); }}
            />
          </div>
          {/* Content Area */}
          <div className={css(styles.drawerContentArea, styles.imageUploaderContainer)}>
            {showBannerCropper
              ? <div>
                <Cropper
                  src={bannerImageUrl}
                  style={{ height: 400, width: '100%' }}
                  checkCrossOrigin
                  checkOrientation={false}
                  aspectRatio={6}
                  background={false}
                  viewMode={1}
                  autoCropArea={1}
                  guides={false}
                  ref={bannerCropperRef}
                />
              </div>
              : null
            }
            {!showBannerCropper
              ? <div>
                  <FileUploader
                    parentRef={bannerFileInputRef}
                    onDrop={handleOnBannerFileDrop}
                    onChange={handleOnBannerFileSelected}
                  />
                </div>
              : null
            }
            {isBannerUploading
              ? <div className={css(styles.imageUploadLoader)}>
                  <Loader iconType="cog" iconSize="5x" text="Uploading Banner Image" />
                </div>
              : null
            }
          </div>
          {/* Drawer footer area */}
          <div
            className={`fx pr-2 ${css(styles.drawerFooter)}`}
            style={{ position: 'absolute', bottom: '0' }}
          >
            <Button
              btnType="primary"
              className="fx fx__hv-center"
              onClick={handleOnClickBannerCrop}
              style={{
                height: '32px',
                width: '340px',
                padding: '3px 0'
              }}
            >
              Crop Banner Image
            </Button>
          </div>
        </div>
      </Drawer>

      {/* Profile avatar image cropper */}
      <Drawer
        placement="right"
        visible={isAvatarCropperDrawerOpen}
        toggleDrawer={() => toggleAvatarCropperDrawer(false)}
        zIndex="2050"
        width={window.innerWidth <= 700 ? window.innerWidth : 358}
      >
        <div className={css(styles.drawerContainer)}>
          {/* Header */}
          <div className={css(styles.drawerHeader)}>
            <div>Upload Profile Avatar</div>
            <i
              className={`fa fa-times-circle ${css(styles.drawerHeaderCloseIcon)}`}
              onClick={closeAvatarCropperDrawer}
            />
          </div>
          {/* Content Area */}
          <div className={css(styles.drawerContentArea, styles.imageUploaderContainer)}>
            {showAvatarCropper
              ? <div>
                <Cropper
                  src={avatarImageUrl}
                  style={{ height: 400, width: '100%' }}
                  checkCrossOrigin
                  checkOrientation={false}
                  aspectRatio={1}
                  background={false}
                  viewMode={1}
                  autoCropArea={1}
                  guides={false}
                  ref={avatarCropperRef}
                />
              </div>
              : null
            }
            {!showAvatarCropper
              ? <div>
                  <FileUploader
                    parentRef={avatarFileInputRef}
                    onDrop={handleOnAvatarFileDrop}
                    onChange={handleOnAvatarFileSelected}
                  />
                </div>
              : null
            }
            {isAvatarUploading
              ? <div className={css(styles.imageUploadLoader)}>
                  <Loader iconType="cog" iconSize="5x" text="Uploading Avatar Image" />
                </div>
              : null
            }
          </div>
          {/* Drawer footer area */}
          <div
            className={`fx pr-2 ${css(styles.drawerFooter)}`}
            style={{ position: 'absolute', bottom: '0' }}
          >
            <Button
              btnType="primary"
              className="fx fx__hv-center"
              onClick={handleOnClickAvatarCrop}
              style={{
                height: '32px',
                width: '340px',
                padding: '3px 0'
              }}
            >
              Crop Avatar Image
            </Button>
          </div>
        </div>
      </Drawer>

      {/* Detail panel header */}
      <div className={css(styles.profileBanner)}>
        <img
          className={css(styles.profileBannerImg)}
          src={userBannerImageUrl}
          alt="cropped-banner"
        />
      </div>
      {/* User card container */}
      <div className={`p-2 ${css(styles.bigUserCardContainer)}`}>
        {/* User card */}
        <div className="fx">
          <div className={css(styles.userCard)}>
            {!userAvatarImageUrl
              ? <i className={`fa fa-building-o ${css(styles.userCardIcon)}`} />
              : <img
                className={css(styles.userCardAvatarImg)}
                src={userAvatarImageUrl}
                alt='user avatar'
              />
            }
          </div>
          <div className="ml-2">
            <div>{userDisplayName}</div>
            <div className={css(styles.userCardDisplayName)}>
              {`@${userUserName}`}
            </div>
          </div>
        </div>
        {/* Ellipsis menu */}
        <div
          ref={popupRef}
          className={css(styles.userCardEllipsisMenuBtn)}
          onClick={() => togglePopupMenu(true)}
        >
          <i className="fa fa-ellipsis-v" />
          <PopupMenu
            top={28}
            right={0}
            visible={isPopupMenuOpen}
            parent={popupRef}
            position="absolute"
            onOutsideClick={() => { togglePopupMenu(false); }}
          >
            <div className={css(styles.popupMenuContainer)}>
              <div
                className={`m-item ${css(styles.popupMenuItem)}`}
                onClick={handleOnClickEditProfile}
              >
                <div className={css(styles.menuItemIconContainer)}>
                  <i className={`fa fa-pencil ${css(styles.menuItemIcon)}`} />
                </div>
                <div>Edit Profile</div>
              </div>
            </div>
          </PopupMenu>
        </div>
      </div>
      {/* Tabs */}
      <div className={css(styles.tabs)}>
        <TabsContainer
          dropDownId="d1"
          showPlusIcon={false}
          containerWidth={340}
          otherElementsWidth={0}
          minWidth={340}
          dropDownTop={39}
          dropDownLeft={44}
          dropDownHeaderStyles={{
            minWidth: '170px',
            maxWidth: '370px',
            width: '100%'
          }}
          containerStyles={{
            width: `calc(100% - ${drawerWidth}px)`
          }}
        >
          {/* About Tab */}
          <TabItem
            tabKey="1"
            key='9898'
            parentKey="0111"
            text="About"
            isActive={activeTab === 'about'}
            iconClass='fa fa-info-circle'
            style={{
              borderRight: `1px solid ${gray[700]}`,
              borderLeft: `1px solid ${gray[700]}`,
              paddingRight: '0',
              width: '90px',
              minWidth: '90px',
              maxWidth: '80px'
            }}
            textStyles={tabsTextStyles}
            onActivate={() => {
              if (activeTab !== 'about') {
                setActiveTab('about');
              }
            }}
          >
            <div>
              About
            </div>
          </TabItem>
          {/* Contact  */}
          <TabItem
            tabKey="2"
            key='9845'
            parentKey="0111"
            text="Contact"
            isActive={activeTab === 'contact'}
            iconClass='fa fa-info-circle'
            style={{
              borderRight: `1px solid ${gray[700]}`,
              borderLeft: `1px solid ${gray[700]}`,
              paddingRight: '0',
              width: '110px',
              minWidth: '110px',
              maxWidth: '110px'
            }}
            textStyles={tabsTextStyles}
            onActivate={() => {
              if (activeTab !== 'contact') {
                setActiveTab('contact');
              }
            }}
          >
            <div>
              Contact
            </div>
          </TabItem>
        </TabsContainer>
        {/* Active tab component */}
        <div>
          {activeComp}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps ({ user }) {
  return { ...user };
}

AboutUser.displayName = 'AboutUser';
AboutUser.propTypes = propTypes;
export default AboutUser;
