export const actionTypes = {
  toggleUserSigninDialog: 'TOGGLE-USER-SIGNIN-DIALOG',
  toggle2FaVerificationDialog: 'TOGGLE-2FA-VERIFY-DIALOG',
  initiateSignin: 'INITIATE-SIGNIN',
  finalizeSignIn: 'FINALIZE-SIGNIN',
  signinFailed: 'SIGNIN-FAILED',
  resend2Fa: 'RESEND-2FA',
  _2faVerificationFailed: '2FA-VERIFICATION-FAILED',
  isSignedIn: 'IS-SIGNED-IN',
  logout: 'LOGOUT',
  updateBanner: 'UPDATE-BANNER',
  updateAvatar: 'UPDATE-AVATAR',
  updateProfile: 'UPDATE-PROFILE',
  // Providers related actions
  addProvider: 'ADD-PROVIDER',
  getProviders: 'GET-PROVIDERS',
  addFacility: 'ADD-FACILITY',
  addPractice: 'ADD-PRACTICE',
  toggleResetPasswordDialog: 'TOGGLE-RESET-PASSWORD-DIALOG',
  sendTempPassword: 'SEND-TEMP-PASSWORD',
  resetPassword: 'RESET-PASSWORD',
  resendTempPassword: 'RESEND-TEMP-PASSWORD',
  // TODO: Delete initiateProviderSignIn action-type
  initiateProviderSignIn: 'INITIATE-PROVIDER-SIGNIN',
  finalizeProviderSignIn: 'FINALIZE-PROVIDER-SIGNIN',
  providerSigninFailed: 'PROVIDER-SIGNIN-FAILED',
  updateProviderProfile: 'UPDATE-PROVIDER-PROFILE',
  // Not used so far ...
  signinSuccessful: 'SIGNIN-SUCCESSFULL',
  updateProviderBanner: 'UPDATE-PROVIDER-BANNER',
  updateProviderAvatar: 'UPDATE-PROVIDER-AVATAR',
  setActiveRecord: 'SET-ACTIVE-RECORD',
  // Practice dashboard actions
  updatePracticeBanner: 'UPDATE-PRACTICE-BANNER',
  updatePracticeAvatar: 'UPDATE-PRACTICE-AVATAR',
  updatePracticeProfile: 'UPDATE-PRACTICE-PROFILE',
  // User dashboard actions
  updateUserBanner: 'UPDATE-USER-BANNER',
  updateUserAvatar: 'UPDATE-USER-AVATAR',
  updateUserProfile: 'UPDATE-USER-PROFILE',
  // Data reload switch
  togglePracticeDataReloadFlag: 'TOGGLE-PRACTICE-DATA-RELOAD-FLAG',
  reloadData: 'RELOAD-DATA',
  // App Alerts
  addAppAlert: 'ADD-APP-ALERT',
  hideAppAlert: 'HIDE-APP-ALERT',
  deleteAppAlert: 'DELETE-APP-ALERT',
  getNotifications: 'GET-NOTIFICATIONS',
  setNotificationsCount: 'SET-NOTIFICATIONS-COUNT',
  clearChatBadge: 'CLEAR-CHAT-BADGE'
};
