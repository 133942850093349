import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite/no-important';
import { BASE_URL, debugLogToConsole, getMonthName, getWeekDay } from '../../utils';
import ChatMessage from './chat-message';
import ChatEditor from './chat-editor';
import { blue, salmon, gray, Drawer } from 'quantux-ui';
import NavbarClone from '../navbar-clone';

const propTypes = {
  chatTitle: PropTypes.string,
  room: PropTypes.object,
  showNewMessgeIcon: PropTypes.bool,
  onPostMessage: PropTypes.func
};

const defaultProps = {
  showNewMessgeIcon: false,
  room: {}
};

const ChatRoom = (props) => {
  const [isFileOver, setIsFileOver] = useState(false);
  const [showNewMessageDrawer, toggleNewMessageDrawer] = useState(false);
  const [chatMessage, setChatMessage] = useState('');

  let stb;
  // Refs
  const lastMsgRef = React.createRef();
  const fileInputRef = React.createRef();
  const containerRef = React.createRef();

  const styles = StyleSheet.create({
    chatRoomContainer: {
      display: 'flex',
      flexFlow: 'column',
      // height: 'calc(100% - 16px)',
      background: gray[900],
      height: '100%',
      flexGrow: '1'
      // paddingBottom: '32px',
    },
    chatRoomMessages: {
      overflowY: 'auto',
      // flexGrow: '1',
      padding: '20px 8px 8px',
      // height: 'calc(100vh - 180px)'
      height: 'calc(100vh - 120px)'
      // paddingTop: '0'
      // height: 'calc(100% - 126px)'
      // height: '-webkit-fill-available'
    },
    chatRoomEditor: {
      // position: 'fixed',
      // bottom: '0',
      // left: '0',
      flexShrink: '0'
      // height: '126px'
    },
    floatingBar: {
      padding: '0 4px',
      position: 'fixed',
      width: '100vw',
      // top: '40px',
      bottom: '0px',
      right: '0px',
      // width: '40px',
      height: '40px',
      minHeight: '40px',
      background: gray[600],
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      // borderRadius: '5px',
      fontSize: '32px',
      zIndex: '1900',
      borderTop: `1px solid ${gray[500]}`
    },
    floatingBar2: {
      padding: '0 4px',
      position: 'fixed',
      width: '100vw',
      top: '0px',
      right: '0px',
      // width: '40px',
      height: '40px',
      minHeight: '40px',
      background: gray[800],
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: '5px',
      fontSize: '32px'
    },
    floatingBarIcon: {
      height: '32px',
      width: '32px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '22px'
      // background: blue[500],
      // color: 'white'
    }
    // bottomSpacer: {
    //   minHeight: '20px'
    // }
  });

  const handleOnSendMessage = (msg) => {
    console.log(`Sending chat message - ${msg}`);
    props.onPostMessage(msg);
  };

  const handleFileUpload = (fileUrl, originalFileName) => {
    if (fileUrl.endsWith('.png') || fileUrl.endsWith('.jpg') || fileUrl.endsWith('jpeg')) {
      props.onUploadFile(fileUrl, true, false, originalFileName);
    } else {
      props.onUploadFile(fileUrl, false, true, originalFileName);
    }
  };

  const handleDragEnter = () => { setIsFileOver(true); };

  const handleDragLeave = () => { setIsFileOver(false); };

  const handleDragOver = (e) => {
    e.preventDefault(e);
    setIsFileOver(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsFileOver(false);

    if (e.dataTransfer.items) {
      for (let i = 0; i < e.dataTransfer.items.length; i++) {
        if (e.dataTransfer.items[i].kind === 'file') {
          const img = e.dataTransfer.items[i].getAsFile();
          console.log('img', img);
          console.log('typeof img', typeof img);
          // this.props.onDrop(img);
          const formData = new FormData();
          formData.append('file', img);
          const options = { method: 'POST', body: formData, redirect: 'follow' };
          fetch(`${BASE_URL}/users/uploadImg`, options)
            .then(response => response.text())
            .then(result => {
              const res = JSON.parse(result);
              console.log(res);
              if (res.success) {
                debugLogToConsole(`File uploaded successfully ${res.fileUrl}`);
                handleFileUpload(res.fileUrl, res.originalFileName);
              }
            })
            .catch(error => console.log('error', error));
        }
      }
    }
  };

  const handleOnClickNewMessage = () => {
    toggleNewMessageDrawer(true);
  };

  const handleOnFileSelected = () => {};

  const handleOnChangeChatMessage = (e) => {
    const { value } = e.target;
    setChatMessage(value);
  };

  const messages = [];
  if (props.room && props.room.messages && props.room.messages.length > 0) {
    let insertDateDivider = true;
    let lastDate = '';
    let msgDate = '';
    props.room.messages.forEach((el, idx) => {
      msgDate = new Date(el.dateTime);
      const mDay = getWeekDay(msgDate.getDay());
      const mDate = msgDate.getDate();
      const mMonth = msgDate.getMonth();
      const mYear = msgDate.getFullYear();

      if (idx === 0) {
        lastDate = new Date(el.dateTime);
      }

      if (mDate !== lastDate.getDate() || mMonth !== lastDate.getMonth() || mYear !== lastDate.getFullYear()) {
        insertDateDivider = true;
      }

      if (idx === props.room.messages.length - 1) {
        if (insertDateDivider) {
          messages.push(
            <div
              style={{
                position: 'relative',
                textAlign: 'center',
                padding: '8px 0',
                top: '13px'
              }}
            >
              <div style={{ height: '0.5px', background: blue[500] }} />
              <div
                style={{
                  position: 'relative',
                  display: 'inline-block',
                  padding: '4px 8px',
                  borderRadius: '12px',
                  border: `1px solid ${blue[500]}`,
                  background: gray[900],
                  top: '-14px',
                  zIndex: '200',
                  fontSize: '12px',
                  fontWeight: 'bold',
                  color: blue[500]
                }}
              >
                {`${mDay}, ${getMonthName(mMonth)} ${mDate}, ${mYear}`}
              </div>
            </div>
          );
          insertDateDivider = false;
          lastDate = msgDate;
        }
        messages.push(
          <ChatMessage
            ref={lastMsgRef}
            key={el._id}
            msgId={el._id}
            message={el}
            senderUserName={el.senderUserName}
          />
        );

        // Auto scroll to bottom to make new messages into user view
        setTimeout(() => {
          if (containerRef && containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
          }
        }, 2000);
      } else {
        if (insertDateDivider) {
          messages.push(
            <div style={{ textAlign: 'center', height: '18px' }}>
              <div style={{ height: '0.5px', background: blue[500] }} />
              <div
                style={{
                  position: 'relative',
                  display: 'inline-block',
                  padding: '4px 8px',
                  borderRadius: '12px',
                  border: `1px solid ${blue[500]}`,
                  background: gray[900],
                  top: '-14px',
                  zIndex: '200',
                  fontSize: '12px',
                  fontWeight: 'bold',
                  color: blue[500]
                }}
              >
                {`${mDay}, ${getMonthName(mMonth)} ${mDate}, ${mYear}`}
              </div>
            </div>
          );
          insertDateDivider = false;
          lastDate = msgDate;
        }
        messages.push(
          <ChatMessage
            key={el._id}
            msgId={el._id}
            message={el}
            senderUserName={el.senderUserName}
          />
        );
      }
    });
  }

  return (
    <div
      className={`${css(styles.chatRoomContainer)} safari_only`}
      onDragEnter={handleDragEnter}
    >
      {isFileOver
        ? <div
            // onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              background: 'black',
              fontSize: '42px'
            }}
          >
            Drop file to upload
          </div>
        : null
      }

      {/* New message drawer */}
      {showNewMessageDrawer
        ? <ChatEditor
          title={props.chatTitle}
          onSendMessage={handleOnSendMessage}
          onFileUpload={handleFileUpload}
          showNewMessageDrawer={showNewMessageDrawer}
          toggleNewMessageDrawer={toggleNewMessageDrawer}
          />
        : null
      }

      {/* Chat room messages */}
      <div
        className={css(styles.chatRoomMessages)}
        ref={containerRef}
      >
        {messages}
        {/* <div className={css(styles.bottomSpacer)} /> */}
      </div>
      {/* Floating navbar */}
      {props.showNewMessgeIcon
        ? <div className={css(styles.floatingBar2)}>
            <NavbarClone />
          </div>
        : null
      }

      {/* New message icon */}
      {props.showNewMessgeIcon
        ? <div
            className={css(styles.floatingBar)}
          >
            <div
              className={css(styles.floatingBarIcon)}
              onClick={props.onCloseChat}
            >
              <i className='fa fa-times-circle' />
            </div>
            <div
              className={css(styles.floatingBarIcon)}
              onClick={handleOnClickNewMessage}
            >
              <i className='fa fa-comment-o' />
            </div>
          </div>
        : null
      }

      {props.showNewMessgeIcon
        ? <div
            onClick={() => {
              // lastMsgRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
              containerRef.current.scrollTop = containerRef.current.scrollHeight;
            }}
            style={{
              padding: '0 4px',
              position: 'fixed',
              bottom: '40px',
              right: '4px',
              width: '40px',
              height: '40px',
              background: gray[800],
              color: 'white',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '5px',
              fontSize: '32px'
            }}
          >
            <i className='fa fa-angle-double-down' />
          </div>
        : null
      }
    </div>
  );
}

ChatRoom.propTypes = propTypes;
ChatRoom.defaultProps = defaultProps;
export default ChatRoom;
