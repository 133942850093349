import React from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Button, Heading } from 'quantux-ui';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    paddingRight: '24px'
  }
});

const ButtonsDemo = () => {
  return (
    <div className={css(styles.container)}>
      {/* -------------- Regular Buttons -------------- */}
      <Heading level={3}>Regular buttons</Heading>
      <hr />
      {/* Primary Button */}
      <div>
        <Button btnType='primary'>
          Primary Button
        </Button>
      </div>
      {/* Sucess Button */}
      <div className='mt-2'>
        <Button btnType='success'>
          Success Button
        </Button>
      </div>
      {/* Warning Button */}
      <div className='mt-2'>
        <Button btnType='warning'>
          Warning Button
        </Button>
      </div>
      {/* Danger Button */}
      <div className='mt-2'>
        <Button btnType='danger'>
          Danger Button
        </Button>
      </div>
      {/* Error Button */}
      <div className='mt-2'>
        <Button btnType='error'>
          Error Button
        </Button>
      </div>
      {/* -------------- Outline Buttons -------------- */}
      <Heading level={3} className="mt-4">
        Outline buttons
      </Heading>
      <hr />
      {/* Primary Outline Button */}
      <div className="mt-2">
        <Button btnType='primary' isOutlineBtn>
            Primary Outline Button
        </Button>
      </div>
      {/* Success Outline Button */}
      <div className="mt-2">
        <Button btnType='success' isOutlineBtn>
            Sucess Outline Button
        </Button>
      </div>
      {/* Warning Outline Button */}
      <div className="mt-2">
        <Button btnType='warning' isOutlineBtn>
            Warning Outline Button
        </Button>
      </div>
      {/* Danger Outline Button */}
      <div className="mt-2">
        <Button btnType='danger' isOutlineBtn>
            Danger Outline Button
        </Button>
      </div>
      {/* Error Outline Button */}
      <div className="mt-2">
        <Button btnType='error' isOutlineBtn>
          Error Outline Button
        </Button>
      </div>
      {/* -------------- Text Buttons -------------- */}
      <Heading level={3} className="mt-4">
        Text buttons
      </Heading>
      <hr />
      <div>
        <Button btnType='primary' isTextBtn>Primary</Button>
        <Button btnType='success' isTextBtn>Success</Button>
        <Button btnType='warning' isTextBtn>Warning</Button>
        <Button btnType='danger' isTextBtn>Danger</Button>
        <Button btnType='error' isTextBtn>Error</Button>
      </div>
    </div>
  );
};

export default ButtonsDemo;
