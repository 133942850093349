import React, { useState, useEffect, useRef } from 'react';
import isEmail from 'validator/lib/isEmail';
import { css, StyleSheet } from 'aphrodite/no-important';
import {
  DialogBox,
  TextInputWithLabel,
  Button,
  gray,
  blue,
  salmon
} from 'quantux-ui';
import { BASE_URL } from '../../utils';

const styles = StyleSheet.create({
  stepsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  stepHeading: {
    color: gray[400]
  },
  activeStepHeading: {
    color: blue[500]
  },
  errMsg: {
    color: salmon[500],
    minHeight: '40px',
    maxHeight: '40px',
    height: '40px'
  },
  txtBtn: {
    color: blue[500],
    cursor: 'pointer'
  },
  disabledTxtBtn: {
    cursor: 'not-allowed',
    color: blue[700]
  },
  statusMsg: {
    color: blue[500],
    textAlign: 'center'
  }
});

const ChangePasswordDialog = (props) => {
  const isFirstRender = useRef(true);
  const [email, setEmail] = useState('');
  const [isEmailPristine, setEmailPristineState] = useState(true);
  const [isEmailValid, setEmailValidState] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerificationCodePristine, setVerificationCodePristineState] = useState(true);
  const [isVerificationCodeValid, setVerificationCodeValidState] = useState(true);
  const [newPassword, setNewPassword] = useState('');
  const [isNewPasswordPristine, setNewPasswordPristineState] = useState(true);
  const [isNewPasswordValid, setNewPasswordValidState] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isConfirmNewPasswordPristine, setConfirmNewPasswordPristineState] = useState(true);
  const [isConfirmNewPasswordValid, setConfirmNewPasswordValidState] = useState(false);
  const [showStatusMsg, setShowStatusMsg] = useState(false);
  const [statusMsg, setStatusMsg] = useState('Status message!');
  const [showLoader, setShowLoader] = useState(false);
  const [canUpdatePassword, setCanUpdatePassword] = useState(false);

  // Check for duplicate email
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (isEmail(email) && !isConfirmNewPasswordPristine) {
      setConfirmNewPasswordValidState(newPassword === confirmNewPassword);
    }
    setCanUpdatePassword(
      verificationCode.length > 0 &&
      !isNewPasswordPristine &&
      isNewPasswordValid &&
      !isConfirmNewPasswordPristine &&
      isConfirmNewPasswordValid &&
      newPassword === confirmNewPassword
    );
  }, [newPassword, confirmNewPassword]);

  const handleOnChangeEmail = (e) => {
    const { value } = e.target;
    setEmailPristineState(false);
    setEmailValidState(isEmail(value));
    setEmail(value);
  };

  const handleOnChangeVerificationCode = (e) => {
    const { value } = e.target;
    setVerificationCodePristineState(false);
    setVerificationCode(value);
  };

  const handleOnClickSendVerificationCode = () => {
    setShowStatusMsg(true);
    setShowLoader(true);
    setStatusMsg('Sending Verification Code To Email');

    const url = new URL(`${BASE_URL}/users/sendPasswordVerificationCode`);
    const params = { email: props.usr.email };
    url.search = new URLSearchParams(params).toString();
    fetch(url)
      .then(res => res.text())
      .then((result) => {
        const data = JSON.parse(result);
        setStatusMsg('Password verification code sent!');
        setShowLoader(false);
        setTimeout(() => {
          setShowStatusMsg(false);
          setShowLoader(false);
        }, 3000)
      })
      .catch(error => console.log('error', error))
  };

  const handleOnChangeNewPassword = (e) => {
    const { value } = e.target;
    setNewPasswordPristineState(false);
    setNewPasswordValidState(value.length >= 8 && value.indexOf(' ') < 0);
    setNewPassword(value);
  }

  const handleOnChangeConfirmNewPassword = (e) => {
    const { value } = e.target;
    setConfirmNewPasswordPristineState(false);
    setConfirmNewPasswordValidState(value.length >= 8 && value.indexOf(' ') < 0);
    setConfirmNewPassword(value);
  }

  const handleOnClickUpdatePassword = () => {
    if (canUpdatePassword) {
      setShowLoader(true);
      setStatusMsg('Updating password!');
      const formData = new FormData();
      formData.append('email', props.usr.email);
      formData.append('newPassword', newPassword);
      formData.append('code', verificationCode);
      formData.append('confirmNewPassword', confirmNewPassword);
      const options = { method: 'POST', body: formData, redirect: 'follow' };
      fetch(`${BASE_URL}/users/verifyPasswordVerificationCode`, options)
        .then(response => response.text())
        .then(result => {
          const res = JSON.parse(result);
          if (res.success) {
            console.log('Password successfully updated');
            setShowLoader(false);
            setShowStatusMsg(false);
            props.onCloseDialogBox(false);
            props.processLogout();
          } else {
            setShowLoader(false);
            setShowStatusMsg(false);
            setVerificationCodeValidState(false);
          }
        })
        .catch(error => console.log('error', error));
    }
  };

  return (
    <DialogBox
      initWidth={window.innerWidth <= 576 ? (window.innerWidth - 16) : 350}
      top={window.innerWidth <= 576 ? 48 : null}
      left={window.innerWidth <= 576 ? 8 : null}
      initHeight={378}
      isOpen={props.isVisible}
      onClose={() => { props.onCloseDialogBox(false); }}
      maskBackground="#004080"
      maskOpacity={0.5}
      closeOnEscKey
      closeOnMaskClick
      header={{
        text: ' Change Password',
        backColor: '#082840',
        foreColor: '#5080B0',
        closeColor: '#FF6060',
        fontStyle: 'bold',
        fontSize: '20px',
        icon: 'fa fa-envelope'
      }}
    >
      <div className="p-2">
        <div>
          Contact your administrator if they created your account for you and you dont know what email they used.
        </div>
        {/* Email */}
        <div style={{ marginTop: '10px' }}>
          <TextInputWithLabel
            value={email}
            titleText="Enter Email"
            isCompactLayout={false}
            titleColor="#5079ab"
            width={328}
            showPreIcon
            preIcon="fa fa-envelope"
            onChange={handleOnChangeEmail}
          />
        </div>
        {/* Invalid email error msg */}
        {(!isEmailPristine && !isEmailValid)
          ? <div className={css(styles.errMsg)}>Invalid email</div>
          : null
        }
        {/* Send verification code */}
        <div
          className={`mt-4 noselect ${css(styles.disabledTxtBtn, isEmailValid && styles.txtBtn)}`}
          style={{ textAlign: 'center' }}
          onClick={handleOnClickSendVerificationCode}
        >
          Send Verification Code to Email ▶
        </div>
        {/* Verification Code */}
        <div className='mt-2'>
          <TextInputWithLabel
            value={verificationCode}
            titleText="Verification Code From New Email"
            isCompactLayout={false}
            titleColor="#5079ab"
            width={328}
            showPreIcon
            preIcon="fa fa-shield"
            onChange={handleOnChangeVerificationCode}
          />
        </div>
        {/* Invalid code error msg */}
        {(!isVerificationCodePristine && !isVerificationCodeValid)
          ? <div className={css(styles.errMsg)}>Invalid Code</div>
          : null
        }
        {/* New Password */}
        <div style={{ marginTop: '10px' }}>
          <TextInputWithLabel
            value={newPassword}
            titleText="New Password (8 or more characters, no spaces)"
            type='password'
            isCompactLayout={false}
            titleColor="#5079ab"
            width={328}
            showPreIcon
            preIcon="fa fa-lock"
            showPostIcon
            postIcon="fa fa-eye"
            onChange={handleOnChangeNewPassword}
          />
        </div>
        {(!isNewPasswordPristine && !isNewPasswordValid)
          ? <div className={css(styles.errMsg)}>Invalid Password</div>
          : null
        }
        {/* Confirm New Password */}
        <div style={{ marginTop: '10px' }}>
          <TextInputWithLabel
            value={confirmNewPassword}
            titleText="Confirm New Password"
            type='password'
            isCompactLayout={false}
            titleColor="#5079ab"
            width={328}
            showPreIcon
            preIcon="fa fa-lock"
            showPostIcon
            postIcon="fa fa-eye"
            onChange={handleOnChangeConfirmNewPassword}
          />
        </div>
        {(!isConfirmNewPasswordPristine && newPassword !== confirmNewPassword)
          ? <div className={css(styles.errMsg)}>Password does not match</div>
          : null
        }
        {/* Status message */}
        {showStatusMsg
          ? <div className={`mt-4 mb-2 noselect ${css(styles.statusMsg)}`}>
              {statusMsg}
              <i
                className="ml-2 fa fa-circle-o-notch fa-spin fa-fw"
                style={{
                  display: showLoader ? '' : 'none'
                }}
              />
            </div>
          : null
        }
        {/* Update Password Btn */}
        <div style={{ marginTop: '32px' }}>
          <Button
            btnType="primary"
            isDisabled={!canUpdatePassword}
            style={{ minWidth: '330px', fontWeight: 'bold', borderRadius: '4px' }}
            onClick={handleOnClickUpdatePassword}
          >
            Update Password
          </Button>
        </div>
      </div>
    </DialogBox>
  );
};

export default ChangePasswordDialog;
