import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite/no-important';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { Cropper } from 'react-cropper';
import {
  Drawer,
  PopupMenu,
  TabsContainer,
  TabItem,
  Button,
  TextInputWithLabel,
  Loader,
  FileUploader,
  blue,
  gray,
  salmon
} from 'quantux-ui';
import {
  updatePracticeBanner,
  updatePracticeAvatar,
  updatePracticeProfile
} from '../../actions';
import { titleCase, BASE_URL } from '../../utils';
import About from '../about';
import Contact from '../contact';
import PracticeUserCard from '../my-practices/practice-user-card';

const propTypes = {
  practiceData: PropTypes.object
}

const AboutPractice = (props) => {
  // Props variables
  const {
    email: practiceEmail = '',
    mobile: practiceMobile = '',
    phone: practicePhone = '',
    fax: practiceFax = '',
    profileDescription: practiceProfileDescription = '',
    mailingAddress = {},
    bannerImageUrl: practiceBannerImageUrl = '',
    avatarImageUrl: practiceAvatarImageUrl = '',
    displayName: practiceDisplayName = '',
    userName: practiceUserName = '',
    practiceName = '',
    npi: practiceNpi = '',
    website: practiceWebsite = '',
    users: practiceUsers = []
  } = props.practiceData;

  const {
    streetAddress1: practiceStreetAddress = '',
    suite: practiceSuite = '',
    city: practiceCity = '',
    state: practiceState = '',
    zip: practiceZip = ''
  } = mailingAddress;

  // #region State variables
  const isFirstRender = useRef(true);
  const [isPopupMenuOpen, togglePopupMenu] = useState(false);
  const [isBannerCropperDrawerOpen, toggleBannerCropperDrawer] = useState(false);
  const [isAvatarCropperDrawerOpen, toggleAvatarCropperDrawer] = useState(false);
  const [isEditProfileDrawerVisible, toggleEditProfileDrawer] = useState(false);
  const [displayName, setDisplayName] = useState(practiceDisplayName);
  const [isDisplayNameValid, checkDisplayNameValidity] = useState(true);
  const [isDisplayNamePristine, setDisplayNamePristineState] = useState(true);
  const [userName, setUserName] = useState(practiceUserName);
  const [isUserNameValid, checkUserNameValidity] = useState(true);
  const [isUserNamePristine, setUserNamePristineState] = useState(true);
  const [isUserNameDuplicate, setUserNameDuplicateStatus] = useState(false);
  const [organization, setOrganization] = useState(practiceName);
  const [streetAddress, setStreetAddress] = useState(practiceStreetAddress);
  const [suite, setSuite] = useState(practiceSuite);
  const [city, setCity] = useState(practiceCity);
  const [state, setState] = useState(practiceState);
  const [zip, setZip] = useState(practiceZip);
  const [phone, setPhone] = useState(practicePhone);
  const [isPhoneValid, checkPhoneValidity] = useState(false);
  const [isPhonePristine, setPhonePristineState] = useState(true);
  const [fax, setFax] = useState(practiceFax);
  const [website, setWebsite] = useState(practiceWebsite);
  const [profileDescription, setProfileDescription] = useState(practiceProfileDescription);
  const [bannerImageUrl, setBannerImageUrl] = useState('https://quantumtrader.s3.amazonaws.com/dwMKF8A8HUKA1_7_5Xns8.png');
  const [avatarImageUrl, setAvatarImageUrl] = useState('');
  const [activeTab, setActiveTab] = useState('about');
  const [showBannerCropper, setShowBannerCropper] = useState(false);
  const [isBannerUploading, setIsBannerUploading] = useState(false);

  const [showAvatarCropper, setShowAvatarCropper] = useState(false);
  const [isAvatarUploading, setIsAvatarUploading] = useState(false);

  const [activeDrawerTab, setActiveDrawerTab] = useState(0);
  const [practiceOwner, setPracticeOwner] = useState('');
  const [practiceOwnerNpi, setPracticeOwnerNpi] = useState('');
  const [practiceOwnerId, setPracticeOwnerId] = useState('');
  const [practiceOwnerAvatarImageUrl, setPracticeOwnerAvatarImageUrl] = useState('');
  const [canAddPracticeOwner, setCanAddPracticeOwner] = useState(false);
  const [showPracticeOwnerSearchMenu, togglePracticeOwnerSearchMenu] = useState(false);
  const [practiceOwnersSearchResults, setPracticeOwnersSearchResults] = useState([]);
  const [practiceOwners, setPracticeOwners] = useState(practiceUsers);
  const [canSavePractice, setCanSavePractice] = useState(false);
  const [deletedOwners, setDeletedOwners] = useState([]);
  // #endregion

  // #region Refs
  const popupRef = React.createRef();
  const drawerBannerRef = React.createRef();
  const drawerAvatarRef = React.createRef();
  const bannerCropperRef = React.createRef();
  const bannerFileInputRef = React.createRef();
  const avatarCropperRef = React.createRef();
  const avatarFileInputRef = React.createRef();

  const practiceOwnerRef = React.createRef();
  // #endregion

  // #region Styles
  const styles = StyleSheet.create({
    userCard: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '80px',
      height: '80px',
      background: '#0080FF',
      borderRadius: '4px'
    },
    userCardIcon: {
      color: 'white',
      fontSize: '70px'
    },
    userCardAvatarImg: {
      width: 'auto',
      height: '80px',
      maxHeight: '80px',
      borderRadius: '4px'
    },
    userCardDisplayName: {
      color: blue[500]
    },
    userCardEllipsisMenuBtn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#60A0E0',
      height: '24px',
      width: '24px',
      borderRadius: '50%',
      color: '#001824',
      position: 'relative'
    },
    profileBanner: {
      borderBottom: '1px solid transparent'
    },
    profileBannerImg: {
      width: '100%',
      height: 'auto',
      maxHeight: '220px'
    },
    bigUserCardContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid transparent'
    },
    tabs: {
      // borderBottom: '1px solid red'
    },
    popupMenuContainer: {
      background: '#083050',
      width: '150px',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'
    },
    popupMenuItem: {
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      color: '#5080B0'
    },
    menuItemIconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '40px',
      width: '40px'
    },
    menuItemIcon: {
      fontSize: '20px'
    },
    drawerContainer: {
      background: gray[500],
      color: gray[300],
      height: '100%',
      position: 'relative',
      display: 'flex',
      flexFlow: 'column'
    },
    drawerHeader: {
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 8px',
      fontWeight: 'bold',
      borderBottom: '1px solid black',
      flexShrink: '0'
    },
    drawerHeaderCloseIcon: {
      color: gray[300],
      ':hover': {
        color: salmon[500]
      }
    },
    drawerBanner: {
      position: 'relative',
      padding: '8px',
      flexShrink: '0'
    },
    drawerBannerImg: {
      width: '100%',
      height: 'auto'
    },
    drawerBannerCameraIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: gray[500],
      color: 'white',
      position: 'absolute',
      right: '0',
      bottom: '8px',
      fontSize: '11px',
      width: '24px',
      height: '24px',
      cursor: 'pointer',
      borderRadius: '50%'
    },
    drawerContentArea: {
      // height: 'calc(100vh - 186px)',
      overflowY: 'auto',
      color: gray[300],
      flexGrow: '1'
    },
    drawerUserCardAvatar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '80px',
      height: '80px',
      background: blue[500],
      color: 'white',
      borderRadius: '4px',
      position: 'relative'
    },
    drawerUserCardAvatarImg: {
      width: 'auto',
      height: '80px',
      maxHeight: '80px'
    },
    drawerAvatarCamera: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: gray[500],
      color: 'white',
      position: 'absolute',
      left: '-3px',
      top: '58px',
      fontSize: '11px',
      width: '24px',
      height: '24px',
      cursor: 'pointer',
      borderRadius: '50%'
    },
    drawerUserCardText: {
      fontSize: '12px'
    },
    profileTextAreaContainer: {
      paddingRight: '8px'
    },
    profileTextArea: {
      width: '100%',
      background: gray[900],
      height: '100px',
      color: gray[100],
      border: 'none',
      resize: 'none',
      overflow: 'auto',
      ':focus': {
        outlineColor: 'transparent',
        outlineStyle: 'none'
      }
    },
    drawerFooter: {
      alignItems: 'center',
      justifyContent: 'flex-end',
      background: gray[700],
      height: '56px',
      width: '100%',
      borderTop: `1px solid ${gray[500]}`,
      // position: 'absolute',
      // top: 'calc(100vh - 56px)',
      flexShrink: '0'
    },
    imageUploaderContainer: {
      position: 'relative',
      maxHeight: '454px'
    },
    imageUploadLoader: {
      position: 'relative',
      height: '454px',
      top: '-400px',
      background: gray[800]
    },
    practiceOwnerRemoveBtn: {
      fontSize: '20px',
      ':hover': {
        color: salmon[500]
      }
    },
    practiceOwnerCard: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '8px',
      background: '#0A3250',
      borderRadius: '5px',
      padding: '8px'
    },
    errMsg: {
      color: '#FF6060',
      fontSize: '12px'
    }
  });

  const tabsTextStyles = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  };
  // #endregion

  // #region ▶️ useEffects Hooks ◀️

  // Search practice owners
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const ddF = setTimeout(() => {
      if (practiceOwner.length > 0) {
        searchPracticeOwners();
      }
    }, 300);
    return () => clearTimeout(ddF);
  }, [practiceOwner]);

  // Check for duplicate username
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (userName.length >= 2 && userName.length <= 20 && userName.indexOf(' ') < 0 && userName !== props.practiceData.userName) {
      const url = new URL(`${BASE_URL}/users/isPracticeUserNameExists`);
      const params = { userName };
      url.search = new URLSearchParams(params).toString();
      fetch(url)
        .then(res => res.text())
        .then((result) => {
          const data = JSON.parse(result);
          // console.log(data);
          setUserNameDuplicateStatus(data.isDuplicate);
        })
        .catch(error => console.log('error', error))
    }
  }, [userName]);

  // Can Save practice flag check
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    console.log('!isUserNameDuplicate', !isUserNameDuplicate);
    const value =
      displayName.length >= 2 && displayName.length <= 40 &&
      userName.length >= 2 && userName.length <= 20 && userName.indexOf(' ') < 0 &&
      !isUserNameDuplicate &&
      streetAddress.length > 5 &&
      city.length >= 2 &&
      state.length === 2 &&
      zip.length >= 5 &&
      practiceOwners.length > 0;
    setCanSavePractice(value);
  }, [
    displayName,
    userName,
    isUserNameDuplicate,
    streetAddress,
    suite,
    city,
    state,
    zip,
    practiceOwners
  ]);
  // #endregion

  // #region Network Requests
  function searchPracticeOwners () {
    const url = new URL(`${BASE_URL}/users/searchMedPros`);
    const params = { searchTerm: practiceOwner };
    url.search = new URLSearchParams(params).toString();
    fetch(url)
      .then(res => res.text())
      .then((result) => {
        const data = JSON.parse(result);
        console.log(data);
        setPracticeOwnersSearchResults(data.users ? data.users : []);
      })
      .catch(error => console.log('error', error));
  };
  // #endregion

  const activeComp = activeTab === 'about'
    ? <About description={practiceProfileDescription} />
    : <Contact
      organization={props.practiceData.practiceName}
      hideName
      mailingAddress={{
        street: practiceStreetAddress,
        suite: practiceSuite,
        city: practiceCity,
        state: practiceState,
        zip: practiceZip
      }}
      email={practiceEmail}
      mobile={null}
      phone={practicePhone}
      fax={practiceFax}
      profileDescription={practiceProfileDescription}
      website={practiceWebsite}
    />

  // #region 'Practice Owners' tab eventhandlers
  const handleOnEditPracticeOwner = (e) => {
    const { value } = e.target;
    setPracticeOwner(value);
  }

  const closePracticeOwnerSearchMenu = () => {
    togglePracticeOwnerSearchMenu(false);
  };

  const handleOnClickAddPracticeOwner = () => {
    if (!canAddPracticeOwner) {
      return;
    }
    const updatePracticeOwners = practiceOwners.concat();
    updatePracticeOwners.push({
      fullName: practiceOwner,
      npi: practiceOwnerNpi,
      id: practiceOwnerId,
      accessType: 'Member',
      avatarImageUrl: practiceOwnerAvatarImageUrl
    });
    setPracticeOwners(updatePracticeOwners);
    setPracticeOwner('');
    setPracticeOwnersSearchResults([]);
    togglePracticeOwnerSearchMenu(false);
    console.log('After add --', practiceOwnersSearchResults);
    setCanAddPracticeOwner(false);
  }

  const removePracticeOwner = (userId) => {
    const updatedPracticeOwners = practiceOwners.filter(el => el.user !== userId);
    setPracticeOwners(updatedPracticeOwners);
  };

  const updateAccessType = (accessType, userId) => {
    const updatedPracticeOwners = practiceOwners.concat();
    updatedPracticeOwners.forEach((el) => {
      if (el.user === userId || el.id === userId) {
        el.accessType = accessType;
      }
    })

    setPracticeOwners(updatedPracticeOwners);
  };
  // #endregion

  // #region Edit Profile Form (Edit Handlers)
  const handleOnEditDisplayName = (e) => {
    const { value } = e.target;
    setDisplayNamePristineState(false);
    checkDisplayNameValidity(value.length >= 2 && value.length <= 40);
    setDisplayName(value);
  };

  const handleOnEditUserName = (e) => {
    const { value } = e.target;
    setUserNamePristineState(false);
    checkUserNameValidity(value.length >= 2 && value.length <= 20 && value.indexOf(' ') < 0);
    setUserName(value);
  };

  const handleOnEditOrganization = (e) => {
    const { value } = e.target;
    setOrganization(value);
  };

  const handleOnEditStreetAddress = (e) => {
    const { value } = e.target;
    setStreetAddress(value);
  };

  const handleOnEditSuite = (e) => {
    const { value } = e.target;
    setSuite(value);
  };

  const handleOnEditCity = (e) => {
    const { value } = e.target;
    setCity(value);
  };

  const handleOnEditState = (e) => {
    const { value } = e.target;
    setState(value);
  };

  const handleOnEditZip = (e) => {
    const { value } = e.target;
    setZip(value);
  };

  const handleOnEditPhone = (e) => {
    const { value } = e.target;
    setPhonePristineState(false);
    checkPhoneValidity(isMobilePhone(value, 'en-US'));
    setPhone(value);
  };

  const handleOnEditFax = (e) => {
    const { value } = e.target;
    setFax(value);
  };

  const handleOnEditWesbite = (e) => {
    const { value } = e.target;
    setWebsite(value);
  };

  const handleOnChangeProfileDescription = (e) => {
    const { value } = e.target;
    setProfileDescription(value);
  };
  // #endregion

  // #region Banner image crop and file upload
  const uploadBanner = () => {
    const formData = new FormData();
    const img = bannerFileInputRef.current.files[0];
    formData.append('file', img);
    const options = { method: 'POST', body: formData, redirect: 'follow' };

    fetch(`${BASE_URL}/users/uploadImg`, options)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        console.log(res);
        if (res.success) {
          setBannerImageUrl(res.fileUrl);
          setIsBannerUploading(false);
          setShowBannerCropper(true);
        }
      })
      .catch(error => console.log('error', error));
  };

  const handleOnBannerFileDrop = (img) => {
    const formData = new FormData();
    formData.append('file', img);
    const options = { method: 'POST', body: formData, redirect: 'follow' };
    setIsBannerUploading(true);
    fetch(`${BASE_URL}/users/uploadImg`, options)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        console.log(res);
        if (res.success) {
          setBannerImageUrl(res.fileUrl);
          setIsBannerUploading(false);
          setShowBannerCropper(true);
        }
      })
      .catch(error => console.log('error', error));
  };

  const handleOnBannerFileSelected = () => {
    if (bannerFileInputRef.current.files.length > 0) {
      console.log('Selected file = ', bannerFileInputRef.current.files[0]);
      console.log('typeof img', typeof bannerFileInputRef.current.files[0]);
      setIsBannerUploading(true);
      uploadBanner();
    } else {
      console.log('No file is selected!');
    }
  };

  const handleOnClickBannerCrop = () => {
    const imageElement = bannerCropperRef.current;
    const { cropper } = imageElement;
    cropper.getCroppedCanvas().toBlob((blob) => {
      const url = URL.createObjectURL(blob);
      drawerBannerRef.current.src = url;
      props.updatePracticeBanner(blob, props.practiceData._id);
      toggleBannerCropperDrawer(false);
    });
  };
  // #endregion

  // #region Avatar image crop and file upload

  const uploadAvatar = () => {
    const formData = new FormData();
    const img = bannerFileInputRef.current.files[0];
    formData.append('file', img);
    const options = { method: 'POST', body: formData, redirect: 'follow' };

    fetch(`${BASE_URL}/users/uploadImg`, options)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        console.log(res);
        if (res.success) {
          setBannerImageUrl(res.fileUrl);
          setIsBannerUploading(false);
          setShowBannerCropper(true);
        }
      })
      .catch(error => console.log('error', error));
  };

  const handleOnAvatarFileDrop = (img) => {
    const formData = new FormData();
    formData.append('file', img);
    const options = { method: 'POST', body: formData, redirect: 'follow' };
    setIsAvatarUploading(true);
    fetch(`${BASE_URL}/users/uploadImg`, options)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        console.log(res);
        if (res.success) {
          setAvatarImageUrl(res.fileUrl);
          setIsAvatarUploading(false);
          setShowAvatarCropper(true);
        }
      })
      .catch(error => console.log('error', error));
  };

  const handleOnAvatarFileSelected = () => {
    if (avatarFileInputRef.current.files.length > 0) {
      console.log('Selected file = ', avatarFileInputRef.current.files[0]);
      console.log('typeof img', typeof avatarFileInputRef.current.files[0]);
      setIsAvatarUploading(true);
      uploadAvatar();
    } else {
      console.log('No file is selected!');
    }
  };

  const handleOnClickAvatarCrop = () => {
    const imageElement = avatarCropperRef.current;
    const { cropper } = imageElement;
    cropper.getCroppedCanvas().toBlob((blob) => {
      const url = URL.createObjectURL(blob);
      drawerAvatarRef.current.src = url;
      props.updatePracticeAvatar(blob, props.practiceData._id);
      toggleAvatarCropperDrawer(false);
    });
  };
  // #endregion

  // Sync current state to previous props, so that any unsaved changes are discarded
  const syncStateToPreviousProps = () => {
    setDisplayName(practiceDisplayName);
    setUserName(practiceUserName);
    setOrganization(practiceName);
    setStreetAddress(practiceStreetAddress);
    setSuite(practiceSuite);
    setCity(practiceCity);
    setState(practiceState);
    setZip(practiceZip);
    setPhone(practicePhone);
    setFax(practiceFax);
    setProfileDescription(practiceProfileDescription);
    setBannerImageUrl(props.practiceData.bannerImageUrl);
    setAvatarImageUrl(props.practiceData.avatarImageUrl);
    setPracticeOwners(props.practiceData.users);
  };

  const resetState = () => {
    setDisplayName(practiceDisplayName);
    checkDisplayNameValidity(true);
    setDisplayNamePristineState(true);
    setUserName(practiceUserName);
    checkUserNameValidity(true);
    setUserNamePristineState(true);
    setUserNameDuplicateStatus(false);
    setOrganization(practiceName);
    setStreetAddress(practiceStreetAddress);
    setSuite(practiceSuite);
    setCity(practiceCity);
    setState(practiceState);
    setZip(practiceZip);
    setPhone(practicePhone);
    checkPhoneValidity(false);
    setPhonePristineState(true);
    setFax(practiceFax);
    setProfileDescription(practiceProfileDescription);
    setBannerImageUrl('https://quantumtrader.s3.amazonaws.com/dwMKF8A8HUKA1_7_5Xns8.png');
    setAvatarImageUrl('');
    setActiveTab('about');
    setShowBannerCropper(false);
    setIsBannerUploading(false);
    setShowAvatarCropper(false);
    setIsAvatarUploading(false);
    setActiveDrawerTab(0);
    setPracticeOwner('');
    setPracticeOwnerNpi('');
    setPracticeOwnerId('');
    setPracticeOwnerAvatarImageUrl('');
    setCanAddPracticeOwner(false);
    togglePracticeOwnerSearchMenu(false);
    setPracticeOwnersSearchResults([]);
    setPracticeOwners(practiceUsers);
    setDeletedOwners([]);
  };

  const closeEditProfileDrawer = () => {
    toggleEditProfileDrawer(false);
    resetState();
  };

  // Toggle 'Edit Profile' drawer
  const handleOnClickEditProfile = () => {
    syncStateToPreviousProps();
    toggleEditProfileDrawer(true);
  };

  // Save practice profile changes
  const handleOnClickSavePracticeProfile = () => {
    // Find deleted owners
    const deletedOwners = [];
    practiceUsers.forEach(el => {
      if (practiceOwners.findIndex(item => item.user === el.user) < 0) {
        deletedOwners.push(el);
      }
    });

    console.log('deletedOwners', deletedOwners);

    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formData = new FormData();
    formData.append('practiceId', props.practiceData._id);
    formData.append('displayName', displayName);
    formData.append('userName', userName);
    formData.append('streetAddress', streetAddress);
    formData.append('suite', suite);
    formData.append('city', city);
    formData.append('state', state);
    formData.append('zip', zip);
    formData.append('phone', phone);
    formData.append('fax', fax);
    formData.append('website', website);
    formData.append('profileDescription', profileDescription);
    formData.append('practiceOwners', JSON.stringify(practiceOwners));
    formData.append('deletedOwners', JSON.stringify(deletedOwners));

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
      redirect: 'follow'
    };

    fetch(`${BASE_URL}/users/updatePracticeProfile`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        if (res.success) {
          closeEditProfileDrawer();
          props.reloadPracticeData();
        }
      })
      .catch(error => console.log('error', error));
  };

  // console.log('props.practiceData', props.practiceData);

  // #region Misc Menu Items

  // Practice owners search results
  const owners = [];
  if (practiceOwnersSearchResults.length > 0) {
    practiceOwnersSearchResults.forEach(el => {
      owners.push(
        <div
          className='m-item'
          style={{
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            borderBottom: '1px solid #001824',
            cursor: 'pointer',
            fontSize: '12px',
            background: gray[700]
          }}
          onClick={() => {
            setPracticeOwner(`${el.lastName}, ${el.firstName}`);
            setPracticeOwnerNpi(el.npi ? el.npi : 'NPI n/a');
            setPracticeOwnerId(el._id);
            setPracticeOwnerAvatarImageUrl(el.avatarImageUrl);
            togglePracticeOwnerSearchMenu(false);
            setCanAddPracticeOwner(true);
          }}
        >
          <div>
            {`${el.lastName}, ${el.firstName} (${el.npi ? el.npi : 'NPI n/a'})`}
          </div>
        </div>
      );
    })
  }

  // Added practice owners cards
  const addedPracticeOwners = [];
  if (practiceOwners.length > 0) {
    practiceOwners.forEach(el => {
      addedPracticeOwners.push(
        <PracticeUserCard
          el={el}
          removePracticeOwner={removePracticeOwner}
          updateAccessType={updateAccessType}
        />
      );
    });
  }

  // #endregion

  // #region Edit Practice Drawer Tabs
  const practiceInfoTab = (
    <div className='p-2'>
      {/* Banner */}
      <div className={css(styles.drawerBanner)}>
        <img
          ref={drawerBannerRef}
          src={bannerImageUrl}
          alt="cropped-banner"
          className={css(styles.drawerBannerImg)}
        />
        {/* Camera icon */}
        <div
          onClick={() => { toggleBannerCropperDrawer(true); }}
          className={css(styles.drawerBannerCameraIcon)}
        >
          <i className="fa fa-camera" />
        </div>
      </div>
      {/* Middle area */}
      <div className={css(styles.drawerContentArea)}>
        {/* User card */}
        <div className="fx ml-2">
          {/* Avatar */}
          <div className={css(styles.drawerUserCardAvatar)}>
            <img
              src={practiceAvatarImageUrl}
              ref={drawerAvatarRef}
              alt="avatar"
              className={css(styles.drawerUserCardAvatarImg)}
              onClick={() => toggleAvatarCropperDrawer(true)}
            />
            <div
              onClick={() => { toggleAvatarCropperDrawer(true); }}
              className={css(styles.drawerAvatarCamera)}
            >
              <i className="fa fa-camera" />
            </div>
          </div>
          {/* User card displayName & userName */}
          <div className={`ml-2 ${css(styles.drawerUserCardText)}`}>
            {/* Name and Npi */}
            <div style={{ fontSize: '12px', color: gray[200], lineHeight: '12px', paddingLeft: '2px' }}>
              {`${practiceName !== '' ? titleCase(practiceName) : ''} (NPI: ${practiceNpi})`}
            </div>
            {/* Display Name */}
            <div className='mt-2'>
              <TextInputWithLabel
                value={displayName}
                titleText="Display Name (2 to 40 characters)*"
                titleFontSize={12}
                isCompactLayout={true}
                titleColor={gray[200]}
                textColor="white"
                selectionColor="white"
                selectionBackground="#0080FF"
                spellcheck={false}
                width={248}
                onChange={handleOnEditDisplayName}
              />
              {/* Error message */}
              {!isDisplayNameValid && !isDisplayNamePristine
                ? <div
                  style={{
                    color: '#FF6060',
                    fontSize: '12px'
                  }}
                >
                  Invalid Display Name
                </div>
                : null
              }
            </div>
            {/* UserName */}
            <div
              style={{
                marginTop: (!isDisplayNameValid && !isDisplayNamePristine) ? '0px' : '4px'
              }}
            >
              <TextInputWithLabel
                value={userName}
                titleText="Username (2 to 20 characters, no spaces)"
                titleFontSize={12}
                isCompactLayout={true}
                titleColor={gray[200]}
                textColor={blue[500]}
                selectionColor="white"
                selectionBackground="#0080FF"
                spellcheck={false}
                width={248}
                onChange={handleOnEditUserName}
              />
              {/* Error message */}
              {!isUserNameValid && !isUserNamePristine
                ? <div
                  style={{
                    color: '#FF6060',
                    fontSize: '12px'
                  }}
                >
                  Invalid User Name
                </div>
                : null
              }
              {!isUserNamePristine && isUserNameDuplicate
                ? <div
                  style={{
                    color: '#FF6060',
                    fontSize: '12px'
                  }}
                >
                  User Name Taken
                </div>
                : null
              }
            </div>
          </div>
        </div>
        {/* Mailing Address */}
        <div className="mt-4 ml-2">
          {/* Heading */}
          <div className="fx fx__v-center">
            <i className="fa fa-map-marker" style={{ fontSize: '28px' }} />
            <div className="ml-2">
              Mailing Address
            </div>
          </div>
          {/* Form */}
          <div>
            {/* Organization */}
            <div>
              <TextInputWithLabel
                value={organization}
                titleText="Organization"
                titleFontSize={12}
                isCompactLayout={true}
                titleColor={gray[200]}
                textColor="white"
                selectionColor="white"
                selectionBackground="#0080FF"
                spellcheck={false}
                width={337}
                onChange={handleOnEditOrganization}
              />
            </div>
            {/* Address */}
            <div className="fx">
              <div>
                <TextInputWithLabel
                  value={streetAddress}
                  titleText="Street"
                  titleFontSize={12}
                  isCompactLayout={true}
                  titleColor={gray[200]}
                  textColor="white"
                  selectionColor="white"
                  selectionBackground="#0080FF"
                  spellcheck={false}
                  width={284}
                  onChange={handleOnEditStreetAddress}
                />
              </div>
              <div className="ml-2">
                <TextInputWithLabel
                  value={suite}
                  titleText="Suite"
                  titleFontSize={12}
                  isCompactLayout={true}
                  titleColor={gray[200]}
                  textColor="white"
                  selectionColor="white"
                  selectionBackground="#0080FF"
                  spellcheck={false}
                  width={45}
                  onChange={handleOnEditSuite}
                />
              </div>
            </div>
            {/* City, State and Zip */}
            <div className="fx mt-2">
              <div>
                <TextInputWithLabel
                  value={city}
                  titleText="City"
                  titleFontSize={12}
                  isCompactLayout={true}
                  titleColor={gray[200]}
                  textColor="white"
                  selectionColor="white"
                  selectionBackground="#0080FF"
                  spellcheck={false}
                  width={230}
                  onChange={handleOnEditCity}
                />
              </div>
              <div className="ml-2">
                <TextInputWithLabel
                  value={state}
                  titleText="State"
                  titleFontSize={12}
                  isCompactLayout={true}
                  titleColor={gray[200]}
                  textColor="white"
                  selectionColor="white"
                  selectionBackground="#0080FF"
                  spellcheck={false}
                  width={45}
                  onChange={handleOnEditState}
                />
              </div>
              <div className="ml-2">
                <TextInputWithLabel
                  value={zip}
                  titleText="Zip"
                  titleFontSize={12}
                  isCompactLayout={true}
                  titleColor={gray[200]}
                  textColor="white"
                  selectionColor="white"
                  selectionBackground="#0080FF"
                  spellcheck={false}
                  width={45}
                  onChange={handleOnEditZip}
                />
              </div>
            </div>
            {/* Email - message */}
            <div
              className='mt-4 fx fx__v-center'
              style={{ color: gray[200] }}
            >
              <i className='fa fa-lg fa-envelope mr-2' />
              <div style={{ fontSize: '12px' }}>
                <div>
                  Email&nbsp;
                  <span>
                    (Go to <span style={{ color: blue[500] }}>Login Credentials</span> to edit)
                  </span>
                </div>
                <div>{practiceEmail}</div>
              </div>
            </div>
            {/* Phone */}
            <div className="mt-2">
              <TextInputWithLabel
                value={phone}
                titleText="Phone"
                titleFontSize={12}
                isCompactLayout={false}
                titleColor={gray[200]}
                textColor="white"
                selectionColor="white"
                selectionBackground="#0080FF"
                spellcheck={false}
                width={337}
                showPreIcon
                preIcon='fa fa-phone'
                onChange={handleOnEditPhone}
              />
              {!isPhonePristine && !isPhoneValid
                ? <div className={css(styles.errMsg)}>Invalid Phone #</div>
                : null
              }
            </div>
            {/* Fax */}
            <div className="mt-2">
              <TextInputWithLabel
                value={fax}
                titleText="Fax"
                titleFontSize={12}
                isCompactLayout={false}
                titleColor={gray[200]}
                textColor="white"
                selectionColor="white"
                selectionBackground="#0080FF"
                spellcheck={false}
                width={337}
                showPreIcon
                preIcon='fa fa-fax'
                onChange={handleOnEditFax}
              />
            </div>
            {/* Website */}
            <div className="mt-2">
              <TextInputWithLabel
                value={website}
                titleText="Website"
                titleFontSize={12}
                isCompactLayout={false}
                titleColor={gray[200]}
                textColor="white"
                selectionColor="white"
                selectionBackground="#0080FF"
                spellcheck={false}
                width={337}
                showPreIcon
                preIcon='fa fa-globe'
                onChange={handleOnEditWesbite}
              />
            </div>
          </div>
        </div>
        {/* Profile descriptiom */}
        <div className="mt-4 ml-2">
          <div>Profile Description</div>
          <div className={css(styles.profileTextAreaContainer)}>
            <textarea
              className={css(styles.profileTextArea)}
              placeholder="Write a description"
              value={profileDescription}
              onChange={handleOnChangeProfileDescription}
            />
          </div>
        </div>
      </div>
    </div>
  );

  const practiceOwnersTab = (
    <div className='p-2'>
      <div className='pl-2 pr-2'>
        <div
          className='mt-3 fx'
          ref={practiceOwnerRef}
        >
          <div>
            <TextInputWithLabel
              value={practiceOwner}
              titleText="Add Practice Owner"
              isCompactLayout={false}
              showPreIcon
              preIcon='fa fa-user-plus'
              titleColor="#60A0E0"
              textColor="#0080FF"
              selectionColor="white"
              selectionBackground="#0080FF"
              spellcheck={false}
              width={280}
              onChange={handleOnEditPracticeOwner}
              onClick={() => {
                togglePracticeOwnerSearchMenu(true);
              }}
            />
            <PopupMenu
              top={145}
              left={16}
              zIndex='1550'
              position="absolute"
              visible={showPracticeOwnerSearchMenu}
              parent={practiceOwnerRef}
              onOutsideClick={closePracticeOwnerSearchMenu}
            >
              <div style={{ background: '#0a3250', width: '328px' }}>
                {practiceOwnersSearchResults.length === 0
                  ? <div
                    className='fx fx__hv-center'
                    style={{ height: '40px' }}
                  >
                    Search Practice Owners
                  </div>
                  : null
                }
                {owners}
              </div>
            </PopupMenu>
          </div>
          {/* Add practice owner button */}
          <Button
            btnType='primary'
            isDisabled={!canAddPracticeOwner}
            style={{
              maxWidth: '48px',
              minWidth: '48px',
              maxHeight: '24px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '14px',
              fontSize: '14px'
            }}
            onClick={handleOnClickAddPracticeOwner}
          >
            Add
          </Button>
        </div>
        <div className='mt-2 pl-2 pr-2'>
          {addedPracticeOwners}
        </div>
      </div>
    </div>
  );
  // #endregion

  return (
    <div className='p-2'>
      {/* Edit profile drawer */}
      {isEditProfileDrawerVisible
        ? <Drawer
          placement="right"
          visible={isEditProfileDrawerVisible}
          toggleDrawer={closeEditProfileDrawer}
          zIndex="2000"
          width={window.innerWidth <= 700 ? window.innerWidth : 358}
        >
          {/* Drawer Container */}
          <div className={css(styles.drawerContainer)}>
            {/* Header */}
            <div className={css(styles.drawerHeader)}>
              <div className='fx fx__hv-center'>
                <i className='mr-2 fa fa-lg fa-building-o' />
                <div>Edit Profile</div>
              </div>
              <i
                className={`fa fa-lg fa-times-circle ${css(styles.drawerHeaderCloseIcon)}`}
                onClick={closeEditProfileDrawer}
              />
            </div>
            {/* Tabs */}
            <div>
              <TabsContainer
                dropDownId="d1"
                showPlusIcon={false}
                containerWidth={500}
                otherElementsWidth={0}
                minWidth={500}
                dropDownTop={39}
                dropDownLeft={44}
                dropDownHeaderStyles={{
                  minWidth: '170px',
                  maxWidth: '370px',
                  width: '100%'
                }}
                containerStyles={{
                  width: 'calc(100% - 320px)'
                }}
              >
                <TabItem
                  tabKey="1"
                  key='9898'
                  parentKey="0111"
                  text="Practice Info"
                  isActive={activeDrawerTab === 0}
                  iconClass='fa fa-info-circle'
                  style={{
                    // borderLeft: '1px solid #082840',
                    paddingRight: '0',
                    width: '130px',
                    minWidth: '130px',
                    maxWidth: '130px'
                  }}
                  textStyles={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                  onActivate={() => {
                    setActiveDrawerTab(0);
                  }}
                >
                  <div>
                    Practice Info
                  </div>
                </TabItem>
                <TabItem
                  tabKey="2"
                  key='9899'
                  parentKey="0111"
                  text="Practice Owners"
                  isActive={activeDrawerTab === 1}
                  iconClass='fa fa-user'
                  style={{
                    // borderLeft: '1px solid #082840',
                    paddingRight: '0',
                    width: '160px',
                    minWidth: '160px',
                    maxWidth: '160px'
                  }}
                  textStyles={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                  onActivate={() => {
                    setActiveDrawerTab(1);
                  }}
                >
                  <div>
                    Practice Owners
                  </div>
                </TabItem>
              </TabsContainer>
            </div>
            {/* Active Tab */}
            <div className={css(styles.drawerContentArea)}>
              {activeDrawerTab === 0
                ? practiceInfoTab
                : practiceOwnersTab
              }
            </div>
            {/* Footer */}
            <div className={`fx pr-2 ${css(styles.drawerFooter)}`} >
              <Button
                btnType="primary"
                isDisabled={!canSavePractice}
                className="fx fx__hv-center"
                onClick={handleOnClickSavePracticeProfile}
                style={{
                  height: '32px',
                  width: '340px',
                  padding: '3px 0'
                }}
              >
                Save Practice Profile
              </Button>
            </div>
          </div>
        </Drawer>
        : null
      }
      {/* Profile banner image cropper */}
      <Drawer
        placement="right"
        visible={isBannerCropperDrawerOpen}
        toggleDrawer={() => toggleBannerCropperDrawer(false)}
        zIndex="2050"
        width={window.innerWidth <= 700 ? window.innerWidth : 358}
      >
        <div className={css(styles.drawerContainer)}>
          {/* Header */}
          <div className={css(styles.drawerHeader)}>
            <div>Upload Profile Banner</div>
            <i
              className={`fa fa-times-circle ${css(styles.drawerHeaderCloseIcon)}`}
              onClick={() => { toggleBannerCropperDrawer(false); }}
            />
          </div>
          {/* Content Area */}
          <div className={css(styles.drawerContentArea, styles.imageUploaderContainer)}>
            {showBannerCropper
              ? <div>
                <Cropper
                  src={bannerImageUrl}
                  style={{ height: 400, width: '100%' }}
                  checkCrossOrigin
                  checkOrientation={false}
                  aspectRatio={6}
                  background={false}
                  viewMode={1}
                  autoCropArea={1}
                  guides={false}
                  ref={bannerCropperRef}
                />
              </div>
              : null
            }
            {!showBannerCropper
              ? <div>
                <FileUploader
                  parentRef={bannerFileInputRef}
                  onDrop={handleOnBannerFileDrop}
                  onChange={handleOnBannerFileSelected}
                />
              </div>
              : null
            }
            {isBannerUploading
              ? <div className={css(styles.imageUploadLoader)}>
                <Loader iconType="cog" iconSize="5x" text="Uploading Banner Image" />
              </div>
              : null
            }
          </div>
          {/* Drawer footer area */}
          <div
            className={`fx pr-2 ${css(styles.drawerFooter)}`}
            style={{ position: 'absolute', bottom: '0' }}
          >
            <Button
              btnType="primary"
              className="fx fx__hv-center"
              onClick={handleOnClickBannerCrop}
              style={{
                height: '32px',
                width: '340px',
                padding: '3px 0'
              }}
            >
              Crop Banner Image
            </Button>
          </div>
        </div>
      </Drawer>

      {/* Profile avatar image cropper */}
      <Drawer
        placement="right"
        visible={isAvatarCropperDrawerOpen}
        toggleDrawer={() => toggleAvatarCropperDrawer(false)}
        zIndex="2050"
        width={window.innerWidth <= 700 ? window.innerWidth : 358}
      >
        <div className={css(styles.drawerContainer)}>
          {/* Header */}
          <div className={css(styles.drawerHeader)}>
            <div>Upload Profile Avatar</div>
            <i
              className={`fa fa-times-circle ${css(styles.drawerHeaderCloseIcon)}`}
              onClick={() => { toggleAvatarCropperDrawer(false); }}
            />
          </div>
          {/* Content Area */}
          <div className={css(styles.drawerContentArea, styles.imageUploaderContainer)}>
            {showAvatarCropper
              ? <div>
                <Cropper
                  src={avatarImageUrl}
                  style={{ height: 400, width: '100%' }}
                  checkCrossOrigin
                  checkOrientation={false}
                  aspectRatio={1}
                  background={false}
                  viewMode={1}
                  autoCropArea={1}
                  guides={false}
                  ref={avatarCropperRef}
                />
              </div>
              : null
            }
            {!showAvatarCropper
              ? <div>
                <FileUploader
                  parentRef={avatarFileInputRef}
                  onDrop={handleOnAvatarFileDrop}
                  onChange={handleOnAvatarFileSelected}
                />
              </div>
              : null
            }
            {isAvatarUploading
              ? <div className={css(styles.imageUploadLoader)}>
                <Loader iconType="cog" iconSize="5x" text="Uploading Avatar Image" />
              </div>
              : null
            }
          </div>
          {/* Drawer footer area */}
          <div
            className={`fx pr-2 ${css(styles.drawerFooter)}`}
            style={{ position: 'absolute', bottom: '0' }}
          >
            <Button
              btnType="primary"
              className="fx fx__hv-center"
              onClick={handleOnClickAvatarCrop}
              style={{
                height: '32px',
                width: '340px',
                padding: '3px 0'
              }}
            >
              Crop Avatar Image
            </Button>
          </div>
        </div>
      </Drawer>

      {/* Detail panel header */}
      <div className={css(styles.profileBanner)}>
        <img
          className={css(styles.profileBannerImg)}
          src={practiceBannerImageUrl}
          alt="cropped-banner"
        />
      </div>
      {/* User card container */}
      <div className={`p-2 ${css(styles.bigUserCardContainer)}`}>
        {/* User card */}
        <div className="fx">
          <div className={css(styles.userCard)}>
            {!practiceAvatarImageUrl
              ? <i className={`fa fa-building-o ${css(styles.userCardIcon)}`} />
              : <img
                className={css(styles.userCardAvatarImg)}
                src={practiceAvatarImageUrl}
                alt='practice avatar'
              />
            }
          </div>
          <div className="ml-2">
            <div>{practiceDisplayName}</div>
            <div className={css(styles.userCardDisplayName)}>
              {`@${practiceUserName}`}
            </div>
          </div>
        </div>

        {/* Ellipsis menu */}
        <div
          ref={popupRef}
          className={css(styles.userCardEllipsisMenuBtn)}
          onClick={() => togglePopupMenu(true)}
        >
          <i className="fa fa-ellipsis-v" />
          <PopupMenu
            top={28}
            right={0}
            visible={isPopupMenuOpen}
            parent={popupRef}
            position="absolute"
            onOutsideClick={() => { togglePopupMenu(false); }}
          >
            <div className={css(styles.popupMenuContainer)}>
              <div
                className={`m-item ${css(styles.popupMenuItem)}`}
                onClick={handleOnClickEditProfile}
              >
                <div className={css(styles.menuItemIconContainer)}>
                  <i className={`fa fa-pencil ${css(styles.menuItemIcon)}`} />
                </div>
                <div>Edit Profile</div>
              </div>
            </div>
          </PopupMenu>
        </div>
      </div>
      {/* Tabs */}
      <div className={css(styles.tabs)}>
        <TabsContainer
          dropDownId="d1"
          showPlusIcon={false}
          containerWidth={800}
          otherElementsWidth={320}
          minWidth={810}
          dropDownTop={39}
          dropDownLeft={44}
          dropDownHeaderStyles={{
            minWidth: '170px',
            maxWidth: '370px',
            width: '100%'
          }}
          containerStyles={{
            width: 'calc(100% - 320px)'
          }}
        >
          {/* About Tab */}
          <TabItem
            tabKey="1"
            key='9898'
            parentKey="0111"
            text="About"
            isActive={activeTab === 'about'}
            iconClass='fa fa-info-circle'
            style={{
              borderRight: `1px solid ${gray[700]}`,
              borderLeft: `1px solid ${gray[700]}`,
              paddingRight: '0',
              width: '90px',
              minWidth: '90px',
              maxWidth: '80px'
            }}
            textStyles={tabsTextStyles}
            onActivate={() => {
              if (activeTab !== 'about') {
                setActiveTab('about');
              }
            }}
          >
            <div>
              About
            </div>
          </TabItem>
          {/* Contact  */}
          <TabItem
            tabKey="1"
            key='9899'
            parentKey="0111"
            text="Contact"
            isActive={activeTab === 'contact'}
            iconClass='fa fa-info-circle'
            style={{
              borderRight: `1px solid ${gray[700]}`,
              borderLeft: `1px solid ${gray[700]}`,
              paddingRight: '0',
              width: '100px',
              minWidth: '90px',
              maxWidth: '100px'
            }}
            textStyles={tabsTextStyles}
            onActivate={() => {
              if (activeTab !== 'contact') {
                setActiveTab('contact');
              }
            }}
          >
            <div>
              Contact
            </div>
          </TabItem>
        </TabsContainer>
        {/* Active tab component */}
        <div>
          {activeComp}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps({ user }) {
  return { ...user };
}

AboutPractice.displayName = 'AboutPractice';
AboutPractice.propTypes = propTypes;
export default connect(mapStateToProps, {
  updatePracticeBanner,
  updatePracticeAvatar,
  updatePracticeProfile
})(AboutPractice);
