import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  DialogBox,
  TextInputWithLabel,
  TextInput,
  Button
} from 'quantux-ui';
import { initiateSignIn, toggleResetPasswordDialog } from '../../actions';

const propTypes = {
  isVisible: PropTypes.bool,
  showLoginErrorMsg: PropTypes.bool,
  onCloseDialogBox: PropTypes.func,
  initiateUserSignIn: PropTypes.func,
  finalizeSignIn: PropTypes.func,
  signinUser: PropTypes.func,
  toggleResetPasswordDialog: PropTypes.func
};

const defaultProps = {
  isVisible: false
};

const SignInDialog = props => {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [password, setPassword] = useState('');

  const dialogWidth = window.innerWidth <= 576 ? (window.innerWidth - 16) : 350;

  const {
    isVisible,
    onCloseDialogBox,
    showLoginErrorMsg
  } = props;

  const handleUserSignin = () => {
    if (usernameOrEmail.length > 0 && password.length > 0) {
      props.initiateSignIn({ usernameOrEmail, password });
    }
  };

  const handleOnChangeUsernameOrEmail = (e) => {
    const { value } = e.target;
    setUsernameOrEmail(value);
  };

  const handleOnChangePassword = (e) => {
    const { value } = e.target;
    setPassword(value);
  };

  return (
    <DialogBox
      initWidth={dialogWidth}
      top={window.innerWidth <= 576 ? 48 : null}
      left={window.innerWidth <= 576 ? 8 : null}
      initHeight={378}
      isOpen={isVisible}
      onClose={() => { onCloseDialogBox(false); }}
      maskBackground="#004080"
      maskOpacity={0.5}
      closeOnEscKey
      closeOnMaskClick
      header={{
        text: 'MedPros Login',
        backColor: '#082840',
        foreColor: '#5080B0',
        closeColor: '#FF6060',
        fontStyle: 'bold',
        fontSize: '20px',
        icon: 'fa fa-user'
      }}
    >
      {/* Dialogbox container */}
      <div className="p-2">
        {/* Email */}
        <div style={{ marginTop: '10px' }}>
          <TextInputWithLabel
            value={usernameOrEmail}
            type='email'
            titleText="Email"
            isCompactLayout={false}
            titleColor="#5079ab"
            width={dialogWidth - 16}
            showPreIcon
            preIcon="fa fa-envelope"
            onChange={handleOnChangeUsernameOrEmail}
          />
        </div>
        {/* Password */}
        <div style={{ marginTop: '30px' }} >
          <TextInputWithLabel
            value={password}
            type="password"
            titleText="Password"
            isCompactLayout={false}
            titleColor="#5079ab"
            width={dialogWidth - 16}
            showPreIcon
            preIcon="fa fa-lock"
            showPostIcon
            postIcon="fa fa-eye"
            onChange={handleOnChangePassword}
          />
        </div>
        {/* Error msg */}
        <div style={{ padding: '24px', color: '#FF6060', textAlign: 'center' }}>
          {showLoginErrorMsg ? 'Incorrect Log In Credentials' : ''}
        </div>
        {/* Forgot password area */}
        <div>
          <div style={{ textAlign: 'center' }}>
            Forgot password or first time logging?
          </div>
          {/* Set/Reset Password HERE */}
          <div
            onClick={props.toggleResetPasswordDialog}
            style={{
              color: '#0080FF',
              textAlign: 'center',
              cursor: 'pointer'
            }}
          >
            Set/Reset Password HERE
          </div>
        </div>
        {/* Signin button */}
        <div style={{ marginTop: '32px' }}>
          <Button
            btnType="primary"
            isDisabled={usernameOrEmail.length === 0 || password.length === 0}
            style={{
              minWidth: `${dialogWidth - 16}px`,
              fontWeight: 'bold',
              borderRadius: '4px'
            }}
            onClick={handleUserSignin}
          >
            Log In to MEDPROS
          </Button>
        </div>
      </div>
    </DialogBox>
  );
};

function mapStateToProps ({ user }) {
  return { ...user };
}

SignInDialog.propTypes = propTypes;
SignInDialog.defaultProps = defaultProps;

export default connect(
  mapStateToProps, {
    initiateSignIn,
    toggleResetPasswordDialog
  })(SignInDialog);
