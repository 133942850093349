import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';

import App from './App';
import './index.css';

const middleware = applyMiddleware(thunk);

ReactDOM.render(
  <Provider store={createStore(reducers, middleware)}>
    <App />
  </Provider>, document.getElementById('root'));
