/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { DataGrid } from 'quantux-ui';
import noPinIcon from '../../imgs/no-pin.svg';
import leftPinIcon from '../../imgs/left-pin.svg';
import rightPinIcon from '../../imgs/right-pin.svg';
import { BASE_URL, history } from '../../utils';

const defaultCellStyle = {
  font: '12px Lato',
  padding: '8px',
  display: 'flex',
  alignItems: 'center',
  borderBottom: '0.5px solid lime',
  borderRight: '0.5px solid lime',
  lineHeight: '14px'
};

const icons = {
  filter: { isSvg: false, path: '', class: 'fa fa-filter' },
  stringSortAscending: { isSvg: false, class: 'fa fa-alpha-asc' },
  stringSortDescending: { isSvg: false, class: 'fa fa-alpha-desc' },
  numberSortAscending: { isSvg: false, class: 'fa fa-numeric-asc' },
  numberSortDescending: { isSvg: false, class: 'fa fa-numeric-desc' },
  dateSortAscending: { isSvg: false, class: 'fa fa-alpha-asc' },
  dateSortDescending: { isSvg: false, class: 'fa fa-alpha-desc' },
  leftPin: { isSvg: true, path: leftPinIcon },
  rightPin: { isSvg: true, path: rightPinIcon },
  noPin: { isSvg: true, path: noPinIcon },
  menuIcon: { isSvg: false, class: 'fa fa-ellipsis-v' }
};

const DOCS_PER_PAGE = 10;

const headerDefs = [
  // Provider
  {
    visible: true,
    dataType: 'string',
    pin: 'left',
    sortable: false,
    sortOrder: 0,
    enableCellClick: true,
    text: 'Provider',
    field: 'provider',
    key: 'provider',
    isCustomFilter: false,
    showFilterHint: false,
    filter: true,
    filterType: 'exactMatch',
    filterMenuType: 'basic', // possible  options => basic, advanced, none
    basicFilterType: 'exactMatch', // possible values are => startsWith, contains, exactMatch, endsWith
    advancedFilterType: 'containsAny', // possible values are => select, containsAll, conainsAny
    disabledFilterTypes: ['startsWith', 'contains', 'endsWith', 'advanced'],
    colStyles: { width: '281px', minWidth: 281, textAlign: 'left' },
    headerCellStyle: {},
    colCellsStyles: { textAlign: 'left' },
    footerCellStyle: {},
    cellRenderer: (text) => {
      const data = text.split('/');
      if (data.length > 2) {
        return (
          <React.Fragment>
            <div style={{ color: '#0080FF', cursor: 'pointer', fontSize: '12px' }}>
              {data[0]}
            </div>
            <div style={{ color: '#0080FF', cursor: 'pointer', fontSize: '12px' }}>
              {`NPI: ${data[1]}`}
            </div>
          </React.Fragment>
        );
      } else {
        return (
          <div style={{ color: '#0080FF', cursor: 'pointer', fontSize: '12px' }}>
            {data[0]}
          </div>
        );
      }
    }
  },
  // Speciality
  // Mailing Address
  {
    visible: true,
    dataType: 'string',
    pin: 'none',
    sortable: false,
    sortOrder: 0,
    enableCellClick: false,
    text: 'Primary Practice Address',
    field: 'mailingAddress',
    key: 'mailingAddress',
    showFilterHint: true,
    isCustomFilter: true,
    filterHint: 'Enter city, state code, or ZIP',
    filter: true,
    filterType: 'exactMatch',
    filterMenuType: 'basic', // possible  options => basic, advanced, none
    basicFilterType: 'exactMatch', // possible values are => startsWith, contains, exactMatch, endsWith
    advancedFilterType: 'containsAny', // possible values are => select, containsAll, conainsAny
    disabledFilterTypes: ['startsWith', 'exactMatch', 'contains', 'endsWith', 'advanced'],
    colStyles: { width: '240px', minWidth: 240, textAlign: 'left' },
    headerCellStyle: {},
    colCellsStyles: { textAlign: 'left' },
    footerCellStyle: {},
    cellRenderer: text => {
      // Format address and zip
      const addTmp = text.split(' ');
      let zip = addTmp.pop();
      zip = zip.length <= 5 ? zip : zip.substr(0, 5) + '-' + zip.substr(5, 9);
      // Generate Google map url
      // const addressUrl = "https://www.google.com/maps/search/?api=1&query=" + encodeURI(text);
      const addressUrl = 'https://www.google.com/maps/search/?api=1&query=' + encodeURI(`${addTmp.join(' ')} ${zip.substr(0, 5)}`);
      return (
        <div className="fx fx__v-center">
          <a
            target="_blank"
            href={addressUrl}
            rel="noreferrer"
            className="mr-2"
            style={{ fontSize: '16px' }}
          >
            <i className="fa fa-map-marker" />
          </a>
          <a
            target="_blank"
            href={addressUrl}
            rel="noreferrer"
            className="mr-2"
          >
            <div>
              {addTmp.join(' ') + ' ' + zip}
            </div>
          </a>
        </div>
      );
    }
  },
  // Phone
  {
    visible: true,
    dataType: 'string',
    pin: 'none',
    sortable: false,
    sortOrder: 0,
    enableCellClick: false,
    text: 'Phone',
    field: 'phone',
    key: 'phone',
    isCustomFilter: false,
    showFilterHint: false,
    filter: false,
    filterType: 'none',
    disabledFilterTypes: ['startsWith', 'exactMatch', 'contains', 'endsWith', 'advanced'],
    filterMenuType: 'advanced',
    basicFilterType: 'contains',
    advancedFilterType: 'containsAny',
    colStyles: { width: '123px', minWidth: 123, textAlign: 'left' },
    headerCellStyle: {},
    colCellsStyles: { textAlign: 'left' },
    footerCellStyle: {},
    cellRenderer: text => (
      <div>
        <a
          href={`tel:+1${text}`}
        >
          {`${text.substr(0, 3)}-${text.substr(3, 3)}-${text.substr(6, 4)}`}
        </a>
      </div>
    )
  },
  // Access Type
  {
    visible: true,
    dataType: 'string',
    pin: 'none',
    sortable: false,
    sortOrder: 0,
    enableCellClick: false,
    text: 'Access Type',
    field: 'accessType',
    key: 'accessType',
    isCustomFilter: false,
    showFilterHint: false,
    filter: false,
    filterType: 'none',
    disabledFilterTypes: ['startsWith', 'exactMatch', 'contains', 'endsWith', 'advanced'],
    filterMenuType: 'advanced',
    basicFilterType: 'contains',
    advancedFilterType: 'containsAny',
    colStyles: { width: '160px', minWidth: 160, textAlign: 'left' },
    headerCellStyle: {},
    colCellsStyles: { textAlign: 'left' },
    footerCellStyle: {}
  }
];

const PracticeProvidersGrid = (props) => {
  const [showLoader, setShowLoader] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [filters, setFilters] = useState({});
  const [docsCount, setDocsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // Effects
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setShowLoader(true);
    const practiceOwners = [];
    props.owners.forEach((el) => {
      practiceOwners.push(el.user);
    });

    const url = new URL(`${BASE_URL}/users/getPracticeProviders`);
    const params = { ownerIds: practiceOwners };
    url.search = new URLSearchParams(params).toString();

    fetch(url)
      .then(response => response.text())
      .then(result => {
        const data = [];
        const reqData = JSON.parse(result);
        for (let i = 0; i < reqData.providers.length; i++) {
          const activeRecord = reqData.providers[i];
          const add1 = activeRecord.mailingAddress.streetAddress1;
          const suite = ''; // activeRecord.mailingAddress.suite;
          const city = activeRecord.mailingAddress.city;
          const state = activeRecord.mailingAddress.state;
          const zip = activeRecord.mailingAddress.zip;
          let accessType = '';
          activeRecord.practices.forEach((el) => {
            if (el.practice === props.practice._id) {
              accessType = el.accessType
            }
          });

          data.push({
            id: activeRecord._id,
            provider: !activeRecord.npi
              ? `${activeRecord.lastName}, ${activeRecord.firstName}/${activeRecord._id}`
              : `${activeRecord.lastName}, ${activeRecord.firstName}/${activeRecord.npi}/${activeRecord._id}`,
            mailingAddress: `${add1} ${suite} ${city}, ${state} ${zip}`,
            phone: activeRecord.phone,
            accessType: accessType
          });
        }
        setGridData(data);
        setShowLoader(false);
      })
      .catch(error => console.log('error', error));
  };

  const handleOnClickCell = (col, value) => {
    console.log(value)
    localStorage.setItem('provider', value);
    history.push('/provider');
  };

  return (
    <div style={{ width: '804px' }}>
      <DataGrid
        loader={showLoader}
        icons={icons}
        headerDefs={headerDefs}
        rowData={gridData}
        filters={filters}
        gridWidth={804}
        gridHeight={300}
        gridHeaderHeight={40}
        gridFooterHeight={0}
        gridContainerHeight="804px"
        rowHeight={40}
        rowsPerPage={DOCS_PER_PAGE}
        defaultCellStyle={defaultCellStyle}
        showFooter={false}
        showEllipsisCol
        enableHover
        totalPages={Math.ceil(docsCount / DOCS_PER_PAGE)}
        currentPage={currentPage}
        onApplyFilter={() => console.log('not implemented yet!')}
        onResetCol={() => console.log('not implemented yet!')}
        onLoadPage={() => console.log('not implemented yet!')}
        onClickCell={handleOnClickCell}
        onColFilterToggle={() => console.log('not implemented yet!')}
        onFilterTypeUpdate={() => console.log('not implemented yet!')}
        onColPinChange={() => console.log('not implemented yet!')}
        onResize={() => console.log('on resize')}
        onPopupMenu={() => console.log('On popup menu')}
        onChangeTextAlignment={() => console.log('Text alignment changed!')}
        onFilterChange={() => console.log('on filter changed')}
        onColVisibilityChange={() => console.log('on col visibility changed')}
        onResetGrid={() => console.log('on reset grid')}
        onSort={() => { console.log('Column sort order changed!'); }}
        onColConfig={() => console.log('on col config changed')}
      />
    </div>
  );
};

export default PracticeProvidersGrid;
