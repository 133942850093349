import React from 'react';
import { connect } from 'react-redux';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Link } from 'react-router-dom';
import { logout } from '../../actions';
import UserCard from '../user-card';
import { gray } from 'quantux-ui';

const styles = StyleSheet.create({
  userCard: {
    background: '#083050',
    width: '302px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'
  }
});

const UserMenu = (props) => {
  return (
    <div className={`p2 ${css(styles.userCard)}`}>
      {/* User card */}
      <UserCard
        userHandle={`@${props.usr ? props.usr.userName : ''}`}
        displayName={props.usr ? props.usr.displayName : ''}
      />
      {/* Security & Settings */}
      <Link to='/settings/loginCredentials' style={{ color: gray[400] }}>
        <div
          className="m-item"
          style={{
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer'
          }}
        >
          <div
            className="fx fx__hv-center"
            style={{
              height: '40px',
              width: '40px'
            }}
          >
            <i style={{ fontSize: '24px' }} className="fa fa-cog" />
          </div>
          <div>Security &amp; Settings</div>
        </div>
      </Link>
      {/* Contact */}
      <div
        className="m-item"
        style={{
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid #001824',
          cursor: 'pointer'
        }}
      >
        <div
          className="fx fx__hv-center"
          style={{
            height: '40px',
            width: '40px'
          }}
        >
          <i style={{ fontSize: '24px' }} className="fa fa-headphones" />
        </div>
        <div>Contact</div>
      </div>
      {/* Logout */}
      <div
        onClick={() => props.logout()}
        className="m-item"
        style={{
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          borderBottom: '1px solid #001824',
          cursor: 'pointer'
        }}
      >
        <div
          className="fx fx__hv-center"
          style={{
            height: '40px',
            width: '40px'
          }}
        >
          <i style={{ fontSize: '24px' }} className="fa fa-sign-out" />
        </div>
        <div>Logout</div>
      </div>
    </div>
  );
};

function mapStateToProps({ user }) {
  return { ...user };
}

export default connect(
  mapStateToProps, {
    logout
  })(UserMenu);
