import React, { useState } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavBrand, Navbar, PopupMenu, Toast, blue, salmon } from 'quantux-ui';
import SiteMap from '../site-map';
import NotificationsDrawer from '../notifications-drawer';
import UserMenu from '../menus/user-menu';
import {
  toggleUserSigninDialog,
  isUserSignedIn,
  toggleResetPasswordDialog,
  hideAlert,
  getNotifications,
  setNotificationsCount
} from '../../actions';
import medprosLogoSmall from '../../imgs/medProsLogoSmall.svg';
import medprosLogoLarge from '../../imgs/medProsLogoLarge.svg';

const styles = StyleSheet.create({
  navbarBrandImage: {
    minHeight: '24px'
  },
  navbarBrandImageLarge: {
    // display: isMobileScreen ? 'none' : 'block'
    display: 'none'
  },
  navbarBrandImageSmall: {
    // display: isMobileScreen ? 'block' : ''
    display: 'block'
  },
  navIcons: {
    fontSize: '24px'
  }
});

const NavbarClone = (props) => {
  const [showSiteMap, toggleSiteMap] = useState(false);
  const [showNotificationsDrawer, toggleNotificationsDrawer] = useState(false);
  const [isUserMenuVisible, setUserMenuVisibility] = useState(false);

  // Refs
  const userIcon = React.createRef();

  return (
    <div
      style={{ fontSize: '16px', position: 'relative' }}
    >
      {/* Site Map */}
      <SiteMap
        showSiteMap={showSiteMap}
        closeSiteMap={() => toggleSiteMap(false)}
      />

      {/* Notificatiosn drawer */}
      {showNotificationsDrawer
        ? <NotificationsDrawer
            showNotificationsDrawer={showNotificationsDrawer}
            closeNotificationsDrawer={() => toggleNotificationsDrawer(false)}
          />
        : null
      }

      <PopupMenu
        top={41}
        right={0}
        visible={isUserMenuVisible}
        parent={userIcon}
        onOutsideClick={() => { setUserMenuVisibility(false); }}
        zIndex='2500'
        position="fixed"
      >
        <UserMenu />
      </PopupMenu>
      <Navbar
        className='sticky-top-nav'
      >
        <NavBrand>
          <Link to="/">
            <div className="fx">
              <img
                alt="Quantux Logo Small"
                src={medprosLogoSmall}
                className={css(styles.navbarBrandImage, styles.navbarBrandImageSmall)}
              />
              <div
                className="blue-text"
                style={{
                  marginLeft: '4px',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <img
                  alt="Quantux Logo Large"
                  src={medprosLogoLarge}
                  className={css(styles.navbarBrandImage, styles.navbarBrandImageLarge)}
                />
              </div>
            </div>
          </Link>
        </NavBrand>
        <Nav>
          {/* Navbar sitemap */}
          <NavItem>
            <i
              className={`fa fa-bars ${css(styles.navIcons)}`}
              onClick={() => toggleSiteMap(!showSiteMap)}
            />
          </NavItem>

          {/* Navbar Search */}
          <NavItem>
            <Link to='/search'>
              <i
                className={`fa fa-search ${css(styles.navIcons)}`}
                style={{ color: '#355d8e' }}
              />
            </Link>
          </NavItem>

          {/* Chat messages */}
          {props.isSignedIn
            ? <NavItem>
              <Link
                to='/chat'
                onClick={() => console.log('Clear chat badge!')}
              >
                <i
                  className={`fa fa-comments ${css(styles.navIcons)}`}
                  style={{ color: '#355d8e' }}
                />
                {props.showChatIconBadge
                  ? <i
                    className='fa fa-exclamation-circle'
                    style={{
                      position: 'relative',
                      top: '-7px',
                      left: '-12px',
                      fontSize: '16px',
                      color: salmon[500],
                      borderRadius: '30%',
                      cursor: 'pointer',
                      minWidth: '12px',
                      textAlign: 'center'
                    }}
                  />
                  : null
                }
              </Link>
            </NavItem>
            : null
          }

          {/* Navbar Notifications */}
          {props.isSignedIn
            ? <NavItem>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingLeft: props.notificationsCount > 0 ? '9px' : ''
                }}
              >
                <i
                  className={`fa fa-bell ${css(styles.navIcons)}`}
                  onClick={() => {
                    props.setNotificationsCount(0);
                    toggleNotificationsDrawer(!showNotificationsDrawer);
                  }}
                />
                {props.notificationsCount && props.notificationsCount > 0
                  ? <span
                    onClick={() => {
                      props.setNotificationsCount(0);
                      toggleNotificationsDrawer(!showNotificationsDrawer);
                    }}
                    style={{
                      position: 'relative',
                      top: '-7px',
                      left: '-12px',
                      fontSize: '10px',
                      background: blue[500],
                      color: 'white',
                      borderRadius: '30%',
                      cursor: 'pointer',
                      minWidth: '12px',
                      textAlign: 'center'
                    }}
                  >
                    {props.notificationsCount}
                  </span>
                  : null
                }
              </div>
            </NavItem>
            : null
          }

          {/* User icon and menu */}
          <NavItem onClick={() => { setUserMenuVisibility(true); }}>
            <div
              ref={userIcon}
              style={{ marginTop: '7px', marginRight: '4px' }}
            >
              {props.usr && props.usr.avatarImageUrl
                ? <img
                  src={props.usr.avatarImageUrl}
                  alt="avatar"
                  style={{
                    width: 'auto',
                    height: '32px',
                    maxHeight: '32px',
                    borderRadius: '50%'
                  }}
                />
                : <i className={`fa fa-user-circle ${css(styles.navIcons)}`} />
              }
            </div>
          </NavItem>
        </Nav>
      </Navbar>
    </div>
  );
};

function mapStateToProps({ user }) {
  return { ...user };
}

export default connect(
  mapStateToProps, {
    toggleUserSigninDialog,
    isUserSignedIn,
    toggleResetPasswordDialog,
    hideAlert,
    getNotifications,
    setNotificationsCount
  })(NavbarClone);
