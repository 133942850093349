/* eslint-disable react/display-name */
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { css, StyleSheet } from 'aphrodite/no-important';
import debouce from 'lodash.debounce';
import { Cropper } from 'react-cropper';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import {
  Button,
  Checkbox,
  DataGrid,
  Drawer,
  PopupMenu,
  TextInputWithLabel,
  Loader,
  FileUploader,
  blue,
  gray,
  salmon
} from 'quantux-ui';
import noPinIcon from '../../imgs/no-pin.svg';
import leftPinIcon from '../../imgs/left-pin.svg';
import rightPinIcon from '../../imgs/right-pin.svg';
import { BASE_URL, titleCase, history } from '../../utils';

const DOCS_PER_PAGE = 10;
const defaultCellStyle = {
  font: '12px Lato',
  padding: '8px',
  display: 'flex',
  alignItems: 'center',
  borderBottom: '0.5px solid lime',
  borderRight: '0.5px solid lime',
  lineHeight: '14px'
};
const icons = {
  filter: { isSvg: false, path: '', class: 'fa fa-filter' },
  stringSortAscending: { isSvg: false, class: 'fa fa-alpha-asc' },
  stringSortDescending: { isSvg: false, class: 'fa fa-alpha-desc' },
  numberSortAscending: { isSvg: false, class: 'fa fa-numeric-asc' },
  numberSortDescending: { isSvg: false, class: 'fa fa-numeric-desc' },
  dateSortAscending: { isSvg: false, class: 'fa fa-alpha-asc' },
  dateSortDescending: { isSvg: false, class: 'fa fa-alpha-desc' },
  leftPin: { isSvg: true, path: leftPinIcon },
  rightPin: { isSvg: true, path: rightPinIcon },
  noPin: { isSvg: true, path: noPinIcon },
  menuIcon: { isSvg: false, class: 'fa fa-ellipsis-v' }
};
const headerDefs = [
  // Name (First, middle and last)
  {
    visible: true,
    dataType: 'string',
    pin: 'left',
    sortable: false,
    sortOrder: 0,
    enableCellClick: true,
    text: 'Name',
    field: 'name',
    key: 'name',

    isCustomFilter: false,
    showFilterHint: false,
    filter: true,
    filterType: 'exactMatch',
    filterMenuType: 'basic', // possible  options => basic, advanced, none
    basicFilterType: 'exactMatch', // possible values are => startsWith, contains, exactMatch, endsWith
    advancedFilterType: 'containsAny', // possible values are => select, containsAll, conainsAny
    disabledFilterTypes: ['startsWith', 'contains', 'endsWith', 'advanced'],
    colStyles: { width: '230px', minWidth: 230, textAlign: 'left' },
    headerCellStyle: {},
    colCellsStyles: { textAlign: 'left' },
    footerCellStyle: {},
    cellRenderer: (text) => {
      const cData = text.split('|');
      const data = cData[0].split('/');
      if (data.length > 2) {
        return (
          <div className='fx'>
            <div>
              <img
                src={cData[1]}
                alt='user avatar'
                style={{ maxHeight: '48px' }}
              />
            </div>
            <div
              className='ml-2'
              style={{
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center'
              }}
            >
              <div style={{ color: '#0080FF', cursor: 'pointer' }}>{data[0]}</div>
              <div style={{ color: '#0080FF', cursor: 'pointer' }}>{data[1]}</div>
            </div>
          </div>
        );
      } else {
        return (
          <div
            style={{
              display: 'flex',
              color: blue[500],
              cursor: 'pointer'
            }}
          >
            <div>
              <img
                src={cData[1]}
                alt='user avatar'
                style={{ maxHeight: '48px' }}
              />
            </div>
            <div
              className='ml-2'
              style={{
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center'
              }}
            >
              {data[0]}
            </div>
          </div>
        );
      }
    }
  },
  // Credentials
  {
    visible: true,
    dataType: 'string',
    pin: 'none',
    sortable: true,
    sortOrder: 0,
    enableCellClick: false,
    text: 'Credentials',
    field: 'credentials',
    key: 'credentials',
    isCustomFilter: false,
    showFilterHint: false,
    filter: false,
    filterType: 'exactMatch',
    filterMenuType: 'basic', // possible  options => basic, advanced, none
    basicFilterType: 'contains', // possible values are => startsWith, contains, exactMatch, endsWith
    advancedFilterType: 'containsAny', // possible values are => select, containsAll, conainsAny
    disabledFilterTypes: ['startsWith', 'endsWith', 'advanced'],
    colStyles: { width: '123px', minWidth: 123, textAlign: 'left' },
    headerCellStyle: {},
    colCellsStyles: { textAlign: 'left' },
    footerCellStyle: {}
  },
  // Speciality
  {
    visible: true,
    dataType: 'string',
    pin: 'none',
    sortable: true,
    sortOrder: 1,
    enableCellClick: false,
    text: 'Specialty',
    field: 'specialty',
    key: 'specialty',
    isCustomFilter: false,
    showFilterHint: false,
    filter: false,
    filterType: 'exactMatch',
    filterMenuType: 'basic', // possible  options => basic, advanced, none
    basicFilterType: 'exactMatch', // possible values are => startsWith, contains, exactMatch, endsWith
    advancedFilterType: 'containsAny', // possible values are => select, containsAll, conainsAny
    disabledFilterTypes: ['startsWith', 'endsWith', 'advanced'],
    colStyles: { width: '123px', minWidth: 123, textAlign: 'left' },
    headerCellStyle: {},
    colCellsStyles: { textAlign: 'left' },
    footerCellStyle: {}
  },
  // Mailing address ()
  {
    visible: true,
    dataType: 'string',
    pin: 'none',
    sortable: false,
    sortOrder: 0,
    enableCellClick: false,
    text: 'Primary Practice Address',
    field: 'mailingAddress',
    key: 'mailingAddress',
    showFilterHint: true,
    isCustomFilter: true,
    filterHint: 'Enter city, state code, or ZIP',
    filter: false,
    filterType: 'exactMatch',
    filterMenuType: 'basic', // possible  options => basic, advanced, none
    basicFilterType: 'exactMatch', // possible values are => startsWith, contains, exactMatch, endsWith
    advancedFilterType: 'containsAny', // possible values are => select, containsAll, conainsAny
    disabledFilterTypes: ['startsWith', 'exactMatch', 'contains', 'endsWith', 'advanced'],
    colStyles: { width: '250px', minWidth: 250, textAlign: 'left' },
    headerCellStyle: {},
    colCellsStyles: { textAlign: 'left' },
    footerCellStyle: {},
    cellRenderer: text => {
      // Format address and zip
      const addTmp = text.split(' ');
      let zip = addTmp.pop();
      zip = zip.length <= 5 ? zip : zip.substr(0, 5) + '-' + zip.substr(5, 9);
      // Generate Google map url
      // const addressUrl = "https://www.google.com/maps/search/?api=1&query=" + encodeURI(text);
      const addressUrl = 'https://www.google.com/maps/search/?api=1&query=' + encodeURI(`${addTmp.join(' ')} ${zip.substr(0, 5)}`);
      return (
        <div className="fx fx__v-center">
          <a
            target="_blank"
            href={addressUrl}
            rel="noreferrer"
            className="mr-2"
            style={{ fontSize: '16px' }}
          >
            <i className="fa fa-map-marker" />
          </a>
          <a
            target="_blank"
            href={addressUrl}
            rel="noreferrer"
            className="mr-2"
          >
            <div>
              {addTmp.join(' ') + ' ' + zip}
            </div>
          </a>
        </div>
      );
    }
  },
  // Mobile (mobile)
  {
    visible: true,
    dataType: 'string',
    pin: 'none',
    sortable: false,
    sortOrder: 0,
    enableCellClick: false,
    text: 'Mobile',
    field: 'mobile',
    key: 'mobile',
    isCustomFilter: false,
    showFilterHint: false,
    filter: false,
    filterType: 'none',
    disabledFilterTypes: ['startsWith', 'exactMatch', 'contains', 'endsWith', 'advanced'],
    filterMenuType: 'advanced',
    basicFilterType: 'contains',
    advancedFilterType: 'containsAny',
    colStyles: { width: '123px', minWidth: 123, textAlign: 'left' },
    headerCellStyle: {},
    colCellsStyles: { textAlign: 'left' },
    footerCellStyle: {},
    cellRenderer: text => (
      <div>
        <a
          href={`tel:+1${text}`}
        >
          {`${text.substr(0, 3)}-${text.substr(3, 3)}-${text.substr(6, 4)}`}
        </a>
      </div>
    )
  },
  // Last updated
  {
    visible: true,
    dataType: 'date',
    pin: 'none',
    sortable: false,
    sortOrder: 0,
    enableCellClick: false,
    text: 'Last Updated',
    field: 'lastUpdate',
    key: 'lastUpdated',
    isCustomFilter: false,
    showFilterHint: false,
    filter: false,
    filterType: 'none',
    disabledFilterTypes: ['startsWith', 'exactMatch', 'contains', 'endsWith', 'advanced'],
    filterMenuType: 'advanced',
    basicFilterType: 'contains',
    advancedFilterType: 'containsAny',
    colStyles: { width: '123px', minWidth: 123, textAlign: 'left' },
    headerCellStyle: {},
    colCellsStyles: { textAlign: 'left' },
    footerCellStyle: {}
  }
];

const UsersDataGrid = (props) => {
  // #region useState Variables
  const isFirstRender = useRef(true);
  const [showLoader, setShowLoader] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [filters, setFilters] = useState({});
  const [sorting, setSorting] = useState({});
  const [docsCount, setDocsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [user, setUser] = useState('');
  const [showAddUserMsg, setShowAddUserMsg] = useState(false);
  const [searchingUsers, setSearchingUsers] = useState(false);
  const [userSearchResults, setUserSearchResults] = useState([]);
  // For add user/provider drawers and copper
  const [isBannerCropperDrawerOpen, toggleBannerCropperDrawer] = useState(false);
  const [isAvatarCropperDrawerOpen, toggleAvatarCropperDrawer] = useState(false);
  const [isAddUserDrawerVisible, toggleAddUserDrawer] = useState(false);
  const [isProvider, setIsProvider] = useState(true);
  const [providerFullName, setProviderFullName] = useState('');
  const [providerCredentials, setProviderCredentials] = useState('');
  const [providerSpeciality, setProviderSpeciality] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [isDisplayNameValid, checkDisplayNameValidity] = useState(false);
  const [isDisplayNamePristine, setDisplayNamePristineState] = useState(true);
  const [userName, setUserName] = useState('');
  const [isUserNameValid, checkUserNameValidity] = useState(false);
  const [isUserNamePristine, setUserNamePristineState] = useState(true);
  const [isUserNameDuplicate, setUserNameDuplicateStatus] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [suite, setSuite] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');
  const [phone, setPhone] = useState('');
  const [isPhoneValid, checkPhoneValidity] = useState(false);
  const [isPhonePristine, setPhonePristineState] = useState(true);
  const [fax, setFax] = useState('');
  const [profileDescription, setProfileDescription] = useState('');
  const [bannerImageUrl, setBannerImageUrl] = useState('https://quantumtrader.s3.amazonaws.com/defaultBanner.svg');
  const [avatarImageUrl, setAvatarImageUrl] = useState('https://quantumtrader.s3.amazonaws.com/default-user-avatar.svg');
  const [showBannerCropper, setShowBannerCropper] = useState(false);
  const [isBannerUploading, setIsBannerUploading] = useState(false);
  const [showAvatarCropper, setShowAvatarCropper] = useState(false);
  const [isAvatarUploading, setIsAvatarUploading] = useState(false);
  const [email, setEmail] = useState('');
  const [isEmailValid, checkEmailValidity] = useState(false);
  const [isEmailPristine, setEmailPristineState] = useState(true);
  const [isEmailDuplicate, setEmailDuplicateStatus] = useState(true);
  const [mobile, setMobile] = useState('');
  const [isMobileValid, checkMobileValidity] = useState(false);
  const [isMobilePristine, setMobilePristineState] = useState(true);
  const [isMobileDuplicate, setMobileDuplicateStatus] = useState(true);
  const [npi, setNpi] = useState('');
  const [website, setWebsite] = useState('');
  const [isLastNameSearchMenuOpen, toggleLastNameSearchMenu] = useState(false);
  const [lastNameSearchResults, setLastNameSearchResults] = useState([]);
  const [canAddUser, setCanAddUser] = useState(false);
  const [showGridEllipsisMenu, toggleGridEllipsisMenu] = useState(false);
  const [cursorX, setCursorX] = useState(0);
  const [cursorY, setCursorY] = useState(0);
  const [popupMenuIndex, setPopupMenuIndex] = useState(-1);
  // #endregion

  // Styles
  const styles = StyleSheet.create({
    gridParent: {
      overflow: 'hidden'
    },
    gridContainer: {
      maxWidth: '914px',
      overflow: 'hidden'
    },
    gridToolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      height: '52px',
      border: '1px solid black',
      paddingLeft: '4px',
      background: gray[500]
    },
    gridToolbarIcon: {
      height: '50px',
      width: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      ':hover': {
        color: blue[500]
      }
    },
    usersSearchMenu: {
      width: '350px',
      background: gray[700],
      overflow: 'hidden'
    },
    drawerContainer: {
      background: gray[500],
      color: gray[300],
      height: '100%',
      position: 'relative',
      display: 'flex',
      flexFlow: 'column'
    },
    drawerHeader: {
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 8px',
      fontWeight: 'bold',
      borderBottom: '1px solid black',
      flexShrink: '0'
    },
    drawerHeaderCloseIcon: {
      color: gray[300],
      ':hover': {
        color: salmon[500]
      }
    },
    drawerBanner: {
      position: 'relative',
      padding: '8px 0 0 2px',
      flexShrink: '0'
    },
    drawerBannerImg: {
      width: '100%',
      height: 'auto'
    },
    drawerBannerCameraIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: gray[500],
      color: 'white',
      position: 'absolute',
      right: '0',
      bottom: '8px',
      fontSize: '11px',
      width: '24px',
      height: '24px',
      cursor: 'pointer',
      borderRadius: '50%'
    },
    drawerContentArea: {
      // height: 'calc(100vh - 186px)',
      overflowY: 'auto',
      color: gray[300],
      flexGrow: '1'
    },
    drawerUserCardAvatar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '80px',
      height: '80px',
      color: 'white',
      borderRadius: '4px',
      position: 'relative'
    },
    drawerUserCardAvatarImg: {
      width: 'auto',
      height: '80px',
      maxHeight: '80px'
    },
    drawerAvatarCamera: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: gray[500],
      color: 'white',
      position: 'absolute',
      left: '-3px',
      top: '58px',
      fontSize: '11px',
      width: '24px',
      height: '24px',
      cursor: 'pointer',
      borderRadius: '50%'
    },
    drawerUserCardText: {
      fontSize: '12px'
    },
    profileTextAreaContainer: {
      paddingRight: '8px'
    },
    profileTextArea: {
      width: '100%',
      background: gray[900],
      height: '100px',
      color: gray[100],
      border: 'none',
      resize: 'none',
      overflow: 'auto',
      fontFamily: 'inherit',
      ':focus': {
        outlineColor: 'transparent',
        outlineStyle: 'none'
      }
    },
    drawerFooter: {
      alignItems: 'center',
      justifyContent: 'flex-end',
      background: gray[700],
      height: '56px',
      width: '100%',
      borderTop: `1px solid ${gray[500]}`,
      flexShrink: '0'
    },
    imageUploaderContainer: {
      position: 'relative',
      maxHeight: '454px'
    },
    imageUploadLoader: {
      position: 'relative',
      height: '454px',
      top: '-400px',
      background: gray[800]
    },
    criticalSmallText: {
      fontSize: '12px',
      color: salmon[500],
      paddingLeft: '8px',
      fontWeight: 'bold',
      width: '140px'
    },
    userSearchItems: {
      cursor: 'pointer',
      ':hover': {
        background: gray[800]
      }
    },
    userSearchItemsIcon: {
      width: '40px',
      height: '40px',
      background: blue[500],
      color: 'white',
      fontSize: '28px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '5px'
    },
    errMsg: {
      color: '#FF6060',
      fontSize: '12px'
    },
    gridEllipsisMenuParent: {
      position: 'fixed',
      height: '64px',
      width: '140px',
      background: 'black',
      zIndex: '100'
    },
    gridEllipsisMenuContainer: {
      minWidth: '140px'
    },
    gridEllipsisMenuItem: {
      display: 'flex',
      alignItems: 'center',
      height: '32px',
      width: '138px',
      paddingLeft: '8px',
      background: '#103A60',
      ':hover': {
        background: '#0066CC'
      }
    }
  });

  // Effects
  useEffect(() => {
    getData();
  }, []);

  // NPPES and Medpors profiles search by npi
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const ddF = setTimeout(() => {
      searchUsersByNpi();
    }, 300);
    return () => clearTimeout(ddF);
  }, [npi]);

  // Check for duplicate username
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (userName.length >= 2 && userName.length <= 20 && userName.indexOf(' ') < 0) {
      // console.log('Checking for duplicate username', userName);
      const url = new URL(`${BASE_URL}/users/exists/username/${userName}`);
      fetch(url)
        .then(res => res.text())
        .then((result) => {
          const data = JSON.parse(result);
          // console.log(data);
          setUserNameDuplicateStatus(data.isDuplicate);
        })
        .catch(error => console.log('error', error));
    }
    // const ddF = setTimeout(() => { searchUsersByLastName(); }, 300);
    // return () => clearTimeout(ddF);
  }, [userName]);

  // Check for duplicate email
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (isEmailValid) {
      const url = new URL(`${BASE_URL}/users/exists/email/${email}`);
      fetch(url)
        .then(res => res.text())
        .then((result) => {
          const data = JSON.parse(result);
          // console.log(data);
          setEmailDuplicateStatus(data.isDuplicate);
        })
        .catch(error => console.log('error', error));
    }
    // const ddF = setTimeout(() => { searchUsersByLastName(); }, 300);
    // return () => clearTimeout(ddF);
  }, [email]);

  // Check for duplicate mobile
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (isMobileValid) {
      const url = new URL(`${BASE_URL}/users/exists/mobile/${mobile}`);
      fetch(url)
        .then(res => res.text())
        .then((result) => {
          const data = JSON.parse(result);
          // console.log(data);
          setMobileDuplicateStatus(data.isDuplicate);
        })
        .catch(error => console.log('error', error));
    }
    // const ddF = setTimeout(() => { searchUsersByLastName(); }, 300);
    // return () => clearTimeout(ddF);
  }, [mobile]);

  // Can add user flag check
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    // console.log('lastName.length', lastName.length);
    // console.log('firstName.length', lastName.length);
    // console.log('displayName', displayName.length >= 2 && displayName.length <= 40);
    // console.log('userName', userName.length >= 2 && userName.length <= 20 && userName.indexOf(' ') < 0);
    // console.log('!isUserNameDuplicate', !isUserNameDuplicate);
    // console.log('isEmailValid', isEmailValid);
    // console.log('!isEmailDuplicate', !isEmailDuplicate);
    // console.log('isMobileValid', isMobileValid);
    // console.log('!isMobileDuplicate', !isMobileDuplicate);
    // console.log('isPhoneValid', isPhoneValid);

    const value =
      firstName.length > 2 &&
      lastName.length > 2 &&
      displayName.length >= 2 && displayName.length <= 40 &&
      userName.length >= 2 && userName.length <= 20 && userName.indexOf(' ') < 0 &&
      !isUserNameDuplicate &&
      streetAddress.length > 5 &&
      city.length >= 2 &&
      state.length === 2 &&
      zip.length >= 5 &&
      isEmailValid &&
      !isEmailDuplicate &&
      isMobileValid &&
      !isMobileDuplicate &&
      isPhoneValid;
    // console.log('Checking for canAddProvider ... ', value);
    setCanAddUser(value);
  }, [
    firstName,
    lastName,
    displayName,
    userName,
    isUserNameDuplicate,
    streetAddress,
    suite,
    city,
    state,
    zip,
    email,
    isEmailValid,
    isEmailDuplicate,
    mobile,
    isMobileValid,
    isMobileDuplicate,
    phone,
    isPhoneValid
  ]);

  const getData = () => {
    setShowLoader(true);
    const formData = new FormData();
    formData.append('skip', (currentPage - 1) * DOCS_PER_PAGE);
    formData.append('limit', DOCS_PER_PAGE);
    formData.append('filters', JSON.stringify(filters));
    formData.append('sorting', JSON.stringify(sorting));

    const reqHeaders = new Headers();
    const token = localStorage.getItem('token');
    reqHeaders.append('Authorization', `Bearer ${token}`);
    const requestOptions = {
      method: 'POST',
      body: formData,
      redirect: 'follow',
      headers: reqHeaders
    };

    fetch(`${BASE_URL}/users/getUsers`, requestOptions)
      .then(response => response.text())
      .then((result) => {
        const data = [];
        const reqData = JSON.parse(result);
        // console.log('reqData.users', reqData.users);
        for (let i = 0; i < reqData.users.length; i++) {
          const activeRecord = reqData.users[i];
          const add1 = activeRecord.mailingAddress.streetAddress1;
          const add2 = activeRecord.mailingAddress.streetAddress2 ? activeRecord.mailingAddress.streetAddress2 : '';
          const suite = activeRecord.mailingAddress.suite ? activeRecord.mailingAddress.suite : '';
          const city = activeRecord.mailingAddress.city;
          const state = activeRecord.mailingAddress.state;
          const zip = activeRecord.mailingAddress.zip;
          const avatarUrl = activeRecord.avatarImageUrl;
          const credentials = activeRecord.credentials ? activeRecord.credentials : 'N/A';
          const specialty = activeRecord.specialty ? activeRecord.specialty : 'N/A';

          data.push({
            id: activeRecord._id,
            name: !activeRecord.npi
              ? `${activeRecord.lastName}, ${activeRecord.firstName}/${activeRecord._id}|${avatarUrl}`
              : `${activeRecord.lastName}, ${activeRecord.firstName}/${activeRecord.npi}/${activeRecord._id}|${avatarUrl}`,
            credentials,
            specialty,
            mailingAddress: `${add1} ${add2} ${suite} ${city}, ${state} ${zip}`,
            mobile: activeRecord.mobile,
            lastUpdate: activeRecord.lastUpdate ? activeRecord.lastUpdate : (new Date()).toLocaleDateString()
          });
        }
        setGridData(data);
        setShowLoader(false);
      })
      .catch(error => console.log('error', error));
  };

  const searchUsers = () => {
    setShowAddUserMsg(false);
    setSearchingUsers(true);

    const url = new URL(`${BASE_URL}/users/getUsers`);
    const params = { searchTerm: user };
    url.search = new URLSearchParams(params).toString();
    fetch(url)
      .then(res => res.text())
      .then((result) => {
        const data = JSON.parse(result);
        setUserSearchResults(data.users);
        setSearchingUsers(false);
        setShowAddUserMsg(data.users && data.users.length === 0);
      })
      .catch(error => console.log('error', error));
  };

  const getUniqueUsername = (firstName, lastName) => {
    const url = new URL(`${BASE_URL}/users/getUsername`);
    const params = { firstName: firstName.charAt(0), lastName };
    url.search = new URLSearchParams(params).toString();
    fetch(url)
      .then(res => res.text())
      .then((result) => {
        const data = JSON.parse(result);
        // console.log(data);
        checkUserNameValidity(data.userName.length >= 2 && data.userName <= 20);
        setUserName(data.userName);
      })
      .catch(error => console.log('error', error));
  };

  function searchUsersByLastName() {
    const url = new URL(`${BASE_URL}/users/searchAll`);
    const params = { searchTerm: lastName };
    url.search = new URLSearchParams(params).toString();
    fetch(url)
      .then(res => res.text())
      .then((result) => {
        const data = JSON.parse(result);
        // console.log(data);
        setLastNameSearchResults(data.users);
      })
      .catch(error => console.log('error', error));
  };

  function searchUsersByNpi() {
    const url = new URL(`${BASE_URL}/users/searchAllNpi`);
    const params = { searchTerm: npi };
    url.search = new URLSearchParams(params).toString();
    fetch(url)
      .then(res => res.text())
      .then((result) => {
        const data = JSON.parse(result);
        // console.log(data);
        setLastNameSearchResults(data.users);
      })
      .catch(error => console.log('error', error));
  };

  // Reset state when Add user drawer is closed
  const resetState = () => {
    toggleBannerCropperDrawer(false);
    toggleAvatarCropperDrawer(false);
    setDisplayName('');
    checkDisplayNameValidity(false);
    setDisplayNamePristineState(true);
    setUserName('');
    checkUserNameValidity(false);
    setUserNamePristineState(true);
    setUserNameDuplicateStatus(true);
    setFirstName('');
    setMiddleName('');
    setLastName('');
    setStreetAddress('');
    setSuite('');
    setCity('');
    setState('');
    setZip('');
    setPhone('');
    checkPhoneValidity(false);
    setPhonePristineState(true);
    setFax('');
    setProfileDescription('');
    setBannerImageUrl('https://quantumtrader.s3.amazonaws.com/defaultBanner.svg');
    // setAvatarImageUrl('https://quantumtrader.s3.amazonaws.com/defaultAvatar.svg');
    setAvatarImageUrl('https://quantumtrader.s3.amazonaws.com/default-user-avatar.svg');
    setShowBannerCropper(false);
    setIsBannerUploading(false);
    setShowAvatarCropper(false);
    setIsAvatarUploading(false);
    setEmail('');
    checkEmailValidity(false);
    setEmailPristineState(true);
    setEmailDuplicateStatus(true);
    setMobile('');
    checkMobileValidity(false);
    setMobilePristineState(true);
    setMobileDuplicateStatus(true);
    setNpi('');
    setWebsite('');
    toggleLastNameSearchMenu(false);
    setLastNameSearchResults([]);
    setCanAddUser(false);
    setIsProvider(true);
    setProviderFullName('');
    setProviderCredentials('');
    setProviderSpeciality('');
  };

  // Select search items from the last name search menu
  const selectUser = (data) => {
    if (data.userName) {
      console.log('User already exists');
      return;
    }
    console.log(data);
    const { number = '', basic, taxonomies } = data;
    const {
      first_name: firstName = '',
      middle_name: middleName = '',
      last_name: lastName = '',
      credential = ''
    } = basic;
    let specialty = '';
    if (taxonomies && Array.isArray(taxonomies) && taxonomies.length > 0) {
      specialty = taxonomies[0].desc;
      console.log(specialty);
    }
    setFirstName(firstName);
    setMiddleName(middleName);
    setLastName(lastName);
    setProviderFullName(`${firstName}${middleName !== '' ? ` ${middleName}` : ''} ${lastName}${credential !== '' ? `, ${credential}` : ''}`);
    setNpi(number.toString());
    toggleLastNameSearchMenu(false);
    getUniqueUsername(firstName, lastName);
    setDisplayName(`${firstName} ${lastName}`);
    setProviderCredentials(credential);
    setProviderSpeciality(specialty);
  };

  const addUserProfile = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formData = new FormData();
    formData.append('npi', npi);
    formData.append('firstName', firstName);
    formData.append('lastName', lastName);
    formData.append('middleName', middleName);
    formData.append('displayName', displayName);
    formData.append('userName', userName);
    formData.append('streetAddress', streetAddress);
    formData.append('suite', suite);
    formData.append('city', city);
    formData.append('state', state);
    formData.append('zip', zip);
    formData.append('email', email);
    formData.append('mobile', mobile);
    formData.append('phone', phone);
    formData.append('fax', fax);
    formData.append('website', website);
    formData.append('avatarImageUrl', avatarImageUrl);
    formData.append('bannerImageUrl', bannerImageUrl);
    formData.append('profileDescription', profileDescription);
    formData.append('credentials', providerCredentials);
    formData.append('specialty', providerSpeciality);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
      redirect: 'follow'
    };

    fetch(`${BASE_URL}/users/addUser2`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        if (res.success) {
          getData();
          toggleAddUserDrawer(false);
        }
      })
      .catch(error => console.log('error', error));
  };

  const deleteUserProfile = (_id) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formData = new FormData();
    formData.append('_id', _id);

    const requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      body: formData,
      redirect: 'follow'
    };

    fetch(`${BASE_URL}/users/deleteUser`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        if (res.success) {
          getData();
        }
      })
      .catch(error => console.log('error', error));
  };

  // #region Grid Toolbar handler functions
  const handleOnClickToggleUserDrawer = () => {
    toggleAddUserDrawer(true);
  };
  // #endregion

  // #region Data Grid Handler Functions
  const handleOnClickCell = (col, value) => {
    const tmp = value.split('|');
    localStorage.setItem('provider', tmp[0]);
    // console.log(value);
    history.push('/provider');
  };

  const handleOnClickResetFilters = () => {
    console.log('Grid reset function not implemented yet');
  };

  const handleOnPopupMenu = (e, idx) => {
    // console.log(e.pageX, e.pageY, idx);
    toggleGridEllipsisMenu(true);
    setCursorX(e.pageX);
    setCursorY(e.pageY);
    setPopupMenuIndex(idx);
  };

  const handleOnClickShowDetailsMenuItem = () => {
    const tmp = (gridData[popupMenuIndex].name).split('|');
    localStorage.setItem('provider', tmp[0]);
    history.push('/provider');
  };

  const handleOnClickDeleteMenuItem = () => {
    const id = (gridData[popupMenuIndex]).id;
    toggleGridEllipsisMenu(false);
    deleteUserProfile(id);
  };
  // #endregion

  // #region Edit Profile Form (Edit Handlers) & Save Profile

  const handleOnEditNpi = (e) => {
    const { value } = e.target;
    setNpi(value);
  };

  const handleOnEditFirstName = (e) => {
    const { value } = e.target;
    setFirstName(value);
  };

  const handleOnEditLastName = (e) => {
    const { value } = e.target;
    setLastName(value);
  };

  const handleOnEditMiddleName = (e) => {
    const { value } = e.target;
    setMiddleName(value);
  };

  // Toggle 'Edit Profile' drawer
  const handleOnClickEditProfile = () => {
    toggleAddUserDrawer(true);
  };

  const handleOnEditDisplayName = (e) => {
    const { value } = e.target;
    setDisplayNamePristineState(false);
    checkDisplayNameValidity(value.length >= 2 && value.length <= 40);
    setDisplayName(value);
  };

  const handleOnEditUserName = (e) => {
    const { value } = e.target;
    setUserNamePristineState(false);
    checkUserNameValidity(value.length >= 2 && value.length <= 20 && value.indexOf(' ') < 0);
    setUserName(value);
  };

  const handleOnEditStreetAddress = (e) => {
    const { value } = e.target;
    setStreetAddress(value);
  };

  const handleOnEditSuite = (e) => {
    const { value } = e.target;
    setSuite(value);
  };

  const handleOnEditCity = (e) => {
    const { value } = e.target;
    setCity(value);
  };

  const handleOnEditState = (e) => {
    const { value } = e.target;
    setState(value);
  };

  const handleOnEditZip = (e) => {
    const { value } = e.target;
    setZip(value);
  };

  const handleOnEditEmail = (e) => {
    const { value } = e.target;
    setEmailPristineState(false);
    checkEmailValidity(isEmail(value));
    setEmail(value);
  };

  const handleOnEditMobile = (e) => {
    const { value } = e.target;
    setMobilePristineState(false);
    checkMobileValidity(isMobilePhone(value, 'en-US'));
    setMobile(value);
  };

  const handleOnEditPhone = (e) => {
    const { value } = e.target;
    setPhonePristineState(false);
    checkPhoneValidity(isMobilePhone(value, 'en-US'));
    setPhone(value);
  };

  const handleOnEditFax = (e) => {
    const { value } = e.target;
    setFax(value);
  };

  const handleOnEditWebsite = (e) => {
    const { value } = e.target;
    setWebsite(value);
  };

  const handleOnChangeProfileDescription = (e) => {
    const { value } = e.target;
    setProfileDescription(value);
  };
  // #endregion

  // #region Banner image crop and file upload
  const uploadBanner = () => {
    const formData = new FormData();
    const img = bannerFileInputRef.current.files[0];
    formData.append('file', img);
    const options = { method: 'POST', body: formData, redirect: 'follow' };

    fetch(`${BASE_URL}/users/uploadImg`, options)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        // console.log(res);
        if (res.success) {
          setBannerImageUrl(res.fileUrl);
          setIsBannerUploading(false);
          setShowBannerCropper(true);
        }
      })
      .catch(error => console.log('error', error));
  };

  const uploadCroppedBanner = (blob) => {
    const formData = new FormData();
    formData.append('file', blob, 'croppedBanner.png');
    const options = { method: 'POST', body: formData, redirect: 'follow' };

    fetch(`${BASE_URL}/users/uploadImg`, options)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        // console.log(res);
        if (res.success) {
          // console.log('Cropped banner image url', res.fileUrl);
          setBannerImageUrl(res.fileUrl);
        }
      })
      .catch(error => console.log('error', error));
  };

  const handleOnBannerFileDrop = (img) => {
    const formData = new FormData();
    formData.append('file', img);
    const options = { method: 'POST', body: formData, redirect: 'follow' };
    setIsBannerUploading(true);
    fetch(`${BASE_URL}/users/uploadImg`, options)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        // console.log(res);
        if (res.success) {
          setBannerImageUrl(res.fileUrl);
          setIsBannerUploading(false);
          setShowBannerCropper(true);
        }
      })
      .catch(error => console.log('error', error));
  };

  const handleOnBannerFileSelected = () => {
    if (bannerFileInputRef.current.files.length > 0) {
      // console.log('Selected file = ', bannerFileInputRef.current.files[0]);
      // console.log('typeof img', typeof bannerFileInputRef.current.files[0]);
      setIsBannerUploading(true);
      uploadBanner();
    } else {
      console.log('No file is selected!');
    }
  };

  const handleOnClickBannerCrop = () => {
    const imageElement = bannerCropperRef.current;
    const { cropper } = imageElement;
    cropper.getCroppedCanvas().toBlob((blob) => {
      const url = URL.createObjectURL(blob);
      drawerBannerRef.current.src = url;
      uploadCroppedBanner(blob);
      toggleBannerCropperDrawer(false);
    });
  };
  // #endregion

  // #region Avatar image crop and file upload

  const uploadAvatar = () => {
    const formData = new FormData();
    const img = bannerFileInputRef.current.files[0];
    formData.append('file', img);
    const options = { method: 'POST', body: formData, redirect: 'follow' };

    fetch(`${BASE_URL}/users/uploadImg`, options)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        // console.log(res);
        if (res.success) {
          setBannerImageUrl(res.fileUrl);
          setIsBannerUploading(false);
          setShowBannerCropper(true);
        }
      })
      .catch(error => console.log('error', error));
  };

  const uploadCroppedAvatar = (blob) => {
    const formData = new FormData();
    formData.append('file', blob);
    const options = { method: 'POST', body: formData, redirect: 'follow' };

    fetch(`${BASE_URL}/users/uploadImg`, options)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        // console.log(res);
        if (res.success) {
          // console.log('Cropped avatar image url', res.fileUrl);
          setAvatarImageUrl(res.fileUrl);
        }
      })
      .catch(error => console.log('error', error));
  };

  const handleOnAvatarFileDrop = (img) => {
    const formData = new FormData();
    formData.append('file', img);
    const options = { method: 'POST', body: formData, redirect: 'follow' };
    setIsAvatarUploading(true);
    fetch(`${BASE_URL}/users/uploadImg`, options)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        // console.log(res);
        if (res.success) {
          setAvatarImageUrl(res.fileUrl);
          setIsAvatarUploading(false);
          setShowAvatarCropper(true);
        }
      })
      .catch(error => console.log('error', error));
  };

  const handleOnAvatarFileSelected = () => {
    if (avatarFileInputRef.current.files.length > 0) {
      // console.log('Selected file = ', avatarFileInputRef.current.files[0]);
      // console.log('typeof img', typeof avatarFileInputRef.current.files[0]);
      setIsAvatarUploading(true);
      uploadAvatar();
    } else {
      console.log('No file is selected!');
    }
  };

  const handleOnClickAvatarCrop = () => {
    const imageElement = avatarCropperRef.current;
    const { cropper } = imageElement;
    cropper.getCroppedCanvas().toBlob((blob) => {
      const url = URL.createObjectURL(blob);
      drawerAvatarRef.current.src = url;
      uploadCroppedAvatar(blob);
      toggleAvatarCropperDrawer(false);
    });
  };
  // #endregion

  const handleOnClickSavePracticeProfile = () => {
    if (!canAddUser) {
      return;
    }
    addUserProfile();
  };

  // Refs
  const lastNameSearchMenu = React.createRef();
  const drawerBannerRef = React.createRef();
  const drawerAvatarRef = React.createRef();
  const bannerCropperRef = React.createRef();
  const bannerFileInputRef = React.createRef();
  const avatarCropperRef = React.createRef();
  const avatarFileInputRef = React.createRef();
  const gridEllipsisMenu = React.createRef();
  // User search results
  const items = [];

  // Last name search results
  const userSearchItems = [];
  if (lastNameSearchResults.length > 0) {
    lastNameSearchResults.forEach((el) => {
      userSearchItems.push(
        <div
          key={el.number}
          className={`fx p-2 ${css(styles.userSearchItems)}`}
          onClick={() => { selectUser(el); }}
        >
          {/* User Avatar */}
          <div className={css(styles.userSearchItemsIcon)}>
            <i className='fa fa-user' />
          </div>
          {/* Name, NPI and username (if available) */}
          <div
            className='ml-2'
            style={{ fontSize: '12px' }}
          >
            <div className='fx'>
              <div>
                <span>{`${titleCase(el.basic.last_name)}, ${titleCase(el.basic.first_name)}`}</span>
                <span style={{ color: gray[400] }}>
                  {el.basic.credential ? ` ${el.basic.credential}` : ''}
                </span>
              </div>
              <div className='ml-2' style={{ color: blue[500] }}>
                {el.userName ? `@${el.userName}` : ''}
              </div>
            </div>
            <div>
              {el.number}
            </div>
          </div>
        </div>
      );
    });
  }

  return (
    <div className={css(styles.gridParent)}>
      {/* Edit profile drawer */}
      {isAddUserDrawerVisible
        ? <Drawer
          placement="right"
          visible={isAddUserDrawerVisible}
          toggleDrawer={() => {
            toggleAddUserDrawer(false);
            resetState();
          }}
          zIndex="2000"
          width={window.innerWidth <= 700 ? window.innerWidth : 358}
        >
          {/* Drawer Container */}
          <div className={css(styles.drawerContainer)}>
            {/* Header */}
            <div className={css(styles.drawerHeader)}>
              <div className='fx fx__hv-center'>
                <i className='mr-2 fa fa-lg fa-user' />
                <div className='noselect'>Create User Profile</div>
              </div>
              <i
                className={`fa fa-lg fa-times-circle ${css(styles.drawerHeaderCloseIcon)}`}
                onClick={() => {
                  toggleAddUserDrawer(false);
                  resetState();
                }}
              />
            </div>
            {/* Banner */}
            <div className={css(styles.drawerBanner)}>
              <img
                ref={drawerBannerRef}
                src={bannerImageUrl}
                alt="cropped-banner"
                className={css(styles.drawerBannerImg)}
              />
              {/* Camera icon */}
              <div
                onClick={() => { toggleBannerCropperDrawer(true); }}
                className={css(styles.drawerBannerCameraIcon)}
              >
                <i className="fa fa-camera" />
              </div>
            </div>

            {/* Middle area */}
            <div className={css(styles.drawerContentArea)}>
              {/* IsProvider Checkbox */}
              <div className='ml-2 mt-0'>
                <Checkbox
                  text='Is Provider?'
                  isChecked={isProvider}
                  onChange={() => { setIsProvider(!isProvider); }}
                />
              </div>
              {/* NPI  */}
              <div
                style={{
                  paddingLeft: '8px',
                  position: 'relative',
                  marginTop: '-2px',
                  display: isProvider ? 'block' : 'none'
                }}
                ref={lastNameSearchMenu}
              >
                <TextInputWithLabel
                  value={npi}
                  titleText='NPI (Required for providers)'
                  titleFontSize={12}
                  isCompactLayout={true}
                  titleColor={gray[200]}
                  textColor="white"
                  selectionColor="white"
                  selectionBackground="#0080FF"
                  spellcheck={false}
                  width={342}
                  onChange={handleOnEditNpi}
                  onClick={() => { toggleLastNameSearchMenu(true); }}
                />
                <PopupMenu
                    top={44}
                    right={0}
                    zIndex='1550'
                    position="absolute"
                    visible={isLastNameSearchMenuOpen}
                    parent={lastNameSearchMenu}
                    onOutsideClick={() => { toggleLastNameSearchMenu(false); }}
                  >
                    <div className={css(styles.usersSearchMenu)}>
                      <div
                        className='centered-text pt-2 pb-2'
                        style={{
                          borderBottom: `1px solid ${blue[700]}`
                        }}
                      >
                        Search Users by NPI
                      </div>
                      <div
                        className='mt-1 mb-1'
                        style={{
                          maxHeight: '250px',
                          overflowY: 'auto',
                          overflowX: 'hidden'
                        }}
                      >
                        {userSearchItems}
                      </div>
                    </div>
                  </PopupMenu>
              </div>
              {/* Last, Middle and First name */}
              {!isProvider
                ? <div
                    className='fx pl-2'
                    style={{ fontSize: '14px', fontWeight: '700' }}
                  >
                    {/* First Name */}
                    <div>
                      <TextInputWithLabel
                        value={firstName}
                        titleText="First Name*"
                        titleFontSize={12}
                        isCompactLayout={true}
                        titleColor={gray[200]}
                        textColor="white"
                        selectionColor="white"
                        selectionBackground="#0080FF"
                        spellcheck={false}
                        width={110}
                        onChange={handleOnEditFirstName}
                      />
                    </div>
                    {/* Middle name */}
                    <div className='ml-2 mr-2'>
                      <TextInputWithLabel
                        value={middleName}
                        titleText="Middle Name"
                        titleFontSize={12}
                        isCompactLayout={true}
                        titleColor={gray[200]}
                        textColor="white"
                        selectionColor="white"
                        selectionBackground="#0080FF"
                        spellcheck={false}
                        width={108}
                        onChange={handleOnEditMiddleName}
                      />
                    </div>
                    {/* Last name */}
                    <div>
                      <TextInputWithLabel
                        value={lastName}
                        titleText="Last Name*"
                        titleFontSize={12}
                        isCompactLayout={true}
                        titleColor={gray[200]}
                        textColor="white"
                        selectionColor="white"
                        selectionBackground="#0080FF"
                        spellcheck={false}
                        width={108}
                        onChange={handleOnEditLastName}
                      />
                    </div>
                  </div>
                : <div className='ml-2 mt-1'>
                    <div>{providerFullName}</div>
                    <div>{npi !== '' ? `NPI: ${npi}` : ''}</div>
                  </div>
              }
              {/* User card */}
              <div className="fx mt-4 ml-2">
                {/* Avatar */}
                <div className={css(styles.drawerUserCardAvatar)}>
                  <img
                    src={avatarImageUrl}
                    ref={drawerAvatarRef}
                    alt="avatar"
                    className={css(styles.drawerUserCardAvatarImg)}
                    onClick={() => toggleAvatarCropperDrawer(true)}
                  />
                  <div
                    onClick={() => { toggleAvatarCropperDrawer(true); }}
                    className={css(styles.drawerAvatarCamera)}
                  >
                    <i className="fa fa-camera" />
                  </div>
                </div>
                {/* User card displayName & userName */}
                <div className={`ml-2 ${css(styles.drawerUserCardText)}`}>
                  {/* Display Name */}
                  <div style={{ marginTop: '-5px' }}>
                    <TextInputWithLabel
                      value={displayName}
                      titleText="Display Name (2 to 40 characters)*"
                      titleFontSize={12}
                      isCompactLayout={true}
                      titleColor={gray[200]}
                      textColor="white"
                      selectionColor="white"
                      selectionBackground="#0080FF"
                      spellcheck={false}
                      width={248}
                      onChange={handleOnEditDisplayName}
                    />
                    {/* Error message */}
                    {!isDisplayNameValid && !isDisplayNamePristine
                      ? <div className={css(styles.errMsg)}>
                        Invalid Display Name
                      </div>
                      : null
                    }
                  </div>
                  {/* UserName */}
                  <div
                    style={{
                      marginTop: (!isDisplayNameValid && !isDisplayNamePristine) ? '0px' : '4px'
                    }}
                  >
                    <TextInputWithLabel
                      value={userName}
                      titleText="Username (2 to 20 characters, no spaces)*"
                      titleFontSize={12}
                      isCompactLayout={true}
                      titleColor={gray[200]}
                      textColor={blue[500]}
                      selectionColor="white"
                      selectionBackground="#0080FF"
                      spellcheck={false}
                      width={248}
                      onChange={handleOnEditUserName}
                    />
                    {/* Error message */}
                    {!isUserNameValid && !isUserNamePristine
                      ? <div className={css(styles.errMsg)}>
                          Invalid User Name
                        </div>
                      : null
                    }
                    {isUserNameValid && !isUserNamePristine && isUserNameDuplicate
                      ? <div className={css(styles.errMsg)}>
                          User Name Taken
                        </div>
                      : null
                    }
                  </div>
                </div>
              </div>
              {/* Mailing Address */}
              <div className="mt-4 ml-2">
                {/* Heading */}
                <div className="fx fx__v-center">
                  <i className="fa fa-map-marker" style={{ fontSize: '22px' }} />
                  <div className="ml-1">
                    Mailing Address *
                  </div>
                </div>
                {/* Form */}
                <div>
                  {/* Address */}
                  <div className="fx">
                    <div>
                      <TextInputWithLabel
                        value={streetAddress}
                        titleText="Street"
                        titleFontSize={12}
                        isCompactLayout={true}
                        titleColor={gray[200]}
                        textColor="white"
                        selectionColor="white"
                        selectionBackground="#0080FF"
                        spellcheck={false}
                        width={284}
                        onChange={handleOnEditStreetAddress}
                      />
                    </div>
                    <div className="ml-2">
                      <TextInputWithLabel
                        value={suite}
                        titleText="Suite"
                        titleFontSize={12}
                        isCompactLayout={true}
                        titleColor={gray[200]}
                        textColor="white"
                        selectionColor="white"
                        selectionBackground="#0080FF"
                        spellcheck={false}
                        width={45}
                        onChange={handleOnEditSuite}
                      />
                    </div>
                  </div>
                  {/* City, State and Zip */}
                  <div className="fx mt-2">
                    <div>
                      <TextInputWithLabel
                        value={city}
                        titleText="City"
                        titleFontSize={12}
                        isCompactLayout={true}
                        titleColor={gray[200]}
                        textColor="white"
                        selectionColor="white"
                        selectionBackground="#0080FF"
                        spellcheck={false}
                        width={230}
                        onChange={handleOnEditCity}
                      />
                    </div>
                    <div className="ml-2">
                      <TextInputWithLabel
                        value={state}
                        titleText="State"
                        titleFontSize={12}
                        isCompactLayout={true}
                        titleColor={gray[200]}
                        textColor="white"
                        selectionColor="white"
                        selectionBackground="#0080FF"
                        spellcheck={false}
                        width={45}
                        onChange={handleOnEditState}
                      />
                    </div>
                    <div className="ml-2">
                      <TextInputWithLabel
                        value={zip}
                        titleText="Zip"
                        titleFontSize={12}
                        isCompactLayout={true}
                        titleColor={gray[200]}
                        textColor="white"
                        selectionColor="white"
                        selectionBackground="#0080FF"
                        spellcheck={false}
                        width={45}
                        onChange={handleOnEditZip}
                      />
                    </div>
                  </div>
                  {/* Email */}
                  <div className="mt-2">
                    <TextInputWithLabel
                      value={email}
                      titleText="Email*"
                      titleFontSize={12}
                      isCompactLayout={false}
                      titleColor={gray[200]}
                      textColor="white"
                      selectionColor="white"
                      selectionBackground="#0080FF"
                      spellcheck={false}
                      width={337}
                      showPreIcon
                      preIcon='fa fa-envelope'
                      onChange={handleOnEditEmail}
                    />
                    {!isEmailPristine && !isEmailValid
                      ? <div className={css(styles.errMsg)}>Invalid Email</div>
                      : null
                    }
                    {isEmailValid && !isEmailPristine && isEmailDuplicate
                      ? <div className={css(styles.errMsg)}>Email already in use</div>
                      : null
                    }
                  </div>
                  {/* Mobile */}
                  <div className="mt-2">
                    <TextInputWithLabel
                      value={mobile}
                      titleText="Mobile # *"
                      titleFontSize={12}
                      isCompactLayout={false}
                      titleColor={gray[200]}
                      textColor="white"
                      selectionColor="white"
                      selectionBackground="#0080FF"
                      spellcheck={false}
                      width={337}
                      showPreIcon
                      preIcon='fa fa-mobile'
                      onChange={handleOnEditMobile}
                    />
                    {!isMobilePristine && !isMobileValid
                      ? <div className={css(styles.errMsg)}>Invalid Mobile #</div>
                      : null
                    }
                    {isMobileValid && !isMobilePristine && isMobileDuplicate
                      ? <div className={css(styles.errMsg)}>Mobile # already in use</div>
                      : null
                    }
                  </div>
                  {/* Phone */}
                  <div className="mt-2">
                    <TextInputWithLabel
                      value={phone}
                      titleText="Phone # *"
                      titleFontSize={12}
                      isCompactLayout={false}
                      titleColor={gray[200]}
                      textColor="white"
                      selectionColor="white"
                      selectionBackground="#0080FF"
                      spellcheck={false}
                      width={337}
                      showPreIcon
                      preIcon='fa fa-phone'
                      onChange={handleOnEditPhone}
                    />
                    {!isPhonePristine && !isPhoneValid
                      ? <div className={css(styles.errMsg)}>Invalid Phone #</div>
                      : null
                    }
                  </div>
                  {/* Fax */}
                  <div className="mt-2">
                    <TextInputWithLabel
                      value={fax}
                      titleText="Fax #"
                      titleFontSize={12}
                      isCompactLayout={false}
                      titleColor={gray[200]}
                      textColor="white"
                      selectionColor="white"
                      selectionBackground="#0080FF"
                      spellcheck={false}
                      width={337}
                      showPreIcon
                      preIcon='fa fa-fax'
                      onChange={handleOnEditFax}
                    />
                  </div>
                  {/* Website */}
                  <div className="mt-2">
                    <TextInputWithLabel
                      value={website}
                      titleText="Website"
                      titleFontSize={12}
                      isCompactLayout={false}
                      titleColor={gray[200]}
                      textColor="white"
                      selectionColor="white"
                      selectionBackground="#0080FF"
                      spellcheck={false}
                      width={337}
                      showPreIcon
                      preIcon='fa fa-globe'
                      onChange={handleOnEditWebsite}
                    />
                  </div>
                </div>
              </div>
              {/* Profile descriptiom */}
              <div className="mt-4 ml-2">
                <div>Profile Description</div>
                <div className={css(styles.profileTextAreaContainer)}>
                  <textarea
                    className={css(styles.profileTextArea)}
                    placeholder="Write a description"
                    value={profileDescription}
                    onChange={handleOnChangeProfileDescription}
                  />
                </div>
              </div>
            </div>
            {/* Footer */}
            <div className={`fx pr-2 ${css(styles.drawerFooter)}`} >
              {/* Required fields msg */}
              <div className={css(styles.criticalSmallText)} >
                *Required fields
              </div>
              <Button
                btnType="primary"
                className="fx fx__hv-center"
                onClick={handleOnClickSavePracticeProfile}
                isDisabled={!canAddUser}
                style={{
                  height: '32px',
                  width: '340px',
                  padding: '3px 0'
                }}
              >
                Create User Profile
              </Button>
            </div>
          </div>
        </Drawer>
        : null
      }
      {/* Profile banner image cropper */}
      <Drawer
        placement="right"
        visible={isBannerCropperDrawerOpen}
        toggleDrawer={() => toggleBannerCropperDrawer(false)}
        zIndex="2050"
        width={window.innerWidth <= 700 ? window.innerWidth : 358}
      >
        <div className={css(styles.drawerContainer)}>
          {/* Header */}
          <div className={css(styles.drawerHeader)}>
            <div>Upload Profile Banner</div>
            <i
              className={`fa fa-times-circle ${css(styles.drawerHeaderCloseIcon)}`}
              onClick={() => { toggleBannerCropperDrawer(false); }}
            />
          </div>
          {/* Content Area */}
          <div className={css(styles.drawerContentArea, styles.imageUploaderContainer)}>
            {showBannerCropper
              ? <div>
                <Cropper
                  src={bannerImageUrl}
                  style={{ height: 400, width: '100%' }}
                  checkCrossOrigin
                  checkOrientation={false}
                  aspectRatio={6}
                  background={false}
                  viewMode={1}
                  autoCropArea={1}
                  guides={false}
                  ref={bannerCropperRef}
                />
              </div>
              : null
            }
            {!showBannerCropper
              ? <div>
                <FileUploader
                  parentRef={bannerFileInputRef}
                  onDrop={handleOnBannerFileDrop}
                  onChange={handleOnBannerFileSelected}
                />
              </div>
              : null
            }
            {isBannerUploading
              ? <div className={css(styles.imageUploadLoader)}>
                <Loader iconType="cog" iconSize="5x" text="Uploading Banner Image" />
              </div>
              : null
            }
          </div>
          {/* Drawer footer area */}
          <div
            className={`fx pr-2 ${css(styles.drawerFooter)}`}
            style={{ position: 'absolute', bottom: '0' }}
          >
            <Button
              btnType="primary"
              className="fx fx__hv-center"
              onClick={handleOnClickBannerCrop}
              style={{
                height: '32px',
                width: '340px',
                padding: '3px 0'
              }}
            >
              Crop Banner Image
            </Button>
          </div>
        </div>
      </Drawer>

      {/* Profile avatar image cropper */}
      <Drawer
        placement="right"
        visible={isAvatarCropperDrawerOpen}
        toggleDrawer={() => toggleAvatarCropperDrawer(false)}
        zIndex="2050"
        width={window.innerWidth <= 700 ? window.innerWidth : 358}
      >
        <div className={css(styles.drawerContainer)}>
          {/* Header */}
          <div className={css(styles.drawerHeader)}>
            <div>Upload Profile Avatar</div>
            <i
              className={`fa fa-times-circle ${css(styles.drawerHeaderCloseIcon)}`}
              onClick={() => { toggleAvatarCropperDrawer(false); }}
            />
          </div>
          {/* Content Area */}
          <div className={css(styles.drawerContentArea, styles.imageUploaderContainer)}>
            {showAvatarCropper
              ? <div>
                <Cropper
                  src={avatarImageUrl}
                  style={{ height: 400, width: '100%' }}
                  checkCrossOrigin
                  checkOrientation={false}
                  aspectRatio={1}
                  background={false}
                  viewMode={1}
                  autoCropArea={1}
                  guides={false}
                  ref={avatarCropperRef}
                />
              </div>
              : null
            }
            {!showAvatarCropper
              ? <div>
                <FileUploader
                  parentRef={avatarFileInputRef}
                  onDrop={handleOnAvatarFileDrop}
                  onChange={handleOnAvatarFileSelected}
                />
              </div>
              : null
            }
            {isAvatarUploading
              ? <div className={css(styles.imageUploadLoader)}>
                <Loader iconType="cog" iconSize="5x" text="Uploading Avatar Image" />
              </div>
              : null
            }
          </div>
          {/* Drawer footer area */}
          <div
            className={`fx pr-2 ${css(styles.drawerFooter)}`}
            style={{ position: 'absolute', bottom: '0' }}
          >
            <Button
              btnType="primary"
              className="fx fx__hv-center"
              onClick={handleOnClickAvatarCrop}
              style={{
                height: '32px',
                width: '340px',
                padding: '3px 0'
              }}
            >
              Crop Avatar Image
            </Button>
          </div>
        </div>
      </Drawer>

      {/* Grid toolbar and users data grid */}
      <div className={css(styles.gridContainer)}>
        {/* Grid toolbar */}
        <div className={css(styles.gridToolbar)}>
          {/* Grid toolbar - right aligned buttons */}
          <div className='fx'>
            {/* Add user button */}
            <div
              className={css(styles.gridToolbarIcon)}
              onClick={handleOnClickToggleUserDrawer}
            >
              <i className="fa fa-lg fa-plus-circle" />
            </div>
            {/* Download */}
            <div className={css(styles.gridToolbarIcon)}>
              <i className="fa fa-lg fa-cloud-download" />
            </div>
            {/* Trash bin */}
            <div className={css(styles.gridToolbarIcon)}>
              <i className="fa fa-lg fa-trash" />
            </div>
            {/* Checkbox */}
            <div className={css(styles.gridToolbarIcon)}>
              <i className="fa fa-lg fa-check-square" />
            </div>
            {/* Reset button */}
            <div
              className={css(styles.gridToolbarIcon)}
              onClick={handleOnClickResetFilters}
            >
              <i className="fa fa-lg fa-refresh" />
            </div>
            {/* Vertical ellipsis */}
            <div
              className={css(styles.gridToolbarIcon)}
              style={{ fontSize: '17px' }}
            >
              <span className='fa-stack'>
                <i className='fa fa-circle fa-stack-2x' />
                <i
                  className='fa fa-ellipsis-v fa-stack-1x'
                  style={{ color: gray[700] }}
                />
                {/* <i class="fa fa-flag fa-stack-1x fa-inverse"></i> */}
              </span>
            </div>
          </div>
        </div>
        {/* Grid ellipsis menu */}
        <div
          ref={gridEllipsisMenu}
          className={css(styles.gridEllipsisMenuParent)}
          style={{
            top: cursorY,
            left: cursorX - 140,
            display: showGridEllipsisMenu ? 'block' : 'none'
          }}
        >
          <PopupMenu
            top={0}
            left={0}
            position="relative"
            visible={showGridEllipsisMenu}
            parent={gridEllipsisMenu}
            onOutsideClick={() => { toggleGridEllipsisMenu(false); }}
          >
            <div className={css(styles.gridEllipsisMenuContainer)}>
              {/* Show details menu item */}
              <div
                className={css(styles.gridEllipsisMenuItem)}
                onClick={handleOnClickShowDetailsMenuItem}
              >
                Show Details
              </div>
              {/* Delete record menu item */}
              <div
                className={css(styles.gridEllipsisMenuItem)}
                onClick={handleOnClickDeleteMenuItem}
              >
                Delete Record
              </div>
              </div>
          </PopupMenu>
        </div>
        {/* Users' Data Grid */}
        <DataGrid
          loader={showLoader}
          icons={icons}
          headerDefs={headerDefs}
          rowData={gridData}
          filters={filters}
          gridWidth={600}
          gridHeight={600}
          gridHeaderHeight={40}
          gridFooterHeight={0}
          gridContainerHeight='730px'
          rowHeight={64}
          rowsPerPage={DOCS_PER_PAGE}
          defaultCellStyle={defaultCellStyle}
          showFooter={false}
          showEllipsisCol
          enableHover
          totalPages={Math.ceil(docsCount / DOCS_PER_PAGE)}
          currentPage={currentPage}
          onClickCell={handleOnClickCell}
          onApplyFilter={() => { console.warn('Handler not set for onApplyFilter prop.'); }}
          onResetCol={() => { console.warn('Handler not set for onResetCol prop.'); }}
          onLoadPage={() => { console.warn('Handler not set for onLoadPage prop.'); }}
          onColFilterToggle={() => { console.warn('Handler not set for onColFilterToggle prop.'); }}
          onFilterTypeUpdate={() => { console.warn('Handler not set for onFilterTypeUpdate prop.'); }}
          onColPinChange={() => { console.warn('Handler not set for onColPinChange prop.'); }}
          onResize={() => { console.warn('Handler not set for onResize prop.'); }}
          onPopupMenu={handleOnPopupMenu}
          onColVisibilityChange={() => { console.warn('Handler not set for onColVisibilityChange prop.'); }}
          onFilterChange={() => { console.warn('Handler not set for onFilterChange prop.'); }}
          onChangeTextAlignment={() => { console.warn('Handler not set for onChangeTextAlignment prop.'); }}
          onResetGrid={() => { console.warn('Handler not set for onResetGrid prop.'); }}
          onSort={() => { console.warn('Handler not set for onSort prop.'); }}
          onColConfig={() => { console.warn('Handler not set for onColConfig prop.'); }}
        />
      </div>
    </div>
  );
};

function mapStateToProps({ user }) {
  return { ...user };
}

export default connect(mapStateToProps, {})(UsersDataGrid);
