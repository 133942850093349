import React, { useState } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { connect } from 'react-redux';
import { blue, gray } from 'quantux-ui';
import ChangeEmailDialog from '../dialogs/change-emaill-dialog';
import ChangePasswordDialog from '../dialogs/change-password-dialog';
import ChangeMobileDialog from '../dialogs/change-mobile-dialog';
import { logout } from '../../actions';
const styles = StyleSheet.create({
  itemContainer: {
    width: '350px'
  },
  sectionHeading: {
    fontSize: '14px',
    marginBottom: '4px',
    fontWeight: '700'
  },
  mutedData: {
    background: gray[700],
    height: '32px',
    minWidth: '350px',
    alignItems: 'center'
  },
  textBtn: {
    textAlign: 'right',
    color: blue[500],
    cursor: 'pointer'
  }
});

const LoginCredentials = (props) => {
  const [isChangeEmailDialogOpen, toggleChangeEmailDialog] = useState(false);
  const [isChangePasswordDialogOpen, toggleChangePasswordDialog] = useState(false);
  const [isChangeMobileDialogOpen, toggleChangeMobileDialog] = useState(false);

  return (
    <div className='p-4'>
      {isChangeEmailDialogOpen
        ? <ChangeEmailDialog
          onCloseDialogBox={toggleChangeEmailDialog}
          isVisible={isChangeEmailDialogOpen}
          usr={props.usr}
          processLogout={() => {
            console.log('processing logout for email update');
            // props.logout({
            //   headerText: 'Email Changed Successfully',
            //   headerIcon: 'fa fa-envelope',
            //   bodyText: 'Your email has been successfully updated... you can now login with your new email.',
            //   showAlert: true,
            //   autoDismiss: true,
            //   autoDismissTime: 5000
            // });
          }}
        />
        : null
      }

      {isChangePasswordDialogOpen
        ? <ChangePasswordDialog
          onCloseDialogBox={toggleChangePasswordDialog}
          isVisible={isChangePasswordDialogOpen}
          usr={props.usr}
          processLogout={() => {
            console.log('processing logout for password update');
            // props.logout({
            //   headerText: 'Password Changed Successfully',
            //   headerIcon: 'fa fa-envelope',
            //   bodyText: 'Your password has been successfully updated... you can now login with your new password.',
            //   showAlert: true,
            //   autoDismiss: true,
            //   autoDismissTime: 5000
            // })
          }}
        />
        : null
      }

      {isChangeMobileDialogOpen
        ? <ChangeMobileDialog
          onCloseDialogBox={toggleChangeMobileDialog}
          isVisible={isChangeMobileDialogOpen}
          usr={props.usr}
          processLogout={() => {
            console.log('processing logout for mobile # update');
            // props.logout({
            //   headerText: 'Mobile # Changed Successfully',
            //   headerIcon: 'fa fa-envelope',
            //   bodyText: 'Your mobile# has been successfully updated... you can now login to revieve 2FA code on new mobile #.',
            //   showAlert: true,
            //   autoDismiss: true,
            //   autoDismissTime: 5000
            // });
          }}
        />
        : null
      }

      <div className={css(styles.itemContainer)}>
        {/* Change Email */}
        <div>
          <div>Email</div>
          <div className={`fx ${css(styles.mutedData)}`}>
            <i className='fa fa-envelope mr-2' />
            <div>{props.usr.email}</div>
          </div>
          <div
            className={css(styles.textBtn)}
            onClick={() => toggleChangeEmailDialog(true)}
          >
            Change Email
          </div>
        </div>
        {/* Change Password */}
        <div className='mt-2'>
          <div>Password</div>
          <div className={`fx ${css(styles.mutedData)}`}>
            <i className='fa fa-envelope mr-2' />
            <div>••••••••••••••••</div>
          </div>
          <div
            className={css(styles.textBtn)}
            onClick={() => toggleChangePasswordDialog(true)}
          >
            Change Password
          </div>
        </div>
        {/* Change Mobile */}
        <div className='mt-2'>
          <div>Mobile #</div>
          <div className={`fx ${css(styles.mutedData)}`}>
            <i className='fa fa-envelope mr-2' />
            <div>{props.usr.mobile}</div>
          </div>
          <div
            className={css(styles.textBtn)}
            onClick={() => toggleChangeMobileDialog(true)}
          >
            Change Mobile #
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({ user }) {
  return { ...user };
}

export default connect(mapStateToProps, { logout })(LoginCredentials);
