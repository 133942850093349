import React, { useState, useRef, useEffect } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import {
  DialogBox,
  TextInputWithLabel,
  Button,
  gray,
  blue,
  salmon
} from 'quantux-ui';
import isEmail from 'validator/lib/isEmail';
import { BASE_URL } from '../../utils';

const styles = StyleSheet.create({
  stepsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  stepHeading: {
    color: gray[400]
  },
  activeStepHeading: {
    color: blue[500]
  },
  errMsg: {
    color: salmon[500],
    minHeight: '40px',
    maxHeight: '40px',
    height: '40px'
  },
  txtBtn: {
    color: blue[500],
    cursor: 'pointer'
  },
  disabledTxtBtn: {
    cursor: 'not-allowed',
    color: blue[700]
  },
  statusMsg: {
    color: blue[500],
    textAlign: 'center'
  }
});

const ChangeEmailDialog = (props) => {
  const isFirstRender = useRef(true);
  const [password, setPassword] = useState('');
  const [showInvalidPasswordErrorMsg, setShowInvalidPasswordErrorMsg] = useState(false);
  const [step, setStep] = useState(1);
  const [newEmail, setNewEmail] = useState('');
  const [isNewEmailPristine, setNewEmailPristineState] = useState(true);
  const [isNewEmailValid, setNewEmailValidState] = useState(false);
  const [isNewEmailDuplicate, setNewEmailDuplicateState] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerificationCodePristine, setVerificationCodePrisitineState] = useState(true);
  const [isVerificationCodeValid, setVerificationCodeValidState] = useState(true);
  const [showStatusMsg, setShowStatusMsg] = useState(false);
  const [statusMsg, setStatusMsg] = useState('Status message!');
  const [showLoader, setShowLoader] = useState(false);
  const [canUpdateEmail, setCanUpdateEmail] = useState(false);

  // Check for duplicate email
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (isEmail(newEmail)) {
      const url = new URL(`${BASE_URL}/users/exists/email/${newEmail}`);
      fetch(url)
        .then(res => res.text())
        .then((result) => {
          const data = JSON.parse(result);
          setNewEmailDuplicateState(data.isDuplicate);
          setCanUpdateEmail(!data.isDuplicate);
        })
        .catch(error => console.log('error', error))
    }
  }, [newEmail]);

  const handleOnChangePassword = (e) => {
    const { value } = e.target;
    setPassword(value);
    setShowInvalidPasswordErrorMsg(false);
  };

  const handleOnClickProceedToStep2 = () => {
    if (password.length > 0) {
      const formData = new FormData();
      const options = { method: 'POST', body: formData, redirect: 'follow' };
      formData.append('email', props.usr.email);
      formData.append('password', password);
      fetch(`${BASE_URL}/users/reauthenticate`, options)
        .then(res => res.text())
        .then((result) => {
          const data = JSON.parse(result);
          const { success } = data;
          if (success) {
            setStep(2);
          } else {
            setShowInvalidPasswordErrorMsg(true);
          }
        })
        .catch(error => console.log('error', error))
    }
  };

  const handleOnChangeNewEmail = (e) => {
    const { value } = e.target;
    setNewEmail(value);
    setNewEmailPristineState(false);
    setNewEmailValidState(isEmail(value));
  };

  const handleOnClickSendEmailVerificationCode = () => {
    setShowStatusMsg(true);
    setShowLoader(true);
    setStatusMsg('Sending email verification code');

    const url = new URL(`${BASE_URL}/users/sendEmailVerificationCode`);
    const params = {
      email: props.usr.email,
      newEmail
    };
    url.search = new URLSearchParams(params).toString();
    fetch(url)
      .then(res => res.text())
      .then((result) => {
        const data = JSON.parse(result);
        // console.log(data);
        setStatusMsg('Email verification code sent!');
        setShowLoader(false);
      })
      .catch(error => console.log('error', error))
  }

  const handleOnChangeVerificationCode = (e) => {
    const { value } = e.target;
    setVerificationCode(value);
    setVerificationCodePrisitineState(false);
    setVerificationCodeValidState(true);
  };

  const handleOnClickUpdateEmail = () => {
    if (canUpdateEmail) {
      setShowLoader(true);
      setStatusMsg('Updating email address!');
      const formData = new FormData();
      formData.append('email', props.usr.email);
      formData.append('newEmail', newEmail);
      formData.append('code', verificationCode);
      const options = { method: 'POST', body: formData, redirect: 'follow' };
      fetch(`${BASE_URL}/users/verifyEmailVerificationCode`, options)
        .then(response => response.text())
        .then(result => {
          const res = JSON.parse(result);
          if (res.success) {
            console.log('email address successfully updated');
            setShowLoader(false);
            setShowStatusMsg(false);
            props.onCloseDialogBox(false);
            props.processLogout();
          } else {
            setShowLoader(false);
            setShowStatusMsg(false);
            setVerificationCodeValidState(false);
          }
        })
        .catch(error => console.log('error', error));
    }
  };

  return (
    <DialogBox
      initWidth={window.innerWidth <= 576 ? (window.innerWidth - 16) : 350}
      top={window.innerWidth <= 576 ? 48 : null}
      left={window.innerWidth <= 576 ? 8 : null}
      initHeight={378}
      isOpen={props.isVisible}
      onClose={() => { props.onCloseDialogBox(false); }}
      maskBackground="#004080"
      maskOpacity={0.5}
      closeOnEscKey
      closeOnMaskClick
      header={{
        text: ' Change Email',
        backColor: '#082840',
        foreColor: '#5080B0',
        closeColor: '#FF6060',
        fontStyle: 'bold',
        fontSize: '20px',
        icon: 'fa fa-envelope'
      }}
    >
      <div className="p-2">
        {/* Step 1 & Step 2 heading */}
        <div className={`mt-2 mb-2 ${css(styles.stepsContainer)}`}>
          <div className={`mr-3 ${css(styles.stepHeading, step === 1 && styles.activeStepHeading)}`}>
            Step 1
          </div>
          <div className={css(styles.stepHeading, step === 2 && styles.activeStepHeading)}>
            Step 2
          </div>
        </div>
        {/* Step 1 */}
        {step === 1
          ? <>
              {/* Password */}
              <div style={{ marginTop: '10px' }}>
                <TextInputWithLabel
                  value={password}
                  titleText="Enter Password"
                  type='password'
                  isCompactLayout={false}
                  titleColor="#5079ab"
                  width={328}
                  showPreIcon
                  preIcon="fa fa-lock"
                  showPostIcon
                  postIcon="fa fa-eye"
                  onChange={handleOnChangePassword}
                />
              </div>
              {/* Invalid password error msg */}
              <div className={css(styles.errMsg)}>
                {showInvalidPasswordErrorMsg ? 'Invalid password' : ''}
              </div>
              {/* Forgot Password */}
              <div
                className='mt-4'
                style={{ textAlign: 'center' }}
              >
                Forgot Password? <span className={css(styles.txtBtn)}>RESET IT HERE</span>
              </div>
              {/* Proceed to Step 2 Btn */}
              <div style={{ marginTop: '32px' }}>
                <Button
                  btnType="primary"
                  isDisabled={password.length === 0}
                  style={{ minWidth: '330px', fontWeight: 'bold', borderRadius: '4px' }}
                  onClick={handleOnClickProceedToStep2}
                >
                  Proceed to Step 2
                </Button>
              </div>
            </>
          : null
        }
        {/* Step 2 */}
        {step === 2
          ? <>
              {/* New Email */}
              <div style={{ marginTop: '10px' }}>
                <TextInputWithLabel
                  value={newEmail}
                  titleText="Enter New Email"
                  isCompactLayout={false}
                  titleColor="#5079ab"
                  width={328}
                  showPreIcon
                  preIcon="fa fa-envelope"
                  onChange={handleOnChangeNewEmail}
                />
              </div>
              {/* Invalid email error msg */}
              {(!isNewEmailPristine && !isNewEmailValid)
                ? <div className={css(styles.errMsg)}>Invalid email</div>
                : null
              }
              {/* Duplicate email error msg */}
              {(!isNewEmailPristine && isNewEmailValid && isNewEmailDuplicate)
                ? <div className={css(styles.errMsg)}>Account with this email already exists</div>
                : null
              }
              {/* Send verification code */}
              <div
                className={`mt-4 noselect ${css(styles.disabledTxtBtn, isNewEmailValid && !isNewEmailDuplicate && styles.txtBtn)}`}
                style={{ textAlign: 'center' }}
                onClick={handleOnClickSendEmailVerificationCode}
              >
                Send Verification Code to New Email ▶
              </div>
              {/* Verification Code */}
              <div className='mt-2'>
                <TextInputWithLabel
                  value={verificationCode}
                  titleText="Verification Code From New Email"
                  isCompactLayout={false}
                  titleColor="#5079ab"
                  width={328}
                  showPreIcon
                  preIcon="fa fa-shield"
                  onChange={handleOnChangeVerificationCode}
                />
              </div>
              {/* Invalid code error msg */}
              {(!isVerificationCodePristine && !isVerificationCodeValid)
                ? <div className={css(styles.errMsg)}>Invalid Code</div>
                : null
              }
              {/* Status message */}
              {showStatusMsg
                ? <div className={`mt-4 mb-2 noselect ${css(styles.statusMsg)}`}>
                    {statusMsg}
                    <i
                      className="ml-2 fa fa-circle-o-notch fa-spin fa-fw"
                      style={{
                        display: showLoader ? '' : 'none'
                      }}
                    />
                  </div>
                : null
              }
              {/* Update Email Btn */}
              <div style={{ marginTop: '32px' }}>
                <Button
                  btnType="primary"
                  isDisabled={!canUpdateEmail}
                  style={{ minWidth: '330px', fontWeight: 'bold', borderRadius: '4px' }}
                  onClick={handleOnClickUpdateEmail}
                >
                  Update Email
                </Button>
              </div>
            </>
          : null
        }
      </div>
    </DialogBox>
  );
};

export default ChangeEmailDialog;
