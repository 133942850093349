import { actionTypes } from '../actions';
import { history } from '../utils';

const initialState = {
  isUserSigninDialogVisible: false,
  is2FaVerificationDialogVisible: false,
  isResetPasswordDialogVisible: false,
  showLoginErrorMsg: false,
  isSignedIn: false,
  show2FaErrorMsg: false,
  showResend2FaMsg: false,
  token: '',
  twoFactorAuthToken: '',
  phone: '',
  usr: {},
  providers: [],
  // Providers
  resetPasswordStep1Active: false,
  resetPasswordStep2Active: false,
  showInvalidEmailErrorMsg: false,
  activeRecord: {},
  shouldReloadPracticeData: false,
  appAlerts: [],
  notificationsCount: 0,
  notifications: [],
  showChatIconBadge: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case actionTypes.toggleUserSigninDialog:
      return {
        ...state,
        isUserSigninDialogVisible: action.payload,
        showLoginErrorMsg: false
      };
    case actionTypes.toggleResetPasswordDialog:
      return {
        ...state,
        isResetPasswordDialogVisible: !state.isResetPasswordDialogVisible,
        isUserSigninDialogVisible: false,
        resetPasswordStep1Active: !state.isResetPasswordDialogVisible,
        resetPasswordStep2Active: false
      };
    case actionTypes.toggle2FaVerificationDialog:
      return {
        ...state,
        is2FaVerificationDialogVisible: action.payload
      };
    case actionTypes.initiateSignin:
      return {
        ...state,
        isUserSigninDialogVisible: false,
        is2FaVerificationDialogVisible: action.payload.success,
        showLoginErrorMsg: false,
        phone: action.payload.phone
      };
    case actionTypes.signinFailed:
      return {
        ...state,
        showLoginErrorMsg: true
      };
    case actionTypes.resend2Fa:
      return {
        ...state,
        showResend2FaMsg: true
      };
    case actionTypes.finalizeSignIn: {
      const usr = JSON.parse(action.payload);
      return {
        ...state,
        isUserSigninDialogVisible: false,
        is2FaVerificationDialogVisible: false,
        isSignedIn: true,
        notificationsCount: usr.notificationsCount,
        showChatIconBadge: usr.showChatIconBadge ? usr.showChatIconBadge : false,
        usr
      };
    }
    case actionTypes._2faVerificationFailed:
      return {
        ...state,
        show2FaErrorMsg: true
      };
    case actionTypes.isSignedIn: {
      const usr = JSON.parse(localStorage.getItem('usr'));
      return {
        ...state,
        isSignedIn: action.payload.success,
        notificationsCount: usr.notificationsCount,
        showChatIconBadge: action.payload.showChatIconBadge ? action.payload.showChatIconBadge : false,
        usr
      };
    }
    case actionTypes.logout: {
      localStorage.removeItem('twoFactorAuthToken');
      localStorage.removeItem('usr');
      localStorage.removeItem('usernameOrEmail');
      localStorage.removeItem('token');
      localStorage.removeItem('isSignedIn');
      if (action.payload) {
        const newId = `${state.appAlerts.length + Math.floor(Math.random() * 100)}`;
        action.payload.id = newId;
        localStorage.setItem('alerts', JSON.stringify(action.payload));
      }
      history.push('/');
      return {
        ...state,
        isUserSigninDialogVisible: false,
        is2FaVerificationDialogVisible: false,
        isSignedIn: false,
        showResend2FaMsg: false,
        show2FaErrorMsg: false,
        usr: {},
        phone: '',
        showLoginErrorMsg: false
      };
    }
    case actionTypes.updateBanner:
      return {
        ...state,
        usr: JSON.parse(action.payload.usr)
      };
    case actionTypes.updatePracticeBanner:
      // location.reload();
      return {
        ...state,
        shouldReloadPracticeData: true
      };
    case actionTypes.updatePracticeAvatar:
      // location.reload();
      return {
        ...state,
        shouldReloadPracticeData: true
      };
    case actionTypes.updatePracticeProfile:
      // location.reload();
      return {
        ...state,
        shouldReloadPracticeData: true
      };
    case actionTypes.togglePracticeDataReloadFlag:
      return {
        ...state,
        shouldReloadPracticeData: action.payload
      };
    case actionTypes.updateAvatar:
      return {
        ...state,
        usr: JSON.parse(action.payload.usr)
      };
    case actionTypes.updateProfile:
      return {
        ...state,
        usr: JSON.parse(action.payload.usr)
      };
    case actionTypes.reloadData:
      return {
        ...state,
        usr: action.payload
      };
    case actionTypes.addProvider:
      return {
        ...state,
        providers: state.providers.concat(action.payload.provider)
      };
    case actionTypes.addFacility:
      return { ...state };
    case actionTypes.addPractice:
      return { ...state };
    case actionTypes.getProviders:
      return {
        ...state,
        providers: [].concat(action.payload.providers)
      };
    case actionTypes.sendTempPassword:
      return {
        ...state,
        showInvalidEmailErrorMsg: action.payload.isEmailInvalid,
        resetPasswordStep1Active: !action.payload.success,
        resetPasswordStep2Active: action.payload.success
      };
    case actionTypes.resetPassword:
      return {
        ...state,
        isUserSigninDialogVisible: true,
        is2FaVerificationDialogVisible: false,
        isResetPasswordDialogVisible: false,
        resetPasswordStep1Active: false,
        resetPasswordStep2Active: false,
        showInvalidEmailErrorMsg: false
      };
    // TODO: Delete actionTypes.initiateProviderSignIn reducer
    case actionTypes.initiateProviderSignIn:
      return {
        ...state,
        isUserSigninDialogVisible: false,
        is2FaVerificationDialogVisible: action.payload.success,
        showLoginErrorMsg: false,
        phone: action.payload.phone
      };
    case actionTypes.providerSigninFailed:
      return {
        ...state,
        showLoginErrorMsg: true
      };
    case actionTypes.updateProviderBanner:
      return {
        ...state,
        usr: JSON.parse(action.payload.usr)
      };
    case actionTypes.updateProviderAvatar:
      return {
        ...state,
        usr: JSON.parse(action.payload.usr)
      };
    case actionTypes.setActiveRecord:
      return {
        ...state,
        activeRecord: action.payload
      };
    case actionTypes.addAppAlert: {
      const newId = `${state.appAlerts.length + Math.floor(Math.random() * 100)}`;
      action.payload.id = newId;
      return {
        ...state,
        appAlerts: state.appAlerts.concat(action.payload)
      };
    }
    case actionTypes.deleteAppAlert:
      return {
        ...state,
        appAlerts: state.appAlerts.filter((el) => {
          return el.id !== action.payload;
        })
      };
    case actionTypes.hideAppAlert:
      return {
        ...state,
        appAlerts: state.appAlerts.map((el) => {
          if (el.id === action.payload) {
            el.showAlert = false;
          }

          return el;
        })
      };
    case actionTypes.getNotifications:
      return {
        ...state,
        notifications: action.payload
      };
    case actionTypes.setNotificationsCount: {
      const usr = Object.assign(state.usr, { notificationsCount: action.payload });
      localStorage.setItem('usr', JSON.stringify(usr));
      return {
        ...state,
        notificationsCount: usr.notificationsCount,
        usr
      };
    }
    case actionTypes.clearChatBadge:
      return {
        ...state,
        showChatIconBadge: false
      }
    default:
      return state;
  }
}
