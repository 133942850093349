import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { css, StyleSheet } from 'aphrodite/no-important';
import { gray, blue } from 'quantux-ui';
import { BASE_URL, getTimeString } from '../../utils';

const propTypes = {
  msgId: PropTypes.string,
  message: PropTypes.object,
  senderUserName: PropTypes.string
};

const defaultProps = {
  message: {
    msg: 'Lorem ipsum ... msg text here',
    senderDisplayName: 'Test User',
    senderUserName: 'testUser',
    dateTime: new Date(),
    status: 'read'
  }
}

const linkRegex = /((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?/gm;
const formatText = (text, title, isDownload = false) => {
  if (!isDownload) {
    return text.replace(linkRegex, (res) => `<a target='_blank' rel='noreferrer' href='${res}'>${res}</a>`);
  }
  if (title) {
    return text.replace(linkRegex, (res) => `<a target='_blank' rel='noreferrer' download href='${res}'>${title}</a>`);
  }
  return text.replace(linkRegex, (res) => `<a target='_blank' rel='noreferrer' download href='${res}'>${res}</a>`);
};

const ChatMessage = React.forwardRef((props, ref) => {
  const [avatarUrl, setAvatarUrl] = useState('  ');

  useEffect(() => {
    const url = new URL(`${BASE_URL}/users/getUserAvatar`);
    const params = { userName: props.senderUserName };
    url.search = new URLSearchParams(params).toString();
    fetch(url)
      .then(res => res.text())
      .then((result) => {
        const data = JSON.parse(result);
        // console.log(data);
        setAvatarUrl(data.avatar);
      })
      .catch(error => console.log('error', error))
  }, []);

  const styles = StyleSheet.create({
    chatMessage: {
      background: gray[800],
      padding: '8px',
      margin: '4px 0',
      borderRadius: '12px'
    },
    header: {
      height: '40px',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 8px'
    },
    userIconContainer: {
      width: '40px',
      height: '40px',
      minWidth: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    userIcon: {
      fontSize: '32px'
    },
    userText: {
      fontSize: '14px',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
      fontWeight: 'bold'
    },
    timeText: {
      fontSize: '12px',
      paddingTop: '2px'
    },
    userName: {
      color: blue[500]
    },
    body: {
      minHeight: '40px',
      padding: '0 40px',
      whiteSpace: 'pre-wrap',
      wordWrap: 'break-word'
    },
    footer: {
      height: '24px',
      textAlign: 'right',
      fontSize: '12px',
      fontWeight: 'bold'
    },
    headerEllipsis: {
      background: gray[300],
      color: 'black',
      minWidth: '24px',
      height: '24px',
      borderRadius: '50%',
      textAlign: 'center'
    }
  });

  let msg = (
    <div>
      <div dangerouslySetInnerHTML={{ __html: formatText(props.message.msg) }} />
    </div>
  );

  if (props.message.isImage) {
    msg = (
      <img
        alt='sent image'
        loading='lazy'
        src={props.message.msg}
        onClick={() => { window.open(props.message.msg, '_blank'); }}
        style={{
          marginTop: '8px',
          height: 'auto',
          width: '200px',
          maxHeight: '400px'
        }}
      />
    );
  }

  if (props.message.isDocument) {
    let icon = 'file-o';
    if (props.message.msg.endsWith('.doc') || props.message.msg.endsWith('.docx')) {
      icon = 'file-word-o';
    }
    if (props.message.msg.endsWith('.xls') || props.message.msg.endsWith('.xlsx')) {
      icon = 'file-excel-o';
    }
    if (props.message.msg.endsWith('.ppt') || props.message.msg.endsWith('.pptx')) {
      icon = 'file-powerpoint-o';
    }
    if (props.message.msg.endsWith('.pdf')) {
      icon = 'file-pdf-o';
    }
    if (props.message.msg.endsWith('.txt')) {
      icon = 'file-text';
    }
    msg = (
      <a
        href={props.message.msg}
        download
        rel='noreferrer'
        target='_blank'
      >
        <i
          className={`fa fa-${icon}`}
          style={{
            paddingTop: '8px',
            fontSize: '50px'
          }}
        />
        <div>
          {props.message.originalFileName}
          {/* <div dangerouslySetInnerHTML={{ __html: formatText(props.message.msg, props.message.originalFileName, true) }} /> */}
        </div>
      </a>
    );
  }

  return (
    <div
      className={css(styles.chatMessage)}
      key={props.msgId}
      ref={ref}
    >
      <div className={css(styles.header)}>
        <div className='fx'>
          <div className={css(styles.userIconContainer)}>
            {/* User avatar */}
            <img
              alt="avatar"
              loading='lazy'
              src={avatarUrl}
              style={{
                width: 'auto',
                height: '32px',
                maxHeight: '32px',
                borderRadius: '4px'
              }}
            />
          </div>
          <div className={css(styles.userText)}>
            <div>{props.message.senderDisplayName}</div>
            <div className={css(styles.userName)}>
              {`@${props.message.senderUserName}`}
            </div>
          </div>
          <div
            className={`ml-2 ${css(styles.timeText)}`}
            title='Date/Time of chat message'
          >
            {getTimeString(props.message.dateTime)}
          </div>
        </div>
        <div className={css(styles.headerEllipsis)}>
          <i className='fa fa-ellipsis-v' />
        </div>
      </div>
      <div className={css(styles.body)}>
        {msg}
      </div>
      {/* <div className={css(styles.footer)}>
        {getTimeString(props.message.dateTime)}
      </div> */}
    </div>
  );
});

ChatMessage.propsTypes = propTypes;
ChatMessage.defaultProps = defaultProps;
export default ChatMessage;
