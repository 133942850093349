const DEV_ROOT = 'http://localhost:8000';
// const PROD_ROOT = 'https://medpros.healthcare';
const PROD_ROOT = window.location.origin;
export const ROOT_URL = process.env.NODE_ENV === 'development' ? DEV_ROOT : PROD_ROOT;

export const BASE_URL = process.env.NODE_ENV === 'development'
  ? 'http://localhost:8000/api/v1'
  : `${window.location.origin}/api/v1`;

export const PUSHER_KEY = process.env.NODE_ENV === 'development' ? 'b89d86cb94d257c01a65' : '308a9227f7fcd28b74a0';

export const PUSHER_CONFIG = {
  cluster: 'us3',
  authEndpoint: `${BASE_URL}/users/pusher/auth`,
  disableStats: false
};

export const DRAWER_BREAK_POINT = 700;
