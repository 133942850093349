import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { css, StyleSheet } from 'aphrodite/no-important';
import { gray, blue } from 'quantux-ui';
import ProviderPracticeGrid from '../components/provider-dashboard/provider-practice-grid';
import AboutUser from '../components/provider-dashboard/about-user';
import { reloadData } from '../actions';
import { DRAWER_BREAK_POINT } from '../utils';

const drawerWidth = window.innerWidth <= DRAWER_BREAK_POINT ? window.innerWidth : 320;

const drawerTabs = {
  about: 'About Profile',
  scheduling: 'Scheduling',
  revenueCycle: 'Revenue Cycle Management',
  credentialing: 'Credentialing',
  practices: 'Practices',
  facilities: 'Facilities',
  companies: 'Companies'
};

const ProviderFacilities = (props) => {
  return (
    <h1>Provider Facilities</h1>
  );
};

const ProviderCompanies = (props) => {
  return (
    <h1>Provider Companies</h1>
  );
};

const Placeholder = props => <h1>{props.text}</h1>;

const UserProfile = (props) => {
  const [isDrawerOpen, toggleDrawer] = useState(true);
  const [isMobileLayout, setMobileLayout] = useState(false);
  const [activeComponent, setActiveComponent] = useState(drawerTabs.about);

  const styles = StyleSheet.create({
    container: {
      marginTop: '40px'
    },
    splitPanel: {
      display: 'flex',
      flexFlow: 'row nowrap',
      position: 'relative',
      background: '#002030',
      height: 'calc(100vh - 45px)',
      width: '100%',
      overflow: 'hidden',
      fontFamily: 'Lato'
    },
    drawer: {
      position: 'relative',
      height: '100%',
      borderRight: '1px solid #082840',
      transition: '0.3s',
      padding: '0',
      overflowY: 'auto',
      zIndex: '1900'
    },
    openDrawer: {
      left: '0',
      minWidth: `${drawerWidth}px`,
      width: `${drawerWidth}px`
    },
    hideDrawer: {
      transition: '0.3s',
      width: '0px',
      minWidth: '0px',
      left: `calc(-1 * ${drawerWidth}px)`
    },
    drawerHeader: {
      height: '40px',
      fontSize: '16px',
      background: '#001824',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      borderBottom: '0.5px solid #082840',
      marginLeft: '8px',
      cursor: 'pointer'
    },
    drawerHeaderIconContainer: {
      color: '#0080FF',
      height: '32px',
      width: '32px',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '4px',
      cursor: 'pointer'
    },
    drawerHeaderIcon: {
      fontSize: '24px',
      color: 'white',
      cursor: 'pointer'
    },
    drawerHeaderTextContainer: {
      textAlign: 'left',
      lineHeight: '14px',
      cursor: 'pointer'
    },
    drawerHeaderText: {
      textAlign: 'center',
      fontSize: '14px',
      color: '#60A0E0',
      cursor: 'pointer'
    },
    drawerHeaderTextSub: {
      color: '#0080FF',
      fontSize: '14px',
      cursor: 'pointer'
    },
    drawerTabsContainer: {
      height: 'calc(100% - 64px)',
      overflowY: 'auto'
    },
    drawerAccordian: {
      position: 'relative',
      display: 'block',
      transition: '0.5s ease',
      maxHeight: '2000px'
    },
    menuItem: {
      position: 'relative',
      left: '-3px',
      color: gray[300]
    },
    detail: {
      position: 'relative',
      transition: '0.3s',
      height: '100%'
    },
    detailHeader: {
      height: '40px',
      background: '#001824',
      display: 'flex',
      alignItems: 'center',
      // justifyContent: 'space-between',
      borderBottom: '0.5px solid #082840',
      padding: '0 8px'
    },
    profileBanner: {
      borderBottom: '1px solid transparent'
    },
    profileBannerImg: {
      width: '100%',
      height: 'auto',
      maxHeight: '220px'
    },
    bigUserCardContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid transparent'
    },
    tabs: {
      // borderBottom: '1px solid red'
    },
    popupMenuContainer: {
      background: '#083050',
      width: '150px',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'
    },
    popupMenuItem: {
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      color: '#5080B0'
    },
    menuItemIconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '40px',
      width: '40px'
    },
    menuItemIcon: {
      fontSize: '20px'
    },
    accordianRightBorder: {
      position: 'absolute',
      width: '1px',
      height: 'calc(100% - 40px)',
      background: '#305C82',
      left: '13px',
      top: '40px'
    },
    linkStyles: {
      display: 'flex',
      alignItems: 'center',
      color: gray[300]
    },
    selectedLink: {
      color: blue[500]
    },
    drawerFile: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '4px',
      color: blue[500],
      height: '40px'
    },
    drawerFileIcon: {
      fontSize: '32px',
      marginRight: '8px'
    },
    activeDrawerMenuItem: {
      borderLeft: `1px solid ${blue[500]}`,
      color: blue[500],
      background: gray[700]
    }
  });

  const handleWindowResize = () => {
    const isMobile = window.innerWidth <= 768;
    console.log('isMobile', isMobile);
    toggleDrawer(!isMobile);
    setMobileLayout(isMobile);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  const handleOnClickMiniUserCard = () => {
    setActiveComponent(drawerTabs.about);
    if (isMobileLayout) {
      toggleDrawer(false);
    }
  };

  let comp = null;
  let activeText = '';
  switch (activeComponent) {
    case drawerTabs.scheduling:
      comp = <Placeholder text='Placeholder for Scheduling' />;
      activeText = drawerTabs.scheduling;
      break;
    case drawerTabs.revenueCycle:
      comp = <Placeholder text='Placeholder for Revenue Cycle' />;
      activeText = drawerTabs.revenueCycle;
      break;
    case drawerTabs.credentialing:
      comp = <Placeholder text='Placeholder for Credentialing' />;
      activeText = drawerTabs.credentialing;
      break;
    case drawerTabs.practices:
      comp = (
        <ProviderPracticeGrid
          practices={props.usr.practices}
        />
      );
      // comp = <Placeholder text='Placeholder for Practices' />;
      activeText = drawerTabs.practices;
      break;
    case drawerTabs.facilities:
      comp = <ProviderFacilities />;
      activeText = drawerTabs.facilities;
      break;
    case drawerTabs.companies:
      comp = <ProviderCompanies />;
      activeText = drawerTabs.companies;
      break;
    case drawerTabs.about:
    default:
      comp = (
        <AboutUser
          userData={props.usr}
          reloadUserData={(usr) => {
            console.log('Reload user data ...');
            console.log(usr);
            props.reloadData(usr);
          }}
        />
      );
      // comp = <Placeholder text='Placeholder for AboutUser' />;
      activeText = drawerTabs.about;
  }

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.splitPanel)}>
        {/* Panel Drawer */}
        <div
          className={css(styles.drawer, isDrawerOpen ? styles.openDrawer : styles.hideDrawer)}
        >
          {/* Drawer header (Mini user card) */}
          <div
            className={css(styles.drawerHeader)}
            onClick={handleOnClickMiniUserCard}
          >
            {/* Header icon */}
            <div className={css(styles.drawerHeaderIconContainer)}>
              {props.usr && props.usr.avatarImageUrl
                ? <img
                    src={props.usr.avatarImageUrl}
                    alt="avatar"
                    style={{
                      width: 'auto',
                      height: '28px',
                      maxHeight: '28px',
                      borderRadius: '4px'
                    }}
                  />
                : <i className={`fa fa-user ${css(styles.drawerHeaderIcon)}`} />
              }
            </div>
            {/* Header text */}
            <div className={css(styles.drawerHeaderTextContainer)}>
              <div className={css(styles.drawerHeaderText)}>
                {`${props.usr.displayName ? props.usr.displayName : 'N/A'}`}
              </div>
              <div className={css(styles.drawerHeaderTextSub)}>
                {`@${props.usr.userName ? props.usr.userName : 'n/a'}`}
              </div>
            </div>
          </div>
          {/* Drawer tabs */}
          <div className={css(styles.drawerTabsContainer)}>
            {/* My Provider Folder */}
            <div className={css(styles.drawerAccordian)}>
              {/* Accordian right border */}
              <div className={css(styles.accordianRightBorder)} />
              <div className={css(styles.drawerFile)}>
                <i className={`fa fa-caret-down ${css(styles.drawerFileIcon)}`} />
                <div>
                  My Provider Folder
                </div>
              </div>
              {/* Scheduling */}
              <div
                className={`noselect ml-3 drawer-menu-item ${css(styles.menuItem, activeComponent === drawerTabs.scheduling && styles.activeDrawerMenuItem)}`}
                onClick={() => {
                  setActiveComponent(drawerTabs.scheduling);
                  if (isMobileLayout) {
                    toggleDrawer(false);
                  }
                }}
              >
                {/* Icon */}
                <div className='icon'>
                  <i className='fa fa-calendar' />
                </div>
                <div
                  className="text"
                  style={{ fontSize: '1rem', lineHeight: '14px' }}
                >
                  Scheduling
                </div>
              </div>
              {/* Revenue Cycle Management */}
              <div
                className={`noselect ml-3 drawer-menu-item ${css(styles.menuItem)} ${activeComponent === drawerTabs.revenueCycle ? 'active' : ''}`}
                onClick={() => {
                  setActiveComponent(drawerTabs.revenueCycle);
                  if (isMobileLayout) {
                    toggleDrawer(false);
                  }
                }}
              >
                {/* Icon */}
                <div className='icon'>
                  <svg
                    width="24"
                    height="24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M23.156 3a.843.843 0 00-.843.844v2.015A12.007 12.007 0 0018.633 2 11.94 11.94 0 0012 0a11.956 11.956 0 00-8.18 3.22 11.958 11.958 0 00-3.787 7.873.845.845 0 001.683.127 10.279 10.279 0 013.253-6.765A10.28 10.28 0 0112 1.687c2.04 0 4.01.593 5.7 1.718a10.304 10.304 0 013.488 3.908h-2.344a.843.843 0 100 1.687h4.312A.843.843 0 0024 8.156V3.844A.843.843 0 0023.156 3zm.03 9.002a.845.845 0 00-.904.778 10.278 10.278 0 01-3.253 6.764A10.27 10.27 0 0112 22.313c-2.04 0-4.01-.593-5.7-1.718a10.304 10.304 0 01-3.487-3.907h2.343a.843.843 0 100-1.687H.844a.843.843 0 00-.844.844v4.312a.843.843 0 101.688 0v-2.015A12.024 12.024 0 005.364 22C7.334 23.309 9.625 24 12 24c3.047 0 5.953-1.144 8.18-3.22a11.945 11.945 0 003.785-7.873.843.843 0 00-.778-.905zM10.654 9.833c0 .634.487 1.04 2 1.434 1.514.393 3.134 1.04 3.134 2.933 0 1.367-1.034 2.12-2.334 2.367V18h-2v-1.447C10.173 16.28 9.08 15.46 9 14h1.467c.073.787.613 1.4 1.986 1.4 1.474 0 1.8-.733 1.8-1.193 0-.62-.333-1.207-2-1.607-1.86-.447-3.133-1.213-3.133-2.753 0-1.287 1.04-2.127 2.333-2.407V6h2v1.46c1.394.34 2.094 1.393 2.14 2.54H14.12c-.04-.833-.48-1.4-1.667-1.4-1.126 0-1.8.507-1.8 1.233z"
                      fill={activeComponent === drawerTabs.revenueCycle ? blue[500] : gray[300]}
                    />
                  </svg>
                </div>
                <div
                  className="text"
                  style={{ fontSize: '1rem', lineHeight: '14px' }}
                >
                  Revenue Cycle Management
                </div>
              </div>
              {/* Credentialing */}
              <div
                className={`noselect ml-3 drawer-menu-item ${css(styles.menuItem, activeComponent === drawerTabs.credentialing && styles.activeDrawerMenuItem)}`}
                onClick={() => {
                  setActiveComponent(drawerTabs.credentialing);
                  if (isMobileLayout) {
                    toggleDrawer(false);
                  }
                }}
              >
                {/* Icon */}
                <div className='icon'>
                  <i className='fa fa-shield' />
                </div>
                <div
                  className="text"
                  style={{ fontSize: '1rem', lineHeight: '14px' }}
                >
                  Credentialing
                </div>
              </div>
            </div>
            {/* Entity Admin Privileges */}
            <div className={css(styles.drawerAccordian)}>
              {/* Accordian right border */}
              <div className={css(styles.accordianRightBorder)} />
              <div className={css(styles.drawerFile)}>
                <i className={`fa fa-caret-down ${css(styles.drawerFileIcon)}`} />
                <div>
                  Entity Admin Privileges
                </div>
              </div>
              {/* Practices */}
              <div
                className={`noselect ml-3 drawer-menu-item ${css(styles.menuItem, activeComponent === drawerTabs.practices && styles.activeDrawerMenuItem)}`}
                onClick={() => {
                  setActiveComponent(drawerTabs.practices);
                  if (isMobileLayout) {
                    toggleDrawer(false);
                  }
                }}
              >
                {/* Icon */}
                <div className='icon'>
                  <i className='fa fa-users' />
                </div>
                <div
                  className="text"
                  style={{ fontSize: '1rem', lineHeight: '14px' }}
                >
                  Practices
                </div>
              </div>
              {/* Facilities */}
              <div
                className={`noselect ml-3 drawer-menu-item ${css(styles.menuItem, activeComponent === drawerTabs.facilities && styles.activeDrawerMenuItem)}`}
                onClick={() => {
                  setActiveComponent(drawerTabs.facilities);
                  if (isMobileLayout) {
                    toggleDrawer(false);
                  }
                }}
              >
                {/* Icon */}
                <div className='icon'>
                  <i className='fa fa-hospital-o' />
                </div>
                <div
                  className="text"
                  style={{ fontSize: '1rem', lineHeight: '14px' }}
                >
                  Facilities
                </div>
              </div>
              {/* Companies */}
              <div
                className={`noselect ml-3 drawer-menu-item ${css(styles.menuItem, activeComponent === drawerTabs.companies && styles.activeDrawerMenuItem)}`}
                onClick={() => {
                  setActiveComponent(drawerTabs.companies);
                  if (isMobileLayout) {
                    toggleDrawer(false);
                  }
                }}
              >
                {/* Icon */}
                <div className='icon'>
                  <i className='fa fa-building' />
                </div>
                <div
                  className="text"
                  style={{ fontSize: '1rem', lineHeight: '14px' }}
                >
                  Companies
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Panel details */}
        <div
          className={css(styles.detail)}
          style={{
            width: isDrawerOpen ? `calc(100vw - ${drawerWidth}px)` : '100vw'
          }}
        >
          <div className={css(styles.detailHeader)}>
            <div
              onClick={() => toggleDrawer(!isDrawerOpen)}
            >
              <i
                className={`mr-2 fa ${isDrawerOpen ? 'fa-chevron-circle-left' : 'fa-chevron-circle-right'}`}
                style={{ fontSize: '24px' }}
              />
            </div>
            <div style={{ minWidht: '150px' }}>
              {activeText}
            </div>
          </div>
          {/* Active component */}
          {comp}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps({ user }) {
  return { ...user };
}

export default connect(mapStateToProps, { reloadData })(UserProfile);
