import React from 'react';

const ProfileAccessManager = (props) => {
  return (
    <div>
      <h1>Profile Access Manager Placeholder</h1>
    </div>
  );
}

export default ProfileAccessManager;
