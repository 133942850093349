import React from 'react';
// import { css, StyleSheet } from 'aphrodite/no-important';
import { Heading } from 'quantux-ui';

// const styles = StyleSheet.create({
//   codeContainer: {
//     width: '700px'
//   }
// });

const TypographyDemo = () => {
  return (
    <div>
      {/* Typography Demo */}
      <Heading canSelect={false} level={1}>h1: This is level1 heading</Heading>
      <Heading level={2}>h2: This is level2 heading</Heading>
      <Heading level={3}>h3: This is level3 heading</Heading>
      <Heading level={4}>h4: This is level4 heading</Heading>
      <Heading level={5}>h5: This is level5 heading</Heading>
      <Heading level={6}>h6: This is level6 heading</Heading>
    </div>
  );
};
export default TypographyDemo;
