import React from 'react';

const About = (props) => {
  return (
    <div>
      {props.description}
    </div>
  );
};

export default About;
