import React from 'react';

const Notifications = (props) => {
  return (
    <div>
      <h1>Notifications & Alerts Placeholder</h1>
    </div>
  );
}

export default Notifications;
