import React, { Component } from 'react';
import { connect } from 'react-redux';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Link, Switch, Route, withRouter } from 'react-router-dom';
import { ThemeContext } from 'quantux-ui';
import { history } from '../utils';
import { addProvider, addFacility, addPractice } from '../actions';
import { AboutProfile } from '../components/about-profile';
import UsersDataGrid from '../components/users-profiles/users-data-grid';
import FacilitiesDataGrid from '../components/my-facilities/facilities-data-grid';
import PracticesDataGrid from '../components/my-practices/practices-data-grid';
import PracticeDashboard from '../components/practice-dashboard/practice-dashboard';
import ProviderDashboard from '../components/provider-dashboard/provider-dashboard';
import { Placeholder } from '../components/placeholder';

const styles = StyleSheet.create({
  splitPanel: {
    display: 'flex',
    flexFlow: 'row nowrap',
    // position: 'relative',
    background: '#002030',
    height: 'calc(100vh - 42px)',
    width: '100%',
    overflow: 'hidden',
    fontFamily: 'Lato',
    marginTop: '40px'
  },
  drawer: {
    position: 'relative',
    height: 'calc(100vh - 40px)',
    minWidth: '320px',
    width: '320px',
    borderRight: '1px solid #082840',
    transition: '0.3s',
    left: '0',
    padding: '0',
    overflowY: 'auto',
    zIndex: '1900'
  },
  hideDrawer: {
    transition: '0.3s',
    left: 'calc(-1 * 320px)'
  },
  drawerHeader: {
    height: '40px',
    fontSize: '16px',
    background: '#001824',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    borderBottom: '0.5px solid #082840',
    marginLeft: '8px',
    cursor: 'pointer'
  },
  drawerHeaderIconContainer: {
    color: '#0080FF',
    height: '32px',
    width: '32px',
    background: '#0080FF',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '4px',
    cursor: 'pointer'
  },
  drawerHeaderIcon: {
    fontSize: '24px',
    color: 'white',
    cursor: 'pointer'
  },
  drawerHeaderTextContainer: {
    textAlign: 'left',
    lineHeight: '14px',
    cursor: 'pointer'
  },
  drawerHeaderText: {
    textAlign: 'center',
    fontSize: '14px',
    color: '#60A0E0',
    cursor: 'pointer'
  },
  drawerHeaderTextSub: {
    color: '#0080FF',
    fontSize: '14px',
    cursor: 'pointer'
  },
  drawerTabsContainer: {
    height: 'calc(100% - 64px)',
    overflowY: 'auto'
  },
  drawerAccordian: {
    position: 'relative',
    display: 'block',
    transition: '0.5s ease',
    maxHeight: '2000px'
  },
  drawerAccordianCollapse: {
    display: 'block',
    transition: '0.26s ease',
    overflow: 'hidden',
    maxHeight: '45px'
  },
  detail: {
    // position: 'relative',
    transition: '0s',
    height: 'calc(100vh - 40px)',
    width: 'calc(100vw - 320px)',
    left: '0'
  },
  detailExapnd: {
    transition: '0.2s',
    left: 'calc(-1 * 320px)',
    width: '100vw'
  },
  detailHeader: {
    height: '40px',
    background: '#001824',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '0.5px solid #082840'
  },
  toggleDrawerBtn: {
    marginLeft: '4px',
    marginRight: '4px',
    width: '24px',
    height: '24px',
    background: '#004080',
    borderRadius: '50%',
    color: '#0080FF',
    paddingTop: '1px',
    paddingLeft: '7px'
  },
  accordianRightBorder: {
    position: 'absolute',
    width: '1px',
    height: 'calc(100% - 38px)',
    background: '#305C82',
    left: '13px',
    top: '39px'
  },
  menuItem: {
    position: 'relative',
    left: '-3px'
  }
});

class AdminProfile extends Component {
  state = {
    isDrawerOpen: true,
    activeItem: 'about',
    isMobileLayout: false
  };

  componentWillMount() {
    this.setState({
      isMobileLayout: window.innerWidth <= 700
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize = () => {
    const isMobileLayout = window.innerWidth <= 700;
    this.setState({ isMobileLayout });
  }

  handleDrawerItemClick = (item) => {
    this.setState({
      activeItem: item
    });
  }

  toggleDrawer = () => {
    this.setState({
      isDrawerOpen: !this.state.isDrawerOpen
    });
  }

  handleOnClickMiniUserCard = () => {
    console.log('Clicked on mini user card!');
    history.push('/admin/profile/about');
  };

  static contextType = ThemeContext;

  render() {
    const { url, path } = this.props.match;
    const { pathname } = this.props.location;
    const { gray, blue } = this.context.colors;

    const lStyles = StyleSheet.create({
      linkStyles: {
        display: 'flex',
        alignItems: 'center',
        color: gray[300]
      },
      selectedLink: {
        color: blue[500]
      },
      drawerFile: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '4px',
        color: blue[500],
        height: '40px'
      },
      drawerFileIcon: {
        fontSize: '32px',
        marginRight: '8px'
      },
      activeDrawerMenuItem: {
        borderLeft: `1px solid ${blue[500]}`
      }
    });
    const {
      isMobileLayout,
      isDrawerOpen
      // activeItem
    } = this.state;

    let titleText = '';
    switch (pathname) {
      case '/admin/users':
        titleText = 'User Profiles';
        break;
      case '/admin/practices':
        titleText = 'Practices';
        break;
      case '/admin/facilities':
        titleText = 'Facilities';
        break;
      case '/admin/companies':
        titleText = 'Companies';
        break;
      default:
        break;
    }

    return (
      <div className={css(styles.splitPanel)}>
        {/* -------- Panel Drawer -------- */}
        <div className={css(styles.drawer, !isDrawerOpen && styles.hideDrawer)}>
          {/* Drawer header (Mini user card) */}
          <div
            className={css(styles.drawerHeader)}
            onClick={this.handleOnClickMiniUserCard}
          >
            {/* Header icon */}
            <div className={css(styles.drawerHeaderIconContainer)}>
              {this.props.usr && this.props.usr.avatarImageUrl && this.props.usr.avatarImageUrl.length > 0
                ? <img
                    src={this.props.usr.avatarImageUrl}
                    alt="avatar"
                    style={{
                      width: 'auto',
                      height: '32px',
                      maxHeight: '32px',
                      borderRadius: '4px'
                    }}
                  />
                : <i className={`fa fa-user-md ${css(styles.drawerHeaderIcon)}`} />
              }

            </div>
            {/* Header text */}
            <div className={css(styles.drawerHeaderTextContainer)}>
              <div className={css(styles.drawerHeaderText)}>
                {this.props.usr ? `${this.props.usr.displayName}, ` : ''}
              </div>
              <div className={css(styles.drawerHeaderTextSub)}>
                {this.props.usr ? `@${this.props.usr.userName}` : ''}
              </div>
            </div>
          </div>
          {/* Drawer tabs */}
          <div className={css(styles.drawerTabsContainer)}>
            {/* Profiles - Group */}
            <div className={css(styles.drawerAccordian)}>
              {/* Accordian right border */}
              <div className={css(styles.accordianRightBorder)} />
              <div className={css(lStyles.drawerFile)}>
                <i className={`fa fa-caret-down ${css(lStyles.drawerFileIcon)}`} />
                <div className='noselect'>
                  Profiles
                </div>
              </div>
              {/* User Profiles - Item */}
              <div
                className={`ml-3 noselect drawer-menu-item ${pathname.endsWith('/users') ? 'active' : ''} ${css(styles.menuItem, pathname.endsWith('/users') && lStyles.activeDrawerMenuItem)}`}
              >
                <Link
                  to={`${url}/users`}
                  className={css(lStyles.linkStyles, pathname.endsWith('/users') && lStyles.selectedLink)}
                >
                  {/* Icon */}
                  <div className='icon'>
                    <svg
                      width="24"
                      height="24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2 0a2 2 0 00-2 2v20a2 2 0 002 2h20a2 2 0 002-2V2a2 2 0 00-2-2H2zm14.875 7.125c0 2.49-2.01 4.5-4.5 4.5s-4.5-2.01-4.5-4.5 2.01-4.5 4.5-4.5 4.5 2.01 4.5 4.5zm-13.5 12c0-3 6-4.65 9-4.65s9 1.65 9 4.65v1.5h-18v-1.5z"
                        fill={pathname.endsWith('/providers') ? blue[500] : '#60A0E0'}
                      />
                    </svg>
                  </div>
                  <div
                    className="text"
                    style={{ fontSize: '1rem', lineHeight: '14px' }}
                  >
                    User Profiles
                  </div>
                </Link>
              </div>
              {/* My Practices - Item */}
              <div
                className={`ml-3 noselect drawer-menu-item ${pathname.endsWith('/practices') ? 'active' : ''} ${css(styles.menuItem, pathname.endsWith('/practices') && lStyles.activeDrawerMenuItem)}`}
              >
                <Link
                  to={`${url}/practices`}
                  className={css(lStyles.linkStyles, pathname.endsWith('/practices') && lStyles.selectedLink)}
                >
                  <div className="icon">
                    <svg
                      width="19"
                      height="21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M15.748 8.861a5.49 5.49 0 011.626 1.097A5.252 5.252 0 0119 13.733v3.854c0 1.17-.983 2.122-2.187 2.122h-5.966A2.197 2.197 0 018.835 21H2.187C.98 21 0 20.046 0 18.877v-3.85c0-1.425.576-2.766 1.619-3.779a5.46 5.46 0 011.626-1.096A4.813 4.813 0 011.5 8.632a4.58 4.58 0 01-.836-2.64c0-2.593 2.172-4.701 4.844-4.701 1.358 0 2.632.544 3.548 1.5A4.717 4.717 0 0110.683.866 4.913 4.913 0 0113.484 0c2.671 0 4.844 2.109 4.844 4.702 0 .949-.29 1.86-.836 2.642a4.784 4.784 0 01-1.744 1.517zm-2.265-7.199c-.838 0-1.624.316-2.214.89a2.965 2.965 0 00-.917 2.15c0 .813.325 1.575.917 2.148.59.575 1.376.89 2.214.89.838 0 1.623-.315 2.213-.89a2.974 2.974 0 000-4.297 3.148 3.148 0 00-2.213-.89zM2.375 5.992c0-.813.325-1.575.918-2.148a3.148 3.148 0 012.213-.891c.839 0 1.624.316 2.214.89a2.973 2.973 0 010 4.298c-.59.575-1.375.89-2.214.89a3.162 3.162 0 01-2.216-.89 2.976 2.976 0 01-.915-2.149zm6.788 13.205a.44.44 0 00.14-.322v-3.849c0-.96-.384-1.87-1.077-2.562l-.007-.01-.032-.032a3.826 3.826 0 00-2.68-1.083c-1.01 0-1.961.384-2.682 1.083a3.603 3.603 0 00-1.115 2.602v3.85c0 .253.212.459.472.459H8.83c.126 0 .244-.05.333-.136zm-.285-8.395a5.472 5.472 0 00-1.108-.653 4.791 4.791 0 002.163-2.247c.37.386.804.711 1.283.957a5.504 5.504 0 00-1.625 1.096 5.712 5.712 0 00-.713.847zm7.93 7.243c.26 0 .472-.206.472-.459v-3.852c0-.98-.395-1.904-1.115-2.603a3.825 3.825 0 00-2.681-1.082 3.823 3.823 0 00-3.387 2.024c.603.875.92 1.892.92 2.951v3.02h5.791z"
                        fill={pathname.endsWith('/practices') ? blue[500] : '#60A0E0'}
                      />
                    </svg>
                  </div>
                  <div
                    className="text"
                    style={{ fontSize: '1rem', lineHeight: '14px' }}
                  >
                    Practice Profiles
                  </div>
                </Link>
              </div>
              {/* My Facilities - Item */}
              <div
                className={`ml-3 noselect drawer-menu-item ${pathname.endsWith('/facilities') ? 'active' : ''} ${css(styles.menuItem, pathname.endsWith('/facilities') && lStyles.activeDrawerMenuItem)}`}
              >
                <Link
                  to={`${url}/facilities`}
                  className={css(lStyles.linkStyles, pathname.endsWith('/facilities') && lStyles.selectedLink)}
                >
                  <div className="icon">
                    <svg
                      width="24"
                      height="24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M0 1a1 1 0 011-1h13.273a1 1 0 011 1v23H0V1zm4.364 13.182h6.545v8.727H4.364v-8.727zm.91-4.368h1.199v-2.4h2.881v2.404h1.2V3.822h-1.2v2.393H6.473V3.818h-1.2v5.996zM15.5 5.455h6.636a1 1 0 011 1v17.546H15.5V5.454zm1.09 2.181h2.182v2.182H16.59V7.636zm5.456 0h-2.182v2.182h2.181V7.636zm-2.182 3.273h2.181v2.182h-2.181v-2.182zm-1.092 0H16.59v2.182h2.182v-2.182zm-2.182 3.273h2.182v2.182H16.59v-2.182zm5.456 0h-2.182v2.182h2.181v-2.182z"
                        fill={pathname.endsWith('/facilities') ? blue[500] : '#60A0E0'}
                      />
                    </svg>
                  </div>
                  <div
                    className="text"
                    style={{ fontSize: '1rem', lineHeight: '14px' }}
                  >
                    Facility Profiles
                  </div>
                </Link>
              </div>
              {/* My Companies - Item */}
              <div
                className={`ml-3 noselect drawer-menu-item ${pathname.endsWith('/companies') ? 'active' : ''} ${css(styles.menuItem, pathname.endsWith('/companies') && lStyles.activeDrawerMenuItem)}`}
              >
                <Link
                  to={`${url}/companies`}
                  className={css(lStyles.linkStyles, pathname.endsWith('/companies') && lStyles.selectedLink)}
                >
                  <div className="icon">
                    <svg
                      width="23"
                      height="20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.111 0v4.444h11.111V20H0V0h11.111zM2.222 17.777h2.222v-2.222H2.222v2.222zm2.222-4.443H2.222v-2.222h2.222v2.222zM2.222 8.889h2.222V6.667H2.222v2.222zm2.222-4.445H2.222V2.222h2.222v2.222zm2.224 13.333H8.89v-2.222H6.668v2.222zm2.222-4.443H6.668v-2.222H8.89v2.222zM6.668 8.889H8.89V6.667H6.668v2.222zM8.89 4.444H6.668V2.222H8.89v2.222zm2.22 13.334h8.888V6.667H11.11v2.222h2.223v2.222h-2.223v2.222h2.223v2.223h-2.223v2.222zm6.668-8.89h-2.222v2.223h2.222V8.888zm-2.222 4.445h2.222v2.223h-2.222v-2.223z"
                        fill={pathname.endsWith('/companies') ? blue[500] : '#60A0E0'}
                      />
                    </svg>
                  </div>
                  <div
                    className="text"
                    style={{ fontSize: '1rem', lineHeight: '14px' }}
                  >
                    Company Profiles
                  </div>
                </Link>
              </div>
              {/* Payer Profiles - Item */}
              <div
                className={`ml-3 noselect drawer-menu-item ${pathname.endsWith('/payers') ? 'active' : ''} ${css(styles.menuItem, pathname.endsWith('/payers') && lStyles.activeDrawerMenuItem)}`}
              >
                <Link
                  to={`${url}/payers`}
                  className={css(lStyles.linkStyles, pathname.endsWith('/payers') && lStyles.selectedLink)}
                >
                  <div className="icon">
                    <svg
                      width="23"
                      height="20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.111 0v4.444h11.111V20H0V0h11.111zM2.222 17.777h2.222v-2.222H2.222v2.222zm2.222-4.443H2.222v-2.222h2.222v2.222zM2.222 8.889h2.222V6.667H2.222v2.222zm2.222-4.445H2.222V2.222h2.222v2.222zm2.224 13.333H8.89v-2.222H6.668v2.222zm2.222-4.443H6.668v-2.222H8.89v2.222zM6.668 8.889H8.89V6.667H6.668v2.222zM8.89 4.444H6.668V2.222H8.89v2.222zm2.22 13.334h8.888V6.667H11.11v2.222h2.223v2.222h-2.223v2.222h2.223v2.223h-2.223v2.222zm6.668-8.89h-2.222v2.223h2.222V8.888zm-2.222 4.445h2.222v2.223h-2.222v-2.223z"
                        fill={pathname.endsWith('/payers') ? blue[500] : '#60A0E0'}
                      />
                    </svg>
                  </div>
                  <div
                    className="text"
                    style={{ fontSize: '1rem', lineHeight: '14px' }}
                  >
                    Payer Profiles
                  </div>
                </Link>
              </div>
            </div>
            {/* Revenue Cycle Management (RCM) */}
            <div className={css(styles.drawerAccordian)}>
              {/* Accordian right border */}
              <div className={css(styles.accordianRightBorder)} />
              <div className={css(lStyles.drawerFile)}>
                <i className={`fa fa-caret-down ${css(lStyles.drawerFileIcon)}`} />
                <div className='noselect'>
                  Revenue Cycle Managment (RCM)
                </div>
              </div>
              {/* My Providers - Item */}
              <div
                className={`ml-3 noselect drawer-menu-item ${pathname.endsWith('/cases') ? 'active' : ''} ${css(styles.menuItem, pathname.endsWith('/cases') && lStyles.activeDrawerMenuItem)}`}
              >
                <Link
                  to={`${url}/cases`}
                  className={css(lStyles.linkStyles, pathname.endsWith('/cases') && lStyles.selectedLink)}
                >
                  {/* Icon */}
                  <div className='icon'>
                    <svg
                      width="24"
                      height="18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.5 4.313h2.813C23.244 4.313 24 5.066 24 6v10.313c0 .932-.755 1.687-1.688 1.687H1.688A1.686 1.686 0 010 16.312V6c0-.933.755-1.688 1.688-1.688H4.5V1.688C4.5.755 5.255 0 6.188 0h11.625c.932 0 1.687.755 1.687 1.688v2.625zm-1.688-2.625H6.188v2.625h11.625V1.688zM22.313 6v10.313H1.688V6h20.625zm-11.156 6H9.094a.843.843 0 110-1.688h2.062V8.25a.843.843 0 111.688 0v2.063h2.062a.843.843 0 110 1.687h-2.062v2.063a.843.843 0 11-1.688 0V12z"
                        fill={pathname.endsWith('/cases') ? blue[500] : '#60A0E0'}
                      />
                    </svg>
                  </div>
                  <div
                    className="text"
                    style={{ fontSize: '1rem', lineHeight: '14px' }}
                  >
                    Case Ledger
                  </div>
                </Link>
              </div>
              {/* Payment Ledger- Item */}
              <div
                className={`ml-3 noselect drawer-menu-item ${pathname.endsWith('/payments') ? 'active' : ''} ${css(styles.menuItem, pathname.endsWith('/payments') && lStyles.activeDrawerMenuItem)}`}
              >
                <Link
                  to={`${url}/payments`}
                  className={css(lStyles.linkStyles, pathname.endsWith('/payments') && lStyles.selectedLink)}
                >
                  <div className="icon">
                    <svg
                      width="24"
                      height="24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23.156 3a.843.843 0 00-.843.844v2.015A12.007 12.007 0 0018.633 2 11.94 11.94 0 0012 0a11.956 11.956 0 00-8.18 3.22 11.958 11.958 0 00-3.787 7.873.845.845 0 001.683.127 10.279 10.279 0 013.253-6.765A10.28 10.28 0 0112 1.687c2.04 0 4.01.593 5.7 1.718a10.304 10.304 0 013.488 3.908h-2.344a.843.843 0 100 1.687h4.312A.843.843 0 0024 8.156V3.844A.843.843 0 0023.156 3zm.03 9.002a.845.845 0 00-.904.778 10.278 10.278 0 01-3.253 6.764A10.27 10.27 0 0112 22.313c-2.04 0-4.01-.593-5.7-1.718a10.304 10.304 0 01-3.487-3.907h2.343a.843.843 0 100-1.687H.844a.843.843 0 00-.844.844v4.312a.843.843 0 101.688 0v-2.015A12.024 12.024 0 005.364 22C7.334 23.309 9.625 24 12 24c3.047 0 5.953-1.144 8.18-3.22a11.945 11.945 0 003.785-7.873.843.843 0 00-.778-.905zM9.93 9.472c0 .74.568 1.214 2.333 1.672 1.766.46 3.656 1.214 3.656 3.423 0 1.594-1.206 2.473-2.722 2.76V19h-2.334v-1.688C9.37 16.993 8.093 16.037 8 14.333h1.711c.086.918.716 1.634 2.318 1.634 1.719 0 2.1-.856 2.1-1.393 0-.723-.389-1.407-2.333-1.874-2.17-.521-3.656-1.416-3.656-3.212 0-1.501 1.213-2.481 2.722-2.808V5h2.334v1.703c1.625.397 2.442 1.626 2.496 2.964h-1.719c-.046-.973-.56-1.634-1.944-1.634-1.315 0-2.1.591-2.1 1.44z"
                        fill={pathname.endsWith('/payments') ? blue[500] : '#60A0E0'}
                      />
                    </svg>
                  </div>
                  <div
                    className="text"
                    style={{ fontSize: '1rem', lineHeight: '14px' }}
                  >
                    Payment Ledger
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* -------- Panel Detail -------- */}
        <div className={css(styles.detail, !isDrawerOpen && styles.detailExapnd)}>
          {/* TAB HEADER */}
          <div className={css(styles.detailHeader)}>
            {/* TABS */}
            <div
              style={{
                display: 'flex',
                flexFlow: 'row nowrap',
                alignItems: 'center',
                justifyContent: 'left'
              }}
            >
              {/* DRAWER BUTTON */}
              {isMobileLayout
                ? <div
                  className={css(styles.toggleDrawerBtn)}
                  onClick={this.toggleDrawer}
                >
                  <i className={`fa ${isDrawerOpen ? 'fa-chevron-right' : 'fa-chevron-right'}`} />
                </div>
                : <div
                  onClick={this.toggleDrawer}
                  style={{
                    marginLeft: '4px',
                    marginRight: '4px',
                    width: '24px',
                    height: '24px',
                    background: '#004080',
                    borderRadius: '50%',
                    color: '#0080FF',
                    paddingTop: '1px',
                    paddingLeft: isDrawerOpen ? '5px' : '7px'
                  }}
                >
                  <i className={`fa ${isDrawerOpen ? 'fa-chevron-left' : 'fa-chevron-right'}`} />
                </div>
              }
              <div>
                <i
                  className="fa fa-square"
                  style={{
                    fontSize: '20px',
                    marginLeft: '4px',
                    marginRight: '8px'
                  }}
                />
              </div>
              <div
                style={{
                  fontFamily: 'Lato',
                  fontSize: '16px',
                  fontWeight: 'bold'
                }}
              >
                {titleText}
              </div>
            </div>
          </div>

          {/* Split panel detail active component */}
          <div
            className="pt-2"
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: !isMobileLayout && isDrawerOpen ? 'calc(100vw - 320px)' : '100vw',
              maxWidth: !isMobileLayout && isDrawerOpen ? 'calc(100vw - 320px)' : '100vw',
              minWidth: !isMobileLayout && isDrawerOpen ? 'calc(100vw - 320px)' : '100vw',
              height: 'calc(100% - 40px)',
              maxHeight: 'calc(100% - 40px)',
              minHeight: 'calc(100% - 40px)'
            }}
          >
            <div
              style={{
                marginRight: '8px',
                marginLeft: '8px',
                overflow: 'auto',
                width: '100%'
                // flexGrow: '1'
                // maxWidth: '1500px'

              }}
            >
              <Switch>
                {/* About route (Drawer header - user card click) */}
                <Route path={`${path}/profile`}>
                  <AboutProfile />
                </Route>
                {/* Providers route */}
                <Route path={`${path}/users`}>
                  <Switch>
                    <Route path={`${path}/users/provider`}>
                      <ProviderDashboard />
                    </Route>
                    <Route path={`${path}/users`}>
                      <UsersDataGrid addUser={this.props.addProvider} />
                    </Route>
                  </Switch>
                </Route>
                {/* Practices route */}
                <Route path={`${path}/practices`}>
                  <PracticesDataGrid />
                </Route>
                {/* Facilities route */}
                <Route path={`${path}/facilities`}>
                  <FacilitiesDataGrid />
                </Route>
                {/* Companies route */}
                <Route path={`${path}/companies`}>
                  <Placeholder text='My Companies placeholder' />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ user }) {
  return { ...user };
}

export default withRouter(connect(mapStateToProps, { addProvider, addFacility, addPractice })(AdminProfile));
