import React, { Fragment, useState } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { DialogBox, Button, TextInputWithLabel } from 'quantux-ui';
import {
  toggle2FaVerificationDialog,
  finalizeSignIn,
  resend2Fa
} from '../../actions';

const propTypes = {
  is2FaVerificationDialogOpen: PropTypes.bool,
  showResend2FaMsg: PropTypes.bool,
  show2FaErrorMsg: PropTypes.bool,
  phone: PropTypes.string,
  toggle2FaVerificationDialog: PropTypes.func,
  finalizeSignIn: PropTypes.func,
  resend2Fa: PropTypes.func
};
const defaultProps = {};

const TwoFactorAuthDialog = (props) => {
  const [_2faCode, set2FaCode] = useState('');

  const dialogWidth = window.innerWidth <= 576 ? (window.innerWidth - 16) : 350;

  const handleFinalizeSignIn = () => {
    props.finalizeSignIn(_2faCode);
  };

  const handleResend2Fa = () => {
    props.resend2Fa();
  };

  const styles = StyleSheet.create({
    twoFaResendMsg: {
      color: 'white',
      fontSize: '14px'
    },
    twoFaErrorMsg: {
      color: '#FF6060',
      fontSize: '12px',
      marginTop: '4px'
    },
    footer: {
      height: '56px',
      borderTop: '1px solid #021E2F',
      alignItems: 'center',
      justifyContent: 'space-between'
    }
  });

  return (
    <DialogBox
      initWidth={dialogWidth}
      top={window.innerWidth <= 576 ? 48 : null}
      left={window.innerWidth <= 576 ? 8 : null}
      initHeight={378}
      isOpen={props.is2FaVerificationDialogOpen}
      onClose={() => { props.toggle2FaVerificationDialog(false); }}
      maskBackground="#004080"
      maskOpacity={0.5}
      closeOnEscKey
      header={{
        text: 'Enter 2FA Code',
        backColor: '#082840',
        foreColor: '#5080B0',
        closeColor: '#FF6060',
        fontStyle: 'bold',
        fontSize: '20px',
        icon: ''
      }}
    >
      <Fragment>
        <div className="p-2">
          {/* 2FA resend msg */}
          <div className={css(styles.twoFaResendMsg)}>
            {!props.showResend2FaMsg
              ? `Verification code sent to mobile # ending in ${props.phone}`
              : `Verification code has been resent to mobile # ending in ${props.phone}`
            }
          </div>

          {/* 2Fa Code */}
          <div className="mt-4 mb-5">
            <TextInputWithLabel
              value={_2faCode}
              titleText='Enter code'
              titleColor='#5079ab'
              width={dialogWidth - 16}
              onChange={(e) => {
                const { value } = e.target;
                set2FaCode(value);
              }}
            />
            {/* 2FA error msg */}
            {props.show2FaErrorMsg
              ? <div className={css(styles.twoFaErrorMsg)}>
                  Incorrect Code
                </div>
              : null
            }
          </div>
        </div>

        {/* Footer */}
        <div className={`fx pl-2 pr-2 ${css(styles.footer)}`}>
          {/* Send new code button */}
          <Button
            btnType="primary"
            isOutlineBtn
            onClick={handleResend2Fa}
            style={{
              background: 'transparent',
              maxWidth: '125px',
              border: 'none',
              height: '40px',
              fontSize: '14px',
              fontWeight: 'bold'
            }}
          >
            Send new code
          </Button>
          {/* Cancel and Continue buttons */}
          <div className='fx'>
            {/* Cancel button */}
            <Button
              btnType='primary'
              onClick={() => { props.toggle2FaVerificationDialog(false); }}
              isOutlineBtn
              style={{
                maxWidth: '90px',
                borderRadius: '4px',
                height: '40px',
                fontSize: '14px',
                fontWeight: 'bold',
                marginRight: '8px'
              }}
            >
              Cancel
            </Button>

            {/* Continue button */}
            <Button
              btnType='primary'
              onClick={handleFinalizeSignIn}
              isDisabled={_2faCode.length < 6}
              style={{
                maxWidth: '100px',
                borderRadius: '4px',
                height: '40px',
                fontSize: '14px',
                fontWeight: 'bold'
              }}
            >
              Continue
            </Button>
          </div>
        </div>
      </Fragment>
    </DialogBox>
  );
};

function mapStateToProps ({ user }) {
  return {
    ...user
  };
}

TwoFactorAuthDialog.propTypes = propTypes;
TwoFactorAuthDialog.defaultProps = defaultProps;

export default connect(
  mapStateToProps, {
    toggle2FaVerificationDialog,
    finalizeSignIn,
    resend2Fa
  })(TwoFactorAuthDialog);
