import React, { Component } from 'react';
import { connect } from 'react-redux';
import { css, StyleSheet } from 'aphrodite/no-important';
import debounce from 'lodash.debounce';
import Pusher from 'pusher-js';
import { clone } from 'ramda';
import {
  blue,
  salmon,
  gray,
  TextInput,
  PopupMenu,
  Drawer,
  TextInputWithLabel,
  TabsContainer,
  TabItem,
  Button
} from 'quantux-ui';
import { clearChatBadge } from '../actions';
import {
  BASE_URL,
  getAuthHeaders,
  PUSHER_CONFIG,
  PUSHER_KEY,
  DRAWER_BREAK_POINT
} from '../utils';
import ChatMessageCard from '../components/chat-message-card';
import ChatRoom from '../components/chat-room';

const drawerWidth = window.innerWidth <= DRAWER_BREAK_POINT ? window.innerWidth : 320;

const styles = StyleSheet.create({
  splitPanel: {
    display: 'flex',
    flexFlow: 'row nowrap',
    background: '#002030',
    // height: (/iPhone|iPad|iPod/i.test(navigator.userAgent) && /Safari/i.test(navigator.userAgent))
    //   ? 'calc(100vh - 100px)'
    //   : 'calc(100vh - 40px)',
    height: '100%',
    width: '100%',
    overflow: 'hidden',
    fontFamily: 'Lato',
    marginTop: '45px'
  },
  drawer: {
    position: 'relative',
    height: '100%',
    borderRight: '1px solid #082840',
    transition: '0.3s',
    padding: '0',
    overflowY: 'auto'
    // zIndex: '1900'
  },
  openDrawer: {
    left: '0',
    minWidth: `${drawerWidth}px`,
    width: `${drawerWidth}px`
  },
  hideDrawer: {
    transition: '0.3s',
    left: `calc(-1 * ${drawerWidth}px)`,
    width: '0px',
    minWidth: '0px'
  },
  drawerHeader: {
    // height: '40px',
    fontSize: '16px',
    background: '#001824',
    // display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    borderBottom: '0.5px solid #082840',
    cursor: 'pointer'
  },
  drawerHeaderIconContainer: {
    height: '32px',
    width: '32px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '4px',
    cursor: 'pointer',
    marginLeft: '4px'
  },
  drawerHeaderIcon: {
    fontSize: '24px',
    cursor: 'pointer'
  },
  drawerHeaderTextContainer: {
    textAlign: 'left',
    lineHeight: '14px',
    cursor: 'pointer',
    paddingLeft: '4px',
    marginLeft: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    borderBottom: `1px solid ${gray[400]}`
  },
  drawerHeaderText: {
    textAlign: 'center',
    fontSize: '18px',
    color: '#60A0E0',
    cursor: 'pointer',
    paddingLeft: '4px'
  },
  drawerHeaderTextSub: {
    color: '#0080FF',
    fontSize: '14px',
    cursor: 'pointer'
  },
  drawerTabsContainer: {
    height: 'calc(100% - 64px)',
    overflowY: 'auto'
  },
  drawerAccordian: {
    position: 'relative',
    display: 'block',
    transition: '0.5s ease',
    maxHeight: '2000px'
  },
  menuItem: {
    position: 'relative',
    left: '-3px',
    color: gray[300]
  },
  detail: {
    position: 'relative',
    transition: '0.3s',
    height: '100%',
    left: '0'
  },
  detailExapnd: {
    transition: '0.2s',
    left: '0',
    width: '100vw'
  },
  detailHeader: {
    height: '40px',
    background: '#001824',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '0.5px solid #082840',
    padding: '0 8px'
  },
  profileBanner: {
    borderBottom: '1px solid transparent'
  },
  profileBannerImg: {
    width: '100%',
    height: 'auto',
    maxHeight: '220px'
  },
  bigUserCardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid transparent'
  },
  // tabs: { borderBottom: '1px solid red' },
  popupMenuContainer: {
    background: '#083050',
    width: '150px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'
  },
  popupMenuItem: {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#5080B0'
  },
  menuItemIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    width: '40px'
  },
  menuItemIcon: {
    fontSize: '20px'
  },
  accordianRightBorder: {
    position: 'absolute',
    width: '1px',
    height: 'calc(100% - 8px)',
    background: '#305C82',
    left: '13px',
    top: '8px'
  },
  linkStyles: {
    display: 'flex',
    alignItems: 'center',
    color: gray[300]
  },
  selectedLink: {
    color: blue[500]
  },
  drawerFile: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '4px',
    color: blue[500],
    height: '40px'
  },
  drawerFileIcon: {
    fontSize: '32px',
    marginRight: '8px'
  },
  activeDrawerMenuItem: {
    borderLeft: `1px solid ${blue[500]}`,
    color: blue[500],
    background: gray[700]
  },
  newChatMenuContainer: {
    background: '#083050',
    width: '200px'
  },
  drawerHeader2: {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px',
    fontWeight: 'bold',
    borderBottom: '1px solid black',
    flexShrink: '0'
  },
  drawerContainer: {
    background: gray[500],
    color: gray[300],
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexFlow: 'column'
  },
  drawerHeaderCloseIcon: {
    color: gray[300],
    ':hover': {
      color: salmon[500]
    }
  },
  drawerContentArea: {
    overflowY: 'auto',
    color: gray[300],
    flexGrow: '1'
  },
  drawerFooter: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    background: gray[700],
    height: '56px',
    width: '100%',
    borderTop: `1px solid ${gray[500]}`,
    flexShrink: '0'
  },
  chatCreator: {
    display: 'flex',
    alignItems: 'center',
    margin: '8px',
    padding: '8px',
    background: gray[600],
    borderRadius: '5px'
  },
  chatCreatorAvatar: {
    width: 'auto',
    height: '40px',
    maxHeight: '40px',
    borderRadius: '4px'
  },
  chatCreatorText: {
    marginLeft: '8px',
    flexGrow: 1
  },
  chatCreatorUserName: {
    color: blue[500]
  },
  groupChatMemberCard: {
    display: 'flex',
    alignItems: 'center',
    margin: '8px',
    padding: '8px',
    background: gray[600],
    borderRadius: '5px'
  },
  groupChatMemberAvatar: {
    width: 'auto',
    height: '40px',
    maxHeight: '40px',
    borderRadius: '4px'
  },
  groupChatMemberText: {
    marginLeft: '8px',
    flexGrow: 1
  },
  groupChatMemberUserName: {
    color: blue[500]
  },
  groupChatMemberCloseIcon: {
    fontSize: '24px',
    ':hover': {
      color: salmon[500]
    }
  }
});

class Chat extends Component {
  state = {
    isDrawerOpen: true,
    isMobileLayout: false,
    searchTerm: '',
    isNewChatMenuVisible: false,
    isNewChatDrawerVisible: false,
    isNewGroupChatDrawerVisible: false,
    userSearch: '',
    userSearchResults: [],
    isUserSearchMenuVisible: false,
    chats: [],
    activeChat: {},
    isChatRoomMenuVisible: false,
    isLoadingChat: false,
    activeTab: 0,
    groupChatTitle: '',
    groupChatUserSearch: '',
    groupChatSearchResults: [],
    groupChatMembers: [],
    isGroupChatUserSearchMenuVisible: false,
    groupChats: [],
    chatTitle: '',
    avatarUrl: '',
    isGroupChatManagerDrawerOpen: false,
    groupChatUpdatedTitle: '',
    groupChatUpdatedParticipants: [],
    groupChatUpdatedUserSearch: '',
    groupChatUpdatedSearchResults: [],
    isGroupChatUpdatedUserSearchMenuVisible: false,
    groupChatUpdatedChatCreatorAvatarUrl: '',
    isChatCreator: false,
    showDmBadge: false,
    showGroupChatBadge: false
  };

  // Refs
  chatIcon = React.createRef();
  chatUserRef = React.createRef();
  groupChatUserRef = React.createRef();
  chatRoomMenuRef = React.createRef();
  usr = JSON.parse(localStorage.getItem('usr'));
  intervalRef = React.createRef();
  groupChatUpdatedUserRef = React.createRef();

  markMessageAsRead = (dmId, msgId) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('dmId', dmId);
    formdata.append('msgId', msgId);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(`${BASE_URL}/users/markMessageAsRead`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        if (res.success) {
          console.log(`${dmId} message ${msgId} has been marked read successfully`);
        }
      })
      .catch(err => console.log(err));
  };

  handleNewMessageEvent = (data) => {
    const { id, msg, chatType } = data;
    // Show tab badge(s)
    if (chatType === 0 && this.state.activeTab === 1) {
      this.setState({ showDmBadge: true })
    }

    if (chatType === 1 && this.state.activeTab === 0) {
      this.setState({ showGroupChatBadge: true });
    }

    if (this.state.activeChat._id === id) {
      const updatedActiveChat = clone(this.state.activeChat);
      updatedActiveChat.messages.push(msg);
      if (this.state.activeTab === 0) {
        this.setState(() => ({ activeChat: updatedActiveChat }), () => {
          this.markMessageAsRead(id, msg._id);
        });
      } else {
        this.setState({ activeChat: updatedActiveChat });
      }
    } else {
      if (this.state.activeTab === 0) {
        const updatedChats = clone(this.state.chats);
        for (let i = 0; i < updatedChats.length; i++) {
          if (updatedChats[i]._id === id) {
            updatedChats[i].unread = updatedChats[i].unread ? updatedChats[i].unread + 1 : 1;
            break;
          }
        }
        this.setState({ chats: updatedChats });
      } else {
        const updatedGroupChats = clone(this.state.groupChats);
        for (let i = 0; i < updatedGroupChats.length; i++) {
          if (updatedGroupChats[i]._id === id) {
            updatedGroupChats[i].unread = updatedGroupChats[i].unread ? updatedGroupChats[i].unread + 1 : 1;
            break;
          }
        }
        this.setState({ groupChats: updatedGroupChats });
      }
    }
  };

  handleNewChatEvent = (data) => {
    const { chatType, chat } = data;

    // Show tab badge(s)
    if (chatType === 0 && this.state.activeTab === 1) {
      this.setState({ showDmBadge: true })
    }

    if (chatType === 1 && this.state.activeTab === 0) {
      this.setState({ showGroupChatBadge: true });
    }

    if (chatType === 0) {
      const updatedChats = clone(this.state.chats);
      updatedChats.push(chat);
      this.setState(() => ({
        chats: updatedChats
      }), () => {
        if (this.state.chats.length === 1 && this.state.activeTab === 0) {
          this.handleOnLoadChat(this.state.chats[0]._id);
        }
      });
    } else {
      const updatedGroupChats = clone(this.state.groupChats);
      updatedGroupChats.push(chat);
      this.setState(() => ({
        groupChats: updatedGroupChats
      }), () => {
        if (this.state.groupChats.length === 1 && this.state.activeTab === 1) {
          this.handleOnLoadGroupChat(this.state.groupChats[0]._id);
        }
      });
    }
  };

  handleDeleteChatEvent = (data) => {
    const { chatType, chat } = data;
    if (chatType === 1) {
      let updatedGroupChats = clone(this.state.groupChats);
      updatedGroupChats = updatedGroupChats.filter(gc => gc._id !== chat._id)
      this.setState(() => ({
        groupChats: updatedGroupChats
      }), () => {
        if (this.state.activeTab === 1) {
          if (this.state.groupChats.length > 0) {
            this.handleOnLoadGroupChat(this.state.groupChats[0]._id);
          } else {
            this.setState({ activeChat: {} });
          }
        }
      });
    }
  };

  // Undelete (UnHide) DM
  handleUnDeleteChatEvent = (data) => {
    const { chatType, chat } = data;

    // Show tab badge(s)
    if (chatType === 0 && this.state.activeTab === 1) {
      this.setState({ showDmBadge: true })
    }

    if (chatType === 0) {
      if (this.state.chats.length > 0) {
        const updatedChats = clone(this.state.chats);
        chat.unread = 1;
        updatedChats.push(chat);
        this.setState({ chats: updatedChats });
      } else {
        const updatedChats = clone(this.state.chats);
        updatedChats.push(chat);
        this.setState(() => ({ chats: updatedChats }), () => {
          this.handleOnLoadChat(this.state.chats[0]._id);
        });
      }
    }
  };

  handleGcTitleUpdateEvent = (data) => {
    const { title, chatId } = data;
    const updatedGroupChats = clone(this.state.groupChats);
    updatedGroupChats.forEach((el) => {
      if (el._id === chatId) {
        el.title = title;
      }
    });
    this.setState({ groupChats: updatedGroupChats });
  }

  handleWindowResize = () => {
    if (window.innerWidth <= 700) {
      this.setState({
        isDrawerOpen: false
      });
    }
  };

  handleWindowResizeDebounced = debounce(this.handleWindowResize, 300);

  // #region Life Cycle Methods
  UNSAFE_componentWillMount () {
    this.pusher = new Pusher(
      PUSHER_KEY, { ...PUSHER_CONFIG, auth: { headers: getAuthHeaders() } });
    this.channel = this.pusher.subscribe(`presence-${this.usr.userName}-channel`);
  }

  componentDidMount () {
    setTimeout(() => { this.props.clearChatBadge(); }, 3000);
    window.addEventListener('resize', this.handleWindowResizeDebounced);
    // Fetch user chats
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('userId', this.usr._id);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(`${BASE_URL}/users/getChats`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        if (res.chats) {
          this.setState({ chats: res.chats });
          if (res.chats.length > 0) {
            this.handleOnLoadChat(res.chats[0]._id, false);
          }
        }
      })
      .catch(err => console.log(err));

    // Subscribing to channel events
    this.channel.bind('new-message-event', this.handleNewMessageEvent);
    this.channel.bind('new-chat-event', this.handleNewChatEvent);
    this.channel.bind('delete-chat-event', this.handleDeleteChatEvent);
    this.channel.bind('undelete-chat-event', this.handleUnDeleteChatEvent);
    this.channel.bind('gc-title-update-event', this.handleGcTitleUpdateEvent);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleWindowResizeDebounced);
    this.channel.unbind();
    this.pusher.unsubscribe(this.channel);
  }
  // #endregion

  toggleNewChatDrawer = (_val) => {
    this.setState({ isNewChatDrawerVisible: _val });
  };

  closeNewChatDrawer = () => {
    this.setState({
      isNewChatDrawerVisible: false,
      userSearch: '',
      userSearchResults: [],
      isUserSearchMenuVisible: false
    });
  };

  searchUsers = async () => {
    const url = new URL(`${BASE_URL}/users/searchMedPros`);
    const params = { searchTerm: this.state.userSearch };
    url.search = new URLSearchParams(params).toString();
    const res = await fetch(url);
    const json = await res.json();
    this.setState({
      userSearchResults: json.users ? json.users : []
    });
  };

  searchUsersDebounced = debounce(this.searchUsers, 300);

  handleOnChangeUserSearch = (e) => {
    const { value } = e.target;
    this.setState(() => ({ userSearch: value }), this.searchUsersDebounced);
  };

  toggleUserSearchMenu = (_val) => {
    this.setState({ isUserSearchMenuVisible: _val });
  }

  closeUserSearchMenu = () => {
    this.toggleUserSearchMenu(false);
  };

  initiateChat = (userId, userName) => {
    if (this.usr.userName === userName) {
      this.closeNewChatDrawer();
      return;
    }
    // Check for existing chats
    const idx = this.state.chats.findIndex((el) => el.recipientUserName === userName);

    if (idx > -1) {
      this.handleOnLoadChat(this.state.chats[idx]._id);
    } else {
      const myHeaders = new Headers();
      const token = localStorage.getItem('token');
      myHeaders.append('Authorization', `Bearer ${token}`);

      const formdata = new FormData();
      formdata.append('senderId', this.usr._id);
      formdata.append('recipientId', userId);
      formdata.append('recipientUserName', userName);

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: formdata,
        redirect: 'follow'
      };

      fetch(`${BASE_URL}/users/initiateChat`, requestOptions)
        .then(response => response.text())
        .then(result => {
          const res = JSON.parse(result);
          if (res.newDm) {
            const updatedChats = clone(this.state.chats);
            updatedChats.push(res.newDm);
            this.setState(() => ({
              chats: updatedChats
            }), () => {
              // Load new DM if it is the only one DM for user
              if (this.state.chats.length === 1) {
                this.handleOnLoadChat(this.state.chats[0]._id);
              }
            });
          }
        })
        .catch(error => console.log('error', error));
    }
  };

  handleOnLoadChat = async (chatId, closeDrawer = true) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);
    const formdata = new FormData();
    formdata.append('chatId', chatId);
    formdata.append('userName', this.usr.userName);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    // Activate chat loader
    const updatedChats = clone(this.state.chats);
    for (let i = 0; i < updatedChats.length; i++) {
      if (updatedChats[i]._id === chatId) {
        updatedChats[i].unread = 0;
      }
    }
    this.setState({
      isLoadingChat: true,
      chats: updatedChats
    });

    const res = await fetch(`${BASE_URL}/users/getChatRoom`, requestOptions).catch(err => console.log(err));
    const data = await res.json();
    if (data.chat) {
      let title = '';
      let avatarUserName = '';
      if (this.usr.userName === data.chat.senderUserName) {
        title = `${data.chat.recipientDisplayName} @${data.chat.recipientUserName}`;
        avatarUserName = data.chat.recipientUserName;
      } else {
        title = `${data.chat.senderDisplayName} @${data.chat.senderUserName}`;
        avatarUserName = data.chat.senderUserName;
      }

      let drawerStatus = false;
      if (!closeDrawer) {
        drawerStatus = true;
      }

      this.setState(() => ({
        isLoadingChat: false,
        activeChat: data.chat,
        chatTitle: title,
        isDrawerOpen: window.innerWidth <= 700 ? drawerStatus : this.state.isDrawerOpen
      }), () => {
        const url = new URL(`${BASE_URL}/users/getUserAvatar`);
        const params = { userName: avatarUserName };
        url.search = new URLSearchParams(params).toString();
        fetch(url)
          .then(res => res.text())
          .then((result) => {
            const data = JSON.parse(result);
            this.setState({ avatarUrl: data.avatar });
          })
          .catch(error => console.log('error', error))
      });
    }
  };

  handleOnChangeSearchTerm = (e) => {
    const { value } = e.target;
    this.setState({ searchTerm: value });
  };

  toggleNewChatMenu = (_val) => {
    this.setState({ isNewChatMenuVisible: _val });
  };

  handleOnClickNewChat = () => {
    this.setState({
      isNewChatDrawerVisible: true,
      userSearch: '',
      userSearchResults: [],
      isUserSearchMenuVisible: false
    });
  };

  handleOnClickNewGroupChat = () => {
    this.setState({
      isNewGroupChatDrawerVisible: true,
      groupChatUserSearch: '',
      groupChatSearchResults: [],
      isGroupChatUserSearchMenuVisible: false
    })
  };

  toggleChatRoomMenu = (_val) => {
    this.setState({ isChatRoomMenuVisible: _val });
  };

  closeChatRoomMenu = () => {
    this.toggleChatRoomMenu(false);
  };

  // Delete (Hide) DM
  handleOnClickDeleteChat = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('userId', this.usr._id);
    formdata.append('chatId', this.state.activeChat._id);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(`${BASE_URL}/users/deleteChat`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        if (res.success) {
          let updatedChats = clone(this.state.chats);
          updatedChats = updatedChats.filter(el => el._id !== res.chatId)
          this.setState(() => ({
            chats: updatedChats
          }), () => {
            if (this.state.chats.length > 0) {
              this.handleOnLoadChat(this.state.chats[0]._id);
            } else {
              this.setState({ activeChat: {} });
            }
          });
        }
      })
      .catch(err => console.log(err));
  };

  handleOnPostDirectMessage = async (msg, isImage = false, isDocument = false, originalFileName) => {
    const { activeChat } = this.state;
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);
    const formdata = new FormData();
    formdata.append('dmId', activeChat._id);
    formdata.append('senderId', this.usr._id);
    formdata.append('senderDisplayName', this.usr.displayName);
    formdata.append('senderUserName', this.usr.userName);
    let tmp = '';
    let tmpId = '';

    if (this.usr.userName === activeChat.senderUserName) {
      tmp = activeChat.recipientUserName;
      tmpId = activeChat.recipient;
    } else {
      tmp = activeChat.senderUserName;
      tmpId = activeChat.sender;
    }
    formdata.append('recipientUserName', tmp);
    formdata.append('recipientId', tmpId);
    formdata.append('msg', msg);
    formdata.append('isImage', isImage);
    formdata.append('isDocument', isDocument);
    formdata.append('originalFileName', originalFileName);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    const res = await fetch(`${BASE_URL}/users/postMessage`, requestOptions).catch(err => console.log(err));
    const data = await res.json();
    if (data.dmId) {
      const updatedActiveChat = clone(this.state.activeChat);
      updatedActiveChat.messages.push(data.msg);
      this.setState({ activeChat: updatedActiveChat });
    }
  };

  handleOnPostGroupChatMessage = async (msg, isImage = false, isDocument = false, originalFileName) => {
    const { activeChat } = this.state;
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);
    const formdata = new FormData();
    formdata.append('chatId', activeChat._id);
    formdata.append('senderId', this.usr._id);
    formdata.append('senderDisplayName', this.usr.displayName);
    formdata.append('senderUserName', this.usr.userName);
    formdata.append('msg', msg);
    formdata.append('isImage', isImage);
    formdata.append('isDocument', isDocument);
    formdata.append('originalFileName', originalFileName);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    const res = await fetch(`${BASE_URL}/users/postGroupChatMessage`, requestOptions).catch(err => console.log(err));
    const data = await res.json();
    if (data.chatId) {
      const updatedActiveChat = clone(this.state.activeChat);
      updatedActiveChat.messages.push(data.msg);
      this.setState({ activeChat: updatedActiveChat });
    }
  };

  handleOnPostMessage = async (msg, isImage = false, isDocument = false, originalFileName) => {
    if (this.state.activeTab === 0) {
      this.handleOnPostDirectMessage(msg, isImage, isDocument, originalFileName);
    } else {
      this.handleOnPostGroupChatMessage(msg, isImage, isDocument, originalFileName);
    }
  };

  handleOnActivateTab = (tab) => {
    this.setState({
      activeTab: tab
    });
  };

  // #region Group Chat Drawer
  toggleNewGroupChatDrawer = (_val) => {
    this.setState({ isNewGroupChatDrawerVisible: _val });
  };

  closeNewGroupChatDrawer = () => {
    this.setState({
      isNewGroupChatDrawerVisible: false,
      groupChatTitle: '',
      groupChatUserSearch: '',
      groupChatSearchResults: [],
      isGroupChatUserSearchMenuVisible: false,
      groupChatMembers: []
    });
  };

  handleOnEditGroupChatTitle = (e) => {
    const { value } = e.target;
    this.setState({ groupChatTitle: value })
  };

  searchGroupChatUsers = async () => {
    const url = new URL(`${BASE_URL}/users/searchMedPros`);
    const params = { searchTerm: this.state.groupChatUserSearch };
    url.search = new URLSearchParams(params).toString();
    const res = await fetch(url);
    const json = await res.json();
    this.setState({
      groupChatSearchResults: json.users ? json.users : []
    });
  };

  searchGroupChatUsersDebounced = debounce(this.searchGroupChatUsers, 300);

  handleOnEditGroupChatUserSearch = (e) => {
    const { value } = e.target;
    this.setState(() => ({ groupChatUserSearch: value }), this.searchGroupChatUsersDebounced);
  };

  toggleGroupChatUserSearchMenu = (_val) => {
    this.setState({ isGroupChatUserSearchMenuVisible: _val });
  };

  closeGroupChatUserSearchMenu = () => {
    this.toggleGroupChatUserSearchMenu(false);
  };

  handleOnClickRemoveGroupChatMember = (userId) => {
    const udpatedGroupChatMembers = this.state.groupChatMembers.filter(el => el._id !== userId);
    this.setState({ groupChatMembers: udpatedGroupChatMembers });
  };
  // #endregion

  // #region Group Chat methods
  handleOnLoadGroupChat = async (chatId, closeDrawer = true) => {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);
    const formdata = new FormData();
    formdata.append('chatId', chatId);
    formdata.append('userName', this.usr.userName);
    formdata.append('userId', this.usr._id);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    // Activate chat loader
    const updatedGroupChats = clone(this.state.groupChats);
    for (let i = 0; i < updatedGroupChats.length; i++) {
      if (updatedGroupChats[i]._id === chatId) {
        updatedGroupChats[i].unread = 0;
      }
    }
    this.setState({
      isLoadingChat: true,
      groupChats: updatedGroupChats
    });

    const res = await fetch(`${BASE_URL}/users/getGroupChatRoom`, requestOptions)
      .catch(err => console.log(err));
    const data = await res.json();
    if (data.chat) {
      let drawerStatus = false;
      if (!closeDrawer) {
        drawerStatus = true;
      }

      this.setState({
        isLoadingChat: false,
        activeChat: data.chat,
        chatTitle: data.chat.title,
        isDrawerOpen: window.innerWidth <= 700 ? drawerStatus : this.state.isDrawerOpen
      });
    }
  };

  loadGroupChatTab = () => {
    this.handleOnActivateTab(1);
    // Fetch user chats
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('userId', this.usr._id);
    formdata.append('userName', this.usr.userName);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(`${BASE_URL}/users/getGroupChats`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        console.log(res);
        if (res.groupChats) {
          this.setState({ groupChats: res.groupChats });
          if (res.groupChats.length > 0) {
            this.handleOnLoadGroupChat(res.groupChats[0]._id, false);
            this.setState({ showGroupChatBadge: false });
          } else {
            this.setState({
              activeChat: {},
              showGroupChatBadge: false
            });
          }
        }
      })
      .catch(err => console.log(err));
  };

  initiateGroupChat = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('title', this.state.groupChatTitle);
    formdata.append('creatorId', this.usr._id);
    const tmp = this.state.groupChatMembers.map(el => el._id);
    formdata.append('members', tmp);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${BASE_URL}/users/initiateGroupChat`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        if (res.newGroupChat) {
          const updatedGroupChats = clone(this.state.groupChats);
          updatedGroupChats.push(res.newGroupChat);
          this.setState(() => ({ groupChats: updatedGroupChats }), () => {
            this.closeNewGroupChatDrawer();
            if (this.state.groupChats.length === 1) {
              this.handleOnLoadGroupChat(this.state.groupChats[0]._id);
            }
          });
        }
      })
      .catch(error => console.log('error', error));
  };
  // #endregion

  // #region Group Chat Manager Drawer
  toggleGroupChatManagerDrawer = (_val) => {
    const url = new URL(`${BASE_URL}/users/getUserAvatar`);
    const params = { userName: this.state.activeChat.chatCreatorUseName };
    url.search = new URLSearchParams(params).toString();
    fetch(url)
      .then(res => res.text())
      .then((result) => {
        const data = JSON.parse(result);
        this.setState({ groupChatUpdatedChatCreatorAvatarUrl: data.avatar });
      })
      .catch(error => console.log('error', error))
    this.setState(() => ({
      isGroupChatManagerDrawerOpen: _val,
      groupChatUpdatedTitle: this.state.activeChat.title,
      groupChatUpdatedParticipants: this.state.activeChat.members,
      groupChatUpdatedUserSearch: '',
      groupChatUpdatedSearchResults: [],
      isGroupChatUpdatedUserSearchMenuVisible: false,
      isChatCreator: this.state.activeChat.chatCreatorUseName === this.usr.userName
    }), async () => {
      const url = new URL(`${BASE_URL}/users/searchUsersByNpi`);
      const params = { ids: this.state.groupChatUpdatedParticipants };
      url.search = new URLSearchParams(params).toString();
      const res = await fetch(url);
      const json = await res.json();
      this.setState({
        groupChatUpdatedParticipants: json.users ? json.users : []
      });
    });
  };

  closeGroupChatManagerDrawer = () => {
    this.setState({ isGroupChatManagerDrawerOpen: false });
  };

  handleOnEditGroupChatUpdatedTitle = (e) => {
    const { value } = e.target;
    this.setState({ groupChatUpdatedTitle: value });
  };

  searchGroupChatUpdatedUsers = async () => {
    const url = new URL(`${BASE_URL}/users/searchMedPros`);
    const params = { searchTerm: this.state.groupChatUpdatedUserSearch };
    url.search = new URLSearchParams(params).toString();
    const res = await fetch(url);
    const json = await res.json();
    this.setState({
      groupChatUpdatedSearchResults: json.users ? json.users : []
    });
  };

  searchGroupChatUpdatedUsersDebounced = debounce(this.searchGroupChatUpdatedUsers, 300);

  handleOnEditGroupChatUpatedUserSearch = (e) => {
    const { value } = e.target;
    this.setState(() => ({ groupChatUpdatedUserSearch: value }), this.searchGroupChatUpdatedUsersDebounced);
  };

  toggleGroupChatUpdatedUserSearchMenu = (_val) => {
    this.setState({ isGroupChatUpdatedUserSearchMenuVisible: _val });
  };

  closeGroupChatUpdatedUserSearchMenu = () => {
    this.toggleGroupChatUpdatedUserSearchMenu(false);
  };

  groupChatUdatedRemoveMember = (userId) => {
    const udpatedGroupChatMembers = this.state.groupChatUpdatedParticipants.filter(el => el._id !== userId);
    this.setState({ groupChatUpdatedParticipants: udpatedGroupChatMembers });
  };

  updateGroupChat = () => {
    const myHeaders = new Headers();
    const token = localStorage.getItem('token');
    myHeaders.append('Authorization', `Bearer ${token}`);

    const formdata = new FormData();
    formdata.append('title', this.state.groupChatUpdatedTitle);
    formdata.append('chatId', this.state.activeChat._id);
    const tmp = this.state.groupChatUpdatedParticipants.map(el => el._id);
    formdata.append('members', tmp);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(`${BASE_URL}/users/updateGroupChat`, requestOptions)
      .then(response => response.text())
      .then(result => {
        const res = JSON.parse(result);
        if (res.chat) {
          const updatedGroupChats = clone(this.state.groupChats);
          for (let i = 0; i < updatedGroupChats.length; i++) {
            if (updatedGroupChats[i]._id === res.chat._id) {
              updatedGroupChats[i] = res.chat;
              break;
            }
          }
          this.setState(() => ({ groupChats: updatedGroupChats }), () => {
            this.closeGroupChatManagerDrawer();
            this.handleOnLoadGroupChat(res.chat._id);
          });
        }
      })
      .catch(error => console.log('error', error));
  };
  // #endregion

  render() {
    const {
      isDrawerOpen,
      isNewChatDrawerVisible,
      isNewGroupChatDrawerVisible,
      userSearch,
      isUserSearchMenuVisible,
      userSearchResults,
      chats,
      activeChat,
      searchTerm,
      isNewChatMenuVisible,
      isChatRoomMenuVisible,
      activeTab,
      groupChatTitle,
      groupChatUserSearch,
      groupChatSearchResults,
      groupChatMembers,
      isGroupChatUserSearchMenuVisible,
      groupChats,
      chatTitle,
      avatarUrl,
      isGroupChatManagerDrawerOpen,
      groupChatUpdatedTitle,
      groupChatUpdatedParticipants,
      groupChatUpdatedUserSearch,
      groupChatUpdatedSearchResults,
      isGroupChatUpdatedUserSearchMenuVisible,
      groupChatUpdatedChatCreatorAvatarUrl,
      isChatCreator,
      showDmBadge,
      showGroupChatBadge
    } = this.state;

    // #region User search menu items
    const searchedUsers = [];
    if (userSearchResults.length > 0) {
      userSearchResults.forEach(el => {
        searchedUsers.push(
          <div
            className='m-item'
            key={el._id}
            style={{
              height: '50px',
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #001824',
              cursor: 'pointer',
              fontSize: '12px',
              background: gray[700]
            }}
            onClick={() => {
              this.toggleNewChatDrawer(false);
              this.initiateChat(el._id, el.userName);
            }}
          >
            <div>
              {`${el.lastName}, ${el.firstName} (${el.npi ? el.npi : 'NPI n/a'})`}
            </div>
          </div>
        );
      });
    }
    // #endregion

    // #region Group chat user search menu items
    const groupChatSearchedUsers = [];
    if (groupChatSearchResults.length > 0) {
      groupChatSearchResults.forEach(el => {
        groupChatSearchedUsers.push(
          <div
            className='m-item'
            key={el._id}
            style={{
              height: '50px',
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #001824',
              cursor: 'pointer',
              fontSize: '12px',
              background: gray[700]
            }}
            onClick={() => {
              const udpatedGroupChatMembers = clone(groupChatMembers);
              udpatedGroupChatMembers.push(el);
              this.setState({
                groupChatMembers: udpatedGroupChatMembers,
                isGroupChatUserSearchMenuVisible: false,
                groupChatUserSearch: ''
              });
            }}
          >
            <div>
              {`${el.lastName}, ${el.firstName} (${el.npi ? el.npi : 'NPI n/a'})`}
            </div>
          </div>
        );
      });
    }
    // #endregion

    // #region Group chat users cards
    const addedGroupChatMembers = [];
    if (groupChatMembers.length > 0) {
      groupChatMembers.forEach((el) => {
        addedGroupChatMembers.push(
          <div className={css(styles.groupChatMemberCard)}>
            <img
              className={css(styles.groupChatMemberAvatar)}
              alt="avatar"
              loading='lazy'
              src={el.avatarImageUrl}
            />
            <div className={css(styles.groupChatMemberText)}>
              <div>{el.displayName}</div>
              <div className={css(styles.groupChatMemberUserName)}>
                {`@${el.userName}`}
              </div>
            </div>
            <div
              onClick={() => this.handleOnClickRemoveGroupChatMember(el._id)}
            >
              <i className={`fa fa-times ${css(styles.groupChatMemberCloseIcon)}`} />
            </div>
          </div>
        );
      });
    }
    // #endregion

    // #region Chat cards
    const dmCards = [];
    if (chats.length > 0) {
      chats.forEach((el) => {
        let title = '';
        let avatarUserName = '';
        if (this.usr.userName === el.senderUserName) {
          title = `${el.recipientDisplayName} @${el.recipientUserName}`;
          avatarUserName = el.recipientUserName;
        } else {
          title = `${el.senderDisplayName} @${el.senderUserName}`;
          avatarUserName = el.senderUserName;
        }

        dmCards.push(
          <ChatMessageCard
            key={el._id}
            id={el._id}
            unread={el.unread}
            title={title}
            isSelected={activeChat._id === el._id}
            style={{ marginTop: '4px' }}
            avatarUserName={avatarUserName}
            preview=''
            onSelection={this.handleOnLoadChat}
          />
        );
      });
    }
    // #endregion

    // #region Group chats cards
    const groupChatCards = [];
    if (groupChats.length > 0) {
      groupChats.forEach((el) => {
        groupChatCards.push(
          <ChatMessageCard
            key={el._id}
            id={el._id}
            unread={el.unread}
            title={el.title}
            isSelected={activeChat._id === el._id}
            style={{ marginTop: '4px' }}
            showAvatar={false}
            onSelection={this.handleOnLoadGroupChat}
            showBadge
            badgeText={el.members ? el.members.length + 1 : 0 }
            preview={el.chatCreatorDisplayName}
            isGroupChat
          />
        );
      });
    }
    // #endregion

    // #region Group chat manager drawer user search menu items
    const groupChatUpdatedSearchedUsers = [];
    if (groupChatUpdatedSearchResults.length > 0) {
      groupChatUpdatedSearchResults.forEach(el => {
        groupChatUpdatedSearchedUsers.push(
          <div
            className='m-item'
            key={el._id}
            style={{
              height: '50px',
              display: 'flex',
              alignItems: 'center',
              borderBottom: '1px solid #001824',
              cursor: 'pointer',
              fontSize: '12px',
              background: gray[700]
            }}
            onClick={() => {
              const udpatedGroupChatMembers = clone(groupChatUpdatedParticipants);
              udpatedGroupChatMembers.push(el);
              this.setState({
                groupChatUpdatedParticipants: udpatedGroupChatMembers,
                isGroupChatUpdatedUserSearchMenuVisible: false,
                groupChatUpdatedUserSearch: ''
              });
            }}
          >
            <div>
              {`${el.lastName}, ${el.firstName} (${el.npi ? el.npi : 'NPI n/a'})`}
            </div>
          </div>
        );
      });
    }
    // #endregion

    // #region Group chat manager drawer participants
    const currentGroupChatParticipants = [];
    if (groupChatUpdatedParticipants.length > 0) {
      groupChatUpdatedParticipants.forEach((el) => {
        currentGroupChatParticipants.push(
          <div className={css(styles.groupChatMemberCard)}>
            <img
              className={css(styles.groupChatMemberAvatar)}
              alt="avatar"
              loading='lazy'
              src={el.avatarImageUrl}
            />
            <div className={css(styles.groupChatMemberText)}>
              <div>{el.displayName}</div>
              <div className={css(styles.groupChatMemberUserName)}>
                {`@${el.userName}`}
              </div>
            </div>
            {/* Remove icon */}
            {this.state.isChatCreator
              ? <div
                  onClick={() => this.groupChatUdatedRemoveMember(el._id)}
                >
                  <i className={`fa fa-times ${css(styles.groupChatMemberCloseIcon)}`} />
                </div>
              : null
            }
          </div>
        );
      });
    }
    // #endregion

    let detailWidth = '0px';
    if (this.state.isDrawerOpen) {
      if (window.innerWidth <= DRAWER_BREAK_POINT) {
        detailWidth = '0';
      } else {
        detailWidth = `calc(100vw - ${drawerWidth}px)`;
      }
    } else {
      detailWidth = '100vw'
    }

    let showMessageIcon = true;
    if (window.innerWidth > 700) {
      showMessageIcon = true;
    } else {
      showMessageIcon = !isDrawerOpen
    }

    return (
      <div
        style={{
          marginTop: '40px',
          height: 'calc(100vh - 45px)',
          // position: 'fixed'
          // overflow: 'hidden',
          '-webkit-overflow-scrolling': 'touch'
        }}
      >
        <div className={css(styles.splitPanel)}>
          {isNewChatDrawerVisible
            ? <Drawer
              placement='right'
              visible={isNewChatDrawerVisible}
              toggleDrawer={this.closeNewChatDrawer}
              zIndex="2000"
              width={window.innerWidth <= 700 ? window.innerWidth : 358}
            >
              <div className={css(styles.drawerContainer)}>
                {/* Header */}
                <div className={css(styles.drawerHeader2)}>
                  <div className='fx fx__hv-center'>
                    <i className='mr-2 fa fa-lg fa-comments' />
                    <div className='noselect'>New Chat</div>
                  </div>
                  <i
                    className={`fa fa-lg fa-times-circle ${css(styles.drawerHeaderCloseIcon)}`}
                    onClick={this.closeNewChatDrawer}
                  />
                </div>
                {/* Drawer content area */}
                <div className={css(styles.drawerContentArea)}>
                  <div
                    className='p-2'
                    ref={this.chatUserRef}
                  >
                    <div>
                      <TextInputWithLabel
                        value={userSearch}
                        titleText="Search"
                        isCompactLayout={false}
                        showPreIcon
                        preIcon='fa fa-search'
                        titleColor="#60A0E0"
                        textColor="#0080FF"
                        selectionColor="white"
                        selectionBackground="#0080FF"
                        spellcheck={false}
                        width={330}
                        onChange={this.handleOnChangeUserSearch}
                        onClick={() => {
                          this.toggleUserSearchMenu(true);
                        }}
                      />
                      <PopupMenu
                        top={95}
                        left={8}
                        zIndex='1550'
                        position="absolute"
                        visible={isUserSearchMenuVisible}
                        parent={this.chatUserRef}
                        onOutsideClick={this.closeUserSearchMenu}
                      >
                        <div style={{ background: '#0a3250', width: '330px' }}>
                          {searchedUsers}
                        </div>
                      </PopupMenu>
                    </div>
                  </div>
                </div>
              </div>
            </Drawer>
            : null
          }
          {/* New group chat drawer */}
          {isNewGroupChatDrawerVisible
            ? <Drawer
              placement='right'
              visible={isNewGroupChatDrawerVisible}
              toggleDrawer={this.closeNewGroupChatDrawer}
              zIndex="2000"
              width={window.innerWidth <= 700 ? window.innerWidth : 358}
            >
              <div className={css(styles.drawerContainer)}>
                {/* Header */}
                <div className={css(styles.drawerHeader2)}>
                  <div className='fx fx__hv-center'>
                    <i className='mr-2 fa fa-lg fa-users' />
                    <div className='noselect'>New Group Chat</div>
                  </div>
                  <i
                    className={`fa fa-lg fa-times-circle ${css(styles.drawerHeaderCloseIcon)}`}
                    onClick={this.closeNewGroupChatDrawer}
                  />
                </div>
                {/* Drawer content area */}
                <div className={css(styles.drawerContentArea)}>
                  {/* Group chat name */}
                  <div className='pl-2 mt-2'>
                    <TextInputWithLabel
                      value={groupChatTitle}
                      titleText="Group Chat Name"
                      isCompactLayout={false}
                      showPreIcon
                      preIcon='fa fa-comments'
                      titleColor="#60A0E0"
                      textColor="#0080FF"
                      selectionColor="white"
                      selectionBackground="#0080FF"
                      spellcheck={false}
                      width={330}
                      onChange={this.handleOnEditGroupChatTitle}
                    />
                  </div>
                  {/* Group chat user search */}
                  <div
                    className='p-2'
                    ref={this.groupChatUserRef}
                  >
                    <div>
                      <TextInputWithLabel
                        value={groupChatUserSearch}
                        titleText="Search"
                        isCompactLayout={false}
                        showPreIcon
                        preIcon='fa fa-search'
                        titleColor="#60A0E0"
                        textColor="#0080FF"
                        selectionColor="white"
                        selectionBackground="#0080FF"
                        spellcheck={false}
                        width={330}
                        onChange={this.handleOnEditGroupChatUserSearch}
                        onClick={() => {
                          this.toggleGroupChatUserSearchMenu(true);
                        }}
                      />
                      <PopupMenu
                        top={145}
                        left={8}
                        zIndex='1550'
                        position="absolute"
                        visible={isGroupChatUserSearchMenuVisible}
                        parent={this.groupChatUserRef}
                        onOutsideClick={this.closeGroupChatUserSearchMenu}
                      >
                        <div style={{ background: '#0a3250', width: '330px' }}>
                          {groupChatSearchedUsers}
                        </div>
                      </PopupMenu>
                    </div>
                  </div>
                  {/* Chat creator */}
                  <div className={css(styles.chatCreator)}>
                    <img
                      className={css(styles.chatCreatorAvatar)}
                      alt="avatar"
                      loading='lazy'
                      src={this.usr.avatarImageUrl}
                    />
                    <div className={css(styles.chatCreatorText)}>
                      <div>{this.usr.displayName}</div>
                      <div className={css(styles.chatCreatorUserName)}>
                        {`@${this.usr.userName}`}
                      </div>
                    </div>
                    <div>
                      Chat Creator
                    </div>
                  </div>
                  {/* Group chat members */}
                  <div>
                    {addedGroupChatMembers}
                  </div>
                </div>
                {/* Drawer Footer */}
                <div
                  style={{ textAlign: 'center', paddingBottom: '8px' }}
                >
                <Button
                  btnType="primary"
                  className="fx fx__hv-center"
                  onClick={this.initiateGroupChat}
                  style={{
                    height: '32px',
                    width: '340px',
                    padding: '3px 0'
                  }}
                >
                  Create Chat
                </Button>
                </div>
              </div>
            </Drawer>
            : null
          }

          {/* Group chat manager drawer */}
          {isGroupChatManagerDrawerOpen
            ? <Drawer
              placement='right'
              visible={ isGroupChatManagerDrawerOpen}
              toggleDrawer={this.closeGroupChatManagerDrawer}
              zIndex="2000"
              width={window.innerWidth <= 700 ? window.innerWidth : 358}
            >
              <div className={css(styles.drawerContainer)}>
                {/* Header */}
                <div className={css(styles.drawerHeader2)}>
                  <div className='fx fx__hv-center'>
                    <i className='mr-2 fa fa-lg fa-users' />
                    <div className='noselect'>
                      {`Chat Participants ${isChatCreator ? ' (creator view)' : ' (participant view)'}`}
                    </div>
                  </div>
                  <i
                    className={`fa fa-lg fa-times-circle ${css(styles.drawerHeaderCloseIcon)}`}
                    onClick={this.closeGroupChatManagerDrawer}
                  />
                </div>
                {/* Drawer content area */}
                <div className={css(styles.drawerContentArea)}>
                  {/* Group chat title */}
                  <div className='pl-2 mt-2'>
                    {isChatCreator
                      ? <TextInputWithLabel
                          value={groupChatUpdatedTitle}
                          titleText="Group Chat Name"
                          isCompactLayout={false}
                          showPreIcon
                          preIcon='fa fa-comments'
                          titleColor="#60A0E0"
                          textColor="#0080FF"
                          selectionColor="white"
                          selectionBackground="#0080FF"
                          spellcheck={false}
                          width={330}
                          onChange={this.handleOnEditGroupChatUpdatedTitle}
                        />
                      : <div style={{ color: blue[500], fontWeight: 'bold' }}>
                          {groupChatUpdatedTitle}
                        </div>
                    }
                  </div>
                  {/* Group chat user search */}
                  <div
                    className='p-2'
                    ref={this.groupChatUpdatedUserRef}
                    style={{
                      display: isChatCreator ? '' : 'none'
                    }}
                  >
                    <div>
                      <TextInputWithLabel
                        value={groupChatUpdatedUserSearch}
                        titleText="Search"
                        isCompactLayout={false}
                        showPreIcon
                        preIcon='fa fa-search'
                        titleColor="#60A0E0"
                        textColor="#0080FF"
                        selectionColor="white"
                        selectionBackground="#0080FF"
                        spellcheck={false}
                        width={330}
                        onChange={this.handleOnEditGroupChatUpatedUserSearch}
                        onClick={() => {
                          this.toggleGroupChatUpdatedUserSearchMenu(true);
                        }}
                      />
                      <PopupMenu
                        top={145}
                        left={8}
                        zIndex='1550'
                        position="absolute"
                        visible={isGroupChatUpdatedUserSearchMenuVisible}
                        parent={this.groupChatUpdatedUserRef}
                        onOutsideClick={this.closeGroupChatUpdatedUserSearchMenu}
                      >
                        <div style={{ background: '#0a3250', width: '330px' }}>
                          {groupChatUpdatedSearchedUsers}
                        </div>
                      </PopupMenu>
                    </div>
                  </div>
                  {/* Chat creator */}
                  <div className={css(styles.chatCreator)}>
                    <img
                      className={css(styles.chatCreatorAvatar)}
                      alt="avatar"
                      loading='lazy'
                      src={groupChatUpdatedChatCreatorAvatarUrl}
                    />
                    <div className={css(styles.chatCreatorText)}>
                      <div>{activeChat.chatCreatorDisplayName}</div>
                      <div className={css(styles.chatCreatorUserName)}>
                        {`@${activeChat.chatCreatorUseName}`}
                      </div>
                    </div>
                    <div>
                      Chat Creator
                    </div>
                  </div>
                  {/* Group chat members */}
                  <div>
                    {currentGroupChatParticipants}
                  </div>
                </div>
                {/* Drawer Footer */}
                {isChatCreator
                  ? <div
                      style={{ textAlign: 'center', paddingBottom: '8px' }}
                    >
                      <Button
                        btnType="primary"
                        className="fx fx__hv-center"
                        onClick={this.updateGroupChat}
                        style={{
                          height: '32px',
                          width: '340px',
                          padding: '3px 0'
                        }}
                      >
                        Update Group Chat
                      </Button>
                    </div>
                  : null
                }
              </div>
            </Drawer>
            : null
          }

          {/* Panel Drawer */}
          <div className={css(styles.drawer, isDrawerOpen ? styles.openDrawer : styles.hideDrawer)}>
            {/* Drawer header */}
            <div className={css(styles.drawerHeader)}>
              {/* Tabs */}
              <div>
                <TabsContainer
                  dropDownId="d1"
                  showPlusIcon={false}
                  containerWidth={320}
                  otherElementsWidth={0}
                  minWidth={320}
                  dropDownTop={39}
                  dropDownLeft={44}
                  dropDownHeaderStyles={{
                    minWidth: '170px',
                    maxWidth: '370px',
                    width: '100%'
                  }}
                  containerStyles={{
                    width: 'calc(100% - 320px)'
                  }}
                >
                  {/* DM's */}
                  <TabItem
                    tabKey="1"
                    key='9898'
                    parentKey="0111"
                    text="DM's"
                    isActive={activeTab === 0}
                    iconClass='fa fa-comments'
                    style={{
                      borderRight: '1px solid #082840',
                      borderLeft: '1px solid #082840',
                      paddingRight: '0',
                      width: '100px',
                      minWidth: '100px',
                      maxWidth: '1000px'
                    }}
                    textStyles={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                    onActivate={() => {
                      this.handleOnActivateTab(0);
                      if (chats.length > 0) {
                        this.handleOnLoadChat(chats[0]._id, false);
                        this.setState({ showDmBadge: false });
                      } else {
                        this.setState({
                          activeChat: {},
                          showDmBadge: false
                        });
                      }
                    }}
                    showBadge={showDmBadge}
                  >
                    DM&#39;s
                  </TabItem>
                  {/* Group chats */}
                  <TabItem
                    tabKey="2"
                    key='9899'
                    parentKey="0111"
                    text="Group Chats"
                    isActive={activeTab === 1}
                    iconClass='fa fa-users'
                    style={{
                      borderRight: '1px solid #082840',
                      borderLeft: '1px solid #082840',
                      paddingRight: '0',
                      width: '140px',
                      minWidth: '140px',
                      maxWidth: '140px'
                    }}
                    textStyles={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                    onActivate={this.loadGroupChatTab}
                    showBadge={showGroupChatBadge}
                  >
                    Group Chats
                  </TabItem>
                </TabsContainer>
              </div>
              {/* Header text */}
              <div className={css(styles.drawerHeaderTextContainer)}>
                <div>
                  <i className='fa fa-search' />
                </div>
                <TextInput
                  value={searchTerm}
                  placeholder="Search Users, Groups, and Messages"
                  style={{ width: `${drawerWidth - 40}px`, fontSize: '14px' }}
                  onChange={this.handleOnChangeSearchTerm}
                />
                {/* New Chat Menu Icon */}
                <div
                  className={css(styles.drawerHeaderIconContainer)}
                  ref={this.chatIcon}
                  onClick={() => {
                    this.toggleNewChatMenu(true);
                    if (activeTab === 0) {
                      this.handleOnClickNewChat();
                    } else {
                      this.handleOnClickNewGroupChat();
                    }
                  }}
                >
                  <svg
                    width="31"
                    height="24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.275 6.585a8.332 8.332 0 0 1 1.93 2.667c.47 1.039.71 2.143.708 3.282a7.87 7.87 0 0 1-.71 3.28 8.367 8.367 0 0 1-1.93 2.666 8.983 8.983 0 0 1-2.845 1.788c-1.1.432-2.269.653-3.472.653a9.5 9.5 0 0 1-2.796-.417L3.565 21.98a.638.638 0 0 1-.594.014.626.626 0 0 1-.341-.488l-.405-3.44C.79 16.534 0 14.578 0 12.532c0-1.137.24-2.241.71-3.28a8.366 8.366 0 0 1 1.93-2.667 8.982 8.982 0 0 1 2.845-1.788 9.462 9.462 0 0 1 3.472-.652c1.204 0 2.373.22 3.473.652a8.98 8.98 0 0 1 2.845 1.788Zm-.86 10.971a7.094 7.094 0 0 0 1.642-2.265 6.654 6.654 0 0 0 .002-5.518 7.105 7.105 0 0 0-1.641-2.266 7.719 7.719 0 0 0-2.45-1.538 8.203 8.203 0 0 0-3.01-.565c-1.045 0-2.058.191-3.01.565A7.749 7.749 0 0 0 3.5 7.507a7.098 7.098 0 0 0-1.641 2.266 6.627 6.627 0 0 0-.598 2.759c0 1.783.715 3.49 2.013 4.806.098.1.161.229.177.369l.318 2.706 2.01-1.142a.63.63 0 0 1 .515-.049c.85.29 1.748.437 2.663.437a8.167 8.167 0 0 0 3.01-.565 7.748 7.748 0 0 0 2.45-1.538Z" fill="#00A0F4"/><path d="M8.439 7.766A10.524 10.524 0 0 1 10.876 4.4 11.345 11.345 0 0 1 14.47 2.14a11.952 11.952 0 0 1 4.386-.824c1.52 0 2.997.278 4.387.824 1.346.53 2.555 1.29 3.593 2.259a10.567 10.567 0 0 1 2.438 3.367 9.98 9.98 0 0 1 .897 4.144c0 2.583-.997 5.054-2.811 6.99l-.51 4.345a.79.79 0 0 1-.79.702.806.806 0 0 1-.393-.104l-3.277-1.862c-1.136.349-2.323.525-3.532.525a11.95 11.95 0 0 1-4.386-.824 11.345 11.345 0 0 1-3.593-2.258 10.566 10.566 0 0 1-2.438-3.368 9.94 9.94 0 0 1-.897-4.144 9.947 9.947 0 0 1 .895-4.146Z"
                      fill={blue[500]}
                    />
                    <path
                      d="M19.815 11.104V7.92h-1.712v3.184H14.92v1.712h3.184V16h1.712v-3.184H23v-1.712h-3.184Z"
                      fill={gray[900]}
                    />
                  </svg>
                </div>
              </div>
            </div>
            {/* Chat message cards */}
            <div className={css(styles.drawerTabsContainer)}>
              {activeTab === 0 ? dmCards : groupChatCards}
            </div>
          </div>
          {/* Panel details */}
          <div
            className={css(styles.detail)}
            style={{
              width: detailWidth,
              overflow: window.innerWidth <= DRAWER_BREAK_POINT ? 'hidden' : '',
              display: 'flex',
              flexFlow: 'column'
            }}
          >
            <div className={css(styles.detailHeader)}>
              <div className='fx' style={{ paddingTop: '1px' }}>
                {/* Collapse/Expand arrow icon */}
                <div
                  onClick={() => { this.setState({ isDrawerOpen: !this.state.isDrawerOpen }); }}
                >
                  <i
                    className={`mr-2 fa ${isDrawerOpen ? 'fa-chevron-circle-left' : 'fa-chevron-circle-right'}`}
                    style={{ fontSize: '24px' }}
                  />
                </div>
                <div
                  className='fx fx__hv-center'
                  style={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '5px',
                    // background: blue[500],
                    color: 'black',
                    display: activeChat && activeChat._id ? '' : 'none'
                  }}
                >
                  {activeTab === 0
                    ? <img
                        src={avatarUrl}
                        alt="avatar"
                        loading='lazy'
                        style={{
                          width: 'auto',
                          height: '30px',
                          maxHeight: '30px',
                          borderRadius: '5px'
                        }}
                      />
                    : <div
                        style={{
                          position: 'relative'
                        }}
                      >
                        <i
                          className='fa fa-users'
                          style={{ fontSize: '24px' }}
                        />
                        <div
                          style={{
                            position: 'absolute',
                            bottom: '-2px',
                            right: '-8px',
                            background: blue[700],
                            color: 'white',
                            fontSize: '12px',
                            height: '16px',
                            width: '16px',
                            textAlign: 'center',
                            borderRadius: '3px',
                            border: '1px solid black',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                        >
                          {activeChat.members ? (activeChat.members.length + 1) : 0}
                        </div>
                      </div>
                  }
                </div>
                <div
                  className='ml-2'
                  style={{ display: activeChat && activeChat._id ? '' : 'none' }}
                >
                  <div
                    style={{
                      fontSize: activeTab === 0 ? '16px' : '14px',
                      height: activeTab === 0 ? '' : '16px',
                      lineHeight: activeTab === 0 ? '' : '16px',
                      minWidth: '150px'
                    }}
                  >
                    {chatTitle}
                  </div>
                  {activeTab === 1
                    ? <div
                        style={{
                          display: 'flex',
                          color: blue[500],
                          fontSize: '16px',
                          paddingLeft: '2px',
                          cursor: 'pointer',
                          top: '-4px',
                          alignItems: 'center',
                          position: 'relative'
                        }}
                        onClick={() => this.toggleGroupChatManagerDrawer(true)}
                      >
                        <div>{activeChat.chatCreatorDisplayName}</div>
                        <i className='ml-2 fa fa-bullhorn' />
                      </div>
                    : null
                  }
                </div>
              </div>
              {activeChat && activeChat._id
                ? <div
                  className='mr-1 fx fx__hv-center'
                  ref={this.chatRoomMenuRef}
                  onClick={() => {
                    if (activeTab === 0) {
                      this.toggleChatRoomMenu(!isChatRoomMenuVisible);
                    }
                  }}
                  style={{
                    background: gray[400],
                    color: 'black',
                    height: '24px',
                    width: '24px',
                    borderRadius: '50%',
                    display: activeTab === 1 ? 'none' : ''
                  }}
                >
                  <i className='fa fa-ellipsis-v' />
                  <PopupMenu
                    top={41}
                    right={0}
                    visible={isChatRoomMenuVisible}
                    parent={this.chatRoomMenuRef}
                    onOutsideClick={this.closeChatRoomMenu}
                    zIndex='1600'
                    position="absolute"
                  >
                    <div className={css(styles.newChatMenuContainer)}>
                      {/* Delete chat room */}
                      <div
                        className="m-item"
                        onClick={this.handleOnClickDeleteChat}
                        style={{
                          height: '40px',
                          display: 'flex',
                          alignItems: 'center',
                          borderBottom: '1px solid #001824',
                          cursor: 'pointer',
                          color: salmon[500]
                        }}
                      >
                        <div
                          className="fx fx__hv-center"
                          style={{
                            height: '40px',
                            width: '40px'
                          }}
                        >
                          <i style={{ fontSize: '24px' }} className="fa fa-trash" />
                        </div>
                        <div>
                          Delete Chat Room
                        </div>
                      </div>
                    </div>
                  </PopupMenu>
                </div>
                : null
              }
            </div>
            {activeChat && activeChat._id
              ? <ChatRoom
                chatTitle={chatTitle}
                room={activeChat}
                onPostMessage={this.handleOnPostMessage}
                onUploadFile={this.handleOnPostMessage}
                showNewMessgeIcon={showMessageIcon}
                onCloseChat={() => {
                  this.setState({ isDrawerOpen: !this.state.isDrawerOpen });
                }}
              />
              : <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '42px'
                  }}
                >
                  Select Chat Room
                </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps ({ user }) {
  return { ...user };
}

export default connect(mapStateToProps, { clearChatBadge })(Chat);
