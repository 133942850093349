import React, { useState, useEffect } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { Link, useParams } from 'react-router-dom';
import { blue, gray } from 'quantux-ui';
import { DRAWER_BREAK_POINT } from '../utils';

const drawerWidth = window.innerWidth <= DRAWER_BREAK_POINT ? window.innerWidth : 320;

const styles = StyleSheet.create({
  splitPanel: {
    display: 'flex',
    flexFlow: 'row nowrap',
    // position: 'relative',
    background: '#002030',
    height: 'calc(100vh - 45px)',
    width: '100%',
    overflow: 'hidden',
    fontFamily: 'Lato'
  },
  drawer: {
    position: 'relative',
    height: '100%',
    borderRight: '1px solid #082840',
    transition: '0.3s',
    padding: '0',
    overflowY: 'auto',
    zIndex: '1900'
  },
  openDrawer: {
    left: '0',
    minWidth: `${drawerWidth}px`,
    width: `${drawerWidth}px`
  },
  hideDrawer: {
    transition: '0.3s',
    width: '0px',
    minWidth: '0px',
    left: `calc(-1 * ${drawerWidth}px)`
  },
  drawerHeader: {
    height: '40px',
    fontSize: '16px',
    background: '#001824',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    borderBottom: '0.5px solid #082840',
    marginLeft: '8px',
    cursor: 'pointer'
  },
  drawerHeaderIconContainer: {
    // color: '#0080FF',
    height: '32px',
    width: '32px',
    // background: '#0080FF',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '4px',
    cursor: 'pointer'
  },
  drawerHeaderIcon: {
    fontSize: '24px',
    // color: 'white',
    cursor: 'pointer'
  },
  drawerHeaderTextContainer: {
    textAlign: 'left',
    lineHeight: '14px',
    cursor: 'pointer'
  },
  drawerHeaderText: {
    textAlign: 'center',
    fontSize: '18px',
    color: '#60A0E0',
    cursor: 'pointer',
    paddingLeft: '4px'
  },
  drawerHeaderTextSub: {
    color: '#0080FF',
    fontSize: '14px',
    cursor: 'pointer'
  },
  drawerTabsContainer: {
    height: 'calc(100% - 64px)',
    overflowY: 'auto',
    paddingTop: '8px'
  },
  drawerAccordian: {
    position: 'relative',
    display: 'block',
    transition: '0.5s ease',
    maxHeight: '2000px'
  },
  menuItem: {
    position: 'relative',
    left: '-3px',
    color: gray[300]
  },
  detail: {
    // position: 'relative',
    transition: '0s',
    height: '100%',
    left: '0'
  },
  detailHeader: {
    height: '40px',
    background: '#001824',
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'space-between',
    borderBottom: '0.5px solid #082840',
    padding: '0 8px'
  },
  profileBanner: {
    borderBottom: '1px solid transparent'
  },
  profileBannerImg: {
    width: '100%',
    height: 'auto',
    maxHeight: '220px'
  },
  bigUserCardContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    borderBottom: '1px solid transparent'
  },
  tabs: {
    // borderBottom: '1px solid red'
  },
  popupMenuContainer: {
    background: '#083050',
    width: '150px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'
  },
  popupMenuItem: {
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#5080B0'
  },
  menuItemIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '40px',
    width: '40px'
  },
  menuItemIcon: {
    fontSize: '20px'
  },
  accordianRightBorder: {
    position: 'absolute',
    width: '1px',
    height: 'calc(100% - 8px)',
    background: '#305C82',
    left: '13px',
    top: '8px'
  },
  linkStyles: {
    display: 'flex',
    alignItems: 'center',
    color: gray[300]
  },
  selectedLink: {
    color: blue[500]
  },
  drawerFile: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '4px',
    color: blue[500],
    height: '40px'
  },
  drawerFileIcon: {
    fontSize: '32px',
    marginRight: '8px'
  },
  activeDrawerMenuItem: {
    borderLeft: `1px solid ${blue[500]}`,
    color: blue[500],
    background: gray[700]
  }
});

const Legal = () => {
  const { tab } = useParams();
  const [activeComponent, setActiveComponent] = useState(tab);
  const [isMobileLayout, setMobileLayout] = useState(false);
  const [isDrawerOpen, toggleDrawer] = useState(true);

  const handleWindowResize = () => {
    const isMobile = window.innerWidth <= 768;
    console.log('isMobile', isMobile);
    toggleDrawer(!isMobile);
    setMobileLayout(isMobile);
  };

  useEffect(() => {
    setActiveComponent(tab);
  }, [activeComponent]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    }
  }, []);

  let activeText = 'Heading';
  switch (tab) {
    case 'pPolicy':
      activeText = 'Privacy Policy (Updated 09/01/2021)';
      break;
    case 'cPolicy':
      activeText = 'Cookie Policy (Updated 10/01/2021)';
      break;
    case 'hPolicy':
      activeText = 'HIPPA Policy (Updated 10/15/2021)';
      break;
    case 'tos':
    default:
      activeText = 'Terms of Service (Updated 10/11/2021)';
  }

  const comp = (
    <div style={{ textAlign: 'center', height: '100%' }}>
      <div
        style={{
          marginTop: '10px',
          height: 'calc(100% - 45px)',
          overflowX: 'hidden',
          overflowY: 'auto',
          margin: 'auto',
          padding: '16px',
          textAlign: 'left',
          marginBottom: '8px'
        }}
      >
        {/* Just to render scroll bar */}
        <div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam euismod facilisis commodo. Cras consectetur massa ut ligula ornare, sed pretium felis auctor. Mauris felis orci, mollis ac commodo vel, venenatis id ligula. Fusce pretium est ut nisi pharetra semper. Quisque accumsan blandit ipsum et rhoncus. Nam a bibendum nibh. Phasellus ut fringilla libero. Suspendisse nec tellus tincidunt, vehicula sem a, faucibus erat. Aenean lacus dui, ultrices at porttitor id, pellentesque in justo. Quisque fermentum justo vehicula, aliquet metus at, ullamcorper nunc. Praesent in diam ac lacus gravida fringilla. In sodales euismod risus at fringilla. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.
          </p>
          <p>
            Vestibulum eget urna aliquam, sodales sapien nec, dapibus nunc. In a vulputate lorem. Aliquam erat volutpat. Praesent justo magna, pulvinar nec eros vel, finibus suscipit mi. Integer condimentum at massa sit amet ornare. In hac habitasse platea dictumst. Cras tincidunt, nulla quis fermentum faucibus, augue felis placerat nulla, eget imperdiet massa enim in justo. Nullam ex dui, interdum in turpis et, ultrices lacinia dolor. Quisque et rhoncus risus, vel tempor purus.
          </p>
          <p>
          Fusce ullamcorper tempor nisi, ac porttitor nisl convallis semper. Nullam dictum justo non iaculis ultrices. Proin placerat hendrerit posuere. Aenean feugiat ullamcorper justo commodo pulvinar. Pellentesque vitae tortor turpis. Fusce tincidunt mi quis pretium rhoncus. Integer elementum augue mauris, auctor accumsan orci suscipit eget. Phasellus sit amet sagittis urna. Nunc commodo, libero ut viverra tincidunt, ante velit venenatis ligula, eget facilisis elit leo ut urna. In ultricies a leo at placerat. Duis rutrum, dui quis sagittis aliquam, arcu lacus porttitor neque, non eleifend quam diam nec est.          </p>
          <p>
            Fusce ullamcorper tempor nisi, ac porttitor nisl convallis semper. Nullam dictum justo non iaculis ultrices. Proin placerat hendrerit posuere. Aenean feugiat ullamcorper justo commodo pulvinar. Pellentesque vitae tortor turpis. Fusce tincidunt mi quis pretium rhoncus. Integer elementum augue mauris, auctor accumsan orci suscipit eget. Phasellus sit amet sagittis urna. Nunc commodo, libero ut viverra tincidunt, ante velit venenatis ligula, eget facilisis elit leo ut urna. In ultricies a leo at placerat. Duis rutrum, dui quis sagittis aliquam, arcu lacus porttitor neque, non eleifend quam diam nec est.
          </p>
          <p>
            Fusce ullamcorper tempor nisi, ac porttitor nisl convallis semper. Nullam dictum justo non iaculis ultrices. Proin placerat hendrerit posuere. Aenean feugiat ullamcorper justo commodo pulvinar. Pellentesque vitae tortor turpis. Fusce tincidunt mi quis pretium rhoncus. Integer elementum augue mauris, auctor accumsan orci suscipit eget. Phasellus sit amet sagittis urna. Nunc commodo, libero ut viverra tincidunt, ante velit venenatis ligula, eget facilisis elit leo ut urna. In ultricies a leo at placerat. Duis rutrum, dui quis sagittis aliquam, arcu lacus porttitor neque, non eleifend quam diam nec est.
          </p>
          <p>
            Fusce ullamcorper tempor nisi, ac porttitor nisl convallis semper. Nullam dictum justo non iaculis ultrices. Proin placerat hendrerit posuere. Aenean feugiat ullamcorper justo commodo pulvinar. Pellentesque vitae tortor turpis. Fusce tincidunt mi quis pretium rhoncus. Integer elementum augue mauris, auctor accumsan orci suscipit eget. Phasellus sit amet sagittis urna. Nunc commodo, libero ut viverra tincidunt, ante velit venenatis ligula, eget facilisis elit leo ut urna. In ultricies a leo at placerat. Duis rutrum, dui quis sagittis aliquam, arcu lacus porttitor neque, non eleifend quam diam nec est.
          </p>
          <p>
            Fusce ullamcorper tempor nisi, ac porttitor nisl convallis semper. Nullam dictum justo non iaculis ultrices. Proin placerat hendrerit posuere. Aenean feugiat ullamcorper justo commodo pulvinar. Pellentesque vitae tortor turpis. Fusce tincidunt mi quis pretium rhoncus. Integer elementum augue mauris, auctor accumsan orci suscipit eget. Phasellus sit amet sagittis urna. Nunc commodo, libero ut viverra tincidunt, ante velit venenatis ligula, eget facilisis elit leo ut urna. In ultricies a leo at placerat. Duis rutrum, dui quis sagittis aliquam, arcu lacus porttitor neque, non eleifend quam diam nec est.
          </p>
          <p>
            Fusce ullamcorper tempor nisi, ac porttitor nisl convallis semper. Nullam dictum justo non iaculis ultrices. Proin placerat hendrerit posuere. Aenean feugiat ullamcorper justo commodo pulvinar. Pellentesque vitae tortor turpis. Fusce tincidunt mi quis pretium rhoncus. Integer elementum augue mauris, auctor accumsan orci suscipit eget. Phasellus sit amet sagittis urna. Nunc commodo, libero ut viverra tincidunt, ante velit venenatis ligula, eget facilisis elit leo ut urna. In ultricies a leo at placerat. Duis rutrum, dui quis sagittis aliquam, arcu lacus porttitor neque, non eleifend quam diam nec est.
          </p>
          <p>
            Fusce ullamcorper tempor nisi, ac porttitor nisl convallis semper. Nullam dictum justo non iaculis ultrices. Proin placerat hendrerit posuere. Aenean feugiat ullamcorper justo commodo pulvinar. Pellentesque vitae tortor turpis. Fusce tincidunt mi quis pretium rhoncus. Integer elementum augue mauris, auctor accumsan orci suscipit eget. Phasellus sit amet sagittis urna. Nunc commodo, libero ut viverra tincidunt, ante velit venenatis ligula, eget facilisis elit leo ut urna. In ultricies a leo at placerat. Duis rutrum, dui quis sagittis aliquam, arcu lacus porttitor neque, non eleifend quam diam nec est.
          </p>
          <p>
            Fusce ullamcorper tempor nisi, ac porttitor nisl convallis semper. Nullam dictum justo non iaculis ultrices. Proin placerat hendrerit posuere. Aenean feugiat ullamcorper justo commodo pulvinar. Pellentesque vitae tortor turpis. Fusce tincidunt mi quis pretium rhoncus. Integer elementum augue mauris, auctor accumsan orci suscipit eget. Phasellus sit amet sagittis urna. Nunc commodo, libero ut viverra tincidunt, ante velit venenatis ligula, eget facilisis elit leo ut urna. In ultricies a leo at placerat. Duis rutrum, dui quis sagittis aliquam, arcu lacus porttitor neque, non eleifend quam diam nec est.
          </p>
          <p>
            Fusce ullamcorper tempor nisi, ac porttitor nisl convallis semper. Nullam dictum justo non iaculis ultrices. Proin placerat hendrerit posuere. Aenean feugiat ullamcorper justo commodo pulvinar. Pellentesque vitae tortor turpis. Fusce tincidunt mi quis pretium rhoncus. Integer elementum augue mauris, auctor accumsan orci suscipit eget. Phasellus sit amet sagittis urna. Nunc commodo, libero ut viverra tincidunt, ante velit venenatis ligula, eget facilisis elit leo ut urna. In ultricies a leo at placerat. Duis rutrum, dui quis sagittis aliquam, arcu lacus porttitor neque, non eleifend quam diam nec est.
          </p>
          <p>
            Fusce ullamcorper tempor nisi, ac porttitor nisl convallis semper. Nullam dictum justo non iaculis ultrices. Proin placerat hendrerit posuere. Aenean feugiat ullamcorper justo commodo pulvinar. Pellentesque vitae tortor turpis. Fusce tincidunt mi quis pretium rhoncus. Integer elementum augue mauris, auctor accumsan orci suscipit eget. Phasellus sit amet sagittis urna. Nunc commodo, libero ut viverra tincidunt, ante velit venenatis ligula, eget facilisis elit leo ut urna. In ultricies a leo at placerat. Duis rutrum, dui quis sagittis aliquam, arcu lacus porttitor neque, non eleifend quam diam nec est.
          </p>
          <p>
            Fusce ullamcorper tempor nisi, ac porttitor nisl convallis semper. Nullam dictum justo non iaculis ultrices. Proin placerat hendrerit posuere. Aenean feugiat ullamcorper justo commodo pulvinar. Pellentesque vitae tortor turpis. Fusce tincidunt mi quis pretium rhoncus. Integer elementum augue mauris, auctor accumsan orci suscipit eget. Phasellus sit amet sagittis urna. Nunc commodo, libero ut viverra tincidunt, ante velit venenatis ligula, eget facilisis elit leo ut urna. In ultricies a leo at placerat. Duis rutrum, dui quis sagittis aliquam, arcu lacus porttitor neque, non eleifend quam diam nec est.
          </p>
          <p>
            Fusce ullamcorper tempor nisi, ac porttitor nisl convallis semper. Nullam dictum justo non iaculis ultrices. Proin placerat hendrerit posuere. Aenean feugiat ullamcorper justo commodo pulvinar. Pellentesque vitae tortor turpis. Fusce tincidunt mi quis pretium rhoncus. Integer elementum augue mauris, auctor accumsan orci suscipit eget. Phasellus sit amet sagittis urna. Nunc commodo, libero ut viverra tincidunt, ante velit venenatis ligula, eget facilisis elit leo ut urna. In ultricies a leo at placerat. Duis rutrum, dui quis sagittis aliquam, arcu lacus porttitor neque, non eleifend quam diam nec est.
          </p>
          <p>
            Fusce ullamcorper tempor nisi, ac porttitor nisl convallis semper. Nullam dictum justo non iaculis ultrices. Proin placerat hendrerit posuere. Aenean feugiat ullamcorper justo commodo pulvinar. Pellentesque vitae tortor turpis. Fusce tincidunt mi quis pretium rhoncus. Integer elementum augue mauris, auctor accumsan orci suscipit eget. Phasellus sit amet sagittis urna. Nunc commodo, libero ut viverra tincidunt, ante velit venenatis ligula, eget facilisis elit leo ut urna. In ultricies a leo at placerat. Duis rutrum, dui quis sagittis aliquam, arcu lacus porttitor neque, non eleifend quam diam nec est.
          </p>
          <p>
            Fusce ullamcorper tempor nisi, ac porttitor nisl convallis semper. Nullam dictum justo non iaculis ultrices. Proin placerat hendrerit posuere. Aenean feugiat ullamcorper justo commodo pulvinar. Pellentesque vitae tortor turpis. Fusce tincidunt mi quis pretium rhoncus. Integer elementum augue mauris, auctor accumsan orci suscipit eget. Phasellus sit amet sagittis urna. Nunc commodo, libero ut viverra tincidunt, ante velit venenatis ligula, eget facilisis elit leo ut urna. In ultricies a leo at placerat. Duis rutrum, dui quis sagittis aliquam, arcu lacus porttitor neque, non eleifend quam diam nec est.
          </p>
          <p>
            Fusce ullamcorper tempor nisi, ac porttitor nisl convallis semper. Nullam dictum justo non iaculis ultrices. Proin placerat hendrerit posuere. Aenean feugiat ullamcorper justo commodo pulvinar. Pellentesque vitae tortor turpis. Fusce tincidunt mi quis pretium rhoncus. Integer elementum augue mauris, auctor accumsan orci suscipit eget. Phasellus sit amet sagittis urna. Nunc commodo, libero ut viverra tincidunt, ante velit venenatis ligula, eget facilisis elit leo ut urna. In ultricies a leo at placerat. Duis rutrum, dui quis sagittis aliquam, arcu lacus porttitor neque, non eleifend quam diam nec est.
          </p>
        </div>
      </div>
    </div>
  );

  return (
    <div style={{ marginTop: '40px' }}>
      <div className={css(styles.splitPanel)}>
        {/* Panel Drawer */}
        <div
          className={css(styles.drawer, isDrawerOpen ? styles.openDrawer : styles.hideDrawer)}
        >
          {/* Drawer header (Mini user card) */}
          <div className={css(styles.drawerHeader)}>
            {/* Header icon */}
            <div className={css(styles.drawerHeaderIconContainer)}>
              <i className={`fa fa-gavel ${css(styles.drawerHeaderIcon)}`} />
            </div>
            {/* Header text */}
            <div className={css(styles.drawerHeaderTextContainer)}>
              <div className={css(styles.drawerHeaderText)}>
                Legal Page
              </div>
            </div>
          </div>
          {/* Drawer tabs */}
          <div className={css(styles.drawerTabsContainer)}>
            <div className={css(styles.drawerAccordian)}>
              {/* Accordian right border */}
              <div className={css(styles.accordianRightBorder)} />
              {/* Terms of Service */}
              <Link to='/legal/tos'>
                <div
                  className={`noselect ml-3 drawer-menu-item ${css(styles.menuItem, activeComponent === 'tos' && styles.activeDrawerMenuItem)}`}
                  onClick={() => {
                    setActiveComponent('tos');
                    if (isMobileLayout) {
                      toggleDrawer(false);
                    }
                  }}
                >
                  <div
                    className="text"
                    style={{ fontSize: '1rem', lineHeight: '14px' }}
                  >
                    Terms of Service
                  </div>
                </div>
              </Link>
              {/* Privacy Policy */}
              <Link to='/legal/pPolicy'>
                <div
                  className={`noselect ml-3 drawer-menu-item ${css(styles.menuItem, activeComponent === 'pPolicy' && styles.activeDrawerMenuItem)}`}
                  onClick={() => {
                    setActiveComponent('pPolicy');
                    if (isMobileLayout) {
                      toggleDrawer(false);
                    }
                  }}
                >
                  <div
                    className="text"
                    style={{ fontSize: '1rem', lineHeight: '14px' }}
                  >
                    Privacy Policy
                  </div>
                </div>
              </Link>
              {/* Cookie Policy */}
              <Link to='/legal/cPolicy'>
                <div
                  className={`noselect ml-3 drawer-menu-item ${css(styles.menuItem, activeComponent === 'cPolicy' && styles.activeDrawerMenuItem)}`}
                  onClick={() => {
                    setActiveComponent('cPolicy');
                    if (isMobileLayout) {
                      toggleDrawer(false);
                    }
                  }}
                >
                  <div
                    className="text"
                    style={{ fontSize: '1rem', lineHeight: '14px' }}
                  >
                    Cookie Policy
                  </div>
                </div>
              </Link>
              {/* HIPPA Policy */}
              <Link to='/legal/hPolicy'>
                <div
                  className={`noselect ml-3 drawer-menu-item ${css(styles.menuItem, activeComponent === 'hPolicy' && styles.activeDrawerMenuItem)}`}
                  onClick={() => {
                    setActiveComponent('hPolicy');
                    if (isMobileLayout) {
                      toggleDrawer(false);
                    }
                  }}
                >
                  <div
                    className="text"
                    style={{ fontSize: '1rem', lineHeight: '14px' }}
                  >
                    HIPPA Policy
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* Panel details */}
        <div
          className={css(styles.detail)}
          style={{
            width: isDrawerOpen ? `calc(100vw - ${drawerWidth}px)` : '100vw'
          }}
        >
          <div className={css(styles.detailHeader)}>
            <div
              onClick={() => toggleDrawer(!isDrawerOpen)}
            >
              <i
                className={`mr-2 fa ${isDrawerOpen ? 'fa-chevron-circle-left' : 'fa-chevron-circle-right'}`}
                style={{ fontSize: '24px' }}
              />
            </div>
            <div>{activeText}</div>
          </div>
          {/* Active component */}
          {comp}
        </div>
      </div>
    </div>
  );
};

export default Legal;
