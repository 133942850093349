import React, { Fragment } from 'react';
import { css, StyleSheet } from 'aphrodite/no-important';
import { blue } from 'quantux-ui';
import Footer from '../components/footer';

const Home = props => {
  const styles = StyleSheet.create({
    txtHero: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: blue[500],
      color: 'black',
      height: '365px',
      '@media (max-width: 574px)': {
        textAlign: 'center'
      }
    },
    textContainer: {
      width: '860px',
      '@media (max-width: 574px)': {
        width: '100%',
        textAlign: 'center'
      }
    },
    content: {
      // height: '735px',
      background: 'white',
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      '@media (min-width: 574px)': {
        height: '735px'
      }
    }
  });
  return (
    <Fragment>
      {/* style={{ marginTop: '40px' }} disabled for now */}
      {/* <Hero
        heroImg='https://quantumtrader.s3.amazonaws.com/hero.jpg'
        showMask
      /> */}
      {/* Hero */}
      <div className={css(styles.txtHero)}>
        <h1>MedPros Healthcare Resources</h1>
      </div>
      {/* Site content */}
      <div className={css(styles.content)}>
        <h2 className='mt-4'>
          MedPros Credentialing Suite
        </h2>
        <div className={css(styles.textContainer)}>
          <p>
            MedPros Credentialing Suite transforms your credentialing process into a seamless, hassle-free experience. Integration with central data sources such as NPPES, FSMB, DEA, and many others makes initial certification, re-certification, and primary source verification a snap.
          </p>
          <p>
            Military-grade 256-bit encryption on HIPAA-comliant Amazon Web Servers provides a central, secure platform for storing sensitive provider data.
          </p>
          <p>
            Our in-app messaging system uses the same security protocols and infrastructure, allowing secure communication between providers and credentialing staff.
          </p>
        </div>
      </div>
      <Footer />
    </Fragment>
  );
};

export default Home;
