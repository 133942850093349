import React, { useState, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import { css, StyleSheet } from 'aphrodite/no-important';
import { ThemeContext } from 'quantux-ui';
import PracticeProvidersGrid from './practice-providers-grid';
import AboutPractice from './about-practice';
import { BASE_URL, history } from '../../utils';
import { togglePracticeDataReloadFlag } from '../../actions';

const drawerTabs = {
  about: 'About Profile',
  providers: 'Providers',
  facilities: 'Facilities',
  insurance: 'Insurance',
  revenueCycle: 'Revenue Cycle Management',
  scheduling: 'Scheduling'
};

const PracticeProviders = (props) => {
  return (
    <h1>Practice Providers</h1>
  );
};

const PracticeFacilities = (props) => {
  return (
    <h1>Practice Facilities</h1>
  );
};

const Placeholder = props => <h1>{props.text}</h1>;

const PracticeDashboard = (props) => {
  const [isUserMenuVisible, setUserMenuVisibility] = useState(false);
  const [isMobileScreen, setMobileScreen] = useState(false);
  const [isDrawerOpen, toggleDrawer] = useState(true);
  const [activeComponent, setActiveComponent] = useState(drawerTabs.about);
  const [activePractice, setActivePractice] = useState({});

  const loadPracticeData = () => {
    const practice = localStorage.getItem('practice');
    const parts = practice.split('/');
    const practiceNpi = +(parts[1]);
    const url = new URL(`${BASE_URL}/users/getPracticeProfile`);
    const params = { npi: practiceNpi };
    url.search = new URLSearchParams(params).toString();

    fetch(url)
      .then(response => response.text())
      .then(result => {
        props.togglePracticeDataReloadFlag(false);
        const reqData = JSON.parse(result);
        setActivePractice(reqData.practice)
      })
      .catch(error => console.log('error', error));
  };

  // Effects hook
  useEffect(() => {
    loadPracticeData();
  }, []);

  // Refs
  const userIcon = React.createRef();

  // Theme
  const context = useContext(ThemeContext);
  const { gray, blue } = context.colors;

  const styles = StyleSheet.create({
    splitPanel: {
      display: 'flex',
      flexFlow: 'row nowrap',
      // position: 'relative',
      background: '#002030',
      height: 'calc(100vh - 42px)',
      width: '100%',
      overflow: 'hidden',
      fontFamily: 'Lato'
    },
    drawer: {
      position: 'relative',
      height: 'calc(100vh - 40px)',
      minWidth: '320px',
      width: '320px',
      borderRight: '1px solid #082840',
      transition: '0.3s',
      left: '0',
      padding: '0',
      overflowY: 'auto',
      zIndex: '1900'
    },
    hideDrawer: {
      transition: '0.3s',
      left: 'calc(-1 * 320px)'
    },
    drawerHeader: {
      height: '40px',
      fontSize: '16px',
      background: '#001824',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'left',
      borderBottom: '0.5px solid #082840',
      marginLeft: '8px',
      cursor: 'pointer'
    },
    drawerHeaderIconContainer: {
      color: '#0080FF',
      height: '32px',
      width: '32px',
      background: '#0080FF',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '4px',
      cursor: 'pointer'
    },
    drawerHeaderIcon: {
      fontSize: '24px',
      color: 'white',
      cursor: 'pointer'
    },
    drawerHeaderTextContainer: {
      textAlign: 'left',
      lineHeight: '14px',
      cursor: 'pointer'
    },
    drawerHeaderText: {
      textAlign: 'center',
      fontSize: '14px',
      color: '#60A0E0',
      cursor: 'pointer'
    },
    drawerHeaderTextSub: {
      color: '#0080FF',
      fontSize: '14px',
      cursor: 'pointer'
    },
    drawerTabsContainer: {
      height: 'calc(100% - 64px)',
      overflowY: 'auto'
    },
    drawerAccordian: {
      position: 'relative',
      display: 'block',
      transition: '0.5s ease',
      maxHeight: '2000px'
    },
    menuItem: {
      position: 'relative',
      left: '-3px',
      color: gray[300]
    },
    detail: {
      // position: 'relative',
      transition: '0s',
      height: 'calc(100vh - 40px)',
      width: 'calc(100vw - 320px)',
      left: '0'
    },
    detailExapnd: {
      transition: '0.2s',
      left: 'calc(-1 * 320px)',
      width: '100vw'
    },
    detailHeader: {
      height: '40px',
      background: '#001824',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderBottom: '0.5px solid #082840',
      padding: '0 8px'
    },
    profileBanner: {
      borderBottom: '1px solid transparent'
    },
    profileBannerImg: {
      width: '100%',
      height: 'auto',
      maxHeight: '220px'
    },
    bigUserCardContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      borderBottom: '1px solid transparent'
    },
    tabs: {
      // borderBottom: '1px solid red'
    },
    popupMenuContainer: {
      background: '#083050',
      width: '150px',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)'
    },
    popupMenuItem: {
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      color: '#5080B0'
    },
    menuItemIconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '40px',
      width: '40px'
    },
    menuItemIcon: {
      fontSize: '20px'
    },
    accordianRightBorder: {
      position: 'absolute',
      width: '1px',
      height: 'calc(100% - 40px)',
      background: '#305C82',
      left: '13px',
      top: '40px'
    },
    linkStyles: {
      display: 'flex',
      alignItems: 'center',
      color: gray[300]
    },
    selectedLink: {
      color: blue[500]
    },
    drawerFile: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '4px',
      color: blue[500],
      height: '40px'
    },
    drawerFileIcon: {
      fontSize: '32px',
      marginRight: '8px'
    },
    activeDrawerMenuItem: {
      borderLeft: `1px solid ${blue[500]}`,
      color: blue[500],
      background: gray[700]
    }
  });

  const handleOnClickMiniUserCard = () => {
    setActiveComponent(drawerTabs.about);
  };

  const handleReloadPracticeData = () => {
    loadPracticeData();
  }

  let comp = null;
  let activeText = '';
  switch (activeComponent) {
    case drawerTabs.providers:
      comp = (
        <PracticeProvidersGrid
          owners={activePractice.users}
          practice={activePractice}
        />
      );
      activeText = drawerTabs.providers;
      break;
    case drawerTabs.facilities:
      comp = <PracticeFacilities />;
      activeText = drawerTabs.facilities;
      break;
    case drawerTabs.insurance:
      comp = <Placeholder text='Placeholder for Insurance' />;
      activeText = drawerTabs.insurance;
      break;
    case drawerTabs.scheduling:
      comp = <Placeholder text='Placeholder for Scheduling' />;
      activeText = drawerTabs.scheduling;
      break;
    case drawerTabs.revenueCycle:
      comp = <Placeholder text='Placeholder for Revenue Cycle' />;
      activeText = drawerTabs.revenueCycle;
      break;
    case drawerTabs.about:
    default:
      comp = (
        <AboutPractice
          practiceData={activePractice}
          reloadPracticeData={handleReloadPracticeData}
        />
      );
      activeText = drawerTabs.about;
  }

  if (props.shouldReloadPracticeData) {
    loadPracticeData();
  }

  return (
    <div style={{ marginTop: '40px' }}>
      <div className={css(styles.splitPanel)}>
        {/* Panel Drawer */}
        <div className={css(styles.drawer, !isDrawerOpen && styles.hideDrawer)}>
          {/* Drawer header (Mini user card) */}
          <div
            className={css(styles.drawerHeader)}
            onClick={handleOnClickMiniUserCard}
          >
            {/* Header icon */}
            <div className={css(styles.drawerHeaderIconContainer)}>
              {activePractice.avatarImageUrl
                ? <img
                    src={activePractice.avatarImageUrl}
                    alt="avatar"
                    style={{
                      width: 'auto',
                      height: '28px',
                      maxHeight: '28px',
                      borderRadius: '4px'
                    }}
                  />
                : <i className={`fa fa-hospital-o ${css(styles.drawerHeaderIcon)}`} />
              }
            </div>
            {/* Header text */}
            <div className={css(styles.drawerHeaderTextContainer)}>
              <div className={css(styles.drawerHeaderText)}>
                {`${activePractice.displayName ? activePractice.displayName : 'N/A'}`}
              </div>
              <div className={css(styles.drawerHeaderTextSub)}>
                {`@${activePractice.userName ? activePractice.userName : 'n/a'}`}
              </div>
            </div>
          </div>
          {/* Drawer tabs */}
          <div className={css(styles.drawerTabsContainer)}>
            {/* Entity Admin Privileges */}
            <div className={css(styles.drawerAccordian)}>
              {/* Providers */}
              <div
                className={`noselect drawer-menu-item ${css(styles.menuItem, activeComponent === drawerTabs.providers && styles.activeDrawerMenuItem)}`}
                onClick={() => { setActiveComponent(drawerTabs.providers); }}
              >
                {/* Icon */}
                <div className='icon'>
                  <i className='fa fa-users' />
                </div>
                <div
                  className="text"
                  style={{ fontSize: '1rem', lineHeight: '14px' }}
                >
                  Providers/Admin Users
                </div>
              </div>
              {/* Facilities */}
              <div
                className={`noselect drawer-menu-item ${css(styles.menuItem, activeComponent === drawerTabs.facilities && styles.activeDrawerMenuItem)}`}
                onClick={() => { setActiveComponent(drawerTabs.facilities); }}
              >
                {/* Icon */}
                <div className='icon'>
                  <i className='fa fa-hospital-o' />
                </div>
                <div
                  className="text"
                  style={{ fontSize: '1rem', lineHeight: '14px' }}
                >
                  Facilities
                </div>
              </div>
              {/* Insurance */}
              <div
                className={`noselect drawer-menu-item ${css(styles.menuItem, activeComponent === drawerTabs.insurance && styles.activeDrawerMenuItem)}`}
                onClick={() => { setActiveComponent(drawerTabs.insurance); }}
              >
                {/* Icon */}
                <div className='icon'>
                  <i className='fa fa-building' />
                </div>
                <div
                  className="text"
                  style={{ fontSize: '1rem', lineHeight: '14px' }}
                >
                  Insurance
                </div>
              </div>
              {/* Revenue Cycle Management */}
              <div
                className={`noselect drawer-menu-item ${css(styles.menuItem, activeComponent === drawerTabs.revenueCycle && styles.activeDrawerMenuItem)}`}
                onClick={() => { setActiveComponent(drawerTabs.revenueCycle); }}
              >
                {/* Icon */}
                <div className='icon'>
                  <i className='fa fa-building' />
                </div>
                <div
                  className="text"
                  style={{ fontSize: '1rem', lineHeight: '14px' }}
                >
                  Revenue Cycle Management
                </div>
              </div>
              {/* Scheduling */}
              <div
                className={`noselect drawer-menu-item ${css(styles.menuItem, activeComponent === drawerTabs.scheduling && styles.activeDrawerMenuItem)}`}
                onClick={() => { setActiveComponent(drawerTabs.scheduling); }}
              >
                {/* Icon */}
                <div className='icon'>
                  <i className='fa fa-building' />
                </div>
                <div
                  className="text"
                  style={{ fontSize: '1rem', lineHeight: '14px' }}
                >
                  Scheduling
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Panel details */}
        <div className={css(styles.detail, !isDrawerOpen && styles.detailExapnd)}>
          <div className={css(styles.detailHeader)}>
            <div>{activeText}</div>
            <div
              onClick={() => {
                if (props.usr.isMasterAdmin) {
                  history.push('/admin/practices');
                } else {
                  history.push('/user');
                }
              }}
            >
              <i className='fa fa-times-circle' style={{ fontSize: '20px' }} />
            </div>
          </div>
          {/* Active component */}
          {comp}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({ user }) {
  return { ...user };
}

export default connect(mapStateToProps, {
  togglePracticeDataReloadFlag
})(PracticeDashboard);
