import React from 'react';

const PaymentMethods = (props) => {
  return (
    <div>
      <h1>Payment Methods Placeholder</h1>
    </div>
  );
}

export default PaymentMethods;
